import React from "react";
import { useState, useEffect, useContext } from "react";
import JobHeadBar from "./JobHeadBar";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  orderBy,
  Timestamp,
  limit,
  startAfter,
  startAt,
  where,
  onSnapshot,
  setDoc,
  addDoc,
} from "firebase/firestore";
import app from "../../firebase";
import PostTable from "./PostTable";
import { useAuthContext } from "../../context/auth";
import moment from "moment";
import { dataContext } from "../../context/data";
import "../../styles/postList.scss";
import { message } from "antd";
import { async } from "@firebase/util";

const postListColumn = [
  {
    title: "JOB ID",
    dataIndex: "job_id",
  },
  {
    title: "DESIGNATION",
    dataIndex: "designation",
  },
  {
    title: "DESCRIPTION",
    dataIndex: "description",
  },
  {
    title: "POSTED BY",
    dataIndex: "user_id",
  },
  {
    title: "PRIMARY EMPLOYER",
    dataIndex: "primary_employer",
  },
  {
    title: "LOCATION",
    dataIndex: "district",
  },
  {
    title: "SALARY",
    dataIndex: "salary_from",
  },
];
const postListPrimaryColumn = [
  {
    title: "JOB ID",
    dataIndex: "job_id",
  },
  {
    title: "DESIGNATION",
    dataIndex: "designation",
  },
  {
    title: "DESCRIPTION",
    dataIndex: "description",
  },
  {
    title: "POSTED BY",
    dataIndex: "user_id",
  },
  {
    title: "LOCATION",
    dataIndex: "district",
  },
  {
    title: "SALARY",
    dataIndex: "salary_from",
  },
];
const postListContractColumn = [
  {
    title: "JOB ID",
    dataIndex: "job_id",
  },
  {
    title: "DESIGNATION",
    dataIndex: "designation",
  },
  {
    title: "DESCRIPTION",
    dataIndex: "description",
  },
  {
    title: "PRIMARY EMPLOYER",
    dataIndex: "primary_employer",
  },
  {
    title: "LOCATION",
    dataIndex: "district",
  },
  {
    title: "SALARY",
    dataIndex: "salary_from",
  },
];
const db = getFirestore(app);

function PostList() {
  const [data, setData] = useState([]);
  const auth = useAuthContext();
  const { jobData, setJobData } = useContext(dataContext);

  useEffect(() => {
    setJobData((pre) => {
      return {
        ...pre,
        DblastFetch: undefined,
        page: 0,
        ResponsePage: 0,
        responseLastFetch: undefined,
        rolesLastFetch: undefined,
        rolesPage: 0,
        rolesFilterValue: undefined,
      };
    });
    getCatagory("primary_employers");
  }, []);

  const mapData = (d, itm, emp = "", con = "") => {
    let qualification = d.qualification;
    let qual = qualification.toString();
    let StartDate = new Timestamp(
      d.start_date.seconds | d.start_date._seconds,
      d.start_date.nanoseconds | d.start_date._nanoseconds
    ).toDate();

    return {
      key: itm.id,
      job_id: d.job_id,
      data: d,
      designation: d.designation,
      startDate: moment(StartDate).format("DD-MM-YYYY"),
      district: `${d.district},
      ${d.state}`,
      description: d.description,
      experience: `₹${d.experience_from} - ${d.experience_to}/${d.experience_per_period}`,
      salary_from: `₹${d.salary_from} - ${d.salary_to}/${d.salary_per_period}`,
      qualification: qual,
      primary_employer: emp ? emp : "--",
      user_id: con ? con : "--",
      formalities: d.joining_formalities,
    };
  };

  const getCatagory = async (type, sVal = "") => {
    let d = [];
    try {
      let q = query(collection(db, type), where("name", ">=", sVal));
      const res = await getDocs(q);
      res.forEach((itm) => d.push({ ...itm.data(), id: itm.id }));
      return d;
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  useEffect(async () => {
    message.loading({ content: "Loading...", duration: 0, key: "Job post" });
    const temp = [];
    var employer, contractor;
    let filterContent = jobData.filterValue;
    let fieldName = filterContent.dataIndex;
    let count = 0;

    if (filterContent?.value !== undefined) {
      switch (filterContent.dataIndex) {
        case "user_id":
          fieldName = "contractors";
          contractor = await getCatagory("contractors", filterContent.value);
          employer = await getCatagory("primary_employers");
          break;
        case "primary_employer":
          fieldName = "primary_employers";
          employer = await getCatagory(
            "primary_employers",
            filterContent.value
          );
          contractor = await getCatagory("contractors");
          break;
        default:
          contractor = await getCatagory("contractors");
          employer = await getCatagory("primary_employers");
      }
    } else {
      contractor = await getCatagory("contractors");
      employer = await getCatagory("primary_employers");
    }

    async function spectraJobPost() {
      try {
        let q;

        if (
          filterContent &&
          filterContent.value !== "" &&
          jobData.DblastFetch === undefined &&
          !["primary_employers", "contractors"].includes(fieldName)
        ) {
          q = query(
            collection(db, "job_posts"),
            where("active_status", "==", true),
            where(
              filterContent.dataIndex,
              filterContent.dataIndex === "job_id" ||
                filterContent.dataIndex === "salary_from"
                ? "=="
                : ">=",
              filterContent.dataIndex === "salary_from"
                ? parseInt(filterContent.value)
                : filterContent.value
            ),
            limit(15)
          );
        } else if (
          filterContent &&
          filterContent.value !== "" &&
          jobData.DblastFetch !== undefined &&
          !["primary_employers", "contractors"].includes(fieldName)
        ) {
          q = query(
            collection(db, "job_posts"),
            where("active_status", "==", true),
            where(
              filterContent.dataIndex,
              filterContent.dataIndex === "job_id" ||
                filterContent.dataIndex === "salary_from"
                ? "=="
                : ">=",
              filterContent.dataIndex === "salary_from"
                ? parseInt(filterContent.value)
                : filterContent.value
            ),
            startAfter(jobData.DblastFetch),
            limit(15)
          );
        } else if (jobData.DblastFetch) {
          q = query(
            collection(db, "job_posts"),
            where("active_status", "==", true),
            orderBy("created_at", "desc"),
            startAfter(jobData.DblastFetch),
            limit(10)
          );
        } else {
          q = query(
            collection(db, "job_posts"),
            where("active_status", "==", true),
            orderBy("created_at", "desc"),
            limit(
              ["primary_employers", "contractors"].includes(fieldName) ? 50 : 15
            )
          );
        }

        const querySnapshot = await getDocs(q);
        const temp = []; // Initialize temp array to store results
        let count = 0;

        querySnapshot.forEach((itm) => {
          let d = itm.data(),
            postedFor,
            postedBy;

          // Get employer name
          postedFor = employer.filter((e) => e.id === d.primary_employer);

          // Get contractor name
          if (d.user_id === "Talntworx Technologies ") {
            postedBy = [{ name: "Talntworx Technologies " }];
          } else {
            postedBy = contractor.filter((c) => c.id === d.user_id);
            if (postedBy.length === 0) {
              postedBy = employer.filter((e) => e.id === d.primary_employer);
            }
          }

          // For primary_employers or contractors filter
          if (["primary_employers", "contractors"].includes(fieldName)) {
            const searchValue = filterContent.value.toLowerCase(); // Convert search term to lowercase

            if (
              (fieldName === "primary_employers" &&
                postedFor[0]?.name.toLowerCase().indexOf(searchValue) > -1) ||
              (fieldName === "contractors" &&
                postedBy[0]?.name.toLowerCase().indexOf(searchValue) > -1)
            ) {
              if (
                (fieldName === "primary_employers" && postedFor[0]?.name) ||
                (fieldName === "contractors" && postedBy[0]?.name)
              ) {
                temp.push(
                  mapData(d, itm, postedFor[0]?.name, postedBy[0]?.name)
                );
              }
            }
          } else {
            if (filterContent.dataIndex === "designation") {

              if (
                d.designation
                  .toLowerCase()
                  .includes(filterContent.value.toLowerCase())
              ) {
                temp.push(
                  mapData(d, itm, postedFor[0]?.name, postedBy[0]?.name)
                );
              }
            } else if (filterContent.dataIndex === "description") {
              if (
                d.description
                  .toLowerCase()
                  .includes(filterContent.value.toLowerCase())
              ) {
                temp.push(
                  mapData(d, itm, postedFor[0]?.name, postedBy[0]?.name)
                );
              }
            } else if (filterContent.dataIndex === "district") {
              // Check if the designation contains the search term (case insensitive)
              if (
                d.district
                  .toLowerCase()
                  .includes(filterContent.value.toLowerCase())
              ) {
                temp.push(
                  mapData(d, itm, postedFor[0]?.name, postedBy[0]?.name)
                );
              }
            } else if (filterContent.dataIndex === "salary_from") {
              const salary = d["salary_from"].toString();
              const searchValue = filterContent.value.toString().trim();

              console.log("Salary:", salary);
              console.log("Search Value:", searchValue);
              // Check if the searchValue is not empty and if the salary starts with the searchValue
              if (searchValue !== "" && salary.startsWith(searchValue)) {
                temp.push(
                  mapData(d, itm, postedFor[0]?.name, postedBy[0]?.name)
                );
              }
            } else {
              // General mapping if not searching by designation
              temp.push(mapData(d, itm, postedFor[0]?.name, postedBy[0]?.name));
            }
          }

          // Handle pagination
          if (querySnapshot.docs.length - 1 === count++) {
            message.destroy("Job post");
            setData((pre) => {
              if (jobData.page > 0) {
                return [...pre, ...temp];
              } else return temp;
            });
            setJobData((pre) => {
              return { ...pre, DblastFetch: itm };
            });
          }
        });

        // If no documents were found
        if (querySnapshot.docs.length <= 0) {
          message.destroy("Job post");
          setData((pre) => {
            if (jobData.page > 0) {
              return [...pre];
            } else return [];
          });
        }
      } catch (err) {
        console.log(err);
        message.destroy("Job post");
      }
      message.destroy("Job post");
    }

    async function contractJobPost() {
      try {
        let q;

        if (
          filterContent &&
          filterContent.value !== "" &&
          jobData.DblastFetch == undefined &&
          !["primary_employers", "contractors"].includes(fieldName)
        ) {
          q = query(
            collection(db, "job_posts"),
            where("active_status", "==", true),
            where("user_id", "==", localStorage.getItem("contractor_id")),
            where(
              filterContent.dataIndex,
              filterContent.dataIndex == "job_id" ||
                filterContent.dataIndex == "salary_from"
                ? "=="
                : ">=",
              filterContent.dataIndex == "salary_from"
                ? parseInt(filterContent.value)
                : filterContent.value
            ),
            limit(15)
          );
        } else if (
          filterContent &&
          filterContent.value !== "" &&
          jobData.DblastFetch !== undefined &&
          !["primary_employers", "contractors"].includes(fieldName)
        ) {
          q = query(
            collection(db, "job_posts"),
            where("active_status", "==", true),
            where("user_id", "==", localStorage.getItem("contractor_id")),
            where(
              filterContent.dataIndex,
              filterContent.dataIndex == "job_id" ||
                filterContent.dataIndex == "salary_from"
                ? "=="
                : ">=",
              filterContent.dataIndex == "salary_from"
                ? parseInt(filterContent.value)
                : filterContent.value
            ),
            startAfter(jobData.DblastFetch),
            limit(15)
          );
        } else if (jobData.DblastFetch) {
          q = query(
            collection(db, "job_posts"),
            where("active_status", "==", true),
            where("user_id", "==", localStorage.getItem("contractor_id")),
            orderBy("created_at", "desc"),
            startAfter(jobData.DblastFetch),
            limit(10)
          );
        } else if (auth.isContract) {
          q = query(
            collection(db, "job_posts"),
            where("active_status", "==", true),
            where("user_id", "==", localStorage.getItem("contractor_id")),
            orderBy("created_at", "desc"),
            limit(10)
          );
        }

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((itm) => {
          let d = itm.data(),
            postedFor,
            postedBy;
          // console.log("d",itm.id)

          // to find employer name
          postedFor = employer.filter((e) => e.id == d.primary_employer);
          //to find contractor name
          postedBy = contractor.filter((c) => c.id == d.user_id);

          if (postedBy.length == 0) {
            postedBy = employer.filter((e) => e.id == d.primary_employer);
          }

          if (["primary_employers"].includes(fieldName)) {
            if (
              "primary_employers" === fieldName &&
              postedFor[0]?.name.indexOf(filterContent.value) > -1
            )
              if (fieldName === "primary_employers" && postedFor[0]?.name) {
                temp.push(mapData(d, itm, postedFor[0]?.name));
              }
          } else {
            temp.push(mapData(d, itm, postedFor[0]?.name));
          }

          if (querySnapshot.docs.length - 1 === count++) {
            message.destroy("Job post");
            setData((pre) => {
              if (jobData.page > 0) {
                return [...pre, ...temp];
              } else return temp;
            });
            setJobData((pre) => {
              return { ...pre, DblastFetch: itm };
            });
          }
        });
        if (querySnapshot.docs.length <= 0) {
          message.destroy("Job post");
          setData((pre) => {
            if (jobData.page > 0) {
              return [...pre];
            } else return [];
          });
        }
      } catch (err) {
        console.log(err);
        message.destroy("Job post");
      }
      message.destroy("Job post");
    }

    async function primaryJobPost() {
      try {
        let q;

        if (
          filterContent &&
          filterContent.value !== "" &&
          jobData.DblastFetch == undefined &&
          !["primary_employers", "contractors"].includes(fieldName)
        ) {
          q = query(
            collection(db, "job_posts"),
            where("active_status", "==", true),
            where(
              "primary_employer",
              "==",
              localStorage.getItem("primary_employer_id")
            ),
            where(
              filterContent.dataIndex,
              filterContent.dataIndex == "job_id" ||
                filterContent.dataIndex == "salary_from"
                ? "=="
                : ">=",
              filterContent.dataIndex == "salary_from"
                ? parseInt(filterContent.value)
                : filterContent.value
            ),
            limit(15)
          );
        } else if (
          filterContent &&
          filterContent.value !== "" &&
          jobData.DblastFetch !== undefined &&
          !["primary_employers", "contractors"].includes(fieldName)
        ) {
          q = query(
            collection(db, "job_posts"),
            where("active_status", "==", true),
            where(
              "primary_employer",
              "==",
              localStorage.getItem("primary_employer_id")
            ),
            where(
              filterContent.dataIndex,
              filterContent.dataIndex == "job_id" ||
                filterContent.dataIndex == "salary_from"
                ? "=="
                : ">=",
              filterContent.dataIndex == "salary_from"
                ? parseInt(filterContent.value)
                : filterContent.value
            ),
            startAfter(jobData.DblastFetch),
            limit(15)
          );
        } else if (jobData.DblastFetch) {
          q = query(
            collection(db, "job_posts"),
            where("active_status", "==", true),
            where(
              "primary_employer",
              "==",
              localStorage.getItem("primary_employer_id")
            ),
            orderBy("created_at", "desc"),
            startAfter(jobData.DblastFetch),
            limit(10)
          );
        } else if (auth.isPrimary) {
          q = query(
            collection(db, "job_posts"),
            where("active_status", "==", true),
            where(
              "primary_employer",
              "==",
              localStorage.getItem("primary_employer_id")
            ),
            orderBy("created_at", "desc"),
            limit(10)
          );
        }

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((itm) => {
          let d = itm.data(),
            postedFor,
            postedBy;
          // console.log("d",d)

          // to find employer name
          postedFor = employer.filter((e) => e.id == d.primary_employer);
          //to find contractor name
          postedBy = contractor.filter((c) => c.id == d.user_id);

          if (postedBy.length == 0) {
            postedBy = employer.filter((e) => e.id == d.primary_employer);
          }

          if (["contractors"].includes(fieldName)) {
            if (
              "contractors" === fieldName &&
              postedBy[0]?.name.indexOf(filterContent.value) > -1
            )
              if (fieldName === "contractors" && postedBy[0]?.name) {
                temp.push(mapData(d, itm, "", postedBy[0]?.name));
              }
          } else {
            temp.push(mapData(d, itm, "", postedBy[0]?.name));
          }

          if (querySnapshot.docs.length - 1 === count++) {
            message.destroy("Job post");
            setData((pre) => {
              if (jobData.page > 0) {
                return [...pre, ...temp];
              } else return temp;
            });
            setJobData((pre) => {
              return { ...pre, DblastFetch: itm };
            });
          }
        });
        if (querySnapshot.docs.length <= 0) {
          message.destroy("Job post");
          setData((pre) => {
            if (jobData.page > 0) {
              return [...pre];
            } else return [];
          });
        }
      } catch (err) {
        console.log(err);
        message.destroy("Job post");
      }
      message.destroy("Job post");
    }

    if (auth.isContract) {
      setTimeout(() => {
        contractJobPost();
      }, 0);
    } else if (auth.isPrimary) {
      setTimeout(() => {
        primaryJobPost();
      }, 0);
    } else if (auth.isSpectra) {
      setTimeout(() => {
        spectraJobPost();
      }, 0);
    }
  }, [
    jobData.postDelete,
    jobData.postSuccess,
    jobData.page,
    jobData.filterValue,
  ]);

  // console.log('state', state);
  // console.log('district', district);
  return (
    <div>
      <JobHeadBar
        column={
          auth.isSpectra
            ? postListColumn
            : auth.isPrimary
            ? postListPrimaryColumn
            : postListContractColumn
        }
        type="post"
      />
      <PostTable
        data={data}
        jobDataColumn={
          auth.isSpectra
            ? postListColumn
            : auth.isPrimary
            ? postListPrimaryColumn
            : postListContractColumn
        }
      />
    </div>
  );
}

export default PostList;

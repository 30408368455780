import { createContext, useContext } from "react";
import { useProvideAuth } from "../hooks/auth";

const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
    const auth = useProvideAuth();
    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
export const useAuthContext = () => useContext(AuthContext);
import React from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import { dataContext } from '../context/data';
import TopTab from './joblist/TopTab';
import '../styles/jobList.scss'
import { Switch, Route } from 'react-router-dom';
import JobDetailBase from './joblist/JobDetailBase';


function JobList({userPath}) {

  
  const { navList, setNavList, setDashboardData, setJobData} = useContext(dataContext);

  useEffect(() => {
    setNavList(pre => { return { ...pre, dashBoard:false, userList:false, jobPost: true, requests: false } })
    setJobData(pre => {return {...pre, DblastFetch: undefined, page:0, editUser: undefined}})
    // setDashboardData(pre => {return {...pre, DblastFetch: undefined, page:0}})
  },[]);
  


  return (
    <div className='jobList'>
      <Switch>
        <Route path={"/"+userPath+"/jobpost"} exact>
            <div>
                <TopTab />
            </div>
        </Route>
        <Route path={"/"+userPath+"/jobpost/user" }>
            <JobDetailBase userPath={userPath}/>
        </Route>
      </Switch>
    </div>
  )
}

export default JobList
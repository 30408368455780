import React, { useState } from "react";
import { Timestamp } from "@firebase/firestore";
import moment from "moment";
import { Input, DatePicker, Form } from "antd";

function Tab3({ ImgPopup, data, isEdit, setUserData }) {
  const [errorMessage, setErrorMessage] = useState({
    contractor: {
      contracter_name: "",
    },
    employer: {
      work_location: "",
    },
  });

  const initialDOB = data?.contractor[0]?.identity?.date_of_joining
    ? moment(new Date(data?.contractor[0]?.identity?.date_of_joining * 1000))
    : null;

  const initialPanDOB = data.identity?.pan?.dob
    ? moment(new Date(data.identity?.pan?.dob.seconds * 1000))
    : null;

  const [dob1, setDob] = useState(initialDOB ?? null);

  const [joinDate, setJoinDate] = useState(initialPanDOB ?? null);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const fieldName = e.target.getAttribute("data-fieldname"); // Access extra field
    // if (fieldName.startsWith("Pan")) {
    //   setUserData((prev) => ({
    //     ...prev,
    //     identity: {
    //       ...prev.identity,
    //       pan: {
    //         ...prev.identity.pan,
    //         [name]: value,
    //       },
    //     },
    //   }));
    //   setErrorMessage((prev) => ({
    //     ...prev,
    //     pan: {
    //       ...prev.pan,
    //       [name]: value ? "" : `Please Enter ${fieldName}`,
    //     },
    //   }));
    // } else if (fieldName.startsWith("Bank")) {
    //   setUserData((prev) => ({
    //     ...prev,
    //     bank: {
    //       ...prev.bank,
    //       [name]: value,
    //     },
    //   }));
    //   setErrorMessage((prev) => ({
    //     ...prev,
    //     bank: {
    //       ...prev.bank,
    //       [name]: value ? "" : `Please Enter ${fieldName}`,
    //     },
    //   }));
    // } else {
    //   setUserData((prev) => ({
    //     ...prev,
    //     identity: {
    //       ...prev.identity,
    //       aadhaar: {
    //         ...prev.identity.aadhaar,
    //         [name]: value,
    //       },
    //     },
    //   }));
    //   setErrorMessage((prev) => ({
    //     ...prev,
    //     aadhaar: {
    //       ...prev.aadhaar,
    //       [name]: value ? "" : `Please Enter ${fieldName}`,
    //     },
    //   }));
    // }
  };

  const handleDobChange = (date) => {
    const formattedDate = date ? date.toDate() : null;

    const formattedDate1 = date ? date.format("DD/MM/YYYY") : null;
    setDob(formattedDate1);
    setUserData((prev) => ({
      ...prev,
      contractor:
        prev.contractor && Array.isArray(prev.contractor) && prev.contractor[0]
          ? [
              {
                ...prev.contractor[0],
                date_of_joining: formattedDate,
              },
            ]
          : [],
    }));
  };

  const handlePanDobChange = (date) => {
    const formattedDate = date ? date.toDate() : null;
    setJoinDate(date);
    setUserData((prev) => ({
      ...prev,
      contractor:
        prev.contractor && Array.isArray(prev.contractor) && prev.contractor[0]
          ? [
              {
                ...prev.contractor[0],
                date_of_joining: formattedDate,
              },
            ]
          : [],
    }));
  };

  return (
    <>
      <div className="detailsContainer">
        <h4 className="cardDetailsHead">Contractor</h4>
        {data.contractor.map((c, i) => {
          const initialDOB = c.date_of_joining
            ? moment(new Date(c.date_of_joining * 1000))
            : null;
          return (
            <div key={i} className="innerContainer">
              <div className="fieldData">
                <div className="labelField">Contractor name</div>
                {!isEdit ? (
                  <div className="fieldDetail">
                    {c.contracter_name ? c.contracter_name : "N/A"}
                  </div>
                ) : (
                  <div>
                    <Input
                      style={{ width: "95%" }}
                      placeholder="Enter mobile number"
                      className="phoneNumberInput"
                      onChange={handleChange}
                      name="name"
                      data-fieldname="name"
                      value={c.contracter_name}
                    />
                    {errorMessage?.contractor.contracter_name && (
                      <div style={{ color: "red", marginTop: "8px" }}>
                        {errorMessage?.contractor.contracter_name}
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* <div className="fieldData">
                <div className="labelField">Date of Joining</div>
                {!isEdit ? (
                  <div className="fieldDetail">
                    {c.date_of_joining !== ""
                      ? moment(
                          new Timestamp(
                            c.date_of_joining?.seconds |
                              c.date_of_joining?._seconds,
                            c.date_of_joining?.nanoseconds |
                              c.date_of_joining?._nanoseconds
                          ).toDate()
                        ).format("DD/MM/YYYY")
                      : "N/A"}
                  </div>
                ) : (
                  <div>
                    <Form
                      initialValues={{
                        dateOfBirth: dob1 ?? initialDOB, // Set initial value for DatePicker here
                      }}
                    >
                      <Form.Item
                        name="dateOfBirth"
                        rules={[
                          {
                            required: true,
                            message: "Please select your date of birth!",
                          },
                        ]}
                      >
                        <DatePicker
                          format="YYYY-MM-DD"
                          style={{ width: "100%" }}
                          value={dob1} // Make DatePicker a controlled component
                          onChange={handleDobChange}
                        />
                      </Form.Item>
                    </Form>
                  </div>
                )}
              </div> */}
              <div className="fieldData">
  <div className="labelField">Date of Joining</div>
  {!isEdit ? (
    <div className="fieldDetail">
      {c.date_of_joining
        ? moment(
            new Date(
              c.date_of_joining.seconds * 1000 + 
              c.date_of_joining.nanoseconds / 1000000
            )
          ).format("DD/MM/YYYY")
        : "N/A"}
    </div>
  ) : (
    <div>
      <Form
        initialValues={{
          dateOfJoining: dob1 ?? moment(c.date_of_joining.toDate()), // Convert Firestore timestamp to moment for initial value
        }}
      >
        <Form.Item
          name="dateOfJoining"
          rules={[
            {
              required: true,
              message: "Please select your date of joining!",
            },
          ]}
        >
          <DatePicker
            format="YYYY-MM-DD"
            style={{ width: "100%" }}
            value={dob1 ? moment(dob1) : moment(c.date_of_joining.toDate())} // Ensure controlled DatePicker with moment
            onChange={handleDobChange}
          />
        </Form.Item>
      </Form>
    </div>
  )}
</div>

              <div className="fieldData">
                <div className="labelField">Date of Exit</div>
                <div className="fieldDetail">
                  {c.date_of_exit !== ""
                    ? moment(
                        new Timestamp(
                          c.date_of_exit?.seconds | c.date_of_exit?._seconds,
                          c.date_of_exit?.nanoseconds |
                            c.date_of_exit?._nanoseconds
                        ).toDate()
                      ).format("DD/MM/YYYY")
                    : "N/A"}
                </div>
              </div>
            </div>
          );
        })}
        {data.contractor.length === 0 && (
          <div className="innerContainer">
            <div className="fieldData">
              <div className="labelField">Contractor name</div>
              {!isEdit ? (
                <div className="fieldDetail">N/A</div>
              ) : (
                <div>
                  <Input
                    style={{ width: "95%" }}
                    placeholder="Enter Contractor Name"
                    className="phoneNumberInput"
                    onChange={handleChange}
                    name="pan_number"
                    data-fieldname="Pan Number"
                    // value={data.identity?.pan?.pan_number.toUpperCase() || ""}
                  />
                  {errorMessage.pan?.pan_number && (
                    <div style={{ color: "red", marginTop: "8px" }}>
                      {errorMessage.pan?.pan_number}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="fieldData">
              <div className="labelField">Date of Joining</div>
              {!isEdit ? (
                <div className="fieldDetail">N/A</div>
              ) : (
                <Form
                  initialValues={{
                    dateOfBirth: dob1 ?? initialPanDOB, // Set initial value for DatePicker here
                  }}
                >
                  <Form.Item
                    name="dateOfBirth"
                    rules={[
                      {
                        required: true,
                        message: "Please select your date of joining",
                      },
                    ]}
                  >
                    <DatePicker
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                      value={dob1} // Make DatePicker a controlled component
                      onChange={handleDobChange}
                      disabledDate={(current) =>
                        current && current > moment().endOf("day")
                      } // Disable future dates
                    />
                  </Form.Item>
                </Form>
              )}
            </div>
            <div className="fieldData">
              <div className="labelField">Date of Exit</div>
              {/* <div className="fieldDetail">N/A</div> */}
              {!isEdit ? (
                <div className="fieldDetail">N/A</div>
              ) : (
                <Form
                  initialValues={{
                    dateOfBirth: joinDate ?? initialPanDOB, // Set initial value for DatePicker here
                  }}
                >
                  <Form.Item
                    name="dateOfBirth"
                    rules={[
                      {
                        required: true,
                        message: "Please select your date of exit",
                      },
                    ]}
                  >
                    <DatePicker
                      format="YYYY-MM-DD"
                      style={{ width: "100%" }}
                      value={joinDate} // Make DatePicker a controlled component
                      onChange={handlePanDobChange}
                      disabledDate={(current) =>
                        current && current > moment().endOf("day")
                      } // Disable future dates
                    />
                  </Form.Item>
                </Form>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="detailsContainer">
        <h4 className="cardDetailsHead">Employer</h4>
        {data.employer.map((e, i) => (
          <div key={i} className="innerContainer">
            <div className="fieldData">
              <div className="labelField">Employer name</div>
              <div className="fieldDetail">
                {e.employer_name ? e.employer_name : "N/A"}
              </div>
            </div>
            <div className="fieldData">
              <div className="labelField">Date of Joining</div>
              <div className="fieldDetail">
                {e.date_of_joining !== ""
                  ? moment(
                      new Timestamp(
                        e.date_of_joining?.seconds |
                          e.date_of_joining?._seconds,
                        e.date_of_joining?.nanoseconds |
                          e.date_of_joining?._nanoseconds
                      ).toDate()
                    ).format("DD/MM/YYYY")
                  : "N/A"}
              </div>
            </div>
            <div className="fieldData">
              <div className="labelField">Date of Exit</div>
              <div className="fieldDetail">
                {e.date_of_exit !== ""
                  ? moment(
                      new Timestamp(
                        e.date_of_exit?.seconds | e.date_of_exit?.seconds,
                        e.date_of_exit?.nanoseconds |
                          e.date_of_exit?.nanoseconds
                      ).toDate()
                    ).format("DD/MM/YYYY")
                  : "N/A"}
              </div>
            </div>
            <div className="fieldData">
              <div className="labelField">Work location</div>
              <div className="fieldDetail">
                {e.work_location ? e.work_location : "N/A"}
              </div>
            </div>
            <div className="fieldData">
              <div className="labelField">PF</div>
              <div className="fieldDetail">{e.pf ? e.pf : "N/A"}</div>
            </div>
            <div className="fieldData">
              <div className="labelField">ESIC</div>
              <div className="fieldDetail">{e.esic ? e.esic : "N/A"}</div>
            </div>
          </div>
        ))}
        {data.employer.length === 0 && (
          <div className="innerContainer">
            <div className="fieldData">
              <div className="labelField">Employer name</div>
              <div className="fieldDetail">N/A</div>
            </div>
            <div className="fieldData">
              <div className="labelField">Date of Joining</div>
              <div className="fieldDetail">N/A</div>
            </div>
            <div className="fieldData">
              <div className="labelField">Date of Exit</div>
              <div className="fieldDetail">N/A</div>
            </div>
            <div className="fieldData">
              <div className="labelField">Work location</div>
              <div className="fieldDetail">N/A</div>
            </div>
            <div className="fieldData">
              <div className="labelField">PF</div>
              <div className="fieldDetail">N/A</div>
            </div>
            <div className="fieldData">
              <div className="labelField">ESIC</div>
              <div className="fieldDetail">N/A</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Tab3;

import React from "react";
import {
  Input,
  Select,
  Button,
  Modal,
  Tag,
  DatePicker,
  InputNumber,
  Form,
  message,
} from "antd";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  orderBy,
} from "firebase/firestore";
import { useState, useContext } from "react";
import "../../styles/JobHeadBar.scss";
import { useEffect } from "react";
import app from "../../firebase";
import { dataContext } from "../../context/data";
import PostForm from "./PostForm";

const db = getFirestore(app);

function JobHeadBar({ column, type }) {
  const [searchSelection, SetSearchSelection] = useState("all");
  const [searchValue, SetSearchValue] = useState("");
  const { jobData, setJobData } = useContext(dataContext);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState();
  const { Option } = Select;

  useEffect(() => {
    if (jobData.activeTabIndex == 1) {
      setJobData((pre) => {
        return { ...pre, filterValue: searchValue };
      });
      setJobData((pre) => {
        return { ...pre, DblastFetch: undefined, page: 0 };
      });
    } else {
      setJobData((pre) => {
        return { ...pre, rolesFilterValue: searchValue };
      });
      setJobData((pre) => {
        return { ...pre, rolesLastFetch: undefined, rolesPage: 0 };
      });
    }
  }, [searchValue]);

  useEffect(async () => {
    const States = [];
    const allValues = {};
    try {
      let q = query(collection(db, "state_city_list"), orderBy("state_name"));
      const response = await getDocs(q);
      response.forEach((itm) => {
        let stateName = itm.data().state_name;
        let district = itm.data().districts;
        States.push(stateName);
        allValues[stateName] = district;
      });
    } catch (err) {
      console.log(err);
    }
    setState(States);
    setDistrict(allValues);
  }, []);

  const handleSearchValue = (e) => {
    SetSearchValue({
      dataIndex: searchSelection,
      value: e.target.value.toUpperCase(),
    });
  };
  const HandleSearchSelection = (value) => {
    SetSearchSelection(value);
  };

  return (
    <div className="jobHeadBar">
      <div className="jobHeadBarLeft">
        <Select
          size="large"
          dropdownMatchSelectWidth={false}
          onChange={(value) => HandleSearchSelection(value)}
        >
          {/* <Option value={"all"}>ALL</Option> */}
          {column.map((val) => {
            return <Option value={val.dataIndex}>{val.title}</Option>;
          })}
        </Select>
        <Input
          placeholder="Search..."
          onChange={(e) => handleSearchValue(e)}
          width="10px"
        />
      </div>
      {state && district && (
        <PostForm type={type} state={state} district={district} role="add" />
      )}
    </div>
  );
}

export default JobHeadBar;

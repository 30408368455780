import React, { useContext, useEffect, useState } from "react";
import "../../../styles/Panel.scss";
import filterIcon from "../../../assets/icons/filter.svg";
import { dataContext } from "../../../context/data";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  where,
  getFirestore,
  orderBy,
  query,
} from "firebase/firestore";
import app from "../../../firebase";
import { useAuthContext } from "../../../context/auth";
import {
  Select,
  Input,
  DatePicker,
  Modal,
  Form,
  Button,
  message,
  Badge,
} from "antd";
import moment from "moment";
const db = getFirestore(app);
const { Search } = Input;

function UserPanelSearch({
  talents,
  contractor,
  userType,
  columnTalent,
  columns,
  selectedColumn,
  totalTalents,
  contractorColumns,
  // primaryemployerArr,
  // contractorArr
}) {
  const { userData, setUserData } = useContext(dataContext);
  const [selectedField, setSelectedField] = useState("all");
  const [filterValue, setFilterValue] = useState([]);
  const { Option } = Select;
  const auth = useAuthContext();
  const userContext = useContext(dataContext);
  const [searchSelectOption, setSearchSelectOption] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [stateName, setStateName] = useState("");
  const [qualification, setQualification] = useState([]);
  const [allowClear, setAllowClear] = useState(false);
  const [filterBadge, setFilterBadge] = useState(false);
  const [filterText, setFilterText] = useState({
    dataIndex: "all",
    value: "",
  });
  const [contractorArr, setContractorArr] = useState([]);
  const [primaryemployerArr, setPrimaryemployeryArr] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      Object.keys(userContext.userData.filterQuery).length > 0 ||
      Object.keys(userContext.dashboardData.filterQuery).length > 0
    ) {
      setFilterBadge(true);
    } else {
      setFilterBadge(false);
    }
  }, [userContext.userData.filterQuery, userContext.dashboardData.filterQuery]);

  useEffect(() => {
    var newArray = columnTalent.filter(function (el) {
      return el.dataIndex == selectedField;
    });
    let array = [];
    if (newArray[0]?.filter == "dropDown") {
      {
        newArray[0].dropDownList.map((itm) => {
          array.push(itm);
        });
      }
    }

    setSearchSelectOption(array);
  }, [selectedField]);

  useEffect(() => {}, [filterValue]);

  useEffect(async () => {
    const docRef = doc(db, "app_data_static", "talent_user_static_data");
    const docSnap = await getDoc(docRef);
    setQualification(docSnap.data().education_level);
  }, []);
  useEffect(async () => {
    const States = [];
    const allValues = {};
    try {
      let q = query(collection(db, "state_city_list"), orderBy("state_name"));
      const response = await getDocs(q);
      response.forEach((itm) => {
        let stateName = itm.data().state_name;
        let stateId = itm.id;
        // let district = itm.data().districts;
        States.push({ stateName, id: stateId });
        // allValues[stateName] = district;
      });
    } catch (err) {
      console.log(err);
    }
    setState(States);
  }, []);

  const handleSearchValueUpdate = (val) => {
    console.log(val);
    setFilterText((pre) => {
      return { ...pre, value: val };
    });
    if (totalTalents) {
      userContext.setDashboardData((pre) => {
        return { ...pre, lastfetch: undefined, page: 0 };
      });
    } else {
      userContext.setUserData((pre) => {
        return { ...pre, lastfetch: undefined, page: 0 };
      });
    }

    if (totalTalents) {
      if (val) {
        userContext.setDashboardData((pre) => {
          if (val === "+91" || val === "")
            return { ...pre, totalTalentFilterValue: [], totalTalentPage: 0 };
          else
            return {
              ...pre,
              totalTalentFilterValue: [
                {
                  sval:
                    val == null
                      ? ""
                      : selectedField == "mnum"
                      ? `+91${val}`
                      : val,
                  dataIndex: selectedField,
                },
              ],
              totalTalentPage: 0,
            };
        });
      } else {
        userContext.setDashboardData((pre) => {
          return { ...pre, totalTalentFilterValue: [], totalTalentPage: 0 };
        });
      }
    } else {
      if (val) {
        userContext.setUserData((pre) => {
          if (val === "+91" || val === "")
            return { ...pre, filterValue: [], page: 0 };
          else
            return {
              ...pre,
              filterValue: [
                {
                  sval:
                    val == null
                      ? ""
                      : selectedField == "mnum"
                      ? `+91${val}`
                      : val,
                  dataIndex: selectedField,
                },
              ],
              page: 0,
            };
        });
      } else {
        userContext.setUserData((pre) => {
          return { ...pre, filterValue: [], page: 0 };
        });
      }
    }
  };
  const handlefilterModal = () => {
    setFilterText({
      dataIndex: "all",
      value: "",
    });
    setUserData((pre) => {
      return {
        ...pre,
        filterMode: !pre.filterMode,
        filterValue: [],
        lastfetch: undefined,
        page: 0,
      };
    });
    setFilterModal(true);
  };
  // const handleStateChanged = async (val) => {
  //   const docRef = doc(db, "state_city_list", val);
  //   const docSnap = await getDoc(docRef);
  //   setDistrict(docSnap.data().districts);
  //   setStateName(docSnap.data().state_name);
  // };
  const handleStateChanged = async (val) => {
    if (!val) {
      console.error("No value passed to handleStateChanged");
      return;
    }

    const docRef = doc(db, "state_city_list", val);
    try {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setDistrict(data.districts || []); 
        setStateName(data.state_name || "");
      } else {
        console.error("No such document in Firestore!");
        setDistrict([]);
        setStateName("");
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  const handleSelect = (value) => {
    setFilterText((pre) => {
      return { ...pre, dataIndex: value, value: "" };
    });

    // if(!["status", "handicapped", "bGroup", "gender"].includes(value)){
    //   if(totalTalents){
    //     userContext.setDashboardData((pre) => {
    //       return { ...pre, totalTalentFilterValue : [{ sval: "", dataIndex: value }], totalTalentPage:0 };
    //     });
    //   }else{
    //     userContext.setUserData((pre) => {
    //       return { ...pre, filterValue : [{ sval: "", dataIndex: value }], page:0 };
    //     });
    //   }
    // }

    if (value === "all") {
      setUserData((pre) => {
        return { ...pre, filterValue: [], page: 0 };
      });
      setAllowClear(true);
      setSelectedField(value);
    } else {
      setSelectedField(value);
    }
  };

  const handleBName = (val) => {
    userContext.setUserData((pre) => {
      return { ...pre, lastfetch: undefined, page: 0 };
    });
    console.log("sval", val, typeof val);
    let value = [
      {
        sval: val,
        dataIndex: selectedField,
      },
    ];
    userContext.setUserData((pre) => {
      return { ...pre, value };
    });
  };
  const [form] = Form.useForm();
  const onFinish = async (val) => {
    console.log("this is value", val);
    let paramsValue = {
      bool: {
        must: [],
      },
    };

    const user_id = localStorage.getItem("uid");
    const isPrimary = auth.isPrimary;
    const isContract = auth.isContract;
    const isSpectra = auth.isSpectra;

    if (isContract) {
      const snap = await getDoc(doc(db, `contractor_users`, user_id));
      paramsValue.bool.must.push({
        match: {
          "status.current_contractor_id": snap.data().personal.contractor_id,
        },
      });
    } else if (isPrimary) {
      const snap = await getDoc(doc(db, `primary_employer_users`, user_id));
      paramsValue.bool.must.push({
        match: {
          "status.current_primary_employer_id":
            snap.data().personal.contractor_id,
        },
      });
      console.log(
        "snap.data().personal.contractor_id",
        snap.data().personal.contractor_id
      );
    }

    function timestampValueCal(val) {
      let getTimestampValue = moment()
        .subtract(val, "years")
        .toDate()
        .getTime()
        .toString();
      return getTimestampValue;
    }

    if (val.gender && val.gender !== "all") {
      paramsValue.bool.must.push({
        match: {
          "personal.gender": val.gender,
        },
      });
    }
    if (val.exp_req_from && val.exp_req_to) {
      paramsValue.bool.must.push({
        range: {
          "employment.experience": {
            gte: val.exp_req_from,
            lte: val.exp_req_to,
          },
        },
      });
    }

    if (val.age_from && val.age_to) {
      let age_from = timestampValueCal(val.age_from);
      let age_to = timestampValueCal(val.age_to);

      paramsValue.bool.must.push({
        range: {
          "personal.dob": {
            gte: age_to,
            lte: age_from,
          },
        },
      });
    }
    if (val.district) {
      paramsValue.bool.must.push({
        match: {
          "contact.city": val.district,
        },
      });
    }
    if (val.marital_status) {
      console.log(" val.marital_status", val.marital_status);
      paramsValue.bool.must.push({
        match: {
          "family.is_married": val.marital_status,
        },
      });
    }
    if (val.primary) {
      console.log(" val.primary", val.primary);
      paramsValue.bool.must.push({
        match: {
          "status.current_primary_employer_name": val.primary,
        },
      });
    }
    if (val.contractor) {
      console.log(" val.contractor", val.contractor);
      paramsValue.bool.must.push({
        match: {
          "status.current_contractor_name": val.contractor,
        },
      });
    }

    if (val.status) {
      var statusCondition;
      if (val.status === "Completed Profile") {
        statusCondition = [
          {
            match: {
              "status.is_approved": false,
            },
          },
          {
            match: {
              "status.is_completed": true,
            },
          },
          {
            match: {
              "status.is_submitted": false,
            },
          },
          {
            match: {
              "status.is_employed": false,
            },
          },
        ];
      } else if (val.status === "Incomplete Profile") {
        statusCondition = [
          {
            match: {
              "status.is_completed": false,
            },
          },
          {
            match: {
              "status.is_employed": false,
            },
          },
        ];
      } else if (val.status === "Yet To Be Verified") {
        statusCondition = [
          {
            match: {
              "status.is_approved": false,
            },
          },
          {
            match: {
              "status.is_submitted": true,
            },
          },
          {
            match: {
              "status.is_employed": false,
            },
          },
        ];
      } else if (val.status === "Employed") {
        statusCondition = [
          {
            match: {
              "status.is_approved": true,
            },
          },
          {
            match: {
              "status.is_completed": true,
            },
          },
          {
            match: {
              "status.is_submitted": true,
            },
          },
          {
            match: {
              "status.is_employed": true,
            },
          },
        ];
      } else {
        statusCondition = [
          {
            match: {
              "status.is_approved": true,
            },
          },
          {
            match: {
              "status.is_completed": true,
            },
          },
          {
            match: {
              "status.is_submitted": true,
            },
          },
          {
            match: {
              "status.is_employed": false,
            },
          },
        ];
      }

      if (statusCondition) {
        statusCondition.map((obj) => {
          paramsValue.bool.must.push(obj);
        });
      }
    }

    if (val.qualification) {
      paramsValue.bool.must.push({
        match: {
          "education.level": val.qualification,
        },
      });
    }
    if (val.state && stateName !== "") {
      paramsValue.bool.must.push({
        match: {
          "contact.state": stateName,
        },
      });
    }

    if (
      ((isPrimary || isContract) && val.gender === "all") ||
      ((isPrimary || isContract) && paramsValue.bool.must.length > 1) ||
      (isSpectra && paramsValue.bool.must.length > 0)
    ) {
      if (totalTalents) {
        userContext.setDashboardData((pre) => {
          return {
            ...pre,
            filterQuery: paramsValue,
            page: 0,
            filterQueryValue: val,
          };
        });
      } else {
        userContext.setUserData((pre) => {
          return {
            ...pre,
            filterQuery: paramsValue,
            page: 0,
            filterQueryValue: val,
          };
        });
      }
    } else {
      console.log("select any one");
      // message.error({content:'something went wrong', key: 'saving'})
    }
    setFilterModal(false);
  };

  const filterFormCancel = () => {
    form.resetFields();
    setFilterModal(false);
    userContext.setDashboardData((pre) => {
      return { ...pre, filterQuery: {} };
    });
    userContext.setUserData((pre) => {
      return { ...pre, filterQuery: {}, filterQueryValue: undefined };
    });
  };

  useEffect(() => {
    console.log("it is working");
    if (!totalTalents && userContext.userData.filterValue.length > 0) {
      let dataOBj = userContext.userData.filterValue[0];
      if (
        ["handicapped", "bGroup", "gender", "status"].includes(
          dataOBj.dataIndex
        )
      ) {
        setSelectedField(dataOBj.dataIndex);
        setFilterText({
          dataIndex: dataOBj.dataIndex,
          value: dataOBj.sval,
        });
      } else {
        setFilterText({
          dataIndex: dataOBj.dataIndex,
          value: dataOBj.sval,
        });
      }
    } else if (!totalTalents && userContext.userData.filterValue.length == 0) {
      console.log("else if is working");
      setSelectedField("all");
      setFilterText({
        dataIndex: "all",
        value: "",
      });
    }

    if (
      (!totalTalents && userContext.userData.filterQueryValue !== undefined) ||
      Object.keys(userContext.userData.filterQuery).length > 0
    ) {
      form.setFieldsValue(userContext.userData.filterQueryValue);
    } else if (
      (!totalTalents && userContext.userData.filterQueryValue == undefined) ||
      Object.keys(userContext.userData.filterQuery).length == 0
    ) {
      form.resetFields();
    }

    if (
      totalTalents &&
      userContext.dashboardData.totalTalentFilterValue?.length > 0
    ) {
      let dataOBj = userContext.dashboardData.totalTalentFilterValue[0];
      if (
        ["handicapped", "bGroup", "gender", "status"].includes(
          dataOBj.dataIndex
        )
      ) {
        setSelectedField(dataOBj.dataIndex);
        setFilterText({
          dataIndex: dataOBj.dataIndex,
          value: dataOBj.sval,
        });
      } else {
        setFilterText({
          dataIndex: dataOBj.dataIndex,
          value: dataOBj.sval,
        });
      }
    } else if (
      totalTalents &&
      userContext.userData.totalTalentFilterValue?.length == 0
    ) {
      setSelectedField("all");
      setFilterText({
        dataIndex: "all",
        value: "",
      });
    }

    if (
      (totalTalents &&
        userContext.dashboardData.filterQueryValue !== undefined) ||
      Object.keys(userContext.userData.filterQuery).length > 0
    ) {
      form.setFieldsValue(userContext.dashboardData.filterQueryValue);
    } else if (
      (totalTalents &&
        userContext.dashboardData.filterQueryValue == undefined) ||
      Object.keys(userContext.userData.filterQuery).length == 0
    ) {
      form.resetFields();
    }
  }, [
    userContext.userData.activeTabIndex,
    userContext.userData.activePrimaryTabIndex,
    userContext.userData.activeContractTabIndex,
  ]);
  // 111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111

  const getCatagory = async (type, sVal = "") => {
    try {
      let d = [],
        q = query(collection(db, type), where("name", ">=", sVal));
      const res = await getDocs(q);
      res.forEach((itm) => d.push({ ...itm.data(), id: itm.id }));
      // console.log(type, d);
      return d;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    async function getContractorAndEmployersData() {
      setLoading(true);
      const responses = await Promise.all(
        ["contractors", "primary_employers"].map(async (type) => {
          const res = await getCatagory(type);
          return res;
        })
      );
      if (responses || responses.length > 0) {
        setContractorArr(responses[0]);
        console.log(responses[0]);
        if (auth.isPrimary) {
          setPrimaryemployeryArr(responses[0]);
          console.log(responses[0]);
        } else {
          setPrimaryemployeryArr(responses[1]);
          console.log(responses[1]);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    const res = getContractorAndEmployersData();
  }, []);
  // 11111111111111111111111111111111111111111111111111111111111111111111111111111111111111

  return (
    <div>
      <div className="searchwraper">
        <Select
          defaultValue={"all"}
          size="large"
          className="searchSelect"
          listHeight={200}
          value={filterText.dataIndex}
          dropdownClassName="searchOption"
          dropdownMatchSelectWidth={false}
          defaultActiveFirstOption={true}
          onChange={(value) => handleSelect(value)}
          allowClear
        >
          <Option value={"all"}>All</Option>
          {talents
            ? columnTalent.map((field, i) => {
                if (selectedColumn.includes(i)) {
                  if (!auth.isSpectra && field.dataIndex == "status") {
                    return;
                  } else {
                    return (
                      <Option value={field.dataIndex}>{field.title}</Option>
                    );
                  }
                }
              })
            : contractor
            ? contractorColumns.map((field, i) => {
                if (
                  !(auth.isContract || auth.isPrimary) &&
                  field.dataIndex !== "logo"
                ) {
                  return <Option value={field.dataIndex}>{field.title}</Option>;
                }
              })
            : columns.map((field, i) => {
                if (
                  selectedColumn.includes(i) &&
                  !(auth.isContract || auth.isPrimary)
                ) {
                  return <Option value={field.dataIndex}>{field.title}</Option>;
                }
              })}
        </Select>
        {selectedField == "dob" ? (
          <DatePicker
            size="large"
            style={{ marginRight: "20px", width: "15vw" }}
            onChange={(value) => handleSearchValueUpdate(value)}
          />
        ) : selectedField == "handicapped" ||
          selectedField == "bGroup" ||
          selectedField == "gender" ||
          selectedField == "status" ? (
          <Select
            size="large"
            placeholder="search"
            allowClear
            showSearch
            value={filterText.value == "" ? undefined : filterText.value}
            showArrow={false}
            style={{ marginRight: "20px", width: "15vw" }}
            onChange={(value) => handleSearchValueUpdate(value)}
          >
            {searchSelectOption?.map((itm) => {
              return <Option value={itm}>{itm}</Option>;
            })}
          </Select>
        ) : (
          <>
            <Search
              placeholder="Search"
              size="large"
              allowClear
              value={filterText.value}
              // id="searchTextInput"
              style={{ marginRight: "20px", width: "15vw" }}
              // className="searchBox"
              onChange={(e) =>
                setFilterText((pre) => {
                  return { ...pre, value: e.target.value };
                })
              }
              onSearch={(value) => handleSearchValueUpdate(value)}
              enterButton
            />
          </>
        )}
        {(userData.activeTabIndex == "1" || totalTalents) && (
          <Badge
            dot={filterBadge}
            size="large"
            style={{ postion: "absolute", left: "20px" }}
          >
            <img
              onClick={handlefilterModal}
              className="filterIcon"
              src={filterIcon}
              alt="filter"
              width="39vw"
            />
          </Badge>
        )}
        <Modal
          visible={filterModal}
          onCancel={() => setFilterModal(false)}
          footer={null}
          width="50%"
        >
          <div className="filterForm">
            <Form layout="vertical" form={form} onFinish={onFinish}>
              <h2 className="filter_title">Filter Talent</h2>

              <div className="Filterscroll">
                <div className="first line">
                  <div className="left">
                    <Form.Item label="Gender" name="gender">
                      <Select placeholder="select" size="large" allowClear>
                        <Option value={"male"}>Male</Option>
                        <Option value={"female"}>Female</Option>
                        <Option value={"others"}>Others</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="right">
                    <div className="expInp" style={{ width: "50%" }}>
                      <label>Age</label>
                      <div className="wrap">
                        <div className="ExpC">
                          <Form.Item style={{ width: "50px" }} name="age_from">
                            <Input
                              type="number"
                              min={0}
                              max={99}
                              size="large"
                              placeholder="00"
                            />
                          </Form.Item>
                          <div className="connector">to</div>
                          <Form.Item style={{ width: "50px" }} name="age_to">
                            <Input
                              type="number"
                              id="handleExpToValidate"
                              size="large"
                              placeholder="00"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="expInp" style={{ width: "50%" }}>
                      <label style={{ marginLeft: "8px" }}>
                        Marital status
                      </label>
                      <div className="wrap">
                        <div className="ExpC">
                          <Form.Item name="marital_status">
                            <Select
                              name="expPer"
                              style={{ width: "11vw", marginLeft: "8px" }}
                              showArrow
                              size="large"
                              placeholder="Select"
                              allowClear
                            >
                              <Option value="true">Married</Option>
                              <Option value="false">Single</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="second line">
                  <div className="left">
                    <Form.Item label="State" name="state">
                      <Select
                        allowClear
                        placeholder="select"
                        size="large"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={(e) => handleStateChanged(e)}
                      >
                        {state.map((States) => {
                          return (
                            <Option value={States.id} key={States.id}>
                              {States.stateName}
                            </Option>
                          );
                        })}
                      </Select>
                      {/* <Select placeholder="select" size='large'>
                              <Option value={'all'}>All</Option>
                              <Option value={'male'}>Male</Option>
                              <Option value={'female'}>Female</Option>
                              <Option value={'others'}>Other</Option>
                            </Select> */}
                    </Form.Item>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Form.Item label="District" name="district">
                      <Select
                        placeholder="select"
                        size="large"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        allowClear
                      >
                        {district.map((districts, i) => {
                          return (
                            <Option value={districts} key={i}>
                              {districts}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="first line">
                  <div className="left">
                    <Form.Item label="Qualification" name="qualification">
                      <Select placeholder="select" size="large" allowClear>
                        {qualification.map((qualifications, i) => {
                          return (
                            <Option value={qualifications} key={i}>
                              {qualifications}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="right">
                    <div className="expInp" style={{ width: "50%" }}>
                      <label style={{ whiteSpace: "pre" }}>
                        Experience required
                      </label>
                      <div className="wrap">
                        <div className="ExpC">
                          <Form.Item
                            style={{ width: "50px" }}
                            name="exp_req_from"
                          >
                            <Input
                              type="number"
                              min={0}
                              max={99}
                              size="large"
                              placeholder="00"
                            />
                          </Form.Item>
                          <div className="connector">to</div>
                          <Form.Item
                            style={{ width: "50px" }}
                            name="exp_req_to"
                          >
                            <Input
                              type="number"
                              id="handleExpToValidate"
                              size="large"
                              placeholder="00"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="expInp" style={{ width: "50%" }}>
                      <label style={{ marginLeft: "8px" }}> </label>
                      <div className="wrap">
                        <div className="ExpC">
                          <Form.Item name="expPer">
                            <Select
                              defaultValue="Months"
                              name="expPer"
                              style={{
                                width: "11vw",
                                marginLeft: "8px",
                                cursor: "not-allowed",
                              }}
                              showArrow
                              size="large"
                              disabled
                              allowClear
                            >
                              {/* <Option value="years">Months</Option> */}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="  fourth line"> */}
                {/* <div style={{ width: "4000rem" }}> */}

                {(auth.isSpectra ||
                  (auth.isContract &&
                    userContext.userData.activeContractTabIndex == "1")) && (
                  <Form.Item label="Primary Employers " name="primary">
                    <Select placeholder="select" size="large" allowClear>
                      {primaryemployerArr.map((primaryemployerArr, i) => {
                        return (
                          <Option value={primaryemployerArr.name} key={i}>
                            {primaryemployerArr.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
                {/* </div> */}

                {/* <div style={{ width: "4000rem" }}> */}
                {(auth.isSpectra || auth.isPrimary) && (
                  <Form.Item label="Contractors" name="contractor">
                    <Select placeholder="select" size="large" allowClear>
                      {contractorArr.map((contractorArr, i) => {
                        return (
                          <Option value={contractorArr.name} key={i}>
                            {contractorArr.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
                {/* </div> */}

                {/* </div> */}

                {auth.isSpectra && (
                  <div className="fifth line">
                    <div style={{ width: "100%" }}>
                      <Form.Item label="Status" name="status">
                        <Select placeholder="select" size="large" allowClear>
                          <Option value={"Completed Profile"}>
                            Completed Profile
                          </Option>
                          <Option value={"Incomplete Profile"}>
                            Incompleted Profile
                          </Option>
                          <Option value={"Yet To Be Verified"}>
                            Yet to be verified
                          </Option>
                          <Option value={"Employed"}> Employed</Option>
                          <Option value={"Available"}>Available</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                )}
              </div>

              <div className="footer_btn">
                <Form.Item>
                  <Button
                    key="back"
                    type="secondary"
                    className="btn"
                    size="large"
                    style={{ marginRight: "1.5rem" }}
                    onClick={filterFormCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    className="btn"
                  >
                    Apply
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default UserPanelSearch;

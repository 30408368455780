import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AuthProvider from "./context/auth";
import DataProvider from "./context/data";
import Contract from "./route/Contract";
import Login from "./route/Login";
import Primary from "./route/Primary";
import { PrivateRoute } from "./route/PrivateRoute";
import SpectrawiseAdmin from "./route/SpectrawiseAdmin";
import './styles/Base.scss';
import './firebase';
import './styles/custom-antd.css';


function App() {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <DataProvider>
            <PrivateRoute path="/Talntworx">
              <Route path="/Talntworx" >
                <SpectrawiseAdmin />
              </Route>
            </PrivateRoute>
            <PrivateRoute path="/contract">
              <Route path="/contract">
                <Contract />
              </Route>
            </PrivateRoute>
            <PrivateRoute path="/primary">
              <Route path="/primary">
                <Primary />
              </Route>
            </PrivateRoute>
          </DataProvider>
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;

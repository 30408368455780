import React, { useState, useEffect } from "react";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import { Input, DatePicker, Form } from "antd";
import { Radio } from "antd";

function Tab4({ ImgPopup, data, isEdit, setUserData }) {
  const [errorMessage, setErrorMessage] = useState({
    family: {
      spouse_name: "",
      father_name: "",
    },
    nominee: {
      name: "",
      aadhaar_number: "",
      relationship: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      pincode: "",
      country: "",
    },
  });

  // const handleChange = (e) => {
  //   const value = e.target.value;
  //   const name = e.target.name;
  //   const fieldName = e.target.getAttribute("data-fieldname");

  //   if (fieldName.startsWith("Family")) {
  //     setUserData((prev) => ({
  //       ...prev,
  //       family: {
  //         ...prev.family,
  //         [name]: value,
  //       },
  //     }));
  //     setErrorMessage((prev) => ({
  //       ...prev,
  //       family: {
  //         ...prev.family,
  //         [name]: value ? "" : `Please Enter ${fieldName}`,
  //       },
  //     }));
  //   } else if (fieldName.startsWith("Nominee")) {
  //     console.log(data);
  //     setUserData((prev) => ({
  //       ...prev,
  //       nominee: {
  //         ...prev.nominee,
  //         [name]: value,
  //       },
  //     }));
  //     setErrorMessage((prev) => ({
  //       ...prev,
  //       nominee: {
  //         ...prev.nominee,
  //         [name]: value ? "" : `Please Enter ${fieldName}`,
  //       },
  //     }));
  //   }
  // };

  const initialDOB = data?.nominee?.dob
    ? moment(new Date(data.nominee?.dob.seconds * 1000))
    : null;

  const [dob1, setDob] = useState(initialDOB ?? null);

  const handleDobChange = (date) => {
    if (date) {
      const formattedDate1 = date ? date.toDate() : null;
      setDob(date);
      setUserData((prev) => ({
        ...prev,
        nominee: {
          ...prev.nominee,
          dob: formattedDate1,
        },
      }));
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const fieldName = e.target.getAttribute("data-fieldname");

    // Aadhaar number validation: 12 digits only
    const aadhaarRegex = /^[0-9]{12}$/;

    if (fieldName.startsWith("Family")) {
      setUserData((prev) => ({
        ...prev,
        family: {
          ...prev.family,
          [name]: value,
        },
      }));
      setErrorMessage((prev) => ({
        ...prev,
        family: {
          ...prev.family,
          [name]: value ? "" : `Please Enter ${fieldName}`,
        },
      }));
    } else if (fieldName.startsWith("Nominee")) {
      setUserData((prev) => ({
        ...prev,
        nominee: {
          ...prev.nominee,
          [name]: value,
        },
      }));

      if (name === "relationship") {
        const value = e.target.value; // Capture the input value
        const hasSpecialChars = /[^a-zA-Z0-9]/; // Regex to check for special characters
      
        // Set the error message based on the presence of special characters
        setErrorMessage((prev) => ({
          ...prev,
          nominee: {
            ...prev.nominee,
            [name]: hasSpecialChars.test(value) 
              ? "Please enter valid text" // Set error message if special characters are found
              : "", // Clear error message if no special characters
          },
        }));
      
        // Optionally, update the user data with the filtered value
        const filteredValue = value.replace(hasSpecialChars, ""); // Filter out special characters if needed
        setUserData((prev) => ({
          ...prev,
          nominee: {
            ...prev.nominee,
            [name]: filteredValue, // Update the state with the filtered value
          },
        }));
      }

      // Validate Aadhaar number
      if (name === "aadhaar_number") {
        setErrorMessage((prev) => ({
          ...prev,
          nominee: {
            ...prev.nominee,
            [name]: aadhaarRegex.test(value)
              ? ""
              : "Aadhaar number must be 12 digits",
          },
        }));
      } else {
        setErrorMessage((prev) => ({
          ...prev,
          nominee: {
            ...prev.nominee,
            [name]: value ? "" : `Please Enter ${fieldName}`,
          },
        }));
      }
    }
  };

  return (
    <>
      <div className="detailsContainer">
        <h4 className="cardDetailsHead">Family</h4>
        <div className="innerContainer">
          <div className="fieldData">
            <div className="labelField">Marital status</div>
            {!isEdit ? (
              <div className="fieldDetail">
                {data.family?.is_married ? "YES" : "NO"}
              </div>
            ) : (
              <div style={{ marginTop: "3%" }}>
                <Radio.Group 
                value={data.family?.is_married ? "YES" : "NO"}
                onChange={(e) => {
                  const value = e.target.value;
                  setUserData((prev) => ({
                    ...prev,
                    family: {
                      ...prev.family,
                      is_married: value === "YES",
                    },
                  }));
                }}
                >
                  <Radio
                    value="YES"
                    onChange={() => {
                      setUserData((prev) => ({
                        ...prev,
                        family: {
                          ...prev.family,
                          is_married: "YES",
                        },
                      }));
                    }}
                  >
                    Married
                  </Radio>
                  <Radio
                    value="NO"
                    onChange={() => {
                      setUserData((prev) => ({
                        ...prev,
                        family: {
                          ...prev.family,
                          is_married: "",
                        },
                      }));
                    }}
                  >
                    Single
                  </Radio>
                </Radio.Group>
              </div>
            )}
          </div>
          <div className="fieldData">
            <div className="labelField">Spouse name</div>
            {!isEdit ? (
              <div className="fieldDetail">
                {data.family?.spouse_name ? data.family?.spouse_name : "N/A"}
              </div>
            ) : (
              <div>
                <Input
                  style={{ width: "95%" }}
                  placeholder="Enter Spouse Name"
                  className="phoneNumberInput"
                  value={data.family?.spouse_name || ""}
                  onChange={handleChange}
                  name="spouse_name"
                  data-fieldname="Family Spouse Name"
                  disabled={data.family?.is_married === false}
                />
                {errorMessage?.family?.spouse_name && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {errorMessage?.family?.spouse_name}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="fieldData">
            <div className="labelField">Father’s name</div>
            {!isEdit ? (
              <div className="fieldDetail">
                {data.family?.father_name ? data.family?.father_name : "N/A"}
              </div>
            ) : (
              <div>
                <Input
                  style={{ width: "95%" }}
                  placeholder="Enter Father Name"
                  className="phoneNumberInput"
                  value={data.family?.father_name || ""}
                  onChange={handleChange}
                  name="father_name"
                  data-fieldname="Family Father Name"
                />
                {errorMessage?.family?.father_name && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {errorMessage?.family?.father_name}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="detailsContainer">
        <h4 className="cardDetailsHead">Nominee</h4>
        <div className="innerContainer">
          <div className="fieldData">
            <div className="labelField">Name</div>
            {!isEdit ? (
              <div className="fieldDetail">
                {data.nominee?.name ? data.nominee?.name : "N/A"}
              </div>
            ) : (
              <div>
                <Input
                  style={{ width: "95%" }}
                  placeholder="Enter Nominee Name"
                  className="phoneNumberInput"
                  value={data.nominee?.name || ""}
                  onChange={handleChange}
                  name="name"
                  data-fieldname="Nominee Spouse Name"
                />
                {errorMessage?.nominee.name && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {errorMessage?.nominee.name}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="fieldData">
            <div className="labelField">Date of Birth</div>
            {!isEdit ? (
              <div className="fieldDetail">
                {dob1
                  ? dob1.format("DD/MM/YYYY")
                  : data.nominee?.dob
                  ? moment(
                      new Timestamp(
                        data.nominee?.dob.seconds | data.nominee?.dob._seconds,
                        data.nominee?.dob.nanoseconds |
                          data.nominee?.dob._nanoseconds
                      ).toDate()
                    ).format("DD/MM/YYYY")
                  : "N/A"}
              </div>
            ) : (
              <Form
                initialValues={{
                  dateOfBirth: dob1 ?? initialDOB, // Set initial value for DatePicker here
                }}
              >
                <Form.Item
                  name="dateOfBirth"
                  rules={[
                    {
                      required: true,
                      message: "Please select your date of birth!",
                    },
                  ]}
                >
                  <DatePicker
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                    value={dob1} // Make DatePicker a controlled component
                    onChange={handleDobChange}
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    } // Disable future dates
                  />
                </Form.Item>
              </Form>
            )}
          </div>
          <div className="fieldData">
            <div className="labelField">Relationship</div>
            {!isEdit ? (
              <div className="fieldDetail">
                {data.nominee?.relationship
                  ? data.nominee?.relationship
                  : "N/A"}
              </div>
            ) : (
              <div>
                <Input
                  style={{ width: "95%" }}
                  placeholder="Enter Relationship"
                  className="phoneNumberInput"
                  value={data.nominee?.relationship || ""}
                  onChange={handleChange}
                  name="relationship"
                  data-fieldname="Nominee Relationship"
                />
                {errorMessage?.nominee?.relationship && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {errorMessage?.nominee?.relationship}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="fieldData">
            <div className="labelField">Aadhaar number</div>
            {!isEdit ? (
              <div className="fieldDetail">
                {data.nominee?.aadhaar_number
                  ? "XXXXXXXX" + data.nominee?.aadhaar_number.substring(8)
                  : "N/A"}
              </div>
            ) : (
              <div>
                <Input
                  style={{ width: "95%" }}
                  placeholder="Enter Aadhaar Number"
                  className="phoneNumberInput"
                  value={data.nominee?.aadhaar_number || ""}
                  onChange={handleChange}
                  name="aadhaar_number"
                  data-fieldname="Nominee Aadhaar Number"
                />
                {errorMessage?.nominee?.aadhaar_number && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {errorMessage?.nominee?.aadhaar_number}
                  </div>
                )}
              </div>
            )}
          </div>
          {!isEdit ? (
            <div className="fieldData">
              <div className="labelField">Address</div>
              <div className="fieldDetail">
                {data.nominee?.address_line_1 ||
                data.nominee?.address_line_2 ||
                data.nominee?.city ||
                data.nominee?.state ||
                data.nominee?.pincode
                  ? `${
                      data.nominee?.address_line_1 &&
                      `${data.nominee?.address_line_1},`
                    } ${
                      data.nominee?.address_line_2 &&
                      `${data.nominee?.address_line_2},`
                    }
                            ${data.nominee?.city && `${data.nominee?.city},`} ${
                      data.nominee?.pincode && `${data.nominee?.pincode},`
                    } ${data.nominee?.state && `${data.nominee?.state},`} ${
                      data.nominee?.country && `${data.nominee?.country}`
                    }`
                  : "N/A"}
              </div>
            </div>
          ) : (
            <div className="firstDetailContainner">
              <div className="firstDetail">
                <div className="fieldData">
                  <div className="labelField">Address Line 1</div>
                  {!isEdit ? (
                    <div className="fieldDetail">
                      {data.nominee.aadhaar_number
                        ? data.nominee.aadhaar_number
                        : "N/A"}
                    </div>
                  ) : (
                    <div>
                      <Input
                        style={{ width: "90%" }}
                        placeholder="Enter Address Line 1"
                        className="phoneNumberInput"
                        value={data.nominee.address_line_1}
                        onChange={handleChange}
                        name="address_line_1"
                        data-fieldname="Nominee Line 1"
                      />
                      {errorMessage?.nominee?.address_line_1 && (
                        <div style={{ color: "red", marginTop: "8px" }}>
                          {errorMessage.nominee?.address_line_1}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {isEdit && (
            <>
              <div>
                <div className="firstDetail">
                  <div className="fieldData">
                    <div className="labelField">Address Line 2</div>
                    {isEdit && (
                      <div>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter Address Line 2"
                          className="phoneNumberInput"
                          value={data.nominee.address_line_2}
                          onChange={handleChange}
                          name="address_line_2"
                          data-fieldname="Nominee Line 2"
                        />
                        {errorMessage?.nominee?.address_line_2 && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage.nominee?.address_line_2}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <div className="firstDetail">
                  <div className="fieldData">
                    <div className="labelField">City</div>
                    {isEdit && (
                      <div>
                        {" "}
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter City"
                          className="phoneNumberInput"
                          value={data.nominee?.city}
                          onChange={handleChange}
                          name="city"
                          data-fieldname="Nominee city"
                        />
                        {errorMessage?.nominee?.city && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage.nominee?.city}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="firstDetail">
                  <div className="fieldData">
                    <div className="labelField">State</div>
                    {isEdit && (
                      <div>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter State"
                          className="phoneNumberInput"
                          value={data.nominee?.state}
                          onChange={handleChange}
                          name="state"
                          data-fieldname="Nominee state"
                        />
                        {errorMessage?.nominee?.state && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage.nominee?.state}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="firstDetail">
                  <div className="fieldData">
                    <div className="labelField">Pincode</div>
                    {isEdit && (
                      <div>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter Pincode"
                          className="phoneNumberInput"
                          value={data.nominee?.pincode}
                          onChange={handleChange}
                          name="pincode"
                          data-fieldname="Nominee pincode"
                        />
                        {errorMessage?.nominee?.pincode && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage.nominee?.pincode}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="firstDetail">
                  <div className="fieldData">
                    <div className="labelField">Country</div>
                    {isEdit && (
                      <div>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter Country"
                          className="phoneNumberInput"
                          value={data.nominee?.country}
                          onChange={handleChange}
                          name="country"
                          data-fieldname="Nominee country"
                        />
                        {errorMessage.nominee?.country && (
                          <div style={{ color: "red", marginTop: "8px" }}>
                            {errorMessage.nominee?.country}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Tab4;

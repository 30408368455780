import React, { useEffect, useState } from 'react'
import JobHeadBar from './JobHeadBar'
import { collection, collectionGroup, doc, getDoc, getDocs, getFirestore, query, orderBy, Timestamp, limit, startAfter, startAt, where, onSnapshot, setDoc, addDoc, updateDoc } from 'firebase/firestore';
import app from '../../firebase';
import RolesTable from './RolesTable';
import { dataContext } from '../../context/data';
import { useContext } from 'react';
import '../../styles/rolesList.scss'
import { message } from 'antd';
import { useAuthContext } from '../../context/auth';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';

const rolesListColumn = [
    {
      title: "DESIGNATION",
      dataIndex: "designation",
      width: '25%',
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      width: '50%',
    },
    {
      title: "GROSS SALARY",
      dataIndex: "gross_salary",
      width: '25%',
    },
  ]

  const db = getFirestore(app);

  function RolesList() {

    const [data, setData] = useState([]);
    const {jobData, setJobData} = useContext(dataContext);
    const auth = useAuthContext();

    const mapData = (d) => {
      return{
        designation: d.designation,
        description: d.description,
        gross_salary: `₹${d.gross_salary}/${d.salary_per_period}`
      }
    }

    useEffect(() => {
      setJobData(pre => {return {...pre, rolesLastFetch: undefined, rolesPage:0, rolesFilterValue: undefined}})
    },[])
    
    async function getRolesData(){
      message.loading({content: 'loading...', duration: 0, key: 'Job roles'});

      let temp = [], q;
      let count = 0;
      let filterContent = jobData.rolesFilterValue;
      let filterField;
      let filterValue;
      let user;

      if(filterContent && filterContent.value !== undefined ){
         filterField = filterContent.dataIndex;
  
        if(filterContent.dataIndex == 'gross_salary'){
          filterValue = parseInt(filterContent.value)
        }else{
          filterValue = filterContent.value
        }
      }
      if(auth.isSpectra){
        user = 'Talntworx Technologies '
      }
      else if(auth.isPrimary){
        user = localStorage.getItem('primary_employer_id')
      }
      else if(auth.isContract){
        user = localStorage.getItem('contractor_id')
      }
      
      // if(auth.isSpectra){
        try{
          if(filterContent && filterContent.value !== '' && jobData.rolesLastFetch == undefined){
            q = query(collection(db, 'job_roles'), where('user_id' , '==', user), where(filterField, (filterField == 'gross_salary' ? '==' : '>='), filterValue),limit(15))
          }
          else if (filterContent && filterContent.value !== '' && jobData.rolesLastFetch !== undefined){
            q = query(collection(db, 'job_roles'), where('user_id' , '==', user), where(filterField, (filterField == 'gross_salary' ? '==' : '>='), filterValue), startAfter(jobData.rolesLastFetch), orderBy('created_at', 'desc'),limit(15))
          }
          else if(jobData.rolesLastFetch){
            q = query(collection(db, 'job_roles'), where('user_id' , '==', user), orderBy('created_at', 'desc'), startAfter(jobData.rolesLastFetch), limit(15));
          }
          else{
            q = query(collection(db, 'job_roles'), where('user_id' , '==', user), orderBy('created_at', 'desc'), limit(10))
          }
    
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((itm) => {
            let d = itm.data();
            temp.push(mapData(d));
  
            if (querySnapshot.docs.length - 1 === count++) {
              message.destroy("Job roles");
              setData(pre => {
                if (jobData.rolesPage > 0) {
                  return [...pre, ...temp];
                }
                else return temp;

              });
              setJobData(pre => { return { ...pre, rolesLastFetch: itm } }); 
            }
          })
          if (querySnapshot.docs.length <= 0) {
            message.destroy("Job roles");
            setData(pre => {
              if (jobData.rolesPage > 0) {
                return [...pre];
              }
              else return [];
            });
          }
          message.destroy("Job roles");
        }catch(err){
          message.error({content: err, key:'Job roles'})
        }
      // };
      }
  
    useEffect(() => {
      getRolesData();
    },[jobData.rolesPage, jobData.roleAddSuccess, jobData.rolesFilterValue])

    console.log('data length', data.length)

    return (
    <div>
        <JobHeadBar column={rolesListColumn} type="role"/>
        <RolesTable jobDataColumn={rolesListColumn} data={data}/>
    </div>
  )
}

export default RolesList
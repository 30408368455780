import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { useContext } from 'react';
import { dataContext } from '../../context/data';
import EnrollRequests from './EnrollRequests';
import DetachRequests from './DetachRequests'

function RequestsTabs() {

    const { TabPane } = Tabs;
    const { requestTabData, setRequestTabData } = useContext(dataContext);
  

  return (
    <div className='topTab' style={{height: '100%'}}>
        <Tabs style={{height: '100%'}} defaultActiveKey="1" activeKey={requestTabData.activeTabIndex} onChange={tabidx => setRequestTabData(pre => { return { ...pre, activeTabIndex: tabidx } })} className='tabs'>
            <TabPane tab="Enroll Requests" key="1" className='tabSlider '>
                <EnrollRequests />
            </TabPane>
            <TabPane tab="Detach Requests" key="2" className='tabSlider'>
                <DetachRequests />
            </TabPane>
        </Tabs>
    </div>
  )
}

export default RequestsTabs
import React, { useContext, useEffect, useState } from "react";
import { Modal, Table, Button, message } from "antd";
import "../../../styles/responsiveTable.scss";
import moreIcon from "../../../assets/icons/more.svg";
import dragIcon from "../../../assets/icons/drag.svg";
import sortIcon from "../../../assets/icons/sort.svg";
import searchIcon from "../../../assets/icons/search.svg";
import clearIcon from "../../../assets/icons/clear.svg";
import YTV from "../../../assets/icons/status/YTV.svg";
import CP from "../../../assets/icons/status/CP.svg";
import IP from "../../../assets/icons/status/IP.svg";
import E from "../../../assets/icons/status/E.svg";
import UE from "../../../assets/icons/status/UE.svg";
import editIcon from "../../../assets/icons/icons8-edit.svg";
import deleteIcon from "../../../assets/icons/icons8-delete.svg";
import { useHistory } from "react-router";
import { dataContext } from "../../../context/data";
import { useAuthContext } from "../../../context/auth";
import InfiniteScroll from "react-infinite-scroll-component";
import { doc, getFirestore, deleteDoc } from "@firebase/firestore";
import app, { firebaseAuth } from "../../../firebase";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import useSortableData from "../../useSortableData";

import { getAuth, deleteUser, updateProfile } from "firebase/auth";
import axios from "axios";

import { collection, query, where, getDocs } from "firebase/firestore";

import baseUrl from "../../../utils/baseUrl";

const db = getFirestore(app);

function ResponsiveTable({
  talents,
  data,
  disableColumnOrder,
  column,
  setColumn,
  setSelectedColumn,
  selectedColumn,
  dashboard,
  totoalTalents,
  userType,
}) {
  const auth = useAuthContext();
  const history = useHistory();
  const userContext = useContext(dataContext);
  const [localData, setLocalData] = useState(data);
  const [filterValue, setFilterValue] = useState([]);
  const { items, requestSort } = useSortableData(localData);

  const [optionVisibile, setOptionVisibile] = useState(false);
  const showOption = () => setOptionVisibile((pre) => !pre);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  useEffect(
    () => userContext.setUserData((pre) => ({ ...pre, selectedRows })),
    [selectedRows]
  );

  useEffect(() => {
    setSelectedRows([]);
    setFilterValue([]);
  }, [
    userContext.userData.activeContractTabIndex,
    userContext.userData.activePrimaryTabIndex,
    userContext.userData.activeTabIndex,
  ]);
  useEffect(() => {
    setSelectedRows([]);
  }, [
    filterValue,
    userContext.userData.filterValue,
    userContext.userData.filterQuery,
  ]);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const handleRowSelection = (row) => {
    const found = selectedRows.some((el) => el.key === row.row.key);
    if (found) {
      setSelectedRows((pre) => pre.filter((x) => x.key !== row.row.key));
    } else {
      setSelectedRows((pre) => [
        ...pre,
        {
          key: row.row.key,
          employer: row.row.employer,
          employer_id: row.row.employer_id,
        },
      ]);
    }
  };

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const fetchMore = () => {
    if (dashboard && !totoalTalents) {
      userContext.setDashboardData((pre) => ({
        ...pre,
        talentPage: pre.talentPage + 1,
      }));
    } else if (totoalTalents) {
      console.log("it is total");
      userContext.setDashboardData((pre) => ({
        ...pre,
        totalTalentPage: pre.totalTalentPage + 1,
      }));
    } else {
      userContext.setUserData((pre) => ({ ...pre, page: pre.page + 1 }));
    }
  };

  const handleSort = (columnName) => {
    if (
      auth.isSpectra &&
      ["2", "3", "4", "5", "6"].includes(userContext.userData.activeTabIndex)
    ) {
      requestSort(columnName);
    } else {
      let sortName = "";
      switch (columnName) {
        case "name":
          sortName = "personal.full_name.keyword";
          break;
        case "bGroup":
          sortName = "personal.blood_group.keyword";
          break;
        case "bankAC":
          sortName = "bank.account_number.keyword";
          break;
        case "contractor":
          sortName = "status.current_contractor_name.keyword";
          break;
        case "employer":
          sortName = "status.current_primary_employer_name.keyword";
          break;
        case "pincode":
          sortName = "contact.pincode.keyword";
          break;
        default:
          break;
      }

      const setSortColumn = (context, sortName, initialSort = "asc") => {
        if (context[sortName]) {
          context[sortName] = context[sortName] === "asc" ? "desc" : "asc";
        } else {
          context[sortName] = initialSort;
        }
        return context;
      };

      const updateSortColumn = (dashboardContext, userContext, sortName) => {
        if (totoalTalents) {
          const newSortColumn = setSortColumn(
            dashboardContext.sortColumn || {},
            sortName
          );
          userContext.setDashboardData((pre) => ({
            ...pre,
            sortColumn: newSortColumn,
            isSort: !pre.isSort,
          }));
        } else {
          const newSortColumn = setSortColumn(
            userContext.sortColumn || {},
            sortName
          );
          userContext.setUserData((pre) => ({
            ...pre,
            sortColumn: newSortColumn,
            isSort: !pre.isSort,
          }));
        }
      };

      updateSortColumn(
        userContext.dashboardData,
        userContext.userData,
        sortName
      );
    }
  };

  const CheckBoxElement = (row) => {
    const data = row;
    const [checked, setChecked] = useState(false);
    useEffect(() => {
      const found = selectedRows.some((el) => el.key === data.row.key);
      setChecked(found);
    }, [data.row.key, selectedRows]);

    return (
      <input
        checked={checked}
        onChange={() => handleRowSelection(row)}
        type="checkbox"
      />
    );
  };

  const handleEdit = (row) => {
    console.log("Edit user:", row);
    // Implement your edit logic here
    userContext.setUserData((pre) => {
      return { ...pre, editUser: row, editUserTrigger: !pre.editUserTrigger };
    });
    localStorage.setItem("count", 1);
  };

  const handleDelete = (row) => {
    setRowToDelete(row);
    setIsDeleteModalVisible(true);
  };

  const confirmDelete = async () => {
    if (!rowToDelete) return;
    const auth = getAuth();
    const user = auth.currentUser;
    let q = query(
      collection(db, "talent_users"),
      where("status.current_contractor_id", "in", [rowToDelete.key]) // 'in' expects an array
    );
    let d = [];
    try {
      const res = await getDocs(q); // Execute the query
      res.forEach((itm) => d.push({ ...itm.data(), id: itm.id })); // Push document data and ID to array
    } catch (error) {
      console.error("Error fetching documents: ", error);
    }
    if (d.length === 0) {
      if (user) {
        try {
          // await deleteUser (user);
          const res = await axios.post(
            `${baseUrl}/deleteUserAuth`,
            {
              id: rowToDelete.key,
            }
          );
          console.log("User account deleted successfully");
          const collections = [
            "all_users",
            "talent_users",
            "spectrawise_bizops_users",
            "primary_employer_users",
            "contractor_users",
            "primary_employers",
            "contractors",
          ];
          try {
            const promises = collections.map((collectionName) => {
              const docRef = doc(db, collectionName, rowToDelete.key);
              return deleteDoc(docRef);
            });
            await Promise.all(promises);

            console.log("Document successfully deleted from all collections!");

            // Update the local state to reflect the deletion
            const newData = localData.filter(
              (item) => item.key !== rowToDelete.key
            );
            setLocalData(newData);
            userContext.setUserData((pre) => ({ ...pre, tableData: newData }));

            console.log("State updated with new data:", newData);
          } catch (error) {
            console.error("Error removing document: ", error);
          }
        } catch (error) {
          console.error("Error deleting user account:", error.message);
        }
      } else {
        console.log("No user is signed in.");
      }
    } else {
      message.error({ content: "Talent User associated with contract user" });
    }

    setIsDeleteModalVisible(false);
    setRowToDelete(null);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalVisible(false);
    setRowToDelete(null);
  };

  return (
    <div className="responsiveTable">
      <InfiniteScroll
        dataLength={localData.length}
        hasMore={true}
        key={userContext.userData.activeContractTabIndex}
        next={fetchMore}
        scrollableTarget="respTableScroll"
      >
        <div className="tableWraper" id="respTableScroll">
          <table>
            <thead>
              <tr>
                {talents && !totoalTalents ? (
                  // <th className="field head">
                  //   <input
                  //     checked={
                  //       data.length === selectedRows.length ? true : false
                  //     }
                  //     onChange={() => {
                  //       if (data.length !== selectedRows.length)
                  //         setSelectedRows(
                  //           data.map((list) => {
                  //             return { key: list.key, employer: list.employer };
                  //           })
                  //         );
                  //       else setSelectedRows([]);
                  //     }}
                  //     type="checkbox"
                  //   />
                  // </th>
                  <th></th>
                ) : (
                  <th></th>
                )}

                {column.map((field, i) => {
                  let ffd = filterValue.find(
                    (fv) => fv.dataIndex === field.dataIndex
                  );
                  if (
                    (selectedColumn.includes(i) ||
                      ["2", "3", "4", "5", "6"].includes(
                        userContext.userData.activeTabIndex
                      )) &&
                    !(
                      (auth.isContract || auth.isPrimary) &&
                      field.dataIndex == "status"
                    )
                  )
                    return (
                      <th key={i} className="field head">
                        {field.title}{" "}
                        {field.sort && (
                          <img
                            style={{ cursor: "pointer" }}
                            width="15px"
                            height="15px"
                            onClick={() => handleSort(field.dataIndex)}
                            src={sortIcon}
                            alt="sort"
                          />
                        )}
                      </th>
                    );
                  else return null;
                })}
                {userType === "spectra" && (
                  <th className="field head">Actions</th>
                )}
              </tr>
            </thead>
            <tbody>
              {items.map((row, idx) => {
                // const found = selectedRows.some(el => el.key === row.key);
                if (selectedColumn.length)
                  return (
                    <tr
                      key={idx}
                      className=""
                      // style={selectedRows.includes(row.key) ? { backgroundColor: "#F5E9FF" } : null}
                    >
                      {talents && !totoalTalents ? (
                        // <td
                        //   style={
                        //     selectedRows.includes(row.key)
                        //       ? { backgroundColor: "#F5E9FF" }
                        //       : null
                        //   }
                        // >
                        //   <CheckBoxElement row={row} />
                        // </td>
                        <td></td>
                      ) : (
                        <td></td>
                      )}
                      {column.map((field, i) => {
                        let statusSRC;
                        if (field.dataIndex === "status")
                          switch (row[field.dataIndex]) {
                            case "Completed Profile":
                              statusSRC = CP;
                              break;
                            case "Incomplete Profile":
                              statusSRC = IP;
                              break;
                            case "Yet To Be Verified":
                              statusSRC = YTV;
                              break;
                            case "Employed":
                              statusSRC = E;
                              break;
                            case "Available":
                              statusSRC = UE;
                              break;
                            default:
                              statusSRC = IP;
                              break;
                          }
                        if (
                          (selectedColumn.includes(i) ||
                            ["2", "3", "4", "5", "6"].includes(
                              userContext.userData.activeTabIndex
                            )) &&
                          !(
                            (auth.isContract || auth.isPrimary) &&
                            field.dataIndex == "status"
                          )
                        )
                          return (
                            <td
                              onClick={() => {
                                if (
                                  auth.isSpectra &&
                                  ["2", "3", "4", "5", "6"].includes(
                                    userContext.userData.activeTabIndex
                                  )
                                ) {
                                  userContext.setUserData((pre) => {
                                    return {
                                      ...pre,
                                      editUser: row,
                                      editUserTrigger: !pre.editUserTrigger,
                                    };
                                  });
                                  localStorage.setItem("count", 1);
                                  return;
                                }
                                if (
                                  dashboard &&
                                  auth.isSpectra &&
                                  userContext.userData.activeTabIndex === "1"
                                ) {
                                  history.push({
                                    pathname: `/Talntworx/user`,
                                    state: { id: row.key },
                                  });
                                  userContext.setDashboardData((pre) => {
                                    return { ...pre, totalTalentTrigger: true };
                                  });
                                } else if (dashboard && auth.isContract) {
                                  history.push({
                                    pathname: `/contract/user`,
                                    state: { id: row.key },
                                  });
                                  userContext.setDashboardData((pre) => {
                                    return { ...pre, totalTalentTrigger: true };
                                  });
                                } else if (dashboard && auth.isPrimary) {
                                  history.push({
                                    pathname: `/primary/user`,
                                    state: { id: row.key },
                                  });
                                  userContext.setDashboardData((pre) => {
                                    return { ...pre, totalTalentTrigger: true };
                                  });
                                } else if (
                                  auth.isSpectra &&
                                  userContext.userData.activeTabIndex === "1"
                                )
                                  history.push({
                                    pathname: `./userPanel/user/`,
                                    state: { id: row.key },
                                  });
                                else if (auth.isContract)
                                  history.push({
                                    pathname: `./userPanel/user/`,
                                    state: { id: row.key },
                                  });
                                else if (auth.isPrimary)
                                  history.push({
                                    pathname: `./userPanel/user/`,
                                    state: { id: row.key },
                                  });
                              }}
                              style={
                                selectedRows.includes(row.key)
                                  ? { backgroundColor: "#F5E9FF" }
                                  : null
                              }
                              key={i}
                              // style={{ width: field.width + "px" }}
                              className=""
                              title={row[field.dataIndex]}
                            >
                              {field.dataIndex === "logo" ? (
                                <img
                                  className="logo_img"
                                  src={row[field.dataIndex]}
                                  alt={row[field.dataIndex]}
                                />
                              ) : field.dataIndex === "status" ? (
                                <img
                                  src={statusSRC}
                                  alt={row[field.dataIndex]}
                                />
                              ) : (
                                // row[field.dataIndex] ? "verified" : <span style={{ color: "#53D060" }}>Yet to be verified</span>
                                row[field.dataIndex]
                              )}

                              {/* <span className='toolTip'>{row[field.dataIndex]}</span> */}
                            </td>
                          );
                        else return null;
                      })}
                      {/* Conditionally render Action column for spectra userType only */}
                      {userType === "spectra" && (
                                              <td>
                        <img
                          src={editIcon}
                          alt="Edit"
                          style={{ cursor: "pointer", marginRight: "10px" }}
                          onClick={() => handleEdit(row)}
                        />
                        <img
                          src={deleteIcon}
                          alt="Delete"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDelete(row)}
                        />
                      </td>
                      )}

                    </tr>
                  );
                else return null;
              })}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
      <Modal
        title="Confirm Deletion"
        visible={isDeleteModalVisible}
        style={{
          width: "100px",
          height: "100px",
          padding: "10px",
        }}
        onOk={confirmDelete}
        onCancel={handleCancelDelete}
        okText="Delete"
        cancelText="Cancel"
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: "18px",
            marginBottom: "-50px",
          }}
        >
          Are you sure you want to delete this user?
        </h1>
      </Modal>
    </div>
  );
}

export default ResponsiveTable;

import React from 'react';
import UserPanel from '../component/UserPanel';
import TopBar from '../component/TopBar';
import { Route, Switch } from 'react-router-dom';
import DashBoard from '../component/DashBoard';
import JobList from '../component/JobList';
import UserDetailsBase from '../component/panel/UserDetails/UserDetailsBase';
// import TotalTalents from '../component/dashboard/TotalTalents';
import TotalTalentsView from '../component/dashboard/TotalTalentsView'
import Requests from '../component/Requests';

function Contract(props) {
  
    const userType = "contract";
    const userPath = "contract";

    return (
        <div className='body'>
            <TopBar userPath={userPath}/>

            <div className='content'>
                <Switch>
                    <Route path='/contract/userPanel'>
                        <UserPanel userType={userType} userPath={userPath} />
                    </Route>
                    <Route path='/contract/' exact>
                        <DashBoard userType={userType} userPath={userPath}/>
                    </Route>
                    <Route path='/contract/jobpost'>
                        <JobList userType={userType} userPath={userPath}/>
                    </Route>
                    <Route path='/contract/requests'>
                        <Requests userType={userType} userPath={userPath}/>
                    </Route>
                    <Route path={"/"+userPath+"/user" }>
                        <UserDetailsBase userType={userType} userPath={userPath}/>
                    </Route>
                    <Route path={"/"+userPath+"/totalTalents"}>
                        <TotalTalentsView userPath={userPath}/>
                    </Route>
                </Switch>  
            </div>

        </div>
    );
}

export default Contract;
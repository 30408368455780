import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PanelHead from './panel/panelHead/PanelHead';
import UserDetailsBase from './panel/UserDetails/UserDetailsBase';
import { useEffect } from 'react';
import { useContext } from 'react';
import { dataContext } from '../context/data';

function UserPanel( {userType, userPath} ) {

  const { navList, setNavList, setDashboardData, setJobData, setMoreOptionVisible} = useContext(dataContext);
  
  useEffect(() => {
    setNavList(pre => { return { ...pre, dashBoard:false, userList:true, jobPost: false, requests: false } });
    setDashboardData(pre => {return {...pre, DblastFetch: undefined, page:0}})
    setJobData(pre => {return {...pre, DblastFetch: undefined, page:0}})
},[]);
  return (
    <div>
        <Switch>
        <Route path={"/"+userPath+"/userPanel/"} exact>
            <div onClick={() => setMoreOptionVisible(false)}>
                <PanelHead userType={userType} />
            </div>
        </Route>
        <Route path={"/"+userPath+"/userPanel/user/" }>
            <UserDetailsBase userType={userType} from='userPanel' userPath={userPath}/>
        </Route>
    </Switch>
    </div>
  )
}

export default UserPanel;
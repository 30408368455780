import React, {useContext, useEffect} from 'react'
import RequestsTable from './RequestsTable'
import { Select, Input, DatePicker, Modal, Form, Button, message, Badge } from "antd";
import { useState } from 'react';
import { dataContext } from '../../context/data';

function RequestsList({column, data}) {

    const { Option } = Select;
    const { Search } = Input;
    const {requestTabData, setRequestTabData} = useContext(dataContext);
    const [searchField, setSearchField] = useState('all');
    const [searchValue, setSearchValue] = useState('');
    const [localSearchValue, setLocalSearchValue] = useState('');

    const handleSearch = (value) => {
        if(requestTabData.activeTabIndex == '1'){
            setRequestTabData((pre) => { return {...pre, page: 0 , lastFetch: undefined,  searchValueObj : {
                fieldName: searchField,
                fieldValue: value,
            }}})
        }else{
            setRequestTabData((pre) => { return {...pre, detachTablepage: 0 , detachLastFetch: undefined, detachSearchValueObj : {
                fieldName: searchField,
                fieldValue: value,
            }}})
        }
    }


    useEffect(() => {
        if(requestTabData.activeTabIndex == '1'){
            if(requestTabData.searchValueObj){
                setSearchField(requestTabData.searchValueObj.fieldName);
                setSearchValue(requestTabData.searchValueObj.fieldValue)
            }else{
                setSearchField('all');
                setSearchValue('')
            }
        }else{
            if(requestTabData.detachSearchValueObj){
                setSearchField(requestTabData.detachSearchValueObj.fieldName);
                setSearchValue(requestTabData.detachSearchValueObj.fieldValue)
            }else{
                setSearchField('all');
                setSearchValue('')
            }
        }
    },[])
    useEffect(() => {
        if(searchValue == ''){
            if(requestTabData.activeTabIndex == '1'){
                setRequestTabData((pre) => { return {...pre,page: 0 , lastFetch: undefined, searchValueObj : undefined}})
            }else{
                setRequestTabData((pre) => { return {...pre,detachTablepage: 0 , detachLastFetch: undefined, detachSearchValueObj : undefined}})
            }
        }
    },[searchValue])
  return (
    <>
        <div className='search'>
            <div>
                <div className='searchwraper'>
                    <Select
                        defaultValue={"all"}
                        size="large"
                        className="searchSelect"
                        listHeight={200}
                        value={searchField}
                        dropdownClassName="searchOption"
                        dropdownMatchSelectWidth={false}
                        defaultActiveFirstOption={true}
                        onChange={(value) => setSearchField(value)}
                    >
                        <Option value={'all'}>All</Option>
                        {column.map((col, i) => {
                            return (
                                <Option key={i} value={col.dataIndex} style={{display: (col.dataIndex == 'dateOfJoining' || col.dataIndex == 'dateOfLeaving' || col.dataIndex == 'requestSentOn' || col.dataIndex == 'noOfTalents') ? 'none' : ''}}>{col.title}</Option>
                            )
                        })}
                    </Select>
                    {searchField == 'status' ? 
                    <Select
                        style={{ marginRight: "20px", width: "15vw" }}
                        placeholder="Select"
                        size="large"
                        value={searchValue}
                        allowClear
                        onChange={(values) => handleSearch(values)}
                    >
                        <Option value={'PENDING'}>Pending</Option>
                        <Option value={'PARTIALLY_ACCEPTED'}>Partially Accepted</Option>
                        <Option value={'COMPLETED'}>Completed</Option>
                    </Select>
                    :
                    <Search 
                        placeholder="Search"
                        size="large"
                        allowClear
                        value={searchValue}
                        // id="searchTextInput"
                        style={{ marginRight: "20px", width: "15vw" }}
                        // className="searchBox"
                        onChange={(e) => setSearchValue(e.target.value)}
                        onSearch={(value) => handleSearch(value)}
                        enterButton
                    />}
                </div>
            </div>
        </div>
        <RequestsTable tableHead={column} data={data}/>
    </>
  )
}

export default RequestsList
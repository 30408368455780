import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import deletebtn from "../../assets/icons/deletePostBtn.svg";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  orderBy,
  Timestamp,
  limit,
  startAfter,
  startAt,
  where,
  onSnapshot,
  setDoc,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import sort from "../../assets/icons/sort.svg";
import { Link } from "react-router-dom";
import { Tooltip, message, Modal, Popconfirm } from "antd";
import { useHistory } from "react-router";
import { useContext, useState } from "react";
import { dataContext } from "../../context/data";
import app from "../../firebase";
import useSortableData from "../useSortableData";

const db = getFirestore(app);

function PostTable({ data, jobDataColumn }) {
  const history = useHistory();
  const userContext = useContext(dataContext);
  const { items, requestSort } = useSortableData(data);

  const moreData = () => {
    userContext.setJobData((pre) => {
      return { ...pre, page: pre.page + 1 };
    });
  };

  const handleDeletePost = (e) => {
    e.stopPropagation();
  };

  const confirm = async (e, key) => {
    e.stopPropagation();
    try {
      await updateDoc(doc(db, "job_posts", key), {
        active_status: false,
      });
      userContext.setJobData((pre) => {
        return { ...pre, DblastFetch: undefined, page: 0 };
      });
      userContext.setJobData((pre) => {
        return { ...pre, postDelete: !pre.postDelete };
      });
      setTimeout(() => {
        message.success("job post Disabled");
      }, [1200]);
    } catch (err) {
      console.log(err);
    }
  };

  const cancel = (e) => {
    e.stopPropagation();
    // console.log(e);
  };

  const handlePost = (row) => {
    // console.log(row);
    userContext.setJobData((pre) => {
      return { ...pre, editUser: row, editTrigger: !pre.editTrigger };
    });
    history.push({
      pathname: `./jobpost/user/`,
      state: { id: row.key },
    });
  };
  return (
    <div>
      <InfiniteScroll
        dataLength={data.length}
        hasMore={true}
        // key={userContext.dashboardData.activeContractTabIndex}
        scrollableTarget="scrolTable"
        next={moreData}
      >
        <div className="jobTableList" id="scrolTable">
          <table>
            <thead>
              <tr>
                {jobDataColumn.map((itm, i) => {
                  return (
                    <th
                      key={i}
                      className={
                        itm.dataIndex == "description"
                          ? "descripThead thead"
                          : "thead"
                      }
                    >
                      {itm.title}
                    </th>
                  );
                })}
                <th className="thead"></th>
              </tr>
            </thead>
            <tbody>
              {items.map((row, idx) => {
                return (
                  <tr
                    key={idx}
                    onClick={() => {
                      handlePost(row);
                    }}
                  >
                    {jobDataColumn.map((field) => {
                      if (field.dataIndex == "description") {
                        return (
                          <td className="descriptionData">
                            <Tooltip
                              title={row[field.dataIndex]}
                              color="#262626"
                              overlayInnerStyle={{ fontWeight: 500 }}
                              placement="bottom"
                              fitToScreen={true}
                              zIndex={2}
                            >
                              {row[field.dataIndex]}
                            </Tooltip>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            className={
                              field.dataIndex == "job_id" ? "classId" : "data"
                            }
                          >
                            {row[field.dataIndex]}
                          </td>
                        );
                      }
                    })}

                    <td>
                      <Popconfirm
                        placement="topRight"
                        title={
                          "are you sure, you want to deactivate this post ?"
                        }
                        onConfirm={(e) => confirm(e, row.key)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                        overlayClassName="popUpDeletePost"
                      >
                        <img
                          src={deletebtn}
                          onClick={(e) => handleDeletePost(e)}
                        ></img>
                      </Popconfirm>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default PostTable;

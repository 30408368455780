import React from 'react';
import '../../styles/ImgPopup.scss';

function ImgPopup({ src,  handleClose }) {
// console.log({ src,  handleClose });
    return (
        <div id='imgPopup' onClick={handleClose} className='popupImgContainer'>
            <embed style={{maxHeight:"100%",maxWidth:"100%"}} src={src} alt="popup" />
        </div>
    );
}

export default ImgPopup;
import { getAuth } from "@firebase/auth";
import { createContext, useEffect, useState } from "react";
import { useAuthContext } from "./auth";
import { onAuthStateChanged } from "@firebase/auth";

export const dataContext = createContext([]);

// const [data, setContextData] = useState('hello');

// export {setContextData};

const DataProvider = ({ children }) => {
    const [userData, setUserData] = useState({ activeTabIndex: "1", activeContractTabIndex: "1", activePrimaryTabIndex: "1", filterMode: false, page: 0, confirmPage: 0, scrollable: true,filterValue: [],  filterQuery: {}, sortColumn: undefined, isSort: true, empTalSort : undefined, isEmpTalSort: false, filterQueryValue: undefined });
    const [dashboardData, setDashboardData] = useState({ activeTabIndex: "1", activeContractTabIndex: "1", activePrimaryTabIndex: "1", filterMode: false, page: 0, confirmPage: 0, scrollable: true , filterValue: [], totalTalentFilterValue: [], totalTalentPage: 0, filterQuery: {}, sortColumn: undefined, isSort: true, filterQueryValue: undefined  });
    const [jobData, setJobData] = useState({ activeTabIndex: "1", activeContractTabIndex: "1", activePrimaryTabIndex: "1",postSuccess: true,postDelete: true, filterMode: false, page: 0, rolesPage: 0, confirmPage: 0, scrollable: true ,postEditSuccess: true, filterValue: [], responsePage: 0, editTrigger: true, roleAddSuccess: true, sortColumn: undefined, isSort: true });
    const [requestTabData, setRequestTabData] = useState({ activeTabIndex: "1", sortColumn: undefined, isSort: true, page: 0, detachTablepage: 0, lastFetch: undefined, detachLastFetch: undefined});
    const [navList, setNavList] = useState({dashBoard:true, userList:false, jobPost: false, requests: false});
    const [moreOptionVisible, setMoreOptionVisible] = useState(false);
    const authContext = useAuthContext();
    const auth = getAuth();
    const user = auth.currentUser;

    onAuthStateChanged(auth, (user) => {
        if (!user) {
            authContext.signOut();
        }
     });

    return <dataContext.Provider value={{ userData, setUserData, dashboardData, setDashboardData, navList, setNavList, jobData, setJobData, moreOptionVisible, setMoreOptionVisible, requestTabData, setRequestTabData }} >{children}</dataContext.Provider>
}

export default DataProvider;
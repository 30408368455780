import React from 'react'
import Modal from 'antd/lib/modal/Modal';
import { useEffect, useState } from 'react'
import expandIcon from '../../assets/icons/expandIcon.svg'


function JobDetail({postData, description, formalities}) {

  const[isModalVisible, setIsModalVisible] = useState(false);
  const[detailType, setDetailType] = useState(1)

  const descrDetailModal = () => {
    setDetailType(1)
    setIsModalVisible(true)
  }

  const formalDetailModal = () => {
    setDetailType(2)
    setIsModalVisible(true)
  }

  return (
    <div className='jobDetail'>
    
      <Modal visible={isModalVisible} onCancel={() => setIsModalVisible(false)} centered footer={null}>
        {detailType === 1 ? 
          <div className='modalContent'><h4 className='modalHeader'>Description</h4><div>{description}</div></div> 
          : 
          <div className='modalContent'>
            <h4 className='modalHeader'>Formalities</h4>
            <ol>
              {formalities.map((itm) => {
                return(
                  <li className='formList'>{itm}</li>
                )
              })}
            </ol>
          </div>
        }
      </Modal>
      <div className='topContent'>
        {postData.map((itm) => {
          return(
            <div className='eachField'>
              <span className='th'>{itm.title}</span>
              <span className='td'>{itm.value}</span>
            </div>
          )
        })}
      </div>
      <div className='middleContent'>
        <div className='description'><div className='contentHeader'><span>Description</span><span><img  src={expandIcon} onClick={descrDetailModal}/></span></div><div className='descriptionContent' style={{marginTop:'10px', color: '#35006B', fontWeight: 500}}>{description}</div></div>
        <div className='formalities'>
          <div className='contentHeader'><span>Formalities</span><span><img  src={expandIcon} onClick={formalDetailModal}/></span></div>
          <div className='formalitiesContent'>
            <ol>
              {formalities.map((itm) => {           
                return(
                  <li style={{marginTop:'10px', color: '#35006B', fontWeight: 500}}>{itm}</li>
                )
              })}
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobDetail
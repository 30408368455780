import { getAuth, signOut } from "firebase/auth";
import { useState } from "react";

export const useProvideAuth = () => {
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(
        localStorage.getItem("token") ? true : false
    );
    const [isSpectra, setIsSpectra] = useState(
        localStorage.getItem("isSpectra") === "true" ? true : false
    );
    const [isContract, setIsContract] = useState(
        localStorage.getItem("isContract") === "true" ? true : false
    );
    const [isPrimary, setIsPrimary] = useState(
        localStorage.getItem("isPrimary") === "true" ? true : false
    );

    const signIn = ({ token, isSpectra, isContract, isPrimary }) => {
        localStorage.setItem("token", token);
        localStorage.setItem("isSpectra", isSpectra);
        localStorage.setItem("isContract", isContract);
        localStorage.setItem("isPrimary", isPrimary);
        setIsUserLoggedIn(true);
        setIsSpectra(isSpectra);
        setIsContract(isContract);
        setIsPrimary(isPrimary)
    };
    const signOutFun = () => {
        setIsUserLoggedIn(false);
        setIsSpectra(false);
        setIsContract(false);
        setIsPrimary(false);
        localStorage.removeItem("token");
        localStorage.removeItem("isSpectra");
        localStorage.removeItem("isContract");
        localStorage.removeItem("isPrimary");
    }
    const signOutUser = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            signOutFun();
        }).catch((error) => {
            console.log(error);
            signOutFun();
        });
    };

    return { isUserLoggedIn, isSpectra, isPrimary, isContract, signIn, signOut: signOutUser };
};

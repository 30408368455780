import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useContext, useState } from "react";
import { dataContext } from "../../context/data";
import YTV from "../../assets/icons/status/YTV.svg";
import CP from "../../assets/icons/status/CP.svg";
import IP from "../../assets/icons/status/IP.svg";
import E from "../../assets/icons/status/E.svg";
import UE from "../../assets/icons/status/UE.svg";
import { Button, Modal } from "antd";
import sort from "../../assets/icons/sort.svg";
import useSortableData from "../useSortableData";

function ResponseList({ talentsDataColumn, ResTalentData }) {
  const userContext = useContext(dataContext);
  const [isModal, setIsModal] = useState(false);
  const { items, requestSort } = useSortableData(ResTalentData);

  const moreData = () => {
    userContext.setJobData((pre) => {
      return { ...pre, ResponsePage: pre.ResponsePage + 1 };
    });
  };

  const handleModal = () => {
    setIsModal(false);
  };
  return (
    <div className="responseList">
      {items.length > 0 && (
        <div className="title">
          <h3>Interested Talents</h3>
        </div>
      )}
      <InfiniteScroll
        dataLength={ResTalentData.length}
        hasMore={true}
        next={moreData}
        scrollableTarget="ScrollTable"
      >
        <div
          id="ScrollTable"
          className="responseTable"
          style={{ overflowY: "auto", scrollbarWidth: "none" }}
        >
          {items.length > 0 && (
            <table>
              <thead>
                <tr>
                  {talentsDataColumn.map((value, idx) => {
                    return (
                      <th key={idx}>
                        {value.title}
                        {value.dataIndex == "address" ||
                        value.dataIndex == "mnum" ? null : (
                          <img
                            src={sort}
                            width="15px"
                            height="15px"
                            style={{ cursor: "pointer" }}
                            onClick={() => requestSort(value.dataIndex)}
                          />
                        )}
                      </th>
                    );
                  })}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {items.map((row, i) => {
                  return (
                    <tr key={i}>
                      {talentsDataColumn.map((value) => {
                        let statusSRC;
                        if (value.dataIndex === "status")
                          switch (row[value.dataIndex]) {
                            case "Completed Profile":
                              statusSRC = CP;
                              break;
                            case "Incomplete Profile":
                              statusSRC = IP;
                              break;
                            case "Yet To Be Verified":
                              statusSRC = YTV;
                              break;
                            case "Employed":
                              statusSRC = E;
                              break;
                            case "Available":
                              statusSRC = UE;
                              break;
                            default:
                              statusSRC = IP;
                              break;
                          }
                        return (
                          <td>
                            {value.dataIndex === "status" ? (
                              <img
                                src={statusSRC}
                                alt={row[value.dataIndex]}
                                width="15px"
                              />
                            ) : (
                              row[value.dataIndex]
                            )}
                          </td>
                        );
                      })}
                      <td>
                        <Button
                          className="modalBtn"
                          type="primary"
                          size="medium"
                          onClick={() => setIsModal(true)}
                        >
                          VIEW APPLICATION
                        </Button>
                      </td>
                      <Modal
                        centered
                        visible={isModal}
                        onCancel={handleModal}
                        footer={null}
                        className="responseModal"
                        width="50vw"
                      >
                        <div>
                          <div className="title">
                            <h3>Talent's Application</h3>
                          </div>
                          <div className="dateContainer">
                            <p>Joining Date</p>
                            <span>{row.joiningDate}</span>
                          </div>
                          <div className="answer" id="post-answers">
                            <div className="head">
                              <h4>Answers</h4>
                            </div>
                            <div>
                              {row.questions.map((itm) => {
                                return (
                                  <div>
                                    <p>{itm.question}</p>
                                    <span>{itm.answer}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default ResponseList;

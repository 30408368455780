import React from 'react'
import { useEffect, useContext } from 'react';
import { dataContext } from '../context/data';
import { Switch, Route } from 'react-router-dom';
import RequestsTabs from './requests/RequestsTabs';
import RequestDetail from './requests/RequestDetail';

function Requests({userPath}) {

    const { navList, setNavList, setDashboardData, setJobData, setRequestTabData, setUserData} = useContext(dataContext);

    useEffect(() => {
      setNavList(pre => { return { ...pre, dashBoard:false, userList:false, jobPost: false, requests: true } })
      setRequestTabData(pre => {return {...pre, page: 0, detachTablepage: 0, detachLastFetch: undefined, lastFetch: undefined}})
      setJobData((pre) => {
        return { ...pre, DblastFetch: undefined, page: 0 };
      });
      setDashboardData(pre => {return {...pre, DblastFetch: undefined, page:0}})
      setUserData((pre) => {
        return { ...pre, DblastFetch: undefined, page: 0 };
      });
    },[]);
  return (
    <div className='jobList'>
    <Switch>
      <Route path={"/"+userPath+"/requests"} exact>
          <div>
              <RequestsTabs />
          </div>
      </Route>
      <Route path={"/"+userPath+"/requests/detail" }>
        <RequestDetail />
      </Route>
    </Switch>
  </div>
  )
}

export default Requests
import React, { useEffect, useState, useContext } from 'react'
import RequestsList from './RequestsList'
import { query, collection, where, onSnapshot,getFirestore, orderBy, limit, startAfter } from 'firebase/firestore'
import { message } from 'antd';
import { useAuthContext } from '../../context/auth';
import { dataContext } from '../../context/data';
import axios from 'axios';
import baseUrl from '../../utils/baseUrl';

const db = getFirestore();

function DetachRequests() {
    const auth = useAuthContext();
    const {requestTabData, setRequestTabData} = useContext(dataContext);
    const [data, setData] = useState([]);
    const column = [
        {
            title: 'Status',
            dataIndex: 'status',
            sort: false,
        },
        ...((auth.isPrimary || auth.isSpectra) ? [{
            title: 'Contractors',
            dataIndex: 'contractors',
            sort: true,
        }]: []),
        ...((auth.isContract || auth.isSpectra) ? [{
            title: 'primary Employer',
            dataIndex: 'primary_employer',
            sort: true,
        }]: []),
        {
            title: 'Arrears',
            dataIndex: 'arrears',
            sort: false,
        },
        {
            title: 'Leave Encashment / LOP',
            dataIndex: 'leaveEncashment',
            sort: false,
        },
        {
            title: 'Date of Leaving',
            dataIndex: 'dateOfLeaving',
            sort: false,
        },
        {
            title: 'Request Sent on',
            dataIndex: 'requestSentOn',
            sort: false,
        },
    ]

    const getDateInFormat = (value) => {
        let date = value?.toDate();
        let formattedDate = date?.toLocaleDateString("en-IN", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric"
        });
        return formattedDate
    }

    const getDateFormat = (values) => {
        var date = new Date((values._seconds * 1000) + (values._nanoseconds / 1000000));
        let formattedDate = date?.toLocaleDateString("en-IN", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric"
        });
        return formattedDate
    }

    const getRequestsList = () => {
        message.loading({content: 'Loading...', key: 'requestList'})
        let q;
        let sort;
        if(requestTabData.detachFsSort){
            console.log('it is working')
            sort = requestTabData.detachFsSort
        }else {
            sort = {
                fieldName : 'created_at',
                order : 'desc'
            }
        }
        console.log(sort.fieldName, sort.order)
        if(auth.isSpectra){
            if(requestTabData.detachTablepage && requestTabData.detachTablepage > 0 && requestTabData.detachFsSort){
                q = query(collection(db, "detachment_requests"), orderBy("status", "desc"), where("status", "!=", "COMPLETED"), orderBy(sort.fieldName, sort.order), limit((requestTabData.detachTablepage + 1)*15))
            }else if(!requestTabData.detachTablepage && !requestTabData.detachTablepage > 0 && requestTabData.detachFsSort){
                q = query(collection(db, "detachment_requests"), orderBy("status", "desc"), where("status", "!=", "COMPLETED"), orderBy(sort.fieldName, sort.order), limit(15));
            }else if(requestTabData.detachTablepage && requestTabData.detachTablepage > 0 && !requestTabData.detachFsSort){
                q = query(collection(db, "detachment_requests"), orderBy("status", "desc"), where("status", "!=", "COMPLETED"), orderBy(sort.fieldName, sort.order), startAfter(requestTabData.detachLastFetch), limit(15))
            }else{
                q = query(collection(db, "detachment_requests"), orderBy("status", "desc"), where("status", "!=", "COMPLETED"), orderBy(sort.fieldName, sort.order), limit(15))
            }
        } else if(auth.isPrimary){
            if(requestTabData.detachTablepage && requestTabData.detachTablepage > 0 && requestTabData.detachFsSort){
                q = query(collection(db, "detachment_requests"), where('primary_employer_id', '==', localStorage.getItem('primary_employer_id')), orderBy("status", "desc"), where("status", "!=", "COMPLETED"), orderBy(sort.fieldName, sort.order), limit((requestTabData.detachTablepage + 1)*15))
            }else if(!requestTabData.detachTablepage && !requestTabData.detachTablepage > 0 && requestTabData.detachFsSort){
                q = query(collection(db, "detachment_requests"), where('primary_employer_id', '==', localStorage.getItem('primary_employer_id')), orderBy("status", "desc"), where("status", "!=", "COMPLETED"), orderBy(sort.fieldName, sort.order), limit(15));
            }else if(requestTabData.detachTablepage && requestTabData.detachTablepage > 0 && !requestTabData.detachFsSort){
                q = query(collection(db, "detachment_requests"), where('primary_employer_id', '==', localStorage.getItem('primary_employer_id')), orderBy("status", "desc"), where("status", "!=", "COMPLETED"), orderBy(sort.fieldName, sort.order), startAfter(requestTabData.detachLastFetch), limit(15))
            }else{
                q = query(collection(db, "detachment_requests"), where('primary_employer_id', '==', localStorage.getItem('primary_employer_id')), orderBy("status", "desc"), where("status", "!=", "COMPLETED"), orderBy(sort.fieldName, sort.order), limit(15))
            }
        }else if(auth.isContract){
            if(requestTabData.detachTablepage && requestTabData.detachTablepage > 0 && requestTabData.detachFsSort){
                q = query(collection(db, "detachment_requests"), where("contractor_id", "==", localStorage.getItem('contractor_id')), orderBy(sort.fieldName, sort.order), limit((requestTabData.detachTablepage + 1)*15))
            }else if(!requestTabData.detachTablepage && !requestTabData.detachTablepage > 0 && requestTabData.detachFsSort){
                console.log('it is query qworking')
                q = query(collection(db, "detachment_requests"), where("contractor_id", "==", localStorage.getItem('contractor_id')), orderBy(sort.fieldName, sort.order), limit(15));
            }else if(requestTabData.detachTablepage && requestTabData.detachTablepage > 0 && !requestTabData.detachFsSort){
                q = query(collection(db, "detachment_requests"), where("contractor_id", "==", localStorage.getItem('contractor_id')), startAfter(requestTabData.detachLastFetch), limit(15))
            }else{
                q = query(collection(db, "detachment_requests"), where("contractor_id", "==", localStorage.getItem('contractor_id')), orderBy(sort.fieldName, sort.order), limit(15))
            }
        }
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const temp = [];
            let count = 0;
            console.log(querySnapshot.docs)
            querySnapshot.forEach((doc) => {
                let data = doc.data()
                temp.push({
                    id: doc.id,
                    status: data.status,
                    contractors: data.contractor_name,
                    primary_employer: data.primary_employer_name,
                    arrears: data.arrears,
                    leaveEncashment: data.leave_encashment,
                    dateOfLeaving: getDateInFormat(data.date_of_leaving),
                    requestSentOn: getDateInFormat(data.created_at) 
                });
                if(querySnapshot.docs.length - 1 == count++){
                    setData((pre) => {
                        if(requestTabData.detachTablepage > 0  && !requestTabData.detachFsSort) {
                            return [...pre, ...temp]
                        }else{
                            return [...temp]
                        }
                    })
                    setRequestTabData((pre) => {return {...pre, detachLastFetch : doc}})
                    message.destroy('requestList')
                }
            });
            if (querySnapshot.docs.length <= 0) {
                message.destroy("requestList");
                setData(pre => {
                  if (requestTabData.detachTablepage > 0) {
                    return [...pre];
                  }
                  else return [];
                });
            }
        });

        return () => unsubscribe();
    }

    const getSearchResult = () => {
        let searchField = requestTabData.detachSearchValueObj.fieldName;
        let searchValue = requestTabData.detachSearchValueObj.fieldValue;
        if(searchField && searchValue){
            message.loading({content: 'Searching...', key: 'search', duration: 0})
            let field_name;
            let user_type = (auth.isSpectra) ? 'Talntworx' : (auth.isPrimary ? 'Primary' : 'Contract')
            let user_id = auth.isPrimary ? localStorage.getItem("primary_employer_id") : auth.isContract ? localStorage.getItem("contractor_id") : '';
            if(searchField == 'all'){
                field_name = 'all'
            }else if(searchField == 'contractors'){
                field_name = 'contractor_name'
            }else if(searchField == 'primary_employer'){
                field_name = 'primary_employer_name'
            }else if(searchField == 'arrears'){
                field_name = 'arrears'
            }else if(searchField == 'leaveEncashment'){
                field_name = 'leave_encashment'
            }
            if(requestTabData.sortColumn){
                console.log('api is calling')
                axios
                .post(
                    `${baseUrl}/detachment_requests_search`,
                    {
                      user_type: user_type,
                      user_id: user_id,
                      field_name: field_name,
                      field_value: searchValue,
                      sortColumn : [requestTabData.sortColumn],
                      title: "detach_request_search",
                    }
                ).then((res) => {
                    console.log(res.data.requestList)
                    const temp = [];
                    res.data.requestList.map((doc) => {
                        console.log(doc)
                        temp.push({
                            id: doc.id,
                            status: doc.result.status,
                            contractors: doc.result.contractor_name,
                            primary_employer: doc.result.primary_employer_name,
                            arrears: doc.result.arrears,
                            leaveEncashment: doc.result.leave_encashment,
                            dateOfLeaving: getDateFormat(doc.result.date_of_leaving),
                            requestSentOn: getDateFormat(doc.result.created_at) 
                        });
                    });
                    setData(temp)
                    message.destroy('search')
                }).catch((err) => {
                    console.log(err)
                    message.destroy('search')
                })
            }else{

                axios
                .post(
                    `${baseUrl}/detachment_requests_search`,
                    {
                      user_type: user_type,
                      user_id: user_id,
                      field_name: field_name,
                      field_value: searchValue,
                      title: "detach_request_search",
                    }
                ).then((res) => {
                    console.log(res.data.requestList)
                    const temp = [];
                    res.data.requestList.map((doc) => {
                        console.log(doc)
                        temp.push({
                            id: doc.id,
                            status: doc.result.status,
                            contractors: doc.result.contractor_name,
                            primary_employer: doc.result.primary_employer_name,
                            arrears: doc.result.arrears,
                            leaveEncashment: doc.result.leave_encashment,
                            dateOfLeaving: getDateFormat(doc.result.date_of_leaving),
                            requestSentOn: getDateFormat(doc.result.created_at) 
                        });
                    });
                    setData(temp)
                    message.destroy('search')
                }).catch((err) => {
                    console.log(err)
                    message.destroy('search')
                })
            }
        }
    }

    useEffect(() => {
        if(requestTabData.detachSearchValueObj){
            console.log('search condition is working')
            getSearchResult();
        }else{
            console.log('initial condition is working')
            getRequestsList();
        }
    },  [requestTabData.detachSearchValueObj, requestTabData.detachSortColumn, requestTabData.detachIsSort, requestTabData.detachTablepage])
  return (
    <RequestsList column={column} data={data}/>
  )
}

export default DetachRequests
import { Button, Modal } from 'antd';
import { collection, getDocs, getFirestore, query, orderBy } from "firebase/firestore";
import React from 'react';
import backDetail from '../../assets/icons/backDetail.svg';
import editPostIcon from '../../assets/icons/editPostIcon.svg';
import '../../styles/jobDetailBase.scss';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import PostForm from './PostForm'
import app from '../../firebase';

const db = getFirestore(app);

function JobDetailHeader({userPath, id}) {

  const [ isVisible, setIsVisible] = useState(false);
  const [ state, setState] = useState([]);
  const [district, setDistrict]= useState();

  useEffect(async() => {
    console.log("it is rendering");
    const States =[];
    const allValues = {}
      try{
        let q = query(collection(db, "state_city_list"), orderBy('state_name'))
        const response = await getDocs(q);
        response.forEach((itm) => { 
          let stateName = itm.data().state_name;
          let district = itm.data().districts;
          States.push(stateName);
          allValues[stateName] = district;
        })
        
      }catch(err){
        console.log(err)
      }
      setState(States);
      setDistrict(allValues);
},[])

  const handelBtn = () => {
    console.log('button is working here');
    setIsVisible(true)
  }

  return (
    <div className='jobDetailHeader'>
        <div className='goBackBtn'><Link to={"/"+userPath+"/jobpost"}><img src={backDetail} width='140px'/></Link></div>
        {state && district && <PostForm type="post" role='edit' state={state} district={district} id={id}/>}
     
    </div>
  )
}

export default JobDetailHeader
import { Input, message, Modal, Select } from 'antd';
import React, { useContext, useEffect } from 'react';
import '../../../styles/assignTalent.scss';
import editIcon from '../../../assets/icons/editIcon.svg';
import deleteIcon from '../../../assets/icons/deleteIcon.svg';
import addIcon from '../../../assets/icons/add.svg';
import { useState } from 'react';
import { addDoc, collection, getDoc, getDocs, getFirestore, query, updateDoc, where, setDoc, doc } from '@firebase/firestore';
import { dataContext } from '../../../context/data';
import axios from 'axios';
import baseUrl from '../../../utils/baseUrl';

function AssignTalent({ visible, heading = "Assign", setVisible }) {
    const db = getFirestore();
    const { Option } = Select;
    const { userData, setUserData } = useContext(dataContext);
    const [fieldData, setFieldData] = useState([]);
    const [fromTime, setFromTime] = useState(undefined);
    const [toTime, setToTime] = useState(undefined);
    const [salary, setSalary] = useState(0);
    const [salUnit, setSalUnit] = useState("");
    const [superVisorName, setSuperVisorName] = useState("");
    const [jobList, setJobList] = useState([]);
    const [jobListValue, setJobListValue] = useState("");
    const handleAddField = () => setFieldData(pre => [...pre, { label: "label", fieldValue: "" }]);
    const handleFieldLabelUpdate = (val, idx) => setFieldData(pre => pre.map((data, i) => idx === i ? { label: val, fieldValue: data.fieldValue } : data));
    const handleFieldvalueUpdate = (val, idx) => setFieldData(pre => pre.map((data, i) => idx === i ? { label: data.label, fieldValue: val } : data));
    const handleFieldDelete = idx => setFieldData(pre => pre.filter((data, i) => idx !== i));
    useEffect(() => {
        if (fromTime && toTime && (fromTime >= toTime)) {
            message.error({ content: "Please select From time less then to time" });
            setToTime(undefined);
        }
    }, [fromTime, toTime]);
    useEffect(()=>{
        const jobRoleList = async() =>{
            const empId = localStorage.getItem('primary_employer_id');
            const jobRoleDetails = query(collection(db, "job_roles"), where("user_id", "==", empId));

            const jobRoleDetailQuery = await getDocs(jobRoleDetails);
            var jobListValue = [];
            for (const jobRoleDetails of jobRoleDetailQuery.docs) {
              jobListValue.push({
                docId : jobRoleDetails.id,
                jobList : jobRoleDetails.data().designation
              })
                
            }
            setJobList(jobListValue)

        }
        jobRoleList();


    }, [])
    // console.log(fieldData);
    let time = [];
    for (let index = 0; index < 24; index++)time.push(index);
    return (
        <Modal
            onCancel={() => setVisible(false)}
            footer={false}
            header={false}
            className='assignTalentPopup'
            visible={visible}
        >
            <div className="assignTalentContainer">
                <form onReset={() => setVisible(false)} onSubmit={e => {
                    e.preventDefault();
                    // console.log(userData.selectedRows);
                    message.loading({ content: "Loading...", duration: 0, key: "assign" });
                    if ((fromTime || fromTime == 0) && (toTime || toTime == 0) && superVisorName) { // && salary && salUnit
                        // console.log(userData.selectedRows);
                        userData.selectedRows.forEach(async row => {
                            try {
                                let actEmpQ = query(collection(db, `talent_users/${row.key}/employer`), where("is_current_employer", "==", true));
                                let actEmp = await getDocs(actEmpQ);
                                actEmp.forEach(async emp => {
                                    try {
                                        const empRef = collection(emp.ref, 'assignment');
                                        let ass = query(empRef, where("is_current_assignment", "==", true));
                                        const actAssRes = await getDocs(ass);
                                        actAssRes.forEach(async as => await updateDoc(as.ref, { "is_current_assignment": false }));
                                        const newAss = {
                                            is_current_assignment: true,
                                            shift_end_time: `${toTime < 10 ? `0${toTime}` : toTime} : 00`,
                                            shift_name: "",
                                            shift_start_time: `${fromTime < 10 ? `0${fromTime}` : fromTime} : 00`,
                                            supervisor: superVisorName,
                                            // salary: salary,
                                            // salUnit: salUnit,
                                            jobRoleId : jobListValue
                                        };
                                        fieldData.forEach(f => newAss[f.label] = f.fieldValue);
                                        const resNewData = await addDoc(empRef, newAss);
                                        if (resNewData.id) {
                                            await updateDoc(doc(db, "talent_users", row.key), {
                                                'status.is_assigned': true
                                              });
                                            await  axios.post(`${baseUrl}/addEmployerDetailsInRazorPay`,
                                            {
                                                title: 'addEmpDetailsInRazorPay',             
                                                userId : row.key,
                                            }
                                            ).then((response) => {
                                                console.log("response", response);
                                          })
                                          .catch((error) => {
                                          console.log(error);
                                          });
                                            
                                            setVisible(false);
                                            setFieldData([]);
                                            setFromTime(undefined);
                                            setToTime(undefined);
                                            setSuperVisorName("")
                                            setSalUnit("");
                                            setSalary(0);
                                            setUserData(pre => { return { ...pre, lastfetch: undefined, page: 0, reload: !pre.reload } })
                                            window.location.reload();
                                            message.success({ content: `${heading}ed talent successfully!`, key: "assign" });
                                        }
                                        else {
                                            message.error({ content: "Can't assign work", key: "assign" });
                                        }
                                    } catch (err) {
                                        message.error({ content: "Something went wrong", key: "assign" });
                                    }
                                })
                            } catch (err) {
                                message.error({ content: "something went wrong", key: "assign" });
                            }
                        });
                    } else {
                        message.info({ content: "Please Fill all the details to continue", key: "assign" });
                    }
                }}>
                    <h3 className='header'>{heading} talent</h3>
                    <div className="shiftContainer">
                        <p>Shift</p>
                        <div className="timeContainer">
                            <Select value={fromTime} onChange={val => setFromTime(val)} className='selectionBox'>
                                {time.map(index =>
                                    <Option className='option' value={index}>{
                                        index === 0 ? "12" : (index < 10 ? "0" + index : (index > 12 ? (index - 12 < 10 ? "0" + (index - 12) : index - 12) : index))
                                    }{":00"}<span style={{ float: "right" }}>{index < 12 ? "AM" : "PM"}</span></Option>
                                )}
                            </Select>
                            <Select value={toTime} onChange={val => setToTime(val)} className='selectionBox'>
                                {time.map(index =>
                                    <Option className='option' value={index}>{
                                        index === 0 ? "12" : (index < 10 ? "0" + index : (index > 12 ? (index - 12 < 10 ? "0" + (index - 12) : index - 12) : index))
                                    }{":00"}<span style={{ float: "right" }}>{index < 12 ? "AM" : "PM"}</span></Option>
                                )}
                            </Select>
                        </div>
                        <div className="superName">
                            {/* <input required value={superVisorName} onChange={e => setSuperVisorName(e.currentTarget.value)} type="text" placeholder='Supervisor name' /> */}
                            <Input required value={superVisorName} onChange={e => setSuperVisorName(e.currentTarget.value)} type="text" placeholder='Supervisor name' />
                        </div>
                        <div className='joblist'>
                            <Select
                                showSearch
                                showArrow
                                size='large'
                                maxTagCount="responsive"
                                listHeight={200}
                                allowClear
                                optionFilterProp="children"
                                title={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 1
                                }
                                onChange={(value)=>setJobListValue(value)}
                                style={{
                                    minWidth: '36vw',
                                    cursor:'pointer'
                                }}>
                                {jobList.map((details, i) => (<><Option key={i} value={details.docId}>{details.jobList}</Option></>))}
                            </Select>

                            {/* <Select value={fromTime} onChange={val => setFromTime(val)} className='selectionBox'>
                                {time.map(index =>
                                    <Option className='option' value={index}>{
                                        index === 0 ? "12" : (index < 10 ? "0" + index : (index > 12 ? (index - 12 < 10 ? "0" + (index - 12) : index - 12) : index))
                                    }{":00"}<span style={{ float: "right" }}>{index < 12 ? "AM" : "PM"}</span></Option>
                                )}
                            </Select> */}
                        </div>
                        {/* <div className="salary">
                            <input required value={salary} onChange={e => {
                                if (isNaN(e.currentTarget.value)) return;
                                setSalary(e.currentTarget.value);
                            }
                            } type="text" placeholder='Enter salary' />
                            <Select value={salUnit} onChange={val => setSalUnit(val)} className='selectionBox'>
                                <Option className='option' value={"per day"}>per Day</Option>
                                <Option className='option' value={"per hour"}>per Hour</Option>
                                <Option className='option' value={"per shift"}>per Shift</Option>
                                <Option className='option' value={"fixed"}>Fixed</Option>
                                <Option className='option' value={"per other unit"}>per Other unit</Option>
                            </Select>
                        </div> */}
                    </div>
                    {fieldData.map((item, idx) => <div key={idx} className="fieldListItem">
                        <div className="label">
                            <input type="text" required value={item.label} onChange={e => handleFieldLabelUpdate(e.currentTarget.value, idx)} onFocus={e => e.currentTarget.select()} placeholder='Label' />
                            <img src={editIcon} onClick={e => e.currentTarget.previousElementSibling.focus()} alt="edit" />
                        </div>
                        <div className="input">
                            <input type="text" required value={item.fieldValue} onChange={e => handleFieldvalueUpdate(e.currentTarget.value, idx)} placeholder='Enter your text' />
                            <img onClick={() => handleFieldDelete(idx)}
                                src={deleteIcon} alt="delete" />
                        </div>
                    </div>)}
                    <img onClick={handleAddField} src={addIcon} alt="add field" className="addBtn" />
                    <div className="btnContainer">
                        <button className='btn' type='reset'>Cancel</button>
                        <button className='btn btn-primary' type='submit'>Assign</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
}
export default AssignTalent;
import React, { useEffect, useState } from "react";
import leftArrow from "../../assets/icons/leftArrow.svg";
import { useHistory, useLocation } from "react-router-dom";
import "../../styles/RequestDetails.scss";
import { Button, message, Spin, Table, Popover, Modal, Popconfirm } from "antd";
import { doc, getDoc, getFirestore, query, collection, onSnapshot, updateDoc, getDocs, where  } from "firebase/firestore";
import { useAuthContext } from "../../context/auth";
import { notification } from "antd";
import axios from "axios";
import baseUrl from "../../utils/baseUrl";

const db = getFirestore();

function DetachRequestDetails() {
  const auth = useAuthContext();
  const history = useHistory();
  const { id } = useLocation().state;
  const [detailsData, setDetailsData] = useState();
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [modalvisible, setModalVisible] = useState(false);
  const [rejectModalvisible, setRejectModalVisible] = useState(false);
  const [filteredTalents, setFilteredTalents] = useState([]);
  const [selectedTalents, setSelectedTalents] = useState([]);
  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: auth.isContract ? 'contractPage' : ''
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
      render: (address) => (
        <Popover className="addressPopup" content={() => (<p>{address}</p>)} style={{width: '20%'}}>
          <span>{address}</span>
        </Popover>
      ) 
    },
    ...(!auth.isContract
      ? [
          {
            title: "",
            width: "200px",
            render: (obj) => (
              <>
                {!(obj.isAlreadyDetached || obj.status == 'REJECTED') ? 
                <> 
                <Popconfirm
                  placement="topRight"
                  title={'are you sure, you want to reject this talent ?'}
                  onConfirm={(e) => rejectRequest(e, [obj])}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                  overlayClassName='popUpDeletePost'
                  >
                  <Button type="secondary" >Reject</Button>
                </Popconfirm>
                <Popconfirm
                  placement="topRight"
                  title={'are you sure, you want to accept this talent ?'}
                  onConfirm={(e) => acceptRequest(e, [obj])}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                  overlayClassName='popUpDeletePost'
                  >
                  <Button type='primary' style={{marginLeft: '10px'}}>Accept</Button>
                </Popconfirm>
                </>
                : <></>
              }
              </>
            ),
          },
        ]
      : []),
  ];
  const getRequestData = async () => {

    const unsubscribe = onSnapshot(doc(db, "detachment_requests", id), (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log("this is data", data);
        setDetailsData(data);
        let count = 0;
        let talentsList = [];
        setTableLoading(true);
        data.talents.map(async (talent) => {
          const talDocRef = doc(db, "talent_users", talent.id);
          const talDocSnap = await getDoc(talDocRef);
          if (talDocSnap.exists()) {
            const talData = talDocSnap.data();
            let isAlreadyDetached;
            if(talData.status.is_employed !== true && !talData.status.current_primary_employer_id){
              isAlreadyDetached = true
            }else{
              isAlreadyDetached = false
            }
            talentsList.push({
              key: talent.id,
              id: talent.id,
              status: talent.status,
              isAlreadyDetached: isAlreadyDetached,
              name: talData.personal.full_name,
              mobileNumber: talData.contact.mobile_number,
              gender: talData.personal.gender,
              emailAddress: talData.contact.email,
              address:
                talData.contact?.address_line_1 ||
                talData.contact?.address_line_2 ||
                talData.contact?.city ||
                talData.contact?.state ||
                talData.contact?.pincode
                  ? `${
                      talData.contact?.address_line_1 &&
                      `${talData.contact?.address_line_1},`
                    }${
                      talData.contact?.address_line_2 &&
                      `${talData.contact?.address_line_2},`
                    }
                ${talData.contact?.city && `${talData.contact?.city},`} ${
                      talData.contact?.pincode && `${talData.contact?.pincode},`
                    } ${talData.contact?.state && talData.contact?.state}`
                  : "--",
            });
          } else {
            message.error({
              content: "Talent Data is not available",
              key: "requestDetail",
            });
          }
          if (data.talents.length - 1 === count++) {
            setTableData(talentsList);
            setTableLoading(false);
          }
        });
      } else {
        message.error({ content: "Something went wrong" });
        setDetailsData({});
        // history.goBack();
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    })

    return () => unsubscribe();
  };
  useEffect(() => {
    console.log('id', id )
    getRequestData();
  }, []);

  useEffect(() => {
    if(tableData){
      const temp = tableData.filter((item) => {
          return !(item.isAlreadyDetached || item.status == 'REJECTED')
      })
      setFilteredTalents(temp);
    }
    
  },[tableData])

  const acceptRequest = (e, talents) => {
    e.preventDefault();
    message.loading({content: 'Detaching...', duration: 0, key: 'detach'});
    let count = 0;
    var error = [];
    var talentsStatus = [...detailsData.talents]
    const date = new Date(detailsData.date_of_leaving.seconds * 1000 + detailsData.date_of_leaving.nanoseconds / 1000000);
    const dateString = date.toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit', year: 'numeric'});
    talents.map(async (tal, i) => {
        let tref = query(collection(db, `talent_users/${tal.id}/employer`), where('is_current_employer', '==', true));
        let templyr = await getDocs(tref);
        templyr.forEach(async emp => {
          await updateDoc(doc(db, `talent_users/${tal.id}/employer`, emp.id), { 
            is_current_employer: false,
            date_of_leaving: detailsData.date_of_leaving,
            reason_of_leaving: detailsData.reason_of_leaving,
            arrears: detailsData.arrears,
            notice_period_recovery: detailsData.notice_period_recovery,
            leave_encashment: detailsData.leave_encashment,
          })
          .then(async (res) => {
            await updateDoc(doc(db, `talent_users`, tal.id), { "status.is_employed": false, "status.current_primary_employer_id": null })
            .then( async (res) => {
              let tempData = talentsStatus.map((item) => {
                let d = item;
                if(item.id == tal.id){
                  d.status = 'ACCEPTED'
                }
                return d
              })
              talentsStatus = tempData;
              await  axios.post(`${baseUrl}/dismissEmployerInRazorPay`,
              {
                title: 'dismissEmpInRazorPay',             
                email_id: tal.emailAddress,
                date_of_leaving: dateString
              }
              ).then((response) => {
                if(response.data.status !== true){
                  error.push(`${tal.name} - ${response.data.message}`)
                }
                if(talents.length - 1 == count ++){
                  setSelectedTalents([])
                  setModalVisible(false)
                  if(error.length > 0){
                    notification.error({
                      message: 'Error for data adding in Razorpay ',
                      description: error.map((s, i) => `
                      ${i + 1}. ${s}`).join('.'),
                      duration: 0,
                      key: "notification",
                      style: { whiteSpace: 'break-spaces', maxHeight: "90vh", overflow: "auto" }
                    });
                    message.error({ content: "Something went wrong", key: "detach" });
                  }else{
                    message.success({ content: "Users Detached Successfully!", key: "detach" });
                  }
                }
              }).catch(() => {
                error.push(`${tal.name} - Razorpay initial request failed`)
                if(talents.length - 1 == count ++){
                  setSelectedTalents([])
                  setModalVisible(false)
                  if(error.length > 0){
                    notification.error({
                      message: 'Error for data adding in Razorpay ',
                      description: error.map((s, i) => `
                      ${i + 1}. ${s}`).join('.'),
                      duration: 0,
                      key: "notification",
                      style: { whiteSpace: 'break-spaces', maxHeight: "90vh", overflow: "auto" }
                    });
                    message.error({ content: "Something went wrong", key: "detach" });
                  }else{
                    message.success({ content: "Users Detached Successfully!", key: "detach" });
                  }
                }
              })
              if (talents.length - 1 == i) {
                if(talentsStatus.every((obj) => obj.status !== 'PENDING')){
                  await updateDoc(doc(db, `detachment_requests`, id), {talents : talentsStatus, status: 'COMPLETED'})
                }else if(talentsStatus.some((obj) => obj.status == 'PENDING')){
                  await updateDoc(doc(db, `detachment_requests`, id), {talents : talentsStatus, status: 'PARTIALLY_ACCEPTED'})
                }
              }
              setSelectedTalents([])
              setModalVisible(false)
              if(error.length > 0){
                notification.error({
                  message: 'Error for data adding in Razorpay ',
                  description: error.map((s, i) => `
                  ${i + 1}. ${s}`).join('.'),
                  duration: 0,
                  key: "notification",
                  style: { whiteSpace: 'break-spaces', maxHeight: "90vh", overflow: "auto" }
                });
                message.error({ content: "Something went wrong", key: "detach" });
              }else{
                message.success({ content: "Users Detached Successfully!", key: "detach" });
              }
            }).catch((err) => {
              error.push(`${tal.name} - err.code`)
              if(talents.length - 1 == count ++){
                setSelectedTalents([])
                setModalVisible(false)
                if(error.length > 0){
                  notification.error({
                    message: 'Error for data adding in Razorpay ',
                    description: error.map((s, i) => `
                    ${i + 1}. ${s}`).join('.'),
                    duration: 0,
                    key: "notification",
                    style: { whiteSpace: 'break-spaces', maxHeight: "90vh", overflow: "auto" }
                  });
                  message.error({ content: "Something went wrong", key: "detach" });
                }else{
                  message.success({ content: "Users Detached Successfully!", key: "detach" });
                }
              }
            })
          }).catch((err) => {
            error.push(`${tal.name} - err.code`)
            if(talents.length - 1 == count ++){
              setSelectedTalents([])
              setModalVisible(false)
              if(error.length > 0){
                notification.error({
                  message: 'Error for data adding in Razorpay ',
                  description: error.map((s, i) => `
                  ${i + 1}. ${s}`).join('.'),
                  duration: 0,
                  key: "notification",
                  style: { whiteSpace: 'break-spaces', maxHeight: "90vh", overflow: "auto" }
                });
                message.error({ content: "Something went wrong", key: "detach" });
              }else{
                message.success({ content: "Users Detached Successfully!", key: "detach" });
              }
            }
          })
        })
    })
  }

  const rejectRequest = async (e, talents) => {
    e.preventDefault();
    let talentsStatus = [...detailsData.talents]
    let count = 0;
    console.log(talents)
    message.loading({content: 'Loading...', duration: 0, key: 'reject'})
    talents.map(async (tal) => {
      let tempData = talentsStatus.map((item) => {
        let d = item;
        if(item.id == tal.id){
          d.status = 'REJECTED'
        }
        return d
      })
      talentsStatus = tempData;
      if(talents.length - 1 == count++){
        let updateObj = null
        if(talentsStatus.every((obj) => obj.status !== 'PENDING')){
          updateObj = {
            talents : talentsStatus,
            status: 'COMPLETED'
          }
        }else if(talentsStatus.some((obj) => obj.status == 'PENDING')){
          updateObj = {
            talents : talentsStatus, 
            status: 'PARTIALLY_ACCEPTED'
          }
        }
        if(updateObj){
          await updateDoc(doc(db, "detachment_requests", id), updateObj).then((res) => {
            message.success({ content: "Users Rejected Successfully!", key: "reject" });
          }).catch((err) => {
            console.log(err)
          })

        }
      }
    })
  }

  return (
    <div className="container">
      <Modal visible={modalvisible} footer={null} onCancel={() => setModalVisible(false)}>
        <div>
          <div className="modalHeader">
            <h3>Are you sure you want to detach this <span style={{fontWeight: '900'}}>{selectedTalents.length > 0 ? selectedTalents.length : filteredTalents.length}</span> talents ?</h3>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem'}}>
            <Button onClick={() => setModalVisible(false)}>Cancel</Button>
            <Button style={{marginLeft: '20px'}} type='primary' onClick={(e) => acceptRequest(e, selectedTalents.length > 0 ? selectedTalents : filteredTalents)}>Detach</Button>
          </div>       
        </div>
      </Modal>
      <Modal visible={rejectModalvisible} footer={null} onCancel={() => setRejectModalVisible(false)}>
        <div>
          <div className="modalHeader">
            <h3>Are you sure you want to reject this <span style={{fontWeight: '900'}}>{selectedTalents.length > 0 ? selectedTalents.length : filteredTalents.length}</span> talents ?</h3>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem'}}>
            <Button onClick={() => setRejectModalVisible(false)}>Cancel</Button>
            <Button style={{marginLeft: '20px'}} type='primary' onClick={(e) => rejectRequest(e, selectedTalents.length > 0 ? selectedTalents : filteredTalents)}>Reject</Button>
          </div>       
        </div>
      </Modal>
      <div className="detailsPageHeader">
        <div className="backBtnContainer" onClick={() => history.goBack()} style={{cursor: 'pointer'}}>
          <img className="image" src={leftArrow} width={"13px"}></img>
          <h2 className="btntop">Detach Request Details</h2>
        </div>
        {!auth.isContract ? <div className="buttons">
          {(selectedTalents.length > 0 || filteredTalents.length > 0) && <> <Button type="secondary" size="middle" className="reject" onClick={() => setRejectModalVisible(true)}>{(selectedTalents.length == filteredTalents.length) ? 'Reject All' : ((selectedTalents.length  > 0)  ? 'Reject' : 'Reject All')}</Button>
          <Button type="primary" size="middle" className="accept" onClick={() => setModalVisible(true)}>{(selectedTalents.length == filteredTalents.length) ? 'Accept All' : ((selectedTalents.length  > 0)  ? 'Accept' : 'Accept All')}</Button> </>}
        </div> : <div></div>}
      </div>
      <>
        {!detailsData ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <div className="enrolldetailscroll">
            <div className="enrolldetails">
              {(auth.isSpectra || auth.isContract) && (
                <div className="reqname">
                  <label> Primaryemployer</label>
                  <h3>{detailsData.primary_employer_name}</h3>
                </div>
              )}
              {(auth.isSpectra || auth.isPrimary) && (
                <div className="reqname">
                  <label>Contractor</label>
                  <h3>{detailsData.contractor_name}</h3>
                </div>
              )}
              <div className="reqname">
                <label>Reason of dismissal</label>
                <h3>{detailsData.reason_of_leaving}</h3>
              </div>
              <div className="reqname">
                <label> Date of exists </label>
                <h3>{detailsData.date_of_leaving?.toDate().toLocaleDateString("en-IN")}</h3>
              </div>
              <div className="reqname">
                <label> Arrears</label>
                <h3>{isNaN(detailsData.arrears) ? detailsData.arrears : `₹${detailsData.arrears}`}</h3>
              </div>
              <div className="reqname">
                <label>Leave encashment/LOP</label>
                <h3>{isNaN(detailsData.leave_encashment) ? detailsData.leave_encashment : `₹${detailsData.leave_encashment}`}</h3>
              </div>
              <div className="reqname">
                <label>Notice period recovery</label>
                <h3>{isNaN(detailsData.notice_period_recovery) ? detailsData.notice_period_recovery : `₹${detailsData.notice_period_recovery}`}</h3>
              </div>
              <div className="reqname">
                <label>Request sent on</label>
                <h3>
                  {detailsData.created_at?.toDate().toLocaleDateString("en-IN")}
                </h3>
              </div>
              <div className="reqname">
                <label>No.of talents</label>
                <h3>{detailsData.talents.length}</h3>
              </div>
            </div>
            <div className="enrolldetailtable">
              <div className="heading">
                <h2> Talents</h2>
              </div>
              <div className="anttable">
                <Table
                  loading={tableLoading}
                  columns={columns}
                  dataSource={tableData}
                  rowSelection={auth.isContract ? false : {
                    onChange: (selectedRowKeys, selectedRows) => {
                      setSelectedTalents(selectedRows);
                    },
                    getCheckboxProps: (record) => ({
                      disabled: (record.isAlreadyDetached === true || record.status === 'REJECTED'),
                    }),
                  }}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
}
export default DetachRequestDetails;

import {
  Modal,
  AutoComplete,
  Input,
  Select,
  message,
  notification,
  DatePicker,
  TimePicker,
  Button,
  Tooltip,
  Form,
  Space,
} from "antd";
import uploadImage from "../../../assets/icons/uploadIcon.svg";
import downloadImage from "../../../assets/icons/downloadIcon.svg";
import whiteDownloadimage from "../../../assets/icons/whiteDownloadIcon.svg";
import upload from "../../../assets/icons/upload.svg";
import cancelBtn from "../../../assets/icons/cancel.svg";
import AssignTalent from "./AssignTalent";
import { useEffect, useRef, useState } from "react";
import {
  addDoc,
  firestore,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  getFirestore,
  Timestamp,
  deleteDoc,
  query,
  setDoc,
  where,
  serverTimestamp,
  get,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import axios from "axios";
import { useContext } from "react";
import { dataContext } from "../../../context/data";
import CSVReader from "react-csv-reader";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import CryptoJS from "crypto-js";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

const app_data_static = {
  handicapped: ["No", "Yes, Visual", "Yes, Hearing", "Yes, Locomotive"],
  blood_group: ["", "A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
  education_name: [
    "BE",
    "BTECH",
    "BCOM",
    "BA",
    "BSc",
    "BBA",
    "BFA",
    "BPharm",
    "BEd",
    "BL",
    "ME",
    "MTECH",
    "MCOM",
    "MA",
    "MSc",
    "MBA",
    "MFA",
    "MPharm",
    "MEd",
    "ML",
    "Others",
  ],
  nationality: ["India", "Sri Lanka", "Bhutan", "Nepal", "Myanmar"],
  education_name_masters: [
    "ME",
    "MTECH",
    "MA",
    "MSc",
    "MCOM",
    "MBA",
    "MFA",
    "MPharm",
    "MEd",
    "ML",
    "Others",
  ],
  education_level: [
    "10 yrs Schooling - SSLC",
    "12 yrs Schooling - HSC",
    "Diploma",
    "Bachelors",
    "Masters",
    "Others",
  ],
  education_name_bachelors: [
    "BE",
    "BTECH",
    "BCOM",
    "BA",
    "BSc",
    "BBA",
    "BFA",
    "BPharm",
    "BEd",
    "BL",
    "Others",
  ],
};

const functions_url = process.env.REACT_APP_FUNCTIONS_URL;
const TabHead = ({ userType }) => {
  const db = getFirestore();
  const [form] = Form.useForm();
  const [secondForm] = Form.useForm();
  const [isModalVisible, setisModalVisible] = useState(false);
  const { userData, setUserData } = useContext(dataContext);
  const [assignTalentVisible, setAssignTalentVisible] = useState(false);
  const [isDetachModalVisible, setisDetachModalVisible] = useState(false);
  const [isEnrollModalVisible, setisEnrollModalVisible] = useState(false);
  const [heading, setHeading] = useState("Assign");
  const [page, setPage] = useState(1);
  //for modal details
  const [userTypeSelected, setUserTypeSelected] = useState(
    "primary_employer_user"
  );
  const [businessName, setBusinessName] = useState("");
  const [editKey, setEditKey] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [{ primary_employers, contractors }, setPrimaryAndContract] = useState({
    primary_employers: [],
    contractors: [],
  });
  const [businessID, setBusinessID] = useState("");
  const [primaryEmpData, setPrimaryEmpData] = useState([]);
  const userContext = useContext(dataContext);
  const [imageValue, setImageValue] = useState(null);
  const [uploadErr, setUploadErr] = useState(false);
  const [imageUploads, setImageUpload] = useState([]);
  const [verify, setVerify] = useState(false);
  const [panIdName, setPanIdName] = useState("");
  const [jobRolesList, setJobRolesList] = useState([]);
  const [contractDetails, setContractDetails] = useState({
    bname: "",
    company: "",
    address: "",
    pan: "",
    gstin: "",
    epf: "",
    esic: "",
    pt: "",
    tan: "",
    linkedPrimaryEmployers: [],
  });
  const [primaryDetails, setPrimaryDetails] = useState({
    bname: "",
    company: "",
    address: "",
    pan: "",
    gstin: "",
    epf: "",
    esic: "",
    pt: "",
    tan: "",
  });
  const [error, setError] = useState({
    pan: false,
    gstin: false,
    epf: false,
    esic: false,
    pt: false,
    tan: false,
  });

  // console.log("contractDetails", contractDetails)

  const { Option } = Select;
  const storage = getStorage();

  const [isHovered, setIsHovered] = useState(false);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      if (
        event.target.files[0].type === "image/png" &&
        event.target.files[0].size < 200000
      ) {
        setImageValue(URL.createObjectURL(event.target.files[0]));
        setImageUpload(event.target.files[0]);
        setUploadErr(false);
      } else {
        setUploadErr(true);
        setImageUpload([]);
      }
    }
  };
  const closeUpoloadImg = () => {
    setImageValue(false);
  };

  const showErrorModal = (errorMessages) => {
    Modal.error({
      title: 'Validation Errors',
      content: (
        <ul>
          {errorMessages.map((msg, index) => (
            <li key={index}>{msg}</li>
          ))}
        </ul>
      ),
      okText: 'Close',
    });
  };

  useEffect(() => {
    setUserTypeSelected(
      userData?.activeTabIndex == "4"
        ? "contractor_user"
        : userData?.activeTabIndex == "5"
        ? "primary_employer_user"
        : userData?.activeTabIndex == "6"
        ? "spectrawise_bizops_user"
        : ""
    );
  }, [userData?.activeTabIndex]);

  useEffect(() => {
    if (userTypeSelected === "spectrawise_bizops_user")
      setBusinessName("Talntworx Technologies ");
    else setBusinessName("");
  }, [userTypeSelected]);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const metadata = {
    contentType: "image/jpeg",
  };
  async function uploadImg(str) {
    try {
      // if (str === "" || /[]{}sd/.test(str))
      const StrRef = ref(
        getStorage(),
        `/docImages/${moment().format("YYYYMMDD_hhmmss_SSS")}`
      );
      await uploadString(StrRef, str, "data_url");
      const res = await getDownloadURL(StrRef);
      console.log(res);
      return res;
    } catch (err) {
      console.log(err);
      return str;
    }

    // docIploadString(${moment()})
  }

  async function handleModal(modal) {
    if (userData?.activeTabIndex == "6")
      setBusinessName("Talntworx Technologies ");
    else setBusinessName("");
    setPhoneNumber("");
    setFullName("");
    setCountryCode("+91");
    setBusinessID("");
    if (["4", "5", "6"].includes(userData?.activeTabIndex)) {
      //get contractors and primary employers business name
      message.loading({
        content: "Please wait...",
        duration: 0,
        key: "fetching",
      });

      const primary_employers = [];
      const contractors = [];
      const q = query(collection(db, "primary_employers"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        primary_employers.push({ id: doc.id, name: doc.data().name });
      });
      const qcontra = query(collection(db, "contractors"));
      const snapshotContra = await getDocs(qcontra);
      snapshotContra.forEach((doc) => {
        contractors.push({ id: doc.id, name: doc.data().name });
      });
      setPrimaryAndContract((pre) => {
        return { contractors, primary_employers };
      });
      message.destroy("fetching");
    }
    setisModalVisible(true);
    setImageValue(false);
    setContractDetails({
      bname: "",
      company: "",
      address: "",
      pan: "",
      gstin: "",
      epf: "",
      esic: "",
      pt: "",
      tan: "",
      linkedPrimaryEmployers: [],
    });
    setPrimaryDetails({
      bname: "",
      company: "",
      address: "",
      pan: "",
      gstin: "",
      epf: "",
      esic: "",
      pt: "",
      tan: "",
    });
    setError({
      pan: false,
      gstin: false,
      epf: false,
      esic: false,
      pt: false,
      tan: false,
    });
    setVerify(false);
  }
  // console.log("imageVale", imageValue, "imageUploads", imageUploads, "Logo", userData.editUser.logo)
  async function handleModalSave() {
    if (
      !phoneNumber.match(/^[6789]\d{9}$/) &&
      ["4", "5", "6"].includes(userData?.activeTabIndex)
    ) {
      message.error({ content: "Please enter correct mobile number" });
      return;
    }
    if (userData?.activeTabIndex === "3") {
      if (uploadErr) {
        return;
      }
    }
    message.loading({ content: "Saving...", duration: 0, key: "saving" });
    if (["2", "3"].includes(userData?.activeTabIndex))
      try {
        let clecName =
          userData?.activeTabIndex === "2"
            ? "contractors"
            : "primary_employers";

        if (userData?.activeTabIndex === "2") {
          if (editKey !== null) {
            var docRef = await updateDoc(doc(db, "contractors", editKey), {
              name: contractDetails.bname,
              company_type: contractDetails.company,
              address: contractDetails.address,
              pan_number: contractDetails.pan,
              gstin_number: contractDetails.gstin,
              epf_reg_number: contractDetails.epf,
              esic_reg_number: contractDetails.esic,
              pt_reg_number: contractDetails.pt,
              tan_number: contractDetails.tan,
              linked_primary_employers: contractDetails.linkedPrimaryEmployers,
            });
            // message.destroy('saving');
            setisModalVisible(false);
            message.success({ content: "updated successfully", key: "saving" });
          } else {
            var docRef = await addDoc(collection(db, clecName), {
              name: contractDetails.bname,
              company_type: contractDetails.company,
              address: contractDetails.address,
              pan_number: contractDetails.pan,
              gstin_number: contractDetails.gstin,
              epf_reg_number: contractDetails.epf,
              esic_reg_number: contractDetails.esic,
              pt_reg_number: contractDetails.pt,
              tan_number: contractDetails.tan,
              linked_primary_employers: contractDetails.linkedPrimaryEmployers,
            });
            if (!docRef.id)
              throw Error("something went wrong while create group");
            // message.destroy('saving');
            setisModalVisible(false);
            message.success({ content: "Created successfully", key: "saving" });
          }
        } else if (userData?.activeTabIndex === "3") {
          if (editKey != null) {
            let obj;
            var promise = new Promise((resolve, reject) => {
              if (imageValue === userData.editUser.logo) {
                obj = {
                  name: primaryDetails.bname,
                  company_type: primaryDetails.company,
                  address: primaryDetails.address,
                  pan_number: primaryDetails.pan,
                  gstin_number: primaryDetails.gstin,
                  epf_reg_number: primaryDetails.epf,
                  esic_reg_number: primaryDetails.esic,
                  pt_reg_number: primaryDetails.pt,
                  tan_number: primaryDetails.tan,
                };
                resolve(obj);
              } else {
                const desertRef = ref(storage, userData.editUser.logo);

                // Delete the file
                deleteObject(desertRef)
                  .then(() => {
                    const imageUpload = ref(
                      storage,
                      `primary_employers/${moment().format(
                        "YYYYMMDD_hhmmss_SSS"
                      )}`
                    );
                    uploadBytes(imageUpload, imageUploads, metadata).then(
                      (Snapshot) => {
                        getDownloadURL(imageUpload).then(async (url) => {
                          const settedUrl = url;
                          obj = {
                            name: primaryDetails.bname,
                            company_type: primaryDetails.company,
                            address: primaryDetails.address,
                            pan_number: primaryDetails.pan,
                            gstin_number: primaryDetails.gstin,
                            epf_reg_number: primaryDetails.epf,
                            esic_reg_number: primaryDetails.esic,
                            pt_reg_number: primaryDetails.pt,
                            tan_number: primaryDetails.tan,
                            logo: settedUrl,
                          };
                          resolve(obj);
                        });
                      }
                    );
                  })
                  .catch((error) => {
                    console.log("image error", error);
                  });
              }
            });
            promise.then(
              (result) => {
                updateDoc(doc(db, `primary_employers`, editKey), result);
                message.success({
                  content: "updated successfully",
                  key: "saving",
                });
                setUserData((pre) => {
                  return { ...pre, reload: !pre.reload };
                });
                setisModalVisible(false);
              },
              function (error) {
                console.log("error", error);
              }
            );
          } else {
            const imageUpload = ref(
              storage,
              `primary_employers/${moment().format("YYYYMMDD_hhmmss_SSS")}`
            );
            uploadBytes(imageUpload, imageUploads, metadata).then(
              (Snapshot) => {
                getDownloadURL(imageUpload).then(async (url) => {
                  const settedUrl = url;
                  docRef = await addDoc(collection(db, clecName), {
                    name: primaryDetails.bname,
                    company_type: primaryDetails.company,
                    address: primaryDetails.address,
                    pan_number: primaryDetails.pan,
                    gstin_number: primaryDetails.gstin,
                    epf_reg_number: primaryDetails.epf,
                    esic_reg_number: primaryDetails.esic,
                    pt_reg_number: primaryDetails.pt,
                    tan_number: primaryDetails.tan,
                    logo: settedUrl,
                  });
                  if (!docRef.id)
                    throw Error("something went wrong while create group");
                  // message.destroy('saving');
                  message.success({
                    content: "updated successfully",
                    key: "saving",
                  });
                  setisModalVisible(false);
                  setUserData((pre) => {
                    return { ...pre, reload: !pre.reload };
                  });
                });
              }
            );
          }
        }
      } catch (err) {
        message.error({
          content: err.message || "Something went wrong",
          key: "saving",
        });
        console.log(err);
      }
    else {
      if (editKey === null) {
        try {
          const chkMobileQ = query(
            collection(db, "all_users"),
            where("mobile_number", "==", countryCode + phoneNumber)
          );
          const chkMobile = await getDocs(chkMobileQ);
          if (!chkMobile.empty) {
            // Iterate through each document in the result
            chkMobile.forEach((doc) => {
              console.log(doc.id, " => ", doc.data()); // doc.id gives document ID, doc.data() gives document data
            });
          } else {
            console.log("No matching documents found.");
          }
          if (!chkMobile.empty) {
            message.error({
              content: "Mobile number already exists",
              key: "saving",
            });
            return;
          }
          const docRef = await addDoc(collection(db, "all_users"), {
            fcm_refresh_token: "",
            user_type: userTypeSelected,
            mobile_number: countryCode + phoneNumber,
          });
          // console.log(docRef.id);
          // console.log(`${countryCode}${phoneNumber}`);
          axios
            .post(`${functions_url}/addUser`, {
              uuid: `${docRef.id}`,
              phoneNumber: `${countryCode}${phoneNumber}`,
            })
            .then(async (res) => {
              if (!res.data.uuid) throw Error("User Creation failed");
              if (res.data.uuid && res.data.uuid !== docRef.id)
                throw Error("This user already Exists");
              let userData;
              if (userTypeSelected !== "spectrawise_bizops_user")
                userData = {
                  personal: {
                    full_name: fullName,
                    contractor_id: businessID,
                    mobile_number: countryCode + phoneNumber,
                    business_name: businessName,
                  },
                  settings: {
                    talent_user_column_list: [
                      "status",
                      "name",
                      "bankAC",
                      "dob",
                      "mnum",
                      "gender",
                    ],
                  },
                };
              else
                userData = {
                  personal: {
                    business_name: businessName,
                    full_name: fullName,
                    mobile_number: countryCode + phoneNumber,
                  },
                  settings: {
                    talent_user_column_list: [
                      "status",
                      "name",
                      "bankAC",
                      "dob",
                      "mnum",
                      "gender",
                    ],
                  },
                };

              await setDoc(
                doc(db, `${userTypeSelected}s`, docRef.id),
                userData
              );
              message.success({
                content: "Created successfully",
                key: "saving",
              });

              setisModalVisible(false);
              setUserData((pre) => {
                return { ...pre, reload: !pre.reload };
              });
            })
            .catch((err) => {
              message.error({
                content: err.message || "Something went wrong",
                key: "saving",
              });

              console.dir(err);
            });
        } catch (e) {
          console.error("Error adding document: ", e);
          message.error({ content: "Something went wrong", key: "saving" });
        }
      } else {
        try {
          if (phoneNumber != userData.editUser?.mnum.slice(3)) {
            const chkMobileQ = query(
              collection(db, "all_users"),
              where("mobile_number", "==", countryCode + phoneNumber)
            );
            const chkMobile = await getDocs(chkMobileQ);
            if (!chkMobile.empty) {
              message.error({
                content: "Mobile number already exists",
                key: "saving",
              });
              return;
            }
          }
          axios
            .post(`${functions_url}/updatePhoneNumber`, {
              uuid: `${editKey}`,
              phoneNumber: `${countryCode}${phoneNumber}`,
            })
            .then(async (res) => {
              // console.log(res);
              if (!res.data.uuid) throw Error(res.data.errorMessage.message);
              if (res.data.uuid && res.data.uuid !== editKey)
                throw Error("This user already Exists");
              let updatedUserData;
              if (userTypeSelected !== "spectrawise_bizops_user")
                updatedUserData = {
                  "personal.full_name": fullName,
                  "personal.contractor_id": businessID,
                  "personal.mobile_number": countryCode + phoneNumber,
                  "personal.business_name": businessName,
                };
              else
                updatedUserData = {
                  "personal.business_name": businessName,
                  "personal.full_name": fullName,
                  "personal.mobile_number": countryCode + phoneNumber,
                };

              await updateDoc(doc(db, "all_users", editKey), {
                user_type: userTypeSelected,
                mobile_number: `${countryCode}${phoneNumber}`,
              });
              const userTypeCheck =
                userData?.activeTabIndex == "4"
                  ? "contractor_user"
                  : userData?.activeTabIndex == "5"
                  ? "primary_employer_user"
                  : userData?.activeTabIndex == "6"
                  ? "spectrawise_bizops_user"
                  : "";
              if (userTypeCheck !== userTypeSelected) {
                await deleteDoc(doc(db, `${userTypeCheck}s`, editKey));
                let userDataNew;
                if (userTypeSelected !== "spectrawise_bizops_user")
                  userDataNew = {
                    personal: {
                      full_name: fullName,
                      contractor_id: businessID,
                      mobile_number: countryCode + phoneNumber,
                      business_name: businessName,
                    },
                    settings: {
                      talent_user_column_list: [
                        "status",
                        "name",
                        "bankAC",
                        "dob",
                        "mnum",
                        "gender",
                      ],
                    },
                  };
                else
                  userDataNew = {
                    personal: {
                      business_name: businessName,
                      full_name: fullName,
                      mobile_number: countryCode + phoneNumber,
                    },
                    settings: {
                      talent_user_column_list: [
                        "status",
                        "name",
                        "bankAC",
                        "dob",
                        "mnum",
                        "gender",
                      ],
                    },
                  };
                await setDoc(
                  doc(db, `${userTypeSelected}s`, editKey),
                  userDataNew
                );
              } else
                await updateDoc(
                  doc(db, `${userTypeSelected}s`, editKey),
                  updatedUserData
                );
              message.success({
                content: "updated successfully",
                key: "saving",
              });

              setisModalVisible(false);
              setUserData((pre) => {
                return { ...pre, reload: !pre.reload };
              });
            })
            .catch((err) => {
              message.error({
                content: err.message || "Something went wrong",
                key: "saving",
              });
              // console.log("from axios problem");
              console.dir(err);
            });
        } catch (e) {
          console.error("Error updating document: ", e);
          message.error({ content: "Something went wrong", key: "saving" });
        }
      }
    }
    setUserData((pre) => {
      return { ...pre, reload: !pre.reload };
    });
    // setEditKey(null);
  }

  // const duplicateCheck = async (field, val) => {
  //   const q = query(collection(db, 'talent_users'), where(field, '==', val));
  //   const qRes = await getDocs(q);
  //   return qRes.empty;
  // }

  const createEnrollRequest = async (values) => {
    const user_id = localStorage.getItem("contractor_id");
    const docRef = doc(db, "contractors", user_id);
    const docSnap = await getDoc(docRef);
    let contractor_name;
    if (docSnap.exists()) {
      contractor_name = docSnap.data().name;
    } else {
      contractor_name = "";
    }
    let employer = primaryEmpData.find((obj) => obj.id == values.employername);
    let jobRole = jobRolesList.find((obj) => obj.id == values.jobrole);
    console.log(jobRole);

    let salary = parseFloat(values.grosssalary);
    let earnings = values.earnings.map((each) => {
      return {
        title: each.title,
        amount: parseFloat(each.amount),
      };
    });
    let deductions = values.deductions.map((each) => {
      return {
        title: each.title,
        amount: parseFloat(each.amount),
      };
    });
    let mandatorySalarySplitUp = {
      basic_salary: parseFloat(values.basic_salary),
      da: parseFloat(values.da),
      hra: parseFloat(values.hra),
      special_allowance: parseFloat(values.special_allowances),
      l_t_allowances: parseFloat(values.l_t_allowances),
      employer_pf: parseFloat(values.employer_pf),
      employer_esi: parseFloat(values.employer_esi),
    };
    let mandatoryEarnings =
      parseFloat(mandatorySalarySplitUp.basic_salary) +
      parseFloat(mandatorySalarySplitUp.da) +
      parseFloat(mandatorySalarySplitUp.hra) +
      parseFloat(mandatorySalarySplitUp.special_allowance) +
      parseFloat(mandatorySalarySplitUp.l_t_allowances) +
      parseFloat(mandatorySalarySplitUp.employer_pf) +
      parseFloat(mandatorySalarySplitUp.employer_esi);
    let additionalEarnings = earnings.reduce(
      (sum, item) => sum + parseFloat(item.amount),
      0
    );
    let salaryDeductions = deductions.reduce(
      (sum, item) => sum + parseFloat(item.amount),
      0
    );
    let startTime = moment(values.shiftStartTime).toString().slice(15, 21);
    let endTime = moment(values.shiftEndTime).toString().slice(15, 21);
    let talents = userData.selectedRows.map((tal) => {
      return {
        id: tal.key,
        status: "PENDING",
      };
    });

    if (salary == mandatoryEarnings + additionalEarnings + salaryDeductions) {
      const docObj = {
        contractor_id: user_id,
        contractor_name: contractor_name,
        primary_employer_id: employer.id,
        primary_employer_name: employer.value,
        designation: jobRole.designation,
        department: jobRole.department ? jobRole.department : "",
        salary: salary,
        salary_per_period: values.salary_per_period,
        major_salary_split: mandatorySalarySplitUp,
        earnings: earnings,
        deductions: deductions,
        date_of_joining: moment(values.dateofjoining).toDate(),
        created_at: serverTimestamp(),
        approved_by: "",
        notes: values.notes,
        shift_start_time: startTime,
        shift_end_time: endTime,
        status: "PENDING",
        supervisor_name: values.supervisorname,
        talents: talents,
      };

      console.log("docObj", docObj);
      message.loading({ content: "Loading...", duration: 0, key: "saving" });
      addDoc(collection(db, "enrollment_requests"), docObj)
        .then((res) => {
          // const updates = talents.map((id) => {
          //   return updateDoc(doc(db, "talent_users", id), { ['status.enroll_request_status']: "PENDING" })
          // });

          // Promise.all(updates)
          // .then(() => {
          setisEnrollModalVisible(false);
          form.resetFields();
          message.success({
            content: "Enroll request sent successfully",
            key: "saving",
          });
          // })
          // .catch((error) => {
          //   setisEnrollModalVisible(false)
          //   form.resetFields();
          //   message.error({ content: 'Something went wrong!', key: 'saving' })
          //   console.error("Error updating documents: ", error);
          // });
        })
        .catch((err) => {
          console.log(err);
          message.error({ content: "Something went wrong!", key: "saving" });
        });
    } else {
      message.error({
        content: "Please Check Salary Split up with Gross Salary",
      });
    }
  };

  const createDetachRequest = async (values) => {
    const user_id = localStorage.getItem("contractor_id");
    const docRef = doc(db, "contractors", user_id);
    const docSnap = await getDoc(docRef);
    let contractor_name;
    if (docSnap.exists()) {
      contractor_name = docSnap.data().name;
    } else {
      contractor_name = "";
    }
    let talents = userData.selectedRows.map((tal) => {
      return {
        id: tal.key,
        status: "PENDING",
      };
    });

    let primaryEmployerID = userData.selectedRows[0]?.employer_id;
    let primaryEmployerName = userData.selectedRows[0]?.employer;
    let docObj = {
      contractor_id: user_id,
      contractor_name: contractor_name,
      primary_employer_id: primaryEmployerID,
      primary_employer_name: primaryEmployerName,
      arrears: values.arrears,
      date_of_leaving: moment(values.dateOfLeaving).toDate(),
      leave_encashment: values.leaveEncashment,
      notice_period_recovery: values.noticePeriodRecovery,
      reason_of_leaving: values.reasonOfLeaving,
      created_at: serverTimestamp(),
      approved_by: "",
      status: "PENDING",
      talents: talents,
    };

    console.log("talents", docObj);
    message.loading({ content: "Loading...", duration: 0, key: "saving" });
    addDoc(collection(db, "detachment_requests"), docObj)
      .then((res) => {
        message.success({
          content: "Detachment request sent successfully",
          key: "saving",
        });
        secondForm.resetFields();
        setisDetachModalVisible(false);
      })
      .catch((err) => {
        message.error({ content: "Something went wrong!", key: "saving" });
        console.log(err);
      });
  };

  const primaryemployer = (value) => {
    let temp = [];
    {
      value.map((item) => {
        primaryEmpData.map((obj) => {
          if (obj.id == item) {
            temp.push({
              id: obj.id,
              name: obj.value,
            });
          }
        });
      });
    }
    setContractDetails((pre) => {
      return { ...pre, linkedPrimaryEmployers: temp };
    });
  };

  const getFormattedDate = (date) => {
    const formatDate = moment(date, "DD-MM-YYYY").toDate();
    if (formatDate.getTime()) return formatDate;
    else throw Error("Please enter Date in specified format");
  };

  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current && userData.editUser) {
      if (localStorage.getItem("count") == 1) {
        async function fetch() {
          if (["2", "3", "4", "5", "6"].includes(userData?.activeTabIndex)) {
            //get contractors and primary employers business name
            message.loading({
              content: "Please wait...",
              duration: 0,
              key: "fetching",
            });

            const primary_employers = [];
            const contractors = [];
            const q = query(collection(db, "primary_employers"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              primary_employers.push({ id: doc.id, name: doc.data().name });
            });
            const qcontra = query(collection(db, "contractors"));
            const snapshotContra = await getDocs(qcontra);
            // console.log("contractors",)
            snapshotContra.forEach((doc) => {
              contractors.push({ id: doc.id, name: doc.data().name });
              // console.log("contractors",doc.data())
            });
            setPrimaryAndContract((pre) => {
              return { contractors, primary_employers };
            });
            message.destroy("fetching");
            setisModalVisible(true);
          }
        }
        fetch();
      } else {
        localStorage.setItem("count", 1);
      }
      localStorage.setItem("count", 2);
      localStorage.setItem("editUsrMnum", userData.editUser?.mnum);
      setBusinessName(userData.editUser?.bName || "");
      setPhoneNumber(userData.editUser?.mnum?.slice(3) || "");
      setFullName(userData.editUser?.name || "");
      setCountryCode(userData.editUser?.mnum?.slice(0, 3) || "+91");
      setBusinessID(userData.editUser?.bId || "");
      setEditKey(userData.editUser?.key || "");
      setImageValue(userData.editUser?.logo || "");
      setVerify(
        userData.editUser?.pan || userData.editUser?.pan !== "" ? true : false
      );
      {
        userData.activeTabIndex == "2" && setContractDetails(userData.editUser);
      }
      {
        userData.activeTabIndex == "3" &&
          setPrimaryDetails((pre) => {
            return {
              bname: userData.editUser?.bname,
              company: userData.editUser?.company,
              address: userData.editUser?.address,
              pan: userData.editUser?.pan,
              gstin: userData.editUser?.gstin,
              epf: userData.editUser?.epf,
              esic: userData.editUser?.esic,
              pt: userData.editUser?.pt,
              tan: userData.editUser?.tan,
            };
          });
      }
      // console.log(userData.editUser);
    } else {
      isMounted.current = true;
    }
  }, [userData.editUserTrigger]);

  function handleModalCancel(e) {
    e.preventDefault();
    const userTypeCheck =
      userData?.activeTabIndex == "4"
        ? "contractor_user"
        : userData?.activeTabIndex == "5"
        ? "primary_employer_user"
        : userData?.activeTabIndex == "6"
        ? "spectrawise_bizops_user"
        : "";
    setUserTypeSelected(userTypeCheck);
    // setBusinessID(userData?.activeTabIndex);
    setContractDetails({
      bname: "",
      company: "",
      address: "",
      pan: "",
      gstin: "",
      epf: "",
      esic: "",
      pt: "",
      tan: "",
      linkedPrimaryEmployers: [],
    });
    setPrimaryDetails({
      bname: "",
      company: "",
      address: "",
      pan: "",
      gstin: "",
      epf: "",
      esic: "",
      pt: "",
      tan: "",
    });
    setError({
      pan: false,
      gstin: false,
      epf: false,
      esic: false,
      pt: false,
      tan: false,
    });
    setImageValue(false);
    setisModalVisible(false);
    setVerify(false);
  }

  function handleDetach() {
    setisDetachModalVisible(true);
    // console.log("handling deteching")
  }

  function handleEnroll() {
    setisEnrollModalVisible(true);
    // console.log("handling Enrolling")
  }

  const enrollToContractors = () => {
    let selectedRowData = userData.tableData.filter((data) =>
      userData.selectedRows.some((rkey) => rkey.key === data.key)
    );
    if (selectedRowData[0]?.contractor !== "") return false;
    return selectedRowData.every(
      (d) => d.contractor === selectedRowData[0]?.contractor
    );
  };

  const enrollCondition = () => {
    let selectedRowData = userData.tableData.filter((data) =>
      userData.selectedRows.some((rkey) => rkey.key === data.key)
    );
    if (
      selectedRowData[0]?.status !== "Available" &&
      selectedRowData[0]?.contractor == ""
    )
      return false;
    return selectedRowData.every(
      (d) => d.status === selectedRowData[0].status && d.contractor !== ""
    );
  };
  const detachCondition = () => {
    let selectedRowData = userData.tableData.filter((data) =>
      userData.selectedRows.some((rkey) => rkey.key === data.key)
    );
    console.log("selectedRowData", selectedRowData);
    if (selectedRowData[0]?.status !== "Employed") return false;
    return selectedRowData.every(
      (d) => d.employer_id === selectedRowData[0].employer_id
    );
  };

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const statusFinder = (s) => {
    if (s?.is_approved && s?.is_completed && s?.is_submitted && s?.is_employed)
      s = "Employed";
    else if (s?.is_approved && s?.is_completed && s?.is_submitted)
      s = "Available"; //need to clarify
    else if (!s?.is_approved && s?.is_completed && !s?.is_submitted)
      s = "Completed Profile";
    else if (!s?.is_approved && s?.is_submitted) s = "Yet To Be Verified";
    //if (!s?.is_approved && !s?.is_completed && s?.is_submitted)
    else s = "Incomplete Profile";
    return s;
  };

  const downloadUserList = () => {
    console.log("am downloading....:)", userContext.userData.tableData);
    message.loading({ content: "Fetching...", key: "fetching", duration: 0 });
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    //  const newPromiss= new Promise((resolve,reject)=>{
    //   getTalents();
    //   resolve('success');
    //   reject('sorry could not finish the task')
    //  })
    async function getTalents() {
      try {
        const q = query(collection(db, "talent_users"));
        const users = await await getDocs(q);
        const userstest = await (await getDocs(q)).docs;
        var temp = [];
        users.forEach(async (item) => {
          // console.log("data:",userstest.length)
          let statusItem = {
            is_approved:
              item?._document?.data?.value?.mapValue?.fields?.status?.mapValue
                ?.fields?.is_approved.booleanValue,
            is_completed:
              item?._document?.data?.value?.mapValue?.fields?.status?.mapValue
                ?.fields?.is_completed.booleanValue,
            is_submitted:
              item?._document?.data?.value?.mapValue?.fields?.status?.mapValue
                ?.fields?.is_submitted.booleanValue,
            is_employed:
              item?._document?.data?.value?.mapValue?.fields?.status?.mapValue
                ?.fields?.is_employed.booleanValue,
          };
          let s = statusFinder(statusItem);
          let anItem = {
            Approval_Status: s,
            Name: item?._document?.data?.value?.mapValue?.fields?.personal
              ?.mapValue?.fields?.full_name.stringValue,
            Contractor_Name: "",
            Blood_group:
              item?._document?.data?.value?.mapValue?.fields?.personal?.mapValue
                ?.fields?.blood_group.stringValue,
            DOB: item?._document?.data?.value?.mapValue?.fields?.personal
              ?.mapValue?.fields?.dob.timestampValue,
            mobile_number:
              item?._document?.data?.value?.mapValue?.fields?.contact?.mapValue
                ?.fields?.mobile_number.stringValue,
            Gender:
              item?._document?.data?.value?.mapValue?.fields?.personal?.mapValue
                ?.fields?.gender.stringValue,
            Email:
              item?._document?.data?.value?.mapValue?.fields?.contact?.mapValue
                ?.fields?.email.stringValue,
            Aadhaar_number:
              item?._document?.data?.value?.mapValue?.fields?.identity?.mapValue
                ?.fields?.aadhaar.mapValue.fields.aadhaar_number.stringValue,
            Pan_number:
              item?._document?.data?.value?.mapValue?.fields?.identity?.mapValue
                ?.fields?.pan?.mapValue?.fields?.pan_number?.stringValue,
            Bank_acc_no:
              item?._document?.data?.value?.mapValue?.fields?.bank?.mapValue
                ?.fields?.account_number.stringValue,
            Handicapped:
              item?._document?.data?.value?.mapValue?.fields?.personal?.mapValue
                ?.fields?.is_handicapped.stringValue,
            Employer_name: "",
            Pincode:
              item?._document?.data?.value?.mapValue?.fields?.contact?.mapValue
                ?.fields?.pincode.stringValue,
          };
          const docSnap = await getDoc(doc(db, "talent_users", item.id));
          if (docSnap.exists()) {
            let contractorId =
              item?._document?.data?.value?.mapValue?.fields?.status?.mapValue
                ?.fields?.current_contractor_id?.stringValue;
            // console.log('contractorId',contractorId);
            const contra = await getDocs(query(collection(db, `contractors`)));
            const employer = await getDocs(
              query(collection(db, `talent_users/${item.id}/employer`))
            );
            anItem.contractor_name = contra.name;
            contra.forEach((conItem) => {
              // console.log('items log:',conItem.data().name);
              if (conItem.id == contractorId) {
                anItem.contractor_name = conItem.data().name;
              }
            });
            // console.log('new contra:',contra);
            employer.forEach(async (d) => {
              try {
                anItem.employer_name = d.data().employer_name;
              } catch (err) {
                console.log(err);
              }
            });
            temp.push(anItem);
          }
        });
        setTimeout(() => {
          if (userData?.filterValue?.length) {
            let temp = userContext?.userData.tableData;
            let talentObj = [];
            temp.forEach((item) => {
              let i = {
                Approval_Status: item.status,
                Name: item.name,
                Contractor_Name: item.contractor,
                Blood_group: item.bGroup,
                DOB: item.dob,
                mobile_number: item.mnum,
                Gender: item.gender,
                Email: item.email,
                Aadhaar_number: item.aadhaar,
                Pan_number: item.pan,
                Bank_acc_no: item.bankAC,
                Handicapped: item.handicapped,
                Employer_name: item.employer,
                Pincode: item.pincode,
              };
              talentObj.push(i);
            });
            exportToCSV(talentObj, "totalTalents");
            message.destroy("fetching");
          } else {
            exportToCSV(temp, "totalTalents");
            message.destroy("fetching");
          }
        }, 3000);
      } catch (err) {
        console.log(err);
        message.error({ content: "Something went wrong", key: "fetching" });
      }
    }
    getTalents();
  };

  useEffect(() => {
    async function fetch() {
      let temp = [];
      const q = query(collection(db, "primary_employers"));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        temp.push({ value: doc.data().name, id: doc.id, key: doc.id });
      });
      // console.log(temp);
      setPrimaryEmpData(temp);
    }
    if (userType === "spectra" && isModalVisible) fetch();
  }, [isModalVisible]);

  useEffect(() => {
    async function fetch() {
      let temp = [];
      const docRef = doc(
        db,
        "contractors",
        localStorage.getItem("contractor_id")
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const contractorData = docSnap.data();
        const linkedPrimaryEmployersList =
          contractorData.linked_primary_employers;
        linkedPrimaryEmployersList?.map((doc) => {
          temp.push({ value: doc.name, id: doc.id, key: doc.id });
        });
        setPrimaryEmpData(temp);
      } else {
        console.log("No such document!");
      }
    }
    if (userType === "contract" || isEnrollModalVisible) fetch();
  }, [isEnrollModalVisible]);

  const getJobRoles = (value) => {
    async function fetch() {
      message.loading({
        content: "Fetching job roles...",
        key: "fetcingJobRole",
        duration: 0,
      });
      let temp = [];
      let count = 0;
      const q = query(
        collection(db, "job_roles"),
        where("user_id", "==", value)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc, i) => {
        const jobRoleMainDocData = doc.data();
        const responses = await Promise.all(
          ["earnings", "deductions"].map(async (type) => {
            let queryRef = query(collection(doc.ref, type));
            const res = await getDocs(queryRef);
            return res;
          })
        );
        if (responses || responses.length > 0) {
          let earnings = [];
          let deductions = [];
          responses[0].docs.forEach((earningDoc) => {
            earnings.push(earningDoc.data());
          });
          responses[1].docs.forEach((deductionDoc) => {
            deductions.push(deductionDoc.data());
          });
          const jobDetailObj = {
            ...jobRoleMainDocData,
            earnings: earnings,
            deductions: deductions,
            id: doc.id,
          };
          temp.push(jobDetailObj);
          if (querySnapshot.docs.length - 1 === count++) {
            console.log(temp);
            setJobRolesList(temp);
            message.destroy("fetcingJobRole");
          }
        }
      });
      // setPrimaryEmpData(temp);
    }
    if (value) {
      fetch();
    }
  };

  async function showMessageWithDelay(content, duration = 2000){
    return new Promise((resolve) => {
      message.error({
        content,
        key: "validate",
        duration: duration / 1000,
        onClose: resolve,
      });
    });
  }

  async function validateNumber() {
    let allClear = {
      pan: false,
      gstin: false,
      pt: false,
      tan: false,
      epf: false,
      esic: false,
    };
    let collectionName =
      userData.activeTabIndex == "2" ? "contractors" : "primary_employers";
    const Gstin = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const tan = /^([A-Z]){4}([0-9]){5}([A-Z]){1}?$/;

    message.loading({ content: "Validating...", key: "validate", duration: 0 });

    //GSTIN Validation
  //   if (contractDetails.gstin || primaryDetails.gstin) {
  //     let value = contractDetails.gstin || primaryDetails.gstin;

  //     if (Gstin.test(value)) {
  //       const q = query(
  //         collection(db, collectionName),
  //         where("gstin_number", "==", value)
  //       );
  //       const qRes = await getDocs(q);
  //     if (value !== userData.editUser?.gstin && !qRes.empty) {
  //       await showMessageWithDelay(`GSTIN number - ${value} already exists`, 3000);
  //     } else {
  //       allClear.gstin = true;
  //     }
  //   } else {
  //     setError((pre) => {
  //       return { ...pre, gstin: true };
  //     });
  //   }
  // }

  let errorMessages = [];

  // GSTIN Validation
if (contractDetails.gstin || primaryDetails.gstin) {
  let value = contractDetails.gstin || primaryDetails.gstin;
  
  // Check if the GSTIN is valid
  if (Gstin.test(value)) {
    const q = query(
      collection(db, collectionName),
      where("gstin_number", "==", value)
    );
    const qRes = await getDocs(q);

    if (value !== userData.editUser?.gstin && !qRes.empty) {
      errorMessages.push(`GSTIN number - ${value} already exists`);
      setError((prev) => ({ ...prev, gstin: true }));
    } else {
      allClear.gstin = true;
      setError((prev) => ({ ...prev, gstin: false }));
    }
  } else {
    errorMessages.push(`Invalid GSTIN number: ${value}`);
    setError((prev) => ({ ...prev, gstin: true }));
  }
} else {
  setError((prev) => ({ ...prev, gstin: true }));
}


  //PT Validation

  //   if (
  //     (contractDetails.pt.length > 10 && userData?.activeTabIndex === "2") ||
  //     (primaryDetails.pt.length > 10 && userData?.activeTabIndex === "3")
  //   ) {
  //     let value =
  //       userData.activeTabIndex == "2" ? contractDetails.pt : primaryDetails.pt;
  //     const q = query(
  //       collection(db, collectionName),
  //       where("pt_reg_number", "==", value)
  //     );
  //     const qRes = await getDocs(q);
  //   if (value !== userData.editUser?.pt && !qRes.empty) {
  //     await showMessageWithDelay(`PT Reg. number - ${value} already exists`, 3000);
  //   } else {
  //     allClear.pt = true;
  //   }
  // } else if (
  //   (contractDetails.pt.length < 10 && userData?.activeTabIndex === "2") ||
  //   (primaryDetails.pt.length < 10 && userData?.activeTabIndex === "3")
  // ) {
  //   setError((pre) => {
  //     return { ...pre, pt: true };
  //   });
  // }

  // PT Validation
  if (
    (contractDetails.pt.length > 10 && userData?.activeTabIndex === "2") ||
    (primaryDetails.pt.length > 10 && userData?.activeTabIndex === "3")
  ) {
    let value = userData.activeTabIndex == "2" ? contractDetails.pt : primaryDetails.pt;
    const q = query(collection(db, collectionName), where("pt_reg_number", "==", value));
    const qRes = await getDocs(q);
  
    if (value !== userData.editUser?.pt && !qRes.empty) {
      errorMessages.push(`PT Reg. number - ${value} already exists`);
      setError((prev) => ({ ...prev, pt: true }));
    } else {
      allClear.pt = true;
      setError((prev) => ({ ...prev, pt: false }));
    }
  } else if (
    (contractDetails.pt.length < 10 && userData?.activeTabIndex === "2") ||
    (primaryDetails.pt.length < 10 && userData?.activeTabIndex === "3")
  ) {
    errorMessages.push("PT Reg. number must be greater than 10 characters");
    setError((prev) => ({ ...prev, pt: true }));
  }


  //TAN Validation

  //   if (contractDetails.tan || primaryDetails.tan) {
  //     let value = contractDetails.tan || primaryDetails.tan;
  //     if (tan.test(value)) {
  //       const q = query(
  //         collection(db, collectionName),
  //         where("tan_number", "==", value)
  //       );
  //       const qRes = await getDocs(q);
  //     if (value !== userData.editUser?.tan && !qRes.empty) {
  //       await showMessageWithDelay(`TAN number - ${value} already exists`, 5000);
  //     } else {
  //       allClear.tan = true;
  //     }
  //   } else {
  //     setError((pre) => {
  //       return { ...pre, tan: true };
  //     });
  //   }
  // }

  // TAN Validation
  if (contractDetails.tan || primaryDetails.tan) {
    let value = contractDetails.tan || primaryDetails.tan;
    if (tan.test(value)) {
      const q = query(collection(db, collectionName), where("tan_number", "==", value));
      const qRes = await getDocs(q);
  
      if (value !== userData.editUser?.tan && !qRes.empty) {
        errorMessages.push(`TAN number - ${value} already exists`);
        setError((prev) => ({ ...prev, tan: true }));
      } else {
        allClear.tan = true;
        setError((prev) => ({ ...prev, tan: false }));
      }
    } else {
      errorMessages.push(`Invalid TAN number: ${value}`);
      setError((prev) => ({ ...prev, tan: true }));
    }
  }


  //EPF Validation
  //   if (contractDetails.epf || primaryDetails.epf) {
  //     let value =
  //       userData.activeTabIndex == "2"
  //         ? contractDetails.epf
  //         : primaryDetails.epf;
  //     const q = query(
  //       collection(db, collectionName),
  //       where("epf_reg_number", "==", value)
  //     );
  //     const qRes = await getDocs(q);
  //   if (value !== userData.editUser?.epf && !qRes.empty) {
  //     await showMessageWithDelay(`EPF Reg. number - ${value} already exists`, 3000);
  //   } else {
  //     allClear.epf = true;
  //   }
  // }

  // EPF Validation
  if (contractDetails.epf || primaryDetails.epf) {
    let value = userData.activeTabIndex == "2" ? contractDetails.epf : primaryDetails.epf;
    const q = query(collection(db, collectionName), where("epf_reg_number", "==", value));
    const qRes = await getDocs(q);
  
    if (value !== userData.editUser?.epf && !qRes.empty) {
      errorMessages.push(`EPF Reg. number - ${value} already exists`);
      setError((prev) => ({ ...prev, epf: true }));
    } else {
      allClear.epf = true;
      setError((prev) => ({ ...prev, epf: false }));
    }
  }



  //ESIC Validation
  //   if (contractDetails.esic || primaryDetails.esic) {
  //     let value =
  //       userData.activeTabIndex == "2"
  //         ? contractDetails.esic
  //         : primaryDetails.esic;
  //     const q = query(
  //       collection(db, collectionName),
  //       where("esic_reg_number", "==", value)
  //     );
  //     const qRes = await getDocs(q);
  //   if (value !== userData.editUser?.esic && !qRes.empty) {
  //     await showMessageWithDelay(`ESIC Reg. number - ${value} already exists`, 2000);
  //   } else {
  //     allClear.esic = true;
  //   }
  // }

  // ESIC Validation
  if (contractDetails.esic || primaryDetails.esic) {
    let value = userData.activeTabIndex == "2" ? contractDetails.esic : primaryDetails.esic;
    const q = query(collection(db, collectionName), where("esic_reg_number", "==", value));
    const qRes = await getDocs(q);
  
    if (value !== userData.editUser?.esic && !qRes.empty) {
      errorMessages.push(`ESIC Reg. number - ${value} already exists`);
      setError((prev) => ({ ...prev, esic: true }));
    } else {
      allClear.esic = true;
      setError((prev) => ({ ...prev, esic: false }));
    }
  }

  // After all validations, if there are any errors, show them in a single popup
// if (errorMessages.length > 0) {
//   const combinedMessage = errorMessages.join("\n");
//   await showMessageWithDelay(combinedMessage, 1000); // Show all errors in one popup
// }

if (errorMessages.length > 0) {
  showErrorModal(errorMessages); // Display errors in Modal
}


    if (
      allClear.gstin &&
      allClear.pt &&
      allClear.tan &&
      allClear.epf &&
      allClear.esic
    ) {
      message.destroy("validate");
      handleModalSave();
    } else {
      console.log(allClear);
      message.destroy("validate");
    }
  }

  function formSubmit(e) {
    e.preventDefault();
    if (userData?.activeTabIndex === "2") {
      validateNumber();
    } else if (userData?.activeTabIndex === "3") {
      validateNumber();
    } else if (
      userData?.activeTabIndex === "4" ||
      userData?.activeTabIndex === "5" ||
      userData?.activeTabIndex === "6"
    ) {
      handleModalSave();
    }
  }
  const addCompanyDetails = (e) => {
    let field = e.target.name;
    let value = e.target.value;
    setError((pre) => {
      return { ...pre, [field]: false };
    });
    if (userData?.activeTabIndex === "2") {
      setContractDetails((pre) => {
        return { ...pre, [field]: value };
      });
    } else if (userData?.activeTabIndex === "3") {
      setPrimaryDetails((pre) => {
        return { ...pre, [field]: value };
      });
    }
  };

  function onlyNumberKey(evt) {
    // Only ASCII character in that range allowed
    var ASCIICode = evt.which ? evt.which : evt.keyCode;
    if (
      (ASCIICode > 47 && ASCIICode < 58) ||
      ASCIICode == 8 ||
      ASCIICode == 46 ||
      (ASCIICode > 36 && ASCIICode < 41) ||
      (ASCIICode > 95 && ASCIICode < 106)
    )
      return true;
    return evt.preventDefault();
  }

  // const verification = async (e) => {
  //   e.preventDefault();
  //   let url = `${functions_url}/verifyCompanyPan`;
  //   // let url = "http://127.0.0.1:5001/prism-dev-uat/us-central1/verifyCompanyPan"
  //   let regex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
  //   let panNumber, collectionName;
  //   if (userData?.activeTabIndex === "2") {
  //     collectionName = "contractors";
  //     panNumber = contractDetails.pan;
  //   } else if (userData?.activeTabIndex === "3") {
  //     collectionName = "primary_employers";
  //     panNumber = primaryDetails.pan;
  //   }
  //   if (regex.test(panNumber)) {
  //     const q = query(
  //       collection(db, collectionName),
  //       where("pan_number", "==", panNumber)
  //     );
  //     const qRes = await getDocs(q);
  //     if (panNumber !== userData.editUser?.pan && !qRes.empty) {
  //       message.error({
  //         content: `Pan number - ${panNumber} is already exists`,
  //         key: "saving",
  //       });
  //     } else {
  //       message.loading({
  //         content: "verifing...",
  //         key: "panVerify",
  //         duration: 0,
  //       });
  //       axios
  //         .post(url, {
  //           id_number: panNumber,
  //         })
  //         .then((res) => {
  //           if (res.status === 200) {
  //             const {
  //               data: { data, status_code, success, message_code },
  //             } = res.data;
  //             if (
  //               status_code === 200 &&
  //               success &&
  //               message_code === "success"
  //             ) {
  //               console.log(data.category);
  //               console.log(data.full_name);
  //               setPanIdName(data.full_name);
  //               setVerify(true);
  //               message.success({
  //                 content: "verified successfully!",
  //                 key: "panVerify",
  //               });
  //             } else {
  //               message.error({
  //                 content: "Verification Failed",
  //                 key: "panVerify",
  //               });
  //             }
  //           } else {
  //             message.error({
  //               content: "Inital Request Failed",
  //               key: "panVerify",
  //             });
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           message.destroy({
  //             content: "Something went wrong",
  //             key: "panVerify",
  //           });
  //         });
  //     }
  //   } else {
  //     setError((pre) => {
  //       return { ...pre, pan: true };
  //     });
  //   }
  // };

  const verification = async (e) => {
    e.preventDefault();
    let url = `${functions_url}/verifyCompanyPan`;
    let regex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
    let panNumber, collectionName;

    if (userData?.activeTabIndex === "2") {
      collectionName = "contractors";
      panNumber = contractDetails.pan;
    } else if (userData?.activeTabIndex === "3") {
      collectionName = "primary_employers";
      panNumber = primaryDetails.pan;
    }

    if (!regex.test(panNumber)) {
      setError((pre) => ({ ...pre, pan: true }));
      return;
    }

    try {
      const q = query(
        collection(db, collectionName),
        where("pan_number", "==", panNumber)
      );
      const qRes = await getDocs(q);

      if (panNumber !== userData.editUser?.pan && !qRes.empty) {
        message.error({
          content: `Pan number - ${panNumber} already exists`,
          key: "saving",
        });
        return;
      }

      message.loading({
        content: "Verifying...",
        key: "panVerify",
        duration: 0,
      });

      const response = await axios.post(url, {
        id_number: panNumber,
      });

      const { data, status_code, success, message_code } = response.data.data;

      if (status_code === 200 && success && message_code === "success") {
        console.log(data.category);
        console.log(data.full_name);
        setPanIdName(data.full_name);
        setVerify(true);
        message.success({
          content: "Verified successfully!",
          key: "panVerify",
        });
      } else {
        throw new Error("Verification failed");
      }
    } catch (error) {
      console.error("Verification error:", error);
      message.error({
        content:
          error.response?.status === 500
            ? "Server error. Please try again later."
            : "Verification failed. Please check your PAN and try again.",
        key: "panVerify",
      });
    }
  };

  const populatingJobRoleData = (values) => {
    const obj = jobRolesList.find((obj) => obj.id == values);
    form.setFieldsValue({
      grosssalary: obj.gross_salary,
      salary_per_period: obj.salary_per_period,
      earnings: obj.earnings,
      deductions: obj.deductions,
      basic_salary: obj.basic_salary ? obj.basic_salary : 0,
      da: obj.da ? obj.da : 0,
      hra: obj.hra ? obj.hra : 0,
      special_allowances: obj.special_allowance ? obj.special_allowance : 0,
      l_t_allowances: obj.l_t_allowances ? obj.l_t_allowances : 0,
      employer_pf: obj.employer_pf ? obj.employer_pf : 0,
      employer_esi: obj.employer_esi ? obj.employer_esi : 0,
    });
  };

  function handleSalaryValidate(e, type) {
    console.log(e);
    let value = e.target.value;
    if (
      value.match(/^[0-9]{8}$/) ||
      e.code == "Minus" ||
      e.code == "NumpadSubtract"
    ) {
      console.log("it is working");
      e.preventDefault();
    }
    if (
      type == "1" &&
      e.target.value.length == 0 &&
      (e.code == "Numpad0" || e.key == 0)
    ) {
      e.preventDefault();
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  
  return (
    // for contract user
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {(userType === "contract" ||
        (userType === "spectra" &&
          userData?.activeTabIndex === "1" &&
          userData?.selectedRows?.length > 0)) && (
        <span className="batchOperation">
          {userType === "contract" &&
            userData?.activeContractTabIndex === "2" && (
              <button
                style={{ marginRight: "10px" }}
                className={
                  userData?.selectedRows?.length > 0 &&
                  (userType === "contract" ||
                    (userType === "spectra" && enrollCondition()))
                    ? "btn btn-primary tabBtn"
                    : "btn disabled tabBtn"
                }
                // use 'disabled' class for disabled btn
                onClick={handleEnroll}
              >
                Enroll Requests
              </button>
            )}
          <Modal
            className="modalClass"
            width="51%"
            centered
            onCancel={() => {
              setisEnrollModalVisible(false);
            }}
            visible={isEnrollModalVisible}
          >
            <div className="enrollModal">
              <div className="enrollModal_Header">
                <h3>Create Enroll Requests</h3>
              </div>
              <Form
                layout={"vertical"}
                form={form}
                onFinish={(values) => createEnrollRequest(values)}
                requiredMark={false}
              >
                <div className="enrollscroll">
                  <div className="enrollModalBody">
                    <div>
                      <p>Selected Talents </p>
                    </div>
                    <div className="count">
                      {`${userData?.selectedRows?.length}`}
                    </div>
                  </div>
                  <div className="enrollEmppar">
                    <div className="enrollleft">
                      <Form.Item name={"employername"} label="Employer Name">
                        <Select
                          className="input"
                          placeholder="Select"
                          onChange={(values) => getJobRoles(values)}
                          rules={[
                            {
                              required: true,
                              message: "Select Primary Employer Name",
                            },
                          ]}
                        >
                          {primaryEmpData.map((emp, i) => {
                            return (
                              <Option key={i} value={emp.id}>
                                {emp.value}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="enrollright">
                      <Form.Item
                        name={"jobrole"}
                        label="Job Role"
                        rules={[
                          {
                            required: true,
                            message: "Select Job Role",
                          },
                        ]}
                      >
                        <Select
                          className="input"
                          placeholder="Select"
                          onChange={(values) => populatingJobRoleData(values)}
                        >
                          {jobRolesList.map((role, i) => {
                            return (
                              <Option key={i} value={role.id}>
                                {role.designation}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="enrollEmppar">
                    <div className="enrollleft">
                      <Form.Item
                        name={"dateofjoining"}
                        label="Date of Joining"
                        rules={[
                          {
                            required: true,
                            message: "Select Date of Joining",
                          },
                        ]}
                      >
                        <DatePicker className="input" />
                      </Form.Item>
                    </div>

                    <div className="enrollempname" style={{ width: "48%" }}>
                      <div className="enrollEmppar">
                        <div className="enrollright">
                          <Form.Item
                            name={"grosssalary"}
                            label="Gross Salary"
                            rules={[
                              {
                                required: true,
                                message: "Enter Salary",
                              },
                            ]}
                          >
                            <Input
                              name="empselect"
                              placeholder="Enter"
                              type="number"
                              onKeyPress={(e) => handleSalaryValidate(e, "1")}
                            ></Input>
                          </Form.Item>
                        </div>
                        <div className="enro" style={{ width: "45%" }}>
                          <Form.Item name={"salary_per_period"} label=" ">
                            <Select placeholder="Select" defaultValue={"month"}>
                              <Option value="month">Month</Option>
                              <Option value="year">Year</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="enrollEm">
                    <Form.Item
                      className="empselect"
                      name={"notes"}
                      label="Notes"
                      rules={[
                        {
                          required: true,
                          message: "Enter Notes",
                        },
                      ]}
                    >
                      <Input placeholder="Enter"></Input>
                    </Form.Item>
                  </div>
                  <div className="enrollEmppar ">
                    <div className="enrollempname" style={{ width: "48%" }}>
                      <div className="enrollEmppar ">
                        <div style={{ width: "48%" }}>
                          <Form.Item
                            name={"shiftStartTime"}
                            label="Shift Start Time"
                            rules={[
                              {
                                required: true,
                                message: "Enter Shift Start Time",
                              },
                            ]}
                          >
                            <TimePicker
                              className="shiftStartTime"
                              use12Hours
                              format="h:mm A"
                              style={{
                                width: 140,
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div style={{ width: "48%" }}>
                          <Form.Item
                            name={"shiftEndTime"}
                            label="Shift End Time"
                            rules={[
                              {
                                required: true,
                                message: "Enter Shift End Time",
                              },
                            ]}
                          >
                            <TimePicker
                              className="shiftEndTime"
                              use12Hours
                              format="h:mm A"
                              style={{
                                width: 140,
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="enrollright">
                      <div>
                        <Form.Item
                          name={"supervisorname"}
                          label="Supervisor Name"
                          rules={[
                            {
                              required: true,
                              message: "Enter Supervisor Name",
                            },
                          ]}
                        >
                          <Input placeholder="Enter"></Input>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="enrollModalButtons">
                    <h4>Salary Spiltup</h4>
                  </div>
                  <div className="enrollEmppar">
                    <div className="enrollleft " style={{ width: "48%" }}>
                      <Space
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          alignItems: "center",
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          className="enrollemppar"
                          label={`Earning Title - 1`}
                          rules={[
                            {
                              required: false,
                              message: "Missing Earning label",
                            },
                          ]}
                        >
                          <Input placeholder="Enter" disabled value={"Basic"} />
                        </Form.Item>
                        <Form.Item
                          name={"basic_salary"}
                          label=" "
                          rules={[
                            {
                              required: true,
                              message: "Missing Earning Value",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter"
                            prefix="₹"
                            type="number"
                            onKeyPress={(e) => handleSalaryValidate(e)}
                          />
                        </Form.Item>
                      </Space>
                      <Space
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          alignItems: "center",
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          className="enrollemppar"
                          label={`Earning Title - 2`}
                          rules={[
                            {
                              required: false,
                              message: "Missing Earning label",
                            },
                          ]}
                        >
                          <Input placeholder="Enter" disabled value={"D A"} />
                        </Form.Item>
                        <Form.Item
                          name={"da"}
                          label=" "
                          rules={[
                            {
                              required: true,
                              message: "Missing Earning Value",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter"
                            prefix="₹"
                            type="number"
                            onKeyPress={(e) => handleSalaryValidate(e)}
                          />
                        </Form.Item>
                      </Space>
                      <Space
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          alignItems: "center",
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          className="enrollemppar"
                          label={`Earning Title - 3`}
                          rules={[
                            {
                              required: false,
                              message: "Missing Earning label",
                            },
                          ]}
                        >
                          <Input placeholder="Enter" disabled value={"HRA"} />
                        </Form.Item>
                        <Form.Item
                          name={"hra"}
                          label=" "
                          rules={[
                            {
                              required: true,
                              message: "Missing Earning Value",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter"
                            prefix="₹"
                            type="number"
                            onKeyPress={(e) => handleSalaryValidate(e)}
                          />
                        </Form.Item>
                      </Space>
                      <Space
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          alignItems: "center",
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          className="enrollemppar"
                          label={`Earning Title - 4`}
                          rules={[
                            {
                              required: false,
                              message: "Missing Earning label",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter"
                            disabled
                            value={"Special allowances"}
                          />
                        </Form.Item>
                        <Form.Item
                          name={"special_allowances"}
                          label=" "
                          rules={[
                            {
                              required: true,
                              message: "Missing Earning Value",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter"
                            prefix="₹"
                            type="number"
                            onKeyPress={(e) => handleSalaryValidate(e)}
                          />
                        </Form.Item>
                      </Space>
                      <Space
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          alignItems: "center",
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          className="enrollemppar"
                          label={`Earning Title - 5`}
                          rules={[
                            {
                              required: false,
                              message: "Missing Earning label",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter"
                            disabled
                            value={"L T Allowances"}
                          />
                        </Form.Item>
                        <Form.Item
                          name={"l_t_allowances"}
                          label=" "
                          rules={[
                            {
                              required: true,
                              message: "Missing Earning Value",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter"
                            prefix="₹"
                            type="number"
                            onKeyPress={(e) => handleSalaryValidate(e)}
                          />
                        </Form.Item>
                      </Space>
                      <Space
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          alignItems: "center",
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          className="enrollemppar"
                          label={`Earning Title - 6`}
                          rules={[
                            {
                              required: false,
                              message: "Missing Earning label",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter"
                            disabled
                            value={"Employer Pf"}
                          />
                        </Form.Item>
                        <Form.Item
                          name={"employer_pf"}
                          label=" "
                          rules={[
                            {
                              required: true,
                              message: "Missing Earning Value",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter"
                            prefix="₹"
                            type="number"
                            onKeyPress={(e) => handleSalaryValidate(e)}
                          />
                        </Form.Item>
                      </Space>
                      <Space
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          alignItems: "center",
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          className="enrollemppar"
                          label={`Earning Title - 7`}
                          rules={[
                            {
                              required: false,
                              message: "Missing Earning label",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter"
                            disabled
                            value={"Employer Esi"}
                          />
                        </Form.Item>
                        <Form.Item
                          name={"employer_esi"}
                          label=" "
                          rules={[
                            {
                              required: true,
                              message: "Missing Earning Value",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter"
                            prefix="₹"
                            type="number"
                            onKeyPress={(e) => handleSalaryValidate(e)}
                          />
                        </Form.Item>
                      </Space>
                      <Form.List name="earnings" initialValue={[""]}>
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Space
                                key={key}
                                style={{
                                  display: "flex",
                                  marginBottom: 8,
                                  alignItems: "flex-end",
                                }}
                                align="baseline"
                              >
                                <Form.Item
                                  className="enrollemppar"
                                  {...restField}
                                  name={[name, "title"]}
                                  label={`Earning Title - ${name + 1}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing Earning label",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "amount"]}
                                  label=" "
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing Earning Value",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter"
                                    prefix="₹"
                                    type="number"
                                    onKeyPress={(e) => handleSalaryValidate(e)}
                                  />
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                  style={{ marginBottom: "10px" }}
                                />
                              </Space>
                            ))}
                            <Button
                              size="small"
                              style={{
                                backgroundColor: "#0361fe",
                                color: "white",
                                width: "max-content",
                              }}
                              onClick={() => add()}
                            >
                              {" "}
                              +{" "}
                            </Button>
                          </>
                        )}
                      </Form.List>
                    </div>

                    <div className="enrollright">
                      <Form.List name="deductions" initialValue={[""]}>
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Space
                                key={key}
                                style={{
                                  display: "flex",
                                  marginBottom: 8,
                                  alignItems: "flex-end",
                                }}
                                align="baseline"
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, "title"]}
                                  label={`Deduction Title - ${name + 1}`}
                                  rules={[
                                    {
                                      required: false,
                                      message: "Missing deduction label",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "amount"]}
                                  label=" "
                                  rules={[
                                    {
                                      required: false,
                                      message: "Missing deduction Value",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter "
                                    prefix="₹"
                                    type="number"
                                    onKeyPress={(e) => handleSalaryValidate(e)}
                                  />
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                  style={{ marginBottom: "10px" }}
                                />
                              </Space>
                            ))}
                            <Button
                              size="small"
                              style={{
                                backgroundColor: "#0361fe",
                                color: "white",
                                width: "max-content",
                              }}
                              onClick={() => add()}
                            >
                              {" "}
                              +{" "}
                            </Button>
                          </>
                        )}
                      </Form.List>
                    </div>
                  </div>
                </div>
                <div
                  className="enrollModalButtons"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "24px",
                    marginBottom: "-24px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Form.Item>
                      <Button
                        style={{ marginRight: "1rem" }}
                        onClick={() => {
                          form.resetFields();
                          setisEnrollModalVisible(false);
                          setJobRolesList([]);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType="submit" type="primary">
                        Send
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </Modal>
          {userType === "contract" &&
            userData?.activeContractTabIndex === "1" && (
              <button
                className={
                  userData?.selectedRows?.length > 0 &&
                  userType === "contract" &&
                  detachCondition()
                    ? "btn btn-primary tabBtn"
                    : "btn disabled tabBtn"
                }
                onClick={handleDetach}
              >
                Detach Requests
              </button>
            )}
          <Modal
            centered
            className="modalClass"
            onCancel={() => {
              setisDetachModalVisible(false);
            }}
            visible={isDetachModalVisible}
          >
            <div className="detachModal">
              <div className="detachModalHeader">
                <h3>Create Detach Requests</h3>
              </div>
              <div className="detachModalBody">
                <div>
                  <p>
                    {userData?.selectedRows
                      ? userData?.selectedRows[0]?.employer
                      : ""}{" "}
                    :{" "}
                  </p>
                </div>
                <div className="count">{`${userData?.selectedRows?.length}`}</div>{" "}
                <span style={{ marginLeft: "10px" }}>Talents</span>
              </div>
              <Form
                form={secondForm}
                layout="vertical"
                onFinish={(values) => createDetachRequest(values)}
              >
                <div
                  className="detachModalForm"
                  style={{
                    height: "45vh",
                    overflowY: "auto",
                    marginRight: "-24px",
                    paddingRight: "24px",
                  }}
                >
                  <div className="row">
                    <div className="column">
                      <Form.Item label="Date of Leaving" name={"dateOfLeaving"}>
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    </div>
                    <div className="column">
                      <Form.Item label="Arrears" name={"arrears"}>
                        <Input
                          placeholder="Enter"
                          type="number"
                          onKeyPress={(e) => handleSalaryValidate(e)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="column">
                      <Form.Item
                        label="Leave Encashment or LOP"
                        name={"leaveEncashment"}
                      >
                        <Input
                          placeholder="Enter"
                          type="number"
                          onKeyPress={(e) => handleSalaryValidate(e)}
                        />
                      </Form.Item>
                    </div>
                    <div className="column">
                      <Form.Item
                        label="Notice Period Recovery"
                        name={"noticePeriodRecovery"}
                      >
                        <Input
                          placeholder="Enter"
                          type="number"
                          onKeyPress={(e) => handleSalaryValidate(e)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <Form.Item
                      label="Reason of Leaving"
                      name={"reasonOfLeaving"}
                    >
                      <TextArea
                        placeholder="Enter reason"
                        style={{ resize: "none" }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div
                  className="detachModalButtons"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "24px",
                    marginBottom: "-24px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Form.Item>
                      <Button
                        style={{ marginRight: "1rem" }}
                        onClick={() => {
                          secondForm.resetFields();
                          setisDetachModalVisible(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Send
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
              {/* <div className="detachModalButtons">
              <button className="detachModalButtonsCancel" onClick={() => { setisDetachModalVisible(false) }} >Cancel</button>
              <button className="detachModalButtonsDetach" onClick={() => {
                message.loading({ content: "Please wait...", duration: 0, key: "detach" });
                try {
                  userData.selectedRows.forEach(async row => {
                    let tref = query(collection(db, `talent_users/${row.key}/employer`), where('is_current_employer', '==', true));
                    let templyr = await getDocs(tref);
                    templyr.forEach(async emp => {
                      await updateDoc(doc(db, `talent_users/${row.key}/employer`, emp.id), { is_current_employer: false, date_of_exit: serverTimestamp(), });
                    });
                    await updateDoc(doc(db, `talent_users`, row.key), { "status.is_employed": false, "status.is_assigned": false, "status.current_primary_employer_id": null }).then(() => {
                      setUserData(pre => { return { ...pre, lastfetch: undefined, page: 0, reload: !pre.reload } });
                      message.success({ content: "User Detached successfully", key: "detach" });
                      setisDetachModalVisible(false);
                      window.location.reload();
                    })
                  });
                } catch (err) {
                  console.log(err);
                  message.error({ content: "Can't detach users", key: "detach" });
                }
              }}>Detach</button>
             </div> */}
            </div>
          </Modal>
        </span>
      )}
      {/* for primary user */}
      {/*userType === 'primary' &&
        <span className="batchOperation">
          {userData?.activePrimaryTabIndex === "2" &&
            <div>
              <button className={userData?.selectedRows?.length > 0 ? 'btn btn-primary tabBtn' : 'btn disabled tabBtn'} onClick={() => { setAssignTalentVisible(true); setHeading("Assign") }}>Assign</button>
            </div>
          }
          <AssignTalent heading={heading} visible={assignTalentVisible} setVisible={setAssignTalentVisible} />
          {userData?.activePrimaryTabIndex === "1" &&
            <div><button className={userData?.selectedRows?.length > 0 ? 'btn btn-primary tabBtn' : 'btn disabled tabBtn'} onClick={() => { setAssignTalentVisible(true); setHeading("Re Assign") }}>Re assign</button>
            </div>
          }
        </span>
        */}

      <span>
        {userType !== "primary" && (
          <div className="flexBox">
            <div>
              <button
                className="btn btn-primary tabBtn"
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => {
                  setEditKey(null);
                  handleModal();
                  setUploadErr(false);
                }}
              >
                Add user
              </button>
            </div>
            {userType === "spectra" && userData.activeTabIndex === "1" ? (
              <div className="btn btn-primary" onClick={downloadUserList}>
                <img src={whiteDownloadimage} id="downloadIcon" alt="" />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </span>

      {/* for Talntworx user */}
      <Modal
        onCancel={handleModalCancel}
        className={
          userData.activeTabIndex == "2"
            ? "modalClass contractorModal"
            : "modalClass" && userData.activeTabIndex == "3"
            ? "modalClass contractorModal"
            : "modalClass"
        }
        title="Basic Modal"
        visible={isModalVisible}
        centered
      >
        <div className="ModalContainer" style={{ width: "300" }}>
          {(userType === "spectra" && userData.activeTabIndex === "1") ||
          userType === "contract" ? (
            <>
              <div className="AddTalentContainer">
                <h3 className="modalHeader talentHeader">Add talent</h3>

                <p className="fileNotification">
                  1. Download the XLSX file for Talent data entry
                </p>
                <a download={true} href="/Sample.xlsx">
                  <div className="downLoadButton">
                    <div>
                      <img src={downloadImage} alt="download XLSX" />
                    </div>
                    <div>Download</div>
                  </div>
                </a>
                <CSVReader
                  inputStyle={{ visibility: "hidden", display: "none" }}
                  parserOptions={{ header: true }}
                  onFileLoaded={(data) => {
                    notification.close("notification");
                    document.querySelector("#react-csv-reader-input").value =
                      "";
                    //for filtering empty rows data
                    let cuntBgn = 0,
                      cuntEnd = 0;
                    for (const k in data[0]) cuntBgn++;
                    for (const k in data[data.length - 1]) cuntEnd++;
                    if (cuntBgn !== cuntEnd) {
                      // console.log("from pop data");
                      data.pop();
                    }
                    const dataBack = [],
                      list_mobile = [],
                      list_employ = [],
                      list_aadhaar = [],
                      list_email = [],
                      list_pan = [],
                      list_bank_ac = [],
                      error = [];
                    data.forEach((d) => {
                      let countKey = 0;
                      for (const k in d) {
                        if (d[k] == "") countKey++;
                      }
                      if (countKey !== Object.keys(d).length) dataBack.push(d);
                    });
                    //to assign filtered data
                    data = dataBack;
                    let contractor_id = "";
                    message.loading({
                      content: "verifying...",
                      duration: 0,
                      key: "mandatory",
                    });
                    let validation = new Promise(async (resolve, reject) => {
                      for (let i = 0; i < data.length; i++) {
                        let tal = data[i];
                        message.loading({
                          content: `verifying...${i}/${data.length}`,
                          duration: 0,
                          key: "mandatory",
                        });
                        // console.log(tal);
                        for (const key in tal) {
                          //list of validation
                          switch (key) {
                            case "mobile_number*":
                              if (!tal[key].match(/^[6-9][0-9]{9}$/)) {
                                error.push(`please enter correct mobile
                            ${tal[key]} is not a mobile number`);
                                // reject(error);
                                // return;
                              }
                              break;
                            case "dob*(DD-MM-YYYY)":
                              try {
                                console.log(getFormattedDate(tal[key]));
                              } catch (err) {
                                error.push(
                                  `please enter DOB in correct format for the corresponding mobile number ${tal["mobile_number*"]}`
                                );
                                // reject(error);
                                // return;
                              }
                              break;
                            // case "employment_uan*":
                            //   if (!await duplicateCheck("employment.uan", tal[key])) {
                            //     error.push(`This ${tal['employment_uan*']} employment_uan number is already exists `);
                            //     // reject(error);
                            //     // return;
                            //   }
                            //   break;
                            case "pan_number":
                              if (tal[key] !== "") {
                                if (
                                  !/[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(tal[key])
                                ) {
                                  error.push(
                                    `please enter aadhar_number in correct number format. Don't put any space between number for the corresponding mobile number ${tal["mobile_number*"]}`
                                  );
                                  // reject(error);
                                  // return;
                                }
                                // if (!await duplicateCheck("identity.pan.pan_number", tal[key])) {
                                //   error.push(`This ${tal['pan_number']} pan number is already exists `);
                                //   // reject(error);
                                //   // return;
                                // }
                              }
                              break;
                            case "aadhaar_dob*(DD-MM-YYYY)":
                              try {
                                getFormattedDate(tal[key]);
                              } catch (err) {
                                error.push(
                                  `please enter aadhaar DOB in correct format for the corresponding mobile number ${tal["mobile_number*"]}`
                                );
                                // reject(error);
                                // return;
                              }
                              break;
                            case "pan_dob(DD-MM-YYYY)":
                              if (tal[key] != "") {
                                try {
                                  getFormattedDate(tal[key]);
                                } catch (err) {
                                  error.push(
                                    `please enter PAN DOB in correct format for the corresponding mobile number ${tal["mobile_number*"]}`
                                  );
                                  // reject(error);
                                  // return;
                                }
                              }
                              break;
                            case "nominee_dob(DD-MM-YYYY)":
                              if (tal[key] != "") {
                                try {
                                  getFormattedDate(tal[key]);
                                } catch (err) {
                                  error.push(
                                    `please enter Nominee DOB in correct format for the corresponding mobile number ${tal["mobile_number*"]}`
                                  );
                                  // reject(error);
                                  // return;
                                }
                              }
                              break;
                            case "aadhaar_number*":
                              if (
                                !/^[0-9]+$/.test(tal[key]) ||
                                tal[key].length !== 12
                              ) {
                                error.push(
                                  `please enter aadhar_number in correct number format. Don't put any space between number for the corresponding mobile number ${tal["mobile_number*"]}`
                                );
                                // reject(error);
                                // return;
                              }
                              // if (!await duplicateCheck("identity.aadhaar.aadhaar_number", tal[key])) {
                              //   error.push(`This ${tal['aadhaar_number*']} aadhaar_number number is already exists `);
                              //   // reject(error);
                              //   // return;
                              // }
                              break;
                            case "nominee_aadhaar":
                              if (tal[key] !== "") {
                                if (
                                  !/^[0-9]+$/.test(tal[key]) ||
                                  tal[key].length !== 12
                                ) {
                                  error.push(
                                    `please enter nominee_aadhaar in correct number format. Don't put any space between number for the corresponding mobile number ${tal["mobile_number*"]}`
                                  );
                                  // reject(error);
                                  // return;
                                }
                              }
                              break;
                            case "ba_account_number":
                              if (tal[key] !== "") {
                                if (!/^[0-9]+$/.test(tal[key])) {
                                  error.push(
                                    `please enter bank account number in number format for the corresponding mobile number ${tal["mobile_number*"]}`
                                  );
                                  // reject(error);
                                  // return;
                                }
                                // if (!await duplicateCheck("bank.account_number", tal[key])) {
                                //   error.push(`This ${tal['ba_account_number']} bank A/C number is already exists `);
                                //   // reject(error);
                                //   // return;
                                // }
                              }
                              break;
                            case "pincode*":
                              if (
                                !/^[0-9]+$/.test(tal[key]) ||
                                tal[key].length !== 6
                              ) {
                                error.push(
                                  `please enter pincode in specified format for the corresponding mobile number ${tal["mobile_number*"]}`
                                );
                                // reject(error);
                                // return;
                              }
                              break;
                            case "aadhaar_pincode*":
                              if (
                                !/^[0-9]+$/.test(tal[key]) ||
                                tal[key].length !== 6
                              ) {
                                error.push(
                                  `please enter aadhaar pincode in specified format for the corresponding mobile number ${tal["mobile_number*"]}`
                                );
                                // reject(error);
                                // return;
                              }
                              break;
                            case "nominee_pincode":
                              if (tal[key] !== "") {
                                if (
                                  !/^[0-9]+$/.test(tal[key]) ||
                                  tal[key].length !== 6
                                ) {
                                  error.push(
                                    `please enter nominee pincode in specified format for the corresponding mobile number ${tal["mobile_number*"]}`
                                  );
                                  // reject(error);
                                  // return;
                                }
                              }
                              break;
                            case "email":
                              if (tal[key] !== "") {
                                if (
                                  !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                    tal[key]
                                  )
                                ) {
                                  error.push(
                                    `please enter correct email in specified format for the corresponding mobile number ${tal["mobile_number*"]}`
                                  );
                                  // reject(error);
                                  // return;
                                }
                                // if (!await duplicateCheck("contact.email", tal[key])) {
                                //   error.push(`This ${tal['email']} email is already exists `);
                                //   // reject(error);
                                //   // return;
                                // }
                              }
                              break;
                            case "gender*":
                              if (
                                !["Male", "Female", "Others"].includes(tal[key])
                              ) {
                                error.push(
                                  `please enter gender in specified format for the corresponding mobile number ${tal["mobile_number*"]}`
                                );
                                // reject(error);
                                // return;
                              }
                              break;
                            case "aadhaar_gender*":
                              if (
                                !["Male", "Female", "Others"].includes(tal[key])
                              ) {
                                error.push(
                                  `please enter aadhaar gender in specified format for the corresponding mobile number ${tal["mobile_number*"]}`
                                );
                                // reject(error);
                                // return;
                              }
                              break;
                            case "handicapped":
                              if (
                                !app_data_static.handicapped.includes(tal[key])
                              ) {
                                error.push(
                                  `please enter handicapped details in spicified format for the corresponding mobile number ${tal["mobile_number*"]}`
                                );
                                // reject(error);
                                // return;
                              }
                              break;
                            case "blood_group":
                              if (
                                !app_data_static.blood_group.includes(tal[key])
                              ) {
                                error.push(
                                  `please enter blood group in spicified format for the corresponding mobile number ${tal["mobile_number*"]}`
                                );
                                // reject(error);
                                // return;
                              }
                              break;
                            case "education_level*":
                              if (
                                !app_data_static.education_level.includes(
                                  tal[key]
                                )
                              ) {
                                error.push(
                                  `please enter education level in spicified format for the corresponding mobile number ${tal["mobile_number*"]}`
                                );
                                // reject(error);
                                // return;
                              }
                              break;
                            case "education_name*":
                              if (
                                tal["education_level*"] == "Bachelors" ||
                                tal["education_level*"] == "Masters"
                              ) {
                                if (
                                  !app_data_static.education_name.includes(
                                    tal[key]
                                  )
                                ) {
                                  error.push(
                                    `please enter education name in spicified format for the corresponding mobile number ${tal["mobile_number*"]}`
                                  );
                                  // reject(error);
                                  // return;
                                }
                              }
                              break;
                            case "family_maritial_status":
                              if (
                                !["Married", "Unmarried"].includes(tal[key])
                              ) {
                                error.push(
                                  `please enter family_maritial_status in spicified format for the corresponding mobile number ${tal["mobile_number*"]}`
                                );
                                // reject(error);
                                // return;
                              }
                              break;
                            case "country*":
                              if (
                                !app_data_static.nationality.includes(tal[key])
                              ) {
                                error.push(
                                  `please enter nationality in spicified format for the corresponding mobile number ${tal["mobile_number*"]}`
                                );
                                // reject(error);
                                // return;
                              }
                              break;
                            case "employment_experience*":
                              if (tal[key] != "" && isNaN(tal[key])) {
                                error.push(
                                  `please enter experience in number format and convert to months for the corresponding mobile number ${tal["mobile_number*"]}`
                                );
                                // reject(error);
                                // return;
                              }
                              break;
                          }
                          if (
                            key !== "education_name*" &&
                            key !== "employment_skill"
                          ) {
                            if (key.indexOf("*") > -1 && tal[key] === "") {
                              error.push(
                                `please fill all mandatory field ${key} is missing for the corresponding mobile number ${tal["mobile_number*"]}`
                              );
                              // reject(error);
                              // return;
                            }
                          }
                        }
                        const q = query(
                          collection(db, "all_users"),
                          where(
                            "mobile_number",
                            "==",
                            `+91${tal["mobile_number*"]}`
                          )
                        );
                        const qRes = await getDocs(q);
                        if (!qRes.empty) {
                          error.push(
                            `+91${tal["mobile_number*"]} already exists`
                          );
                          // reject(error);
                          // return;
                        }
                        list_mobile.push(tal["mobile_number*"]);
                        list_employ.push(tal["employment_uan*"]);
                        list_aadhaar.push(tal["aadhaar_number*"]);
                        if (tal["email"] !== "") list_email.push(tal["email"]);
                        if (tal["pan_number"] !== "")
                          list_pan.push(tal["pan_number"]);
                        if (tal["ba_account_number"] !== "")
                          list_bank_ac.push(tal["ba_account_number"]);
                        if (data.length - 1 == i) {
                          //to check the reduendancy
                          if (
                            new Set(list_mobile).size !== list_mobile.length
                          ) {
                            error.push(`mobile number should be unique`);
                            // reject(error);
                            // return;
                          }
                          if (
                            new Set(list_employ).size !== list_employ.length
                          ) {
                            error.push(`employment UAN should be unique`);
                            // reject(error);
                            // return;
                          }
                          if (
                            new Set(list_aadhaar).size !== list_aadhaar.length
                          ) {
                            error.push(`Aadhaar number should be unique`);
                            // reject(error);
                            // return;
                          }
                          if (new Set(list_email).size !== list_email.length) {
                            error.push(`email should be unique`);
                            // reject(error);
                            // return;
                          }
                          if (new Set(list_pan).size !== list_pan.length) {
                            error.push(`PAN number should be unique`);
                            // reject(error);
                            // return;
                          }
                          if (
                            new Set(list_bank_ac).size !== list_bank_ac.length
                          ) {
                            error.push(`Bank A/c number should be unique`);
                            // reject(error);
                            // return;
                          }
                          if (userType === "contract") {
                            const contra = await getDoc(
                              doc(
                                db,
                                "contractor_users",
                                localStorage.getItem("uid")
                              )
                            );
                            if (contra.exists()) {
                              contractor_id =
                                contra.data().personal.contractor_id;
                            } else {
                              message.error({
                                content: "Can't fetch Contractor details",
                                key: "mandatory",
                              });
                            }
                          }
                          if (error.length) reject(error);
                          resolve();
                        }
                      }
                    });
                    validation
                      .then(() => {
                        data.forEach(async (tal, i) => {
                          const value = CryptoJS.SHA256(data.aadhaar_number);
                          message.loading({
                            content: `Uploading...${i}/${data.length}`,
                            duration: 0,
                            key: "mandatory",
                          });
                          // console.log(tal['mobile_number*']);
                          try {
                            const validData = {
                              bank: {
                                is_verified: false,
                                bank_name: tal.ba_bank_name,
                                account_number: tal.ba_account_number,
                                ifsc_code: tal.ba_ifsc_code,
                                account_name: tal.ba_account_holder_name,
                              },
                              personal: {
                                handicapped_reason: ["NO", "no", "No"].includes(
                                  tal.handicapped
                                )
                                  ? ""
                                  : tal.handicapped,
                                blood_group: tal.blood_group,
                                full_name: tal["name*"],
                                is_handicapped: ["NO", "no", "No"].includes(
                                  tal.handicapped
                                )
                                  ? false
                                  : true,
                                gender: `${tal["gender*"]
                                  .charAt(0)
                                  .toUpperCase()}${tal["gender*"].slice(1)}`,
                                dob: getFormattedDate(tal["dob*(DD-MM-YYYY)"]),
                                nationality: tal.nationality,
                              },
                              contact: {
                                mobile_number: `+91${tal["mobile_number*"]}`,
                                pincode: tal["pincode*"],
                                email: tal.email,
                                state: tal["state*"],
                                country: tal["country*"],
                                city: tal["district*"],
                                address_line_2: tal["address_line_2*"],
                                address_line_1: tal["address_line_1*"],
                              },
                              identity: {
                                aadhaar: {
                                  aadhaar_hash: value.toString(
                                    CryptoJS.enc.Hex
                                  ),
                                  address: `${tal["aadhaar_address*"]},${tal["aadhaar_district*"]},${tal["aadhaar_state*"]},${tal["aadhaar_pincode*"]}`,
                                  url: "",
                                  gender: `${tal["aadhaar_gender*"]
                                    .charAt(0)
                                    .toUpperCase()}${tal[
                                    "aadhaar_gender*"
                                  ].slice(1)}`,
                                  dob: getFormattedDate(
                                    tal["aadhaar_dob*(DD-MM-YYYY)"]
                                  ),
                                  aadhaar_number: tal["aadhaar_number*"],
                                  name: tal["aadhaar_name*"],
                                  is_verified: false,
                                },
                                pan: {
                                  dob: tal["pan_dob(DD-MM-YYYY)"]
                                    ? getFormattedDate(
                                        tal["pan_dob(DD-MM-YYYY)"]
                                      )
                                    : "",
                                  is_verified: false,
                                  name: tal["pan_name"],
                                  pan_number: tal["pan_number"],
                                  url: await uploadImg(tal["pan_image"]),
                                },
                              },
                              nominee: {
                                city: tal.nominee_district,
                                dob: tal["nominee_dob(DD-MM-YYYY)"]
                                  ? getFormattedDate(
                                      tal["nominee_dob(DD-MM-YYYY)"]
                                    )
                                  : "",
                                address_line_1: tal.nominee_address_line_1,
                                address_line_2: tal.nominee_address_line_2,
                                pincode: tal.nominee_pincode,
                                relationship: tal.nominee_relationship,
                                aadhaar_number: tal.nominee_aadhaar,
                                state: tal.nominee_state,
                                name: tal.nominee_name,
                                country: tal.nominee_country,
                              },
                              status: {
                                current_contractor_id: contractor_id,
                                is_employed: false,
                                is_submitted: true,
                                is_approved: false,
                                is_completed: true,
                              },
                              family: {
                                spouse_name: tal.family_spouse_name,
                                is_married:
                                  tal.family_maritial_status === "Married"
                                    ? true
                                    : false,
                                father_name: tal.family_father_name,
                              },
                              education: {
                                level: tal["education_level*"],
                                other_name: tal["education_other_name*"],
                                name: tal["education_name*"],
                                url: [await uploadImg(tal["education_image"])],
                              },
                              employment: {
                                skill: tal["employment_skill*"],
                                uan: tal["employment_uan*"],
                                experience: tal["employment_experience*"],
                              },
                            };
                            const docRef = await addDoc(
                              collection(db, "all_users"),
                              {
                                fcm_refresh_token: "",
                                mobile_number: `+91${tal["mobile_number*"]}`,
                                user_type: "talent",
                              }
                            );
                            const axioRes = await axios.post(
                              `${functions_url}./addUser`,
                              {
                                uuid: `${docRef.id}`,
                                phoneNumber: `+91${tal["mobile_number*"]}`,
                              }
                            );
                            // console.log(axioRes);
                            if (!axioRes.data.uuid) {
                              await deleteDoc(doc(db, "all_users", docRef.id));
                              throw Error("User Creation failed");
                            }
                            if (
                              axioRes.data.uuid &&
                              axioRes.data.uuid !== docRef.id
                            ) {
                              await deleteDoc(doc(db, "all_users", docRef.id));
                              throw Error("This user already Exists");
                            }
                            await setDoc(
                              doc(db, "talent_users", docRef.id),
                              validData
                            );
                            if (userType === "contract") {
                              await addDoc(
                                collection(
                                  db,
                                  `talent_users/${docRef.id}/contractor`
                                ),
                                {
                                  contractor_id: contractor_id,
                                  date_of_exit: "",
                                  date_of_joining: serverTimestamp(),
                                  is_current_contractor: true,
                                }
                              );
                            }

                            if (data.length - 1 === i) {
                              message.success({
                                content: "Details added successfully",
                                key: "mandatory",
                              });
                              setUserData((pre) => {
                                return { ...pre, reload: !pre.reload };
                              });
                              setisModalVisible(false);
                            }
                          } catch (err) {
                            message.error({
                              content: "something went wrong",
                              key: "mandatory",
                            });

                            console.log(err);
                          }
                        });
                      })
                      .catch((err) => {
                        notification.error({
                          message: "Data breaks the rules",
                          description: err
                            .map(
                              (s, i) => `
                    ${i + 1}. ${s}`
                            )
                            .join("."),
                          duration: 0,
                          key: "notification",
                          style: {
                            whiteSpace: "break-spaces",
                            maxHeight: "90vh",
                            overflow: "auto",
                          },
                        });
                        message.error({
                          content: "Please fix the following errors",
                          key: "mandatory",
                        });
                        console.log(err);
                      });
                  }}
                />
                <p className="fileNotification">
                  2. Upload the filled CSV file
                </p>
                <label htmlFor="react-csv-reader-input">
                  <div
                    htmlFor="react-csv-reader-input"
                    className="uploaddButton"
                  >
                    <div>
                      <img src={uploadImage} alt="upload CSV" />
                    </div>
                    <div>Upload</div>
                  </div>
                </label>
              </div>
            </>
          ) : (
            <form onSubmit={formSubmit}>
              <h3 className="modalHeader">
                {userData?.activeTabIndex === "4"
                  ? editKey
                    ? "Update Contract User"
                    : "Add Contract User"
                  : userData?.activeTabIndex === "2"
                  ? editKey
                    ? "Update Contractors"
                    : "Add Contractors"
                  : userData?.activeTabIndex === "3"
                  ? editKey
                    ? "Update Primary Employer"
                    : "Add Primary Employer"
                  : userData?.activeTabIndex === "5"
                  ? editKey
                    ? "Update Primary Employer Users"
                    : "Add Primary Employer Users"
                  : userData?.activeTabIndex === "6"
                  ? editKey
                    ? "Update Talntworx Bizops"
                    : "Add Talntworx Bizops"
                  : ""}
              </h3>
              <div className="modalFirstLineInput">
                {userData?.activeTabIndex === "2" && (
                  <>
                    <div className="Form" style={{ overflowstyle: "none" }}>
                      <div className="Vendor-Registered">
                        <div className="firstLine" style={{ width: "49%" }}>
                          <label className="label_text" htmlFor="name">
                            Registered business name
                          </label>

                          <input
                            required
                            name="bname"
                            value={contractDetails.bname}
                            onChange={(e) => addCompanyDetails(e)}
                            type="text"
                            className="labelOnBorderInput"
                            placeholder={"Enter"}
                          ></input>
                          {/* {error && contractDetails.bname <= 0 ? (<label className="error" style={{color:'red'}}>Enter the Registered business name </label>) : ("")}  */}
                        </div>
                        <div className="firstLine" style={{ width: "48%" }}>
                          <label className="label_text" htmlFor="name">
                            Company type
                          </label>
                          <select
                            required
                            placeholder={"Select company type"}
                            name="company"
                            value={contractDetails.company}
                            onChange={(e) => addCompanyDetails(e)}
                            className="labelOnBorderInput"
                          >
                            <option value="" disabled selected hidden>
                              Select company type
                            </option>
                            <option value="Proprietory">Proprietory</option>
                            <option value="Limited Liability Partnership">
                              Limited Liability Partnership
                            </option>
                            <option value="private limited">
                              Private limited
                            </option>
                            <option value="Partnership">Partnership</option>
                          </select>
                        </div>
                      </div>
                      <div className="Compay-Address">
                        <div className="firstLine" style={{ width: "100%" }}>
                          <label className="label_text" htmlFor="name">
                            Address
                          </label>
                          <input
                            required
                            name="address"
                            value={contractDetails.address}
                            onChange={(e) => addCompanyDetails(e)}
                            type="text"
                            className="labelOnBorderInput"
                            placeholder={"Enter"}
                          ></input>
                        </div>
                      </div>
                      <div className="Pan-Gstin">
                        <div className="firstLine" style={{ width: "48%" }}>
                          <label className="label_text" htmlFor="name">
                            PAN{""}
                            {verify && editKey == null ? `- ${panIdName}` : " "}
                          </label>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <input
                              required
                              style={{
                                borderColor: error.pan && "red",
                                width: "70%",
                              }}
                              type="text"
                              value={contractDetails.pan}
                              name="pan"
                              maxlength="10"
                              onChange={(e) => addCompanyDetails(e)}
                              className="labelOnBorderInput"
                              placeholder={"Enter"}
                              disabled={verify}
                            ></input>
                            <button
                              style={{
                                width: "100px",
                                backgroundColor: verify ? "#34B53A" : "#0361fe",
                                border: "none",
                                color: "white",
                                fontWeight: "500",
                                cursor: "pointer",
                              }}
                              onClick={(e) => verification(e)}
                              disabled={verify}
                            >
                              {verify && (
                                <span style={{ marginRight: "5px" }}>
                                  &#10003;
                                </span>
                              )}{" "}
                              {verify ? `Verified` : "Verify"}
                            </button>
                          </div>
                          {error.pan ? (
                            <label className="error" style={{ color: "red" }}>
                              *please enter valid pan number
                            </label>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="firstLine" style={{ width: "49%" }}>
                          <label className="label_text" htmlFor="name">
                            GSTIN
                          </label>

                          <input
                            required
                            style={{ 
                              borderColor: error.gstin ? "red" : "#ccc",
                              borderWidth: error.gstin ? "2px" : "1px"
                            }}
                            type="text"
                            value={contractDetails.gstin}
                            name="gstin"
                            maxlength="15"
                            onChange={(e) => addCompanyDetails(e)}
                            className="labelOnBorderInput"
                            placeholder={"Enter"}
                          ></input>

                          {error.gstin && (
                            <label className="error" style={{ color: "red", fontSize: "0.85rem", marginTop: "5px" }}>
                              Invalid or existing GSTIN Number
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="Epf-Esic">
  <div className="firstLine" style={{ width: "48%" }}>
    <label className="label_text" htmlFor="epf">
      EPF Reg. no
    </label>
    <input
      required
      style={{ borderColor: error.epf ? "red" : "#ccc", borderWidth: error.epf ? "2px" : "1px" }}
      value={contractDetails.epf}
      name="epf"
      onChange={(e) => addCompanyDetails(e)}
      className="labelOnBorderInput"
      placeholder="Enter"
    />
    {error.epf && (
      <label className="error" style={{ color: "red", fontSize: "0.85rem", marginTop: "5px" }}>
        Invalid EPF Number
      </label>
    )}
  </div>
  <div className="firstLine" style={{ width: "49%" }}>
    <label className="label_text" htmlFor="esic">
      ESIC Reg. no
    </label>
    <input
      required
      style={{ borderColor: error.esic ? "red" : "#ccc", borderWidth: error.esic ? "2px" : "1px" }}
      value={contractDetails.esic}
      name="esic"
      maxLength="17"
      onChange={(e) => addCompanyDetails(e)}
      className="labelOnBorderInput"
      placeholder="Enter"
    />
    {error.esic && (
      <label className="error" style={{ color: "red", fontSize: "0.85rem", marginTop: "5px" }}>
        Invalid ESIC Number
      </label>
    )}
  </div>
</div>
<div className="Pt-Tan">
  <div className="firstLine" style={{ width: "48%" }}>
    <label className="label_text" htmlFor="pt">
      PT Reg. no
    </label>
    <input
      required
      style={{ borderColor: error.pt ? "red" : "#ccc", borderWidth: error.pt ? "2px" : "1px" }}
      value={contractDetails.pt}
      name="pt"
      maxLength="11"
      onChange={(e) => addCompanyDetails(e)}
      className="labelOnBorderInput"
      placeholder="Enter"
    />
    {error.pt && (
      <label className="error" style={{ color: "red", fontSize: "0.85rem", marginTop: "5px" }}>
        Invalid or existing PT Number
      </label>
    )}
  </div>
                        {/* <div className="firstLine" style={{ width: "49%" }}>
                          <label className="label_text" htmlFor="name">
                            TAN
                          </label>
                          <input
                            required
                            style={{ borderColor: error.tan && "red" }}
                            type="text"
                            value={contractDetails.tan}
                            name="tan"
                            maxlength="10"
                            onChange={(e) => addCompanyDetails(e)}
                            className="labelOnBorderInput"
                            placeholder={"Enter"}
                          ></input>
                          {error.tan ? (
                            <label className="error" style={{ color: "red" }}>
                              Invalid TAN Number
                            </label>
                          ) : (
                            ""
                          )}
                        </div> */}
                          <div className="firstLine" style={{ width: "49%" }}>
    <label className="label_text" htmlFor="tan">
      TAN
    </label>
    <input
      required
      style={{ borderColor: error.tan ? "red" : "#ccc", borderWidth: error.tan ? "2px" : "1px" }}
      value={contractDetails.tan}
      name="tan"
      maxLength="10"
      onChange={(e) => addCompanyDetails(e)}
      className="labelOnBorderInput"
      placeholder="Enter"
    />
    {error.tan && (
      <label className="error" style={{ color: "red", fontSize: "0.85rem", marginTop: "5px" }}>
        Invalid TAN Number
      </label>
    )}
  </div>
                      </div>
                      <div className="Pt-Tan" style={{ display: "block" }}>
                        <div
                          className="firstLine"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <label
                            className="label_text"
                            style={{ marginTop: 0 }}
                          >
                            Primary Employers
                          </label>
                          <Select
                            mode="tags"
                            allowClear
                            value={contractDetails?.linkedPrimaryEmployers?.map(
                              (item) => item.id
                            )}
                            // className='labelOnBorderInput'
                            onChange={(value) => primaryemployer(value)}
                            style={{ width: "100%" }}
                            placeholder="Select Primary Details"
                            showSearch
                            showArrow
                            name="primaryId"
                            size="large"
                          >
                            {primaryEmpData?.map((opt, i) => {
                              return (
                                <Option key={i} value={opt.id}>
                                  {opt.value}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {userData?.activeTabIndex === "3" && (
                <>
                  {/* page 1start */}
                  <div className="Form">
                    <div className="Vendor-Registered">
                      <div className="firstLine" style={{ width: "49%" }}>
                        <label
                          id="name"
                          className="label_text"
                          htmlFor="name"
                          style={{ display: "flex" }}
                        >
                          Registered business name{" "}
                          <p style={{ color: "red" }}>*</p>
                        </label>
                        <input
                          required
                          value={primaryDetails.bname}
                          name="bname"
                          onChange={(e) => addCompanyDetails(e)}
                          type="text"
                          className="labelOnBorderInput"
                          placeholder={"Enter"}
                        ></input>
                      </div>
                      <div className="firstLine" style={{ width: "48%" }}>
                        <label
                          id="name"
                          className="label_text"
                          htmlFor="name"
                          style={{ display: "flex" }}
                        >
                          Company type Registered business name{" "}
                          <p style={{ color: "red" }}>*</p>
                        </label>
                        <div>
                          <select
                            value={primaryDetails.company}
                            required
                            name="company"
                            onChange={(e) => addCompanyDetails(e)}
                            className="labelOnBorderInput"
                            placeholder="Select UserType"
                          >
                            <option value="" disabled selected hidden>
                              Select company type{" "}
                              <p style={{ color: "red" }}>*</p>
                            </option>
                            <option value="Proprietory">Proprietory</option>
                            <option value="Limited Liability Partnership">
                              Limited Liability Partnership
                            </option>
                            <option value="private limited">
                              Private limited
                            </option>
                            <option value="Partnership">Partnership</option>
                          </select>
                        </div>
                        {/* <input required name="primaryCompany" onChange={(e) => setPrimaryDetails((pre) => { return { ...pre, primaryCompany: e.target.value } })} type='text' className='labelOnBorderInput' placeholder={"Enter"}></input> */}
                      </div>
                    </div>
                    <div className="Compay-Address">
                      <div className="firstLine" style={{ width: "100%" }}>
                        <label
                          id="name"
                          className="label_text"
                          htmlFor="name"
                          style={{ display: "flex" }}
                        >
                          Address <p style={{ color: "red" }}>*</p>
                        </label>
                        <input
                          required
                          value={primaryDetails.address}
                          name="address"
                          onChange={(e) => addCompanyDetails(e)}
                          type="text"
                          className="labelOnBorderInput"
                          placeholder={"Enter"}
                        ></input>
                      </div>
                    </div>
                    <div className="Pan-Gstin">
                      <div className="firstLine" style={{ width: "48%" }}>
                        <label
                          className="label_text"
                          htmlFor="name"
                          style={{ display: "flex" }}
                        >
                          PAN <p style={{ color: "red" }}>*</p>
                          {verify && editKey == null ? `- ${panIdName}` : " "}
                        </label>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            required
                            style={{
                              borderColor: error.pan ? "red" : "initial",
                              width: "70%",
                            }}
                            type="text"
                            value={primaryDetails.pan}
                            name="pan"
                            maxlength="10"
                            onChange={(e) => addCompanyDetails(e)}
                            className="labelOnBorderInput"
                            placeholder={"Enter"}
                            disabled={verify}
                          ></input>
                          <button
                            style={{
                              width: "100px",
                              backgroundColor: verify ? "#34B53A" : "#0361fe",
                              border: "none",
                              color: "white",
                              fontWeight: "500",
                              cursor: "pointer",
                            }}
                            onClick={(e) => verification(e)}
                            disabled={verify}
                          >
                            {verify && (
                              <span style={{ marginRight: "5px" }}>
                                &#10003;
                              </span>
                            )}{" "}
                            {verify ? `Verified` : "Verify"}
                          </button>
                        </div>
                        {error.pan ? (
                          <label className="error" style={{ color: "red" }}>
                            *please enter valid pan number
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="firstLine" style={{ width: "49%" }}>
                        <label
                          id="name"
                          className="label_text"
                          htmlFor="name"
                          style={{ display: "flex" }}
                        >
                          GSTIN <p style={{ color: "red" }}>*</p>
                        </label>
                        <input
                          style={{
                            borderColor: error.gstin ? "red" : "initial",
                          }}
                          required
                          value={primaryDetails.gstin}
                          type="text"
                          name="gstin"
                          onChange={(e) => addCompanyDetails(e)}
                          maxlength="15"
                          className="labelOnBorderInput"
                          placeholder={"Enter"}
                        ></input>
                        {error.gstin ? (
                          <label className="error" style={{ color: "red" }}>
                            Invalid or Existing GSTIN Number
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="Epf-Esic">
                      <div className="firstLine" style={{ width: "48%" }}>
                        <label
                          id="name"
                          className="label_text"
                          htmlFor="name"
                          style={{ display: "flex" }}
                        >
                          EPF Reg. no <p style={{ color: "red" }}>*</p>
                        </label>
                        <input
                          style={{ borderColor: error.epf ? "red" : "initial" }}
                          required
                          value={primaryDetails.epf}
                          type="text"
                          name="epf"
                          onChange={(e) => addCompanyDetails(e)}
                          id="name"
                          className="labelOnBorderInput"
                          placeholder={"Enter"}
                        ></input>
                        {error.epf ? (
                          <label className="error" style={{ color: "red" }}>
                            Invalid or Existing EPF Number
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="firstLine" style={{ width: "49%" }}>
                        <label
                          id="name"
                          className="label_text"
                          htmlFor="name"
                          style={{ display: "flex" }}
                        >
                          ESIC Reg. no <p style={{ color: "red" }}>*</p>
                        </label>
                        <input
                          style={{
                            borderColor: error.esic ? "red" : "initial",
                          }}
                          required
                          value={primaryDetails.esic}
                          onKeyDown={(e) => onlyNumberKey(e)}
                          maxLength="17"
                          name="esic"
                          onChange={(e) => addCompanyDetails(e)}
                          className="labelOnBorderInput"
                          placeholder={"Enter"}
                        ></input>
                        {error.esic ? (
                          <label className="error" style={{ color: "red" }}>
                            Invalid or Existing ESIC Number
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="Pt-Tan">
                      <div className="firstLine" style={{ width: "48%" }}>
                        <label
                          id="name"
                          className="label_text"
                          htmlFor="name"
                          style={{ display: "flex" }}
                        >
                          PT Reg. no <p style={{ color: "red" }}>*</p>
                        </label>
                        <input
                          style={{ borderColor: error.pt ? "red" : "initial" }}
                          required
                          value={primaryDetails.pt}
                          maxLength="11"
                          onKeyDown={(e) => onlyNumberKey(e)}
                          name="pt"
                          onChange={(e) => addCompanyDetails(e)}
                          id="name"
                          className="labelOnBorderInput"
                          placeholder={"Enter"}
                        ></input>
                        {error.pt ? (
                          <label className="error" style={{ color: "red" }}>
                            Invalid or Existing PT Number
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="firstLine" style={{ width: "49%" }}>
                        <label
                          id="name"
                          className="label_text"
                          htmlFor="name"
                          style={{ display: "flex" }}
                        >
                          TAN <p style={{ color: "red" }}>*</p>
                        </label>
                        <input
                          style={{ borderColor: error.tan ? "red" : "initial" }}
                          required
                          value={primaryDetails.tan}
                          type="text"
                          maxLength="10"
                          name="tan"
                          onChange={(e) => addCompanyDetails(e)}
                          className="labelOnBorderInput"
                          placeholder={"Enter"}
                        ></input>
                        {error.tan ? (
                          <label className="error" style={{ color: "red" }}>
                            Invalid or Existing TAN Number
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="file-Upload">
                      <div style={{ marginTop: "10px" }}>
                        <label id="" htmlFor="name" className="label_text">
                          Logo <span className="span_first">*</span>
                          <span
                            className={`span_class ${
                              uploadErr ? "span_error" : "span_second"
                            }`}
                          >
                            {" "}
                            png only, size &#60; 200kb
                          </span>
                        </label>
                      </div>

                      <div className="upload_btn" style={{ marginTop: "15px" }}>
                        {!imageValue && (
                          <input
                            type="file"
                            className="input_type_file"
                            name="contractor_user_image"
                            id=""
                            onChange={onImageChange}
                            required
                          />
                        )}
                        {!imageValue && (
                          <div className="upload_container">
                            <div className="upload_content">
                              <p>Upload</p>
                              <img
                                src={upload}
                                className="upload_img"
                                alt=""
                                srcset=""
                              />
                            </div>
                            {uploadErr && (
                              <p className="span_error">
                                Invalid “format” or “size”
                              </p>
                            )}
                          </div>
                        )}
                        {imageValue && (
                          <div className="is_upload_img">
                            <div>
                              {/* Small Image */}
                              <img
                                src={imageValue}
                                alt="preview image"
                                style={{
                                  width: "54px",
                                  borderRadius: "6px",
                                  cursor: "pointer",
                                }}
                                onClick={openModal}
                              />

                              {/* Modal for Large Image */}
                              {isModalOpen && (
                                <div
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    zIndex: 1000,
                                  }}
                                  onClick={closeModal} // Close modal on click outside
                                >
                                  <img
                                    src={imageValue}
                                    alt="full view"
                                    style={{
                                      maxWidth: "90%",
                                      maxHeight: "90%",
                                      borderRadius: "6px",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <img
                              src={cancelBtn}
                              alt=""
                              srcset=""
                              style={{ width: "30px" }}
                              onClick={closeUpoloadImg}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {userData?.activeTabIndex === "4" ||
              userData?.activeTabIndex === "5" ||
              userData?.activeTabIndex === "6" ? (
                <div>
                  <div>
                    <label id="name" className="label_text" htmlFor="name">
                      Name
                    </label>
                  </div>
                  <div>
                    <input
                      required
                      type="text"
                      value={fullName}
                      onChange={(e) => setFullName(e.currentTarget.value)}
                      id="name"
                      className="labelOnBorderInput"
                      placeholder={
                        userData?.activeTabIndex === "2" ? "Company name" : null
                      }
                    ></input>
                  </div>
                  <div className="modalMiddleLineInput">
                    <div className="ModalphoneNumberInput">
                      <div>
                        <div>
                          <label
                            className="phoneNumberLabel"
                            htmlFor="mobileNumber"
                          >
                            Mobile number
                          </label>
                          <div>
                            <Select
                              required
                              onChange={(val) => setCountryCode(val)}
                              className="contryCode"
                              defaultValue="+91"
                            >
                              <Option key="1" value="+91">
                                +91
                              </Option>
                              <Option key="2" value="+82">
                                +82
                              </Option>
                              <Option key="3" value="+82">
                                +72
                              </Option>
                              <Option key="4" value="+82">
                                +53
                              </Option>
                            </Select>
                            <input
                              required
                              value={phoneNumber}
                              onChange={(e) => {
                                setPhoneNumber(e.currentTarget.value);
                              }}
                              className="phoneNumberInput"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modalUserTypeSelect">
                      <div>
                        <label>User type</label>
                      </div>
                      <div>
                        <select
                          required
                          value={userTypeSelected}
                          onChange={(e) => {
                            setUserTypeSelected(e.currentTarget.value);
                            setBusinessName("");
                            setBusinessID("");
                          }}
                          className="userSelectors"
                          placeholder="Select UserType"
                        >
                          <option value="primary_employer_user">
                            Primary Employer User
                          </option>
                          <option value="contractor_user">
                            Contractor User
                          </option>
                          <option value="spectrawise_bizops_user">
                            Talntworx Bizops
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="modalBottomLineInput" id="name">
                      Business name
                    </label>
                    {userTypeSelected === "spectrawise_bizops_user" && (
                      <input
                        type="text"
                        disabled
                        onChange={(e) => {
                          setBusinessName(e.currentTarget.value);
                        }}
                        value={businessName}
                        htmlFor="name"
                        placeholder=" "
                        className="labelOnBorderInput"
                      ></input>
                    )}
                    {userTypeSelected !== "spectrawise_bizops_user" && (
                      <select
                        required
                        type="text"
                        value={businessID}
                        onChange={(e) => {
                          setBusinessName(
                            e.currentTarget.selectedOptions[0].innerText
                          );
                          setBusinessID(e.currentTarget.value);
                        }}
                        htmlFor="name"
                        className="labelOnBorderInput"
                      >
                        <option hidden></option>
                        {userTypeSelected === "primary_employer_user" &&
                          primary_employers.map((d) => (
                            <option value={d.id}>{d.name}</option>
                          ))}

                        {userTypeSelected === "contractor_user" &&
                          contractors.map((d) => (
                            <option value={d.id}>{d.name}</option>
                          ))}
                      </select>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="modalFooter">
                <button className="cancelButton" onClick={handleModalCancel}>
                  Cancel
                </button>
                <button
                  className="saveButton"
                  type="submit"
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      userData.activeTabIndex == "2" ||
                      userData.activeTabIndex == "3"
                        ? verify
                          ? "#0361fe"
                          : "#DEB3FF"
                        : "8F00FF",
                  }}
                  disabled={
                    userData.activeTabIndex == "2" ||
                    userData.activeTabIndex == "3"
                      ? !verify
                      : false
                  }
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default TabHead;

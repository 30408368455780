import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import RolesList from './RolesList';
import PostList from './PostList';
import '../../styles/jobList.scss'
import { useContext } from 'react';
import { dataContext } from '../../context/data';

function TopTab() {

  const { TabPane } = Tabs;
  const { jobData, setJobData } = useContext(dataContext);

  useEffect(() => {
    setJobData(pre => { return { ...pre, activeTabIndex: "1" } })
  },[])

  return (
    <div className='topTab'>
        <Tabs defaultActiveKey="1" activeKey={jobData.activeTabIndex} onChange={tabidx => setJobData(pre => { return { ...pre, activeTabIndex: tabidx } })} className='tabs'>
            <TabPane tab="Job posted" key="1" className='tabSlider '>
                <PostList />
            </TabPane>
            <TabPane tab="Job roles" key="2" className='tabSlider'>
                <RolesList />
            </TabPane>
        </Tabs>
    </div>
  )
}

export default TopTab
import isUrl from "is-url";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import cardImg from "../../../assets/imgs/card.png";
import { Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FaPencilAlt } from "react-icons/fa";
import { uploadImageAndUpdateFirestore } from "./imageUpload/uploadImage";
import { Select, Form } from "antd";

const educationOption = [
  {
    id: 1,
    title: "SSC(10th)",
    value: "SSC(10th)",
  },
  {
    id: 2,
    title: "HSC(12th)",
    value: "HSC(12th)",
  },
  {
    id: 3,
    title: "Diploma",
    value: "Diploma",
  },
  {
    id: 4,
    title: "Bachelors",
    value: "Bachelors",
  },
  {
    id: 5,
    title: "Masters",
    value: "Masters",
  },
  {
    id: 6,
    title: "Others",
    value: "Others",
  },
];

const { Option } = Select;
const { TextArea } = Input;
function Tab2({ ImgPopup, data, isEdit, setUserData }) {
  const { id } = useLocation().state;
  const [url, setUrl] = useState([]);

  useEffect(() => {
    const updatedUrls = [];
    for (let i = 0; i < 3; i++) {
      updatedUrls[i] = data?.education?.url[i] || "";
    }
    setUrl(updatedUrls);
  }, [data?.education?.url]);

  const [errorMessage, setErrorMessage] = useState({
    education: {
      college_name: "",
      field: "",
      level: "",
      location: "",
      others: "",
    },
    employment: {
      experience: "",
      uan: "",
    },
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const fieldName = e.target.getAttribute("data-fieldname");
    if (name === "experience") {
      const regex = /^(?:\d*|\d*\.\d{0,11})$/;
      let splitVal = value.split(".");
      if (
        (splitVal[1] <= 11 || splitVal[1] === undefined) &&
        (regex.test(value) || value === "")
      ) {
        const parsedValue = value === "" ? 0 : parseFloat(value);
        if (parsedValue <= 30.11 || splitVal[1] <= 11) {
          setUserData((prev) => ({
            ...prev,
            employment: {
              ...prev.employment,
              [name]: value,
            },
          }));
          setErrorMessage((prev) => ({
            ...prev,
            employment: {
              ...prev.employment,
              [name]: "",
            },
          }));
        } else {
          setErrorMessage((prev) => ({
            ...prev,
            employment: {
              ...prev.employment,
              [name]: "Experience cannot exceed 30.11",
            },
          }));
        }
      } else {
        setErrorMessage((prev) => ({
          ...prev,
          employment: {
            ...prev.employment,
            [name]: "Please enter a valid experience",
          },
        }));
      }
    }
    // Handling UAN input
    else if (name === "uan") {
      const uanRegex = /^[0-9]{12}$/;

      // Check if the value is valid according to the regex
      if (!uanRegex.test(value) && value !== "") {
        setErrorMessage((prev) => ({
          ...prev,
          employment: {
            ...prev.employment,
            [name]: "UAN number must be 12 digits",
          },
        }));
      } else {
        setErrorMessage((prev) => ({
          ...prev,
          employment: {
            ...prev.employment,
            [name]: value ? "" : `Please Enter ${fieldName}`,
          },
        }));
      }

      // Update the state with the UAN value
      setUserData((prev) => ({
        ...prev,
        employment: {
          ...prev.employment,
          [name]: value,
        },
      }));
    }
    // Handling other fields
    else {
      setUserData((prev) => ({
        ...prev,
        education: {
          ...prev.education,
          [name]: value,
        },
      }));

      setErrorMessage((prev) => ({
        ...prev,
        education: {
          ...prev.education,
          [name]: value ? "" : `Please Enter ${fieldName}`,
        },
      }));
    }
  };

  let [uploadImgFiledName, setUploadImgFiledName] = useState("");
  let [index, setIndex] = useState("");

  const getEducationImages = (data) => ({
    educationDoc1: data?.education?.url[0] || cardImg,
    educationDoc2: data?.education?.url[1] || cardImg,
    educationDoc3: data?.education?.url[2] || cardImg,
  });

  const [imageSrc, setImageSrc] = useState(getEducationImages(data));

  useEffect(() => {
    setImageSrc(getEducationImages(data));
  }, [data?.education?.url]);

  const handleImageEdit = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        console.log("data", data);
        setImageSrc(event.target.result);
        uploadImageAndUpdateFirestore(
          event.target.result,
          id,
          setUserData,
          uploadImgFiledName,
          index
        );
        e.target.value = "";
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileSelect = (filed) => {
    setUploadImgFiledName(filed);
    let splitIndex = filed.split("_");
    setIndex(Number(splitIndex[1]) - 1);
    document.getElementById("fileInput").click();
  };

  console.log("data.education?.level", data.education?.level);

  let res =
    educationOption?.length > 0 &&
    educationOption.find((ele) => {
      return ele.title === data.education?.level;
    });

  return (
    <>
      <div className="detailsContainer">
        <h4 className="cardDetailsHead">Education</h4>
        <div className="innerContainer">
          <div className="fieldData">
            <div className="labelField">Grade</div>
            {!isEdit ? (
              <div className="fieldDetail">
                {data.education?.level || "N/A"}
              </div>
            ) : (
              <div className="firstLine" style={{ width: "100%" }}>
                <Form.Item
                  name="qualification"
                  rules={[{ required: true, message: "Enter qualification" }]}
                >
                  <Select
                    allowClear
                    defaultValue={data.education?.level}
                    className="input"
                    placeholder="Select qualification"
                    showSearch
                    showArrow
                    name="qualification"
                    style={{ width: "90%" }}
                    onChange={(value) => {
                      setUserData((prev) => ({
                        ...prev,
                        education: {
                          ...prev.education,
                          level: value,
                          field:
                            value === "SSC(10th)" || value === "HSC(12th)"
                              ? ""
                              : data.education?.field,
                        },
                      }));
                    }}
                    size="middle"
                  >
                    {educationOption && educationOption.length > 0 ? (
                      educationOption.map((ele) => (
                        <Option value={ele.value} key={ele.id}>
                          {ele.title}
                        </Option>
                      ))
                    ) : (
                      <Option disabled>No qualifications available</Option>
                    )}
                  </Select>
                  {errorMessage?.education?.field && (
                    <div style={{ color: "red", marginTop: "8px" }}>
                      {errorMessage.education?.field}
                    </div>
                  )}
                </Form.Item>
              </div>
            )}
          </div>
          {res?.title === "SSC(10th)" || res?.title === "HSC(12th)" ? null : (
            <div className="fieldData">
              <div className="labelField">Field</div>
              {!isEdit ? (
                <div className="fieldDetail">
                  {data.education?.field || "N/A"}
                </div>
              ) : (
                <div>
                  <Input
                    style={{ width: "90%" }}
                    placeholder="Enter Field"
                    className="phoneNumberInput"
                    value={data.education?.field}
                    onChange={handleChange}
                    name="field"
                    data-fieldname="Education Field"
                  />
                  {errorMessage?.education?.field && (
                    <div style={{ color: "red", marginTop: "8px" }}>
                      {errorMessage.education?.field}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          <div className="fieldData">
            <div className="labelField">Card Image</div>

            <div
              className="fieldDetail imgPopup"
              onClick={(e) => ImgPopup(data?.education?.url[0])}
            >
              <div
                className="parent-container"
                style={{ position: "relative" }}
              >
                <embed
                  width="102px"
                  height="60px"
                  className="imgPopup"
                  // src={imageSrc}
                  alt="card img"
                  src={
                    url[0]?.indexOf("data:image/") > -1
                      ? url[0]
                      : isUrl(url[0])
                      ? url[0]
                      : cardImg
                  }
                />
                <div
                  className="edit-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    triggerFileSelect("education_1");
                  }}
                  style={{
                    position: "absolute",
                    // right: "5px",
                    right: "55%",
                    // top: "13%",
                    bottom: "20%",
                    cursor: "pointer",
                    borderRadius: "50%",
                    padding: "5px",
                  }}
                >
                  {isEdit && <FaPencilAlt size={14} color="#000" />}
                </div>
              </div>
            </div>
            <input
              id="fileInput"
              type="file"
              onChange={handleImageEdit}
              accept="image/*"
              style={{ display: "none" }}
            />
          </div>
          <div className="fieldData">
            <div className="labelField">Card Image</div>
            <div
              className="fieldDetail imgPopup"
              onClick={(e) => ImgPopup(data?.education?.url[1])}
            >
              <div
                className="parent-container"
                style={{ position: "relative" }}
              >
                <embed
                  width="102px"
                  height="60px"
                  className="imgPopup"
                  src={
                    url[1]?.indexOf("data:image/") > -1
                      ? url[1]
                      : isUrl(url[1])
                      ? url[1]
                      : cardImg
                  }
                  // src={imageSrc}
                  alt="card img"
                />
                <div
                  className="edit-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    triggerFileSelect("education_2");
                  }}
                  style={{
                    position: "absolute",
                    // right: "5px",
                    right: "55%",
                    // top: "13%",
                    bottom: "20%",
                    cursor: "pointer",
                    borderRadius: "50%",
                    padding: "5px",
                  }}
                >
                  {isEdit && <FaPencilAlt size={14} color="#000" />}
                </div>
              </div>
            </div>
            <input
              id="fileInput"
              type="file"
              onChange={handleImageEdit}
              accept="image/*"
              style={{ display: "none" }}
            />
          </div>
          <div className="fieldData">
            <div className="labelField">Card Image</div>
            <div
              className="fieldDetail imgPopup"
              onClick={(e) => ImgPopup(data?.education?.url[1])}
            >
              <div
                className="parent-container"
                style={{ position: "relative" }}
              >
                <embed
                  width="102px"
                  height="60px"
                  className="imgPopup"
                  src={
                    url[2]?.indexOf("data:image/") > -1
                      ? url[2]
                      : isUrl(url[2])
                      ? url[2]
                      : cardImg
                  }
                  alt="card img"
                />
                <div
                  className="edit-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    triggerFileSelect("education_3");
                  }}
                  style={{
                    position: "absolute",
                    // right: "5px",
                    right: "55%",
                    // top: "13%",
                    bottom: "20%",
                    cursor: "pointer",
                    borderRadius: "50%",
                    padding: "5px",
                  }}
                >
                  {isEdit && <FaPencilAlt size={14} color="#000" />}
                </div>
              </div>
              <input
                id="fileInput"
                type="file"
                onChange={handleImageEdit}
                accept="image/*"
                style={{ display: "none" }} // Hide the file input
              />
            </div>
          </div>
        </div>
      </div>
      <div className="detailsContainer">
        <h4 className="cardDetailsHead">Employement</h4>
        <div className="innerContainer">
          <div className="fieldData">
            <div className="labelField">Experience</div>
            {!isEdit ? (
              <div className="fieldDetail">{data?.employment?.experience}</div>
            ) : (
              <div>
                <Input
                  style={{ width: "90%" }}
                  placeholder="Enter Experience in Years"
                  className="phoneNumberInput"
                  // value={
                  //   data.employment?.experience
                  //     ? (parseFloat(data.employment.experience) / 12).toFixed(1)
                  //     : ""
                  // }
                  value={data.employment.experience}
                  onChange={handleChange}
                  name="experience"
                  data-fieldname="Experience"
                  type="text"
                />
                {errorMessage?.employment?.experience && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {errorMessage.employment?.experience}
                  </div>
                )}
                {/* <div>
                  <label>Experience (in months): </label>
                  <input
                    type="text"
                    value={experience}
                    onChange={handleChange1}
                    placeholder="Enter experience (max 30.11)"
                  />
                </div> */}
              </div>
            )}
          </div>
          <div className="fieldData">
            <div className="labelField">UAN</div>
            {!isEdit ? (
              <div className="fieldDetail">{data.employment?.uan || "N/A"}</div>
            ) : (
              <div>
                <Input
                  style={{ width: "90%" }}
                  placeholder="Enter Field"
                  className="phoneNumberInput"
                  value={data.employment?.uan}
                  onChange={handleChange}
                  name="uan"
                  data-fieldname="UAN"
                />
                {errorMessage?.employment?.uan && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {errorMessage.employment?.uan}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Tab2;

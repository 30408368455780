import React from 'react';
import { useEffect, useContext,useState } from 'react';
import { dataContext } from '../../context/data';
import { useLocation } from 'react-router-dom';
import JobDetailHeader from './JobDetailHeader';
import JobDetail from './JobDetail';
import { collection, collectionGroup, doc, getDoc, getDocs, getFirestore, query, orderBy, Timestamp, limit, startAfter, startAt, where, onSnapshot, setDoc, addDoc } from 'firebase/firestore';
import app from '../../firebase';
import moment from 'moment';
import { message } from 'antd';
import ResponseList from './ResponseList';


const talentsDataColumn = [
  {
    title: "STATUS",
    dataIndex: "status"
  },
  {
    title: "NAME",
    dataIndex: "name"
  },
  {
    title: "MOBILE NUMBER",
    dataIndex: "mnum"
  },
  {
    title: "GENDER",
    dataIndex: "gender"
  },
  {
    title: "JOINING DATE",
    dataIndex: "joiningDate"
  },
  {
    title: "ADDRESS",
    dataIndex: "address"
  }
]
const db = getFirestore(app);

function JobDetailBase({userPath}) {

  const {jobData, setJobData} = useContext(dataContext);
  const { id } = useLocation().state;
  const [postData, setPostData] = useState({});
  const [formalities, setFormalities] = useState();
  const [description, setDescription] = useState();
  const [ResTalentData, setResTalentData] = useState();

  

  const mapData = (d, emp, con, count) => {
    
    let qualification = d.qualification
    let qual = qualification.toString();
    let StartDate = new Timestamp(d.start_date.seconds | d.start_date._seconds, d.start_date.nanoseconds | d.start_date._nanoseconds).toDate();

    return [
      {
        title: 'Job Id',
        value:  d.job_id
      },
      {
        title: 'Principal Employer',
        value:  emp
      },
      {
        title: 'Salary',
        value:  `₹${d.salary_from} - ${d.salary_to}/${d.salary_per_period}`
      },
      {
        title: 'Experience Required',
        value: `₹${d.experience_from} - ${d.experience_to}/${d.experience_per_period}`
      },
      {
        title: 'Qualification',
        value:  qual
      },
      {
        title: 'Designation',
        value:  d.designation
      },
      {
        title: 'Posted by',
        value: con
      },
      {
        title: 'Location',
        value:  `${d.district},${d.state}`
      },
      {
        title: 'Start date',
        value:  moment(StartDate).format("DD-MM-YYYY")
      },
      {
        title: 'No. of questions',
        value:  count
      }

    ]
    
  }

  const statusFinder = (s) => {
    if (s?.is_approved && s?.is_completed && s?.is_submitted && s?.is_employed)
      s = "Employed";
    else if (s?.is_approved && s?.is_completed && s?.is_submitted)
      s = "Available";  //need to clarify
    else if (!s?.is_approved && s?.is_completed && !s?.is_submitted)
      s = "Completed Profile";
    else if (!s?.is_approved && s?.is_submitted)
      s = "Yet To Be Verified";
    else //if (!s?.is_approved && !s?.is_completed && s?.is_submitted)
      s = "Incomplete Profile";
    return s;
  }

  const mapTalentData = (d, itm, joiningDate, questions) => {
    let s = statusFinder(d.status);
    let date = new Timestamp(joiningDate.seconds | joiningDate._seconds, joiningDate.nanoseconds | joiningDate._nanoseconds).toDate();
    
    return {
      key: itm.id,
      data: d,
      status: s,
      name: d.personal?.full_name,
      address: `${d.contact?.address_line_1},
      ${d.contact?.address_line_2},
      ${d.contact?.city},
      ${d.contact?.state},
      ${d.contact?.country}-${d.contact?.pincode}`,
      mnum: d.contact?.mobile_number,
      gender: d.personal?.gender,
      joiningDate:  moment(date).format("DD-MM-YYYY"),
      questions: questions
    }
  }

  async function getResponseList() {
    let temp = [];
    let q;
    let count = 0;
    try{
      if(jobData.responseLastFetch){
        q = query(collection(db, 'job_posts', id, 'talent_user_job_post_response'),orderBy('submitted_at', 'asc'), startAfter(jobData.responseLastFetch), limit(10));
      }
      else{
        q = query(collection(db, 'job_posts', id, 'talent_user_job_post_response'),orderBy('submitted_at', 'asc'), limit(10));
      }
      const res = await (getDocs(q));
      res.forEach(async (itm) => {
        let joiningDate = itm.data().joining_date;
        let questions = itm.data().question_ans;
        try{
          let docTalRef = await getDoc(doc(db, `talent_users/${itm.data().user_id}` ));
          let d = docTalRef.data();
          temp.push(mapTalentData(d, itm, joiningDate, questions));
          console.log('temp', temp);
        }catch(err){
          console.log(err)
        }

        if (res.docs.length - 1 === count++) {
          // message.destroy("Job post");
          setResTalentData(pre => {
            if (jobData.ResponsePage > 0) {
              return [...pre, ...temp];
            }
            else return temp;
          });
          setJobData(pre => { return { ...pre, responseLastFetch: itm } });    
        }
        if (res.docs.length <= 0) {
          // message.destroy("Job post");
          setResTalentData(pre => {
            if (jobData.ResponsePage > 0) {
              return [...pre];
            }
            else return [];
          });
        }
      })

    }catch(err){
      console.log(err)
    }
  }

  const getCatagory = async (type, sVal = "") => {
    let d = []
    try {
      let q = query(collection(db, type), where('name', ">=", sVal));
      const res = await getDocs(q);
      res.forEach(itm => d.push({ ...itm.data(), id: itm.id }))
      return (
        d
      )

    } catch (err) {
      console.log(err);
      console.log(err);
    }
  } 

  async function getPostData(){
    message.loading({content: 'Loading...', duration:0, key:'getPostData'})
    let value ;
    var employer, contractor;
    employer = await getCatagory("primary_employers");
    contractor = await getCatagory("contractors");

    try{
      const docRef = await getDoc(doc(db, 'job_posts', id));
      if(docRef.exists()){
        let d = docRef.data();
        let postedBy, postedFor;
        let description = d.description, formalities = d.joining_formalities;

        const quesRef = await (getDocs(query(collection(db, 'job_posts', id, 'questions'))));
        let counter = quesRef.size;
        
        postedFor = employer.filter(e => e.id == d.primary_employer);
        
        if(d.user_id === 'Talntworx Technologies '){
          postedBy = [{name:'Talntworx Technologies '}]
        }else{
          postedBy = contractor.filter(c => c.id == d.user_id);
          if(postedBy.length == 0){
            postedBy = employer.filter(e => e.id == d.primary_employer);
          }
        }

        value = (mapData(d, postedFor[0]?.name, postedBy[0]?.name, counter));
        setPostData(value);
        setFormalities(formalities);
        setDescription(description);
        message.destroy('getPostData')

      }else{
        console.log('No such document ')
      }
      } catch(err){
      console.log(err)
    }
  }
  useEffect(() => {
    getResponseList();
  },[jobData.ResponsePage])

  useEffect(() => {
    getPostData();
  },[jobData.postEditSuccess])

  useEffect(() => {
    setJobData(pre => {return {...pre, DblastFetch: undefined, page:0, responseLastFetch: undefined, ResponsePage: 0}})
    getPostData();
    getResponseList();
  },[]);


  return (
    <div className='jobDetailBase'>
      <JobDetailHeader userPath={userPath} id={id}/>
      {description && formalities && <JobDetail postData={postData} formalities={formalities} description= {description}/>}
      {ResTalentData && <ResponseList ResTalentData={ResTalentData} talentsDataColumn={talentsDataColumn}/> }
    </div>
  )
}

export default JobDetailBase
import React, { useEffect, useState, useRef } from "react";
import ResponsiveTable from "../panel/panelHead/ResponsiveTable";
import { dataContext } from "../../context/data";
import { useContext } from "react";
import { useAuthContext } from "../../context/auth";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  orderBy,
  Timestamp,
  limit,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import app from "../../firebase";
import { message } from "antd";
import moment from "moment";
import TotalBack from "../../assets/icons/TotalBack.svg";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import UserPanelSearch from "../panel/panelHead/userPanelSearch";
import MoreOption from "../panel/panelHead/MoreOption";
import axios from "axios";
import baseUrl from "../../utils/baseUrl";


const db = getFirestore(app);

function TotalTalents({ userPath }) {
  const [dataTalent, setDataTalent] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState([
    0, 1, 2, 3, 5, 8, 9, 10, 4,
  ]);
  const { dashboardData, setDashboardData } = useContext(dataContext);
  const auth = useAuthContext();
  const location = useLocation();
  const currentStatus = sessionStorage.getItem("dashboardStatusType")
    ? sessionStorage.getItem("dashboardStatusType")
    : location.state.name;

  useEffect(() => {
    setDashboardData((pre) => {
      return { ...pre, talentLastfetch: undefined, talentPage: 0 };
    });
  }, []);

  useEffect(() => {
    async function fetch() {
    const colName = auth.isSpectra ? "spectrawise_bizops_users" : auth.isContract ? "contractor_users" : auth.isPrimary ? "primary_employer_users" : "";
    const colPosData = await getDoc(doc(db, colName, localStorage.getItem('uid')));
    // console.log(colPosData.data().settings.talent_user_column_list);;
    let selCoList = [];
    setColumnTalent(pre => {
     const d1 = [], d = colPosData.data().settings.talent_user_column_list.map(didx => didx === 'full_name' ? "name" : didx);
     d.map(didx => {
      if(didx !== "dob"){
        d1.push(pre.filter(itm => itm.dataIndex === didx)[0])
      }
     })
     
     selCoList = [...d1.map(itm => { return { ...itm, visibility: true } }), ...pre.filter(itm => !d.includes(itm.dataIndex)).map(itm => { return { ...itm, visibility: false } })];
     console.log("selcollist",selCoList);
     return selCoList;
    }
    );
    setSelectedColumn(pre => {
     let selColuList = [];
     selCoList.forEach((itm, idx) => {
    if (itm.visibility)
     selColuList .push(idx);
     })
     return selColuList;
    })
     }
     if ((auth.isSpectra) || auth.isContract || auth.isPrimary)
    fetch();
    }, []);

  const statusFinderTalent = (s) => {
    if (
      s?.status?.is_approved &&
      s?.status?.is_completed &&
      s?.status?.is_submitted &&
      s?.status?.is_employed
    )
      s = "Employed";
    else if (
      s?.status?.is_approved &&
      s?.status?.is_completed &&
      s?.status?.is_submitted
    )
      s = "Available"; //need to clarify
    else if (
      !s?.status?.is_approved &&
      s?.status?.is_completed &&
      !s?.status?.is_submitted
    )
      s = "Completed Profile";
    else if (!s?.status?.is_approved && s?.status?.is_submitted)
      s = "Yet To Be Verified";
    //if (!s?.is_approved && !s?.is_completed && s?.is_submitted)
    else s = "Incomplete Profile";
    return s;
  };

  const changeTalentData = (d) => {
    let s = statusFinderTalent(d.talentData);
    return {
      key: d.key,
      data: d,
      contractor: d?.talentData?.status?.current_contractor_name
        ? d.talentData?.status?.current_contractor_name
        : "",
      employer: d?.talentData.status?.current_primary_employer_name
        ? d?.talentData.status?.current_primary_employer_name
        : "",
      status: s,
      name: d?.talentData?.personal?.full_name,
      dob: d?.talentData?.personal?.dob
        ? moment(parseInt(d?.talentData?.personal?.dob)).format("DD-MM-YYYY")
        : "",
      address: `${d?.talentData.contact?.address_line_1},
      ${d?.talentData?.contact?.address_line_2},
      ${d?.talentData?.contact?.city},
      ${d?.talentData?.contact?.state},
      ${d?.talentData?.contact?.country}-${d?.talentData?.contact?.pincode}`,
      mnum: d?.talentData?.contact?.mobile_number,
      gender: d?.talentData?.personal?.gender,
      bGroup: d?.talentData?.personal?.blood_group,
      email: d?.talentData?.contact?.email,
      aadhaar: d?.talentData?.identity?.aadhaar?.aadhaar_number,
      pan: d?.talentData.identity?.pan?.pan_number,
      pincode: d?.talentData?.contact?.pincode,
      bankAC: d?.talentData.bank?.account_number,
      handicapped: d?.talentData?.personal?.is_handicapped
        ? d?.talentData?.personal?.handicapped_reason
        : "NO",
    };
  };

  async function spectraFunction() {
    if (
      typeof dashboardData.totalTalentFilterValue !== "undefined" &&
      dashboardData.totalTalentFilterValue.length > 0
    ) {
      var field_name = "";
      if (dashboardData.totalTalentFilterValue[0].dataIndex === "all") {
        field_name = "all";
      } else if (dashboardData.totalTalentFilterValue[0].dataIndex === "name") {
        field_name = "personal.full_name";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "status"
      ) {
        field_name = "status";
      } else if (dashboardData.totalTalentFilterValue[0].dataIndex === "dob") {
        field_name = "personal.dob";
      } else if (dashboardData.totalTalentFilterValue[0].dataIndex === "mnum") {
        field_name = "contact.mobile_number";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "address"
      ) {
        field_name = "address";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "gender"
      ) {
        field_name = "personal.gender";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "bGroup"
      ) {
        field_name = "personal.blood_group";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "email"
      ) {
        field_name = "email";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "aadhaar"
      ) {
        field_name = "identity.aadhaar.aadhaar_number";
      } else if (dashboardData.totalTalentFilterValue[0].dataIndex === "pan") {
        field_name = "identity.pan.pan_number";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "bankAC"
      ) {
        field_name = "bank.account_number";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "handicapped"
      ) {
        if (dashboardData.totalTalentFilterValue[0].sval === "All") {
          field_name = "personal.is_handicapped";
        } else {
          field_name = "personal.handicapped_reason";
        }
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "contractor"
      ) {
        field_name = "status.current_contractor_name";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "employer"
      ) {
        field_name = "status.current_primary_employer_name";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "pincode"
      ) {
        field_name = "contact.pincode";
      }
    }

    if (currentStatus == "total") {
      if (
        dashboardData.contractValue &&
        dashboardData.contractValue.length !== 0
      ) {
        if (
          typeof dashboardData.totalTalentFilterValue !== "undefined" &&
          dashboardData.totalTalentFilterValue.length > 0
        ) {
          message.loading({
            content: "Loading",
            duration: 0,
            key: "totalTalent",
          });
          if(dashboardData.sortColumn){
            axios
            .post(
              `${baseUrl}/spectraTotalTalentData`,
              {
                talentFilterValue: {
                  field_name: field_name,
                  field_value:
                    dashboardData.totalTalentFilterValue[0].dataIndex ===
                      "handicapped" &&
                    dashboardData.totalTalentFilterValue[0].sval === "All"
                      ? true
                      : dashboardData.totalTalentFilterValue[0].sval,
                },
                contractor_Ids: dashboardData.contractValue,
                sortColumn: [dashboardData.sortColumn]
              }
            )
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }else{
            axios
            .post(
              `${baseUrl}/spectraTotalTalentData`,
              {
                talentFilterValue: {
                  field_name: field_name,
                  field_value:
                    dashboardData.totalTalentFilterValue[0].dataIndex ===
                      "handicapped" &&
                    dashboardData.totalTalentFilterValue[0].sval === "All"
                      ? true
                      : dashboardData.totalTalentFilterValue[0].sval,
                },
                contractor_Ids: dashboardData.contractValue,
              }
            )
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }
        } else if (Object.keys(dashboardData.filterQuery).length > 0) {
          message.loading({
            content: "Loading",
            duration: 0,
            key: "totalTalent",
          });
          const talentData = [];
          if(dashboardData.sortColumn){
            axios
            .post(
              `${baseUrl}/SpectraFilterQueryTalentData`,
              {
                query: dashboardData.filterQuery,
                status: "total",
                contractor_Ids: dashboardData.contractValue,
                sortColumn: [dashboardData.sortColumn]
              }
            )
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }else{
            axios
            .post(
              `${baseUrl}/SpectraFilterQueryTalentData`,
              {
                query: dashboardData.filterQuery,
                status: "total",
                contractor_Ids: dashboardData.contractValue,
              }
            )
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }
        } else {
          message.loading({
            content: "Loading",
            duration: 0,
            key: "totalTalent",
          });
          if(dashboardData.sortColumn){
            axios
            .post(
              `${baseUrl}/spectraTotalTalentData`,
              {
                size: 20 + dashboardData.totalTalentPage * 20,
                contractor_Ids: dashboardData.contractValue,
                sortColumn: [dashboardData.sortColumn]
              }
            )
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent(talentData)
              }else{
                setDataTalent([])
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              console.log(error);
              message.destroy("totalTalent");
            });
          }else{
            axios
            .post(
              `${baseUrl}/spectraTotalTalentData`,
              {
                from: dashboardData.totalTalentPage * 20,
                size: 20,
                contractor_Ids: dashboardData.contractValue,
              }
            )
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent((pre) => {
                  if (dashboardData.totalTalentPage > 0) {
                    return [...pre, ...talentData];
                  } else return talentData;
                });
              }else{
                setDataTalent((pre) => {
                  if (dashboardData.totalTalentPage > 0) {
                    return [...pre, ...talentData];
                  } else return talentData;
                });
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              console.log(error);
              message.destroy("totalTalent");
            });
          }
        }
      } else {
        if (
          typeof dashboardData.totalTalentFilterValue !== "undefined" &&
          dashboardData.totalTalentFilterValue.length > 0
        ) {
          message.loading({
            content: "Loading",
            duration: 0,
            key: "totalTalent",
          });
          if(dashboardData.sortColumn){
            axios
            .post(
              `${baseUrl}/spectraTotalTalentData`,
              {
                type: "total",
                talentFilterValue: {
                  field_name: field_name,
                  field_value:
                    dashboardData.totalTalentFilterValue[0].dataIndex ===
                      "handicapped" &&
                    dashboardData.totalTalentFilterValue[0].sval === "All"
                      ? true
                      : dashboardData.totalTalentFilterValue[0].sval,
                },
                sortColumn: [dashboardData.sortColumn]
              }
            )
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }else{
            axios
            .post(
              `${baseUrl}/spectraTotalTalentData`,
              {
                type: "total",
                talentFilterValue: {
                  field_name: field_name,
                  field_value:
                    dashboardData.totalTalentFilterValue[0].dataIndex ===
                      "handicapped" &&
                    dashboardData.totalTalentFilterValue[0].sval === "All"
                      ? true
                      : dashboardData.totalTalentFilterValue[0].sval,
                },
              }
            )
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }
        } else if (Object.keys(dashboardData.filterQuery).length > 0) {
          message.loading({
            content: "Loading",
            duration: 0,
            key: "totalTalent",
          });
          const talentData = [];
          if(dashboardData.sortColumn){
            axios
            .post(
              `${baseUrl}/SpectraFilterQueryTalentData`,
              {
                status: "total",
                query: dashboardData.filterQuery,
                sortColumn: [dashboardData.sortColumn]
              }
            )
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }else{
            axios
            .post(
              `${baseUrl}/SpectraFilterQueryTalentData`,
              {
                status: "total",
                query: dashboardData.filterQuery,
              }
            )
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }
        } else {
          message.loading({
            content: "Loading",
            duration: 0,
            key: "totalTalent",
          });
          if (dashboardData.sortColumn) {
            axios
              .post(
                `${baseUrl}/spectraTotalTalentData`,
                {
                  type: "total",
                  size: 20 + dashboardData.totalTalentPage * 20,
                  sortColumn: [dashboardData.sortColumn]
                }
              )
              .then((response) => {
                const talentData = [];
                response.data.talentDetails.forEach((val) => {
                  talentData.push(changeTalentData(val));
                });
                
                if (talentData.length > 0) {
                  setDataTalent(talentData)
                }else{
                  setDataTalent([])
                }
                message.destroy("totalTalent");
              })
              .catch((error) => {
                message.destroy("totalTalent");
                console.log(error);
              });
          } else {
            axios
              .post(
                `${baseUrl}/spectraTotalTalentData`,
                {
                  type: "total",
                  from: dashboardData.totalTalentPage * 20,
                  size: 20,
                }
              )
              .then((response) => {
                const talentData = [];
                response.data.talentDetails.forEach((val) => {
                  talentData.push(changeTalentData(val));
                });
                
                if (talentData.length > 0) {
                  setDataTalent((pre) => {
                    if (dashboardData.totalTalentPage > 0) {
                      return [...pre, ...talentData];
                    } else return talentData;
                  });
                }else{
                  setDataTalent((pre) => {
                    if (dashboardData.totalTalentPage > 0) {
                      return [...pre, ...talentData];
                    } else return talentData;
                  });
                }
                message.destroy("totalTalent");
              })
              .catch((error) => {
                message.destroy("totalTalent");
                console.log(error);
              });
          }
        }
      }
    } else if (currentStatus !== "total") {
      if (
        dashboardData.contractValue &&
        dashboardData.contractValue.length !== 0
      ) {
        if (
          typeof dashboardData.totalTalentFilterValue !== "undefined" &&
          dashboardData.totalTalentFilterValue.length > 0
        ) {
          message.loading({
            content: "Loading",
            duration: 0,
            key: "totalTalent",
          });
          if(dashboardData.sortColumn){
            axios
            .post(
              `${baseUrl}/spectraStatusTalentData`,
              {
                status:
                  currentStatus == "Incomplete Profile"
                    ? "incomplete"
                    : currentStatus == "Yet to Be Verified"
                    ? "yet to be verified"
                    : currentStatus == "Available"
                    ? "available"
                    : currentStatus == "Employed"
                    ? "employed"
                    : "all",
                talentFilterValue: {
                  field_name: field_name,
                  field_value:
                    dashboardData.totalTalentFilterValue[0].dataIndex ===
                      "handicapped" &&
                    dashboardData.totalTalentFilterValue[0].sval === "All"
                      ? true
                      : dashboardData.totalTalentFilterValue[0].sval,
                },
                contractor_Ids: dashboardData.contractValue,
                sortColumn: [dashboardData.sortColumn]
              }
            )
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }else{
            axios
            .post(
              `${baseUrl}/spectraStatusTalentData`,
              {
                status:
                  currentStatus == "Incomplete Profile"
                    ? "incomplete"
                    : currentStatus == "Yet to Be Verified"
                    ? "yet to be verified"
                    : currentStatus == "Available"
                    ? "available"
                    : currentStatus == "Employed"
                    ? "employed"
                    : "all",
                talentFilterValue: {
                  field_name: field_name,
                  field_value:
                    dashboardData.totalTalentFilterValue[0].dataIndex ===
                      "handicapped" &&
                    dashboardData.totalTalentFilterValue[0].sval === "All"
                      ? true
                      : dashboardData.totalTalentFilterValue[0].sval,
                },
                contractor_Ids: dashboardData.contractValue,
              }
            )
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }
        } else if (Object.keys(dashboardData.filterQuery).length > 0) {
          message.loading({
            content: "Loading",
            duration: 0,
            key: "totalTalent",
          });
          const talentData = [];
          if(dashboardData.sortColumn){
            axios
            .post(
              `${baseUrl}/SpectraFilterQueryTalentData`,
              {
                query: dashboardData.filterQuery,
                status:
                  currentStatus == "Incomplete Profile"
                    ? "incomplete"
                    : currentStatus == "Yet to Be Verified"
                    ? "yet to be verified"
                    : currentStatus == "Available"
                    ? "available"
                    : currentStatus == "Employed"
                    ? "employed"
                    : "all",
                contractor_Ids: dashboardData.contractValue,
                sortColumn: [dashboardData.sortColumn]
              }
            )
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }else{
            axios
            .post(
              `${baseUrl}/SpectraFilterQueryTalentData`,
              {
                query: dashboardData.filterQuery,
                status:
                  currentStatus == "Incomplete Profile"
                    ? "incomplete"
                    : currentStatus == "Yet to Be Verified"
                    ? "yet to be verified"
                    : currentStatus == "Available"
                    ? "available"
                    : currentStatus == "Employed"
                    ? "employed"
                    : "all",
                contractor_Ids: dashboardData.contractValue,
              }
            )
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }
        } else {
          message.loading({
            content: "Loading",
            duration: 0,
            key: "totalTalent",
          });
          if(dashboardData.sortColumn){
            console.log('contrac value, no filter sort')
            axios
            .post(
              `${baseUrl}/spectraStatusTalentData`,
              {
                status:
                  currentStatus == "Incomplete Profile"
                    ? "incomplete"
                    : currentStatus == "Yet to Be Verified"
                    ? "yet to be verified"
                    : currentStatus == "Available"
                    ? "available"
                    : currentStatus == "Employed"
                    ? "employed"
                    : "all",
                contractor_Ids: dashboardData.contractValue,
                size: 20 + dashboardData.totalTalentPage * 20,
                sortColumn: [dashboardData.sortColumn]
              }
            )
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent(talentData)
              }else{
                setDataTalent([])
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }else{
            axios
            .post(
              `${baseUrl}/spectraStatusTalentData`,
              {
                status:
                  currentStatus == "Incomplete Profile"
                    ? "incomplete"
                    : currentStatus == "Yet to Be Verified"
                    ? "yet to be verified"
                    : currentStatus == "Available"
                    ? "available"
                    : currentStatus == "Employed"
                    ? "employed"
                    : "all",
                from: dashboardData.totalTalentPage * 20,
                size: 20,
                contractor_Ids: dashboardData.contractValue,
              }
            )
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent((pre) => {
                  if (dashboardData.totalTalentPage > 0) {
                    return [...pre, ...talentData];
                  } else return talentData;
                });
              }else{
                setDataTalent((pre) => {
                  if (dashboardData.totalTalentPage > 0) {
                    return [...pre, ...talentData];
                  } else return talentData;
                });
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              console.log(error);
              message.destroy("totalTalent");
            });
          }
        }
      } else {
        if (
          typeof dashboardData.totalTalentFilterValue !== "undefined" &&
          dashboardData.totalTalentFilterValue.length > 0
        ) {
          message.loading({
            content: "Loading",
            duration: 0,
            key: "totalTalent",
          });
          if(dashboardData.sortColumn){
            axios
            .post(
              `${baseUrl}/spectraStatusTalentData`,
              {
                type: "total",
                status:
                  currentStatus == "Incomplete Profile"
                    ? "incomplete"
                    : currentStatus == "Yet to Be Verified"
                    ? "yet to be verified"
                    : currentStatus == "Available"
                    ? "available"
                    : currentStatus == "Employed"
                    ? "employed"
                    : "all",
                talentFilterValue: {
                  field_name: field_name,
                  field_value:
                    dashboardData.totalTalentFilterValue[0].dataIndex ===
                      "handicapped" &&
                    dashboardData.totalTalentFilterValue[0].sval === "All"
                      ? true
                      : dashboardData.totalTalentFilterValue[0].sval,
                },
                sortColumn: [dashboardData.sortColumn]
              }
            )
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              console.log(error);
              message.destroy("totalTalent");
            });
          }else{
            axios
            .post(
              `${baseUrl}/spectraStatusTalentData`,
              {
                type: "total",
                status:
                  currentStatus == "Incomplete Profile"
                    ? "incomplete"
                    : currentStatus == "Yet to Be Verified"
                    ? "yet to be verified"
                    : currentStatus == "Available"
                    ? "available"
                    : currentStatus == "Employed"
                    ? "employed"
                    : "all",
                talentFilterValue: {
                  field_name: field_name,
                  field_value:
                    dashboardData.totalTalentFilterValue[0].dataIndex ===
                      "handicapped" &&
                    dashboardData.totalTalentFilterValue[0].sval === "All"
                      ? true
                      : dashboardData.totalTalentFilterValue[0].sval,
                },
              }
            )
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              console.log(error);
              message.destroy("totalTalent");
            });
          }
        } else if (Object.keys(dashboardData.filterQuery).length > 0) {
          console.log("it is true");
          message.loading({
            content: "Loading",
            duration: 0,
            key: "totalTalent",
          });
          const talentData = [];
          if(dashboardData.sortColumn){
            axios
            .post(
              `${baseUrl}/SpectraFilterQueryTalentData`,
              {
                status:
                  currentStatus == "Incomplete Profile"
                    ? "incomplete"
                    : currentStatus == "Yet to Be Verified"
                    ? "yet to be verified"
                    : currentStatus == "Available"
                    ? "available"
                    : currentStatus == "Employed"
                    ? "employed"
                    : "all",
                query: dashboardData.filterQuery,
                sortColumn: [dashboardData.sortColumn]
              }
            )
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }else{
            axios
            .post(
              `${baseUrl}/SpectraFilterQueryTalentData`,
              {
                status:
                  currentStatus == "Incomplete Profile"
                    ? "incomplete"
                    : currentStatus == "Yet to Be Verified"
                    ? "yet to be verified"
                    : currentStatus == "Available"
                    ? "available"
                    : currentStatus == "Employed"
                    ? "employed"
                    : "all",
                query: dashboardData.filterQuery,
              }
            )
            .then((response) => {
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData);
              } else {
                setDataTalent([]);
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }
        } else {
          message.loading({
            content: "Loading",
            duration: 0,
            key: "totalTalent",
          });
          if(dashboardData.sortColumn){
            axios
            .post(
              `${baseUrl}/spectraStatusTalentData`,
              {
                type: "total",
                status:
                  currentStatus == "Incomplete Profile"
                    ? "incomplete"
                    : currentStatus == "Yet to Be Verified"
                    ? "yet to be verified"
                    : currentStatus == "Available"
                    ? "available"
                    : currentStatus == "Employed"
                    ? "employed"
                    : "all",
                size: 20 + dashboardData.totalTalentPage * 20,
                sortColumn: [dashboardData.sortColumn]
              }
            )
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent(talentData)
              }else{
                setDataTalent([])
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }else{
            axios
            .post(
              `${baseUrl}/spectraStatusTalentData`,
              {
                type: "total",
                status:
                  currentStatus == "Incomplete Profile"
                    ? "incomplete"
                    : currentStatus == "Yet to Be Verified"
                    ? "yet to be verified"
                    : currentStatus == "Available"
                    ? "available"
                    : currentStatus == "Employed"
                    ? "employed"
                    : "all",
                from: dashboardData.totalTalentPage * 20,
                size: 20,
              }
            )
            .then((response) => {
              const talentData = [];
              response.data.talentDetails.forEach((val) => {
                talentData.push(changeTalentData(val));
              });
              if (talentData.length > 0) {
                setDataTalent((pre) => {
                  if (dashboardData.totalTalentPage > 0) {
                    return [...pre, ...talentData];
                  } else return talentData;
                });
              }else{
                setDataTalent((pre) => {
                  if (dashboardData.totalTalentPage > 0) {
                    return [...pre, ...talentData];
                  } else return talentData;
                });
              }
              message.destroy("totalTalent");
            })
            .catch((error) => {
              message.destroy("totalTalent");
              console.log(error);
            });
          }
        }
      }
    }
  }

  async function contractorFunction() {
    var userId = localStorage.getItem("contractor_id");
    if (
      typeof dashboardData.totalTalentFilterValue !== "undefined" &&
      dashboardData.totalTalentFilterValue.length > 0
    ) {
      var field_name = "";
      if (dashboardData.totalTalentFilterValue[0].dataIndex === "all") {
        field_name = "all";
      } else if (dashboardData.totalTalentFilterValue[0].dataIndex === "name") {
        field_name = "personal.full_name";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "status"
      ) {
        field_name = "status";
      } else if (dashboardData.totalTalentFilterValue[0].dataIndex === "dob") {
        field_name = "personal.dob";
      } else if (dashboardData.totalTalentFilterValue[0].dataIndex === "mnum") {
        field_name = "contact.mobile_number";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "address"
      ) {
        field_name = "address";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "gender"
      ) {
        field_name = "personal.gender";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "bGroup"
      ) {
        field_name = "personal.blood_group";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "email"
      ) {
        field_name = "email";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "aadhaar"
      ) {
        field_name = "identity.aadhaar.aadhaar_number";
      } else if (dashboardData.totalTalentFilterValue[0].dataIndex === "pan") {
        field_name = "identity.pan.pan_number";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "bankAC"
      ) {
        field_name = "bank.account_number";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "handicapped"
      ) {
        if (dashboardData.totalTalentFilterValue[0].sval === "All") {
          field_name = "personal.is_handicapped";
        } else {
          field_name = "personal.handicapped_reason";
        }
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "contractor"
      ) {
        field_name = "status.current_contractor_name";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "employer"
      ) {
        field_name = "status.current_primary_employer_name";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "pincode"
      ) {
        field_name = "contact.pincode";
      }
    }
    if (currentStatus == "total") {
      if (
        typeof dashboardData.totalTalentFilterValue !== "undefined" &&
        dashboardData.totalTalentFilterValue.length > 0
      ) {
        message.loading({
          content: "Loading",
          duration: 0,
          key: "totalTalent",
        });
        if(dashboardData.sortColumn){
          axios
          .post(
            `${baseUrl}/ContractorTotalTalentData`,
            {
              talentFilterValue: {
                field_name: field_name,
                field_value:
                  dashboardData.totalTalentFilterValue[0].dataIndex ===
                    "handicapped" &&
                  dashboardData.totalTalentFilterValue[0].sval === "All"
                    ? true
                    : dashboardData.totalTalentFilterValue[0].sval,
              },
              user_id: userId,
              sortColumn: [dashboardData.sortColumn]
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/ContractorTotalTalentData`,
            {
              talentFilterValue: {
                field_name: field_name,
                field_value:
                  dashboardData.totalTalentFilterValue[0].dataIndex ===
                    "handicapped" &&
                  dashboardData.totalTalentFilterValue[0].sval === "All"
                    ? true
                    : dashboardData.totalTalentFilterValue[0].sval,
              },
              user_id: userId,
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }
      } else if (Object.keys(dashboardData.filterQuery).length > 0) {
        message.loading({
          content: "Loading",
          duration: 0,
          key: "totalTalent",
        });
        const talentData = [];
        if(dashboardData.sortColumn){
          axios
          .post(
            `${baseUrl}/ContractorFilterQueryTalentData`,
            {
              query: dashboardData.filterQuery,
              status: "total",
              sortColumn: [dashboardData.sortColumn]
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/ContractorFilterQueryTalentData`,
            {
              query: dashboardData.filterQuery,
              status: "total",
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }
      } else {
        message.loading({
          content: "Loading",
          duration: 0,
          key: "totalTalent",
        });
        if(dashboardData.sortColumn){
          axios
          .post(
            `${baseUrl}/ContractorTotalTalentData`,
            {
              size: 20 + dashboardData.totalTalentPage * 20,
              user_id: userId,
              type: "total",
              sortColumn: [dashboardData.sortColumn]
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData)
            }else{
              setDataTalent([])
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/ContractorTotalTalentData`,
            {
              from: dashboardData.totalTalentPage * 20,
              size: 20,
              user_id: userId,
              type: "total",
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent((pre) => {
                if (dashboardData.totalTalentPage > 0) {
                  return [...pre, ...talentData];
                } else return talentData;
              });
            }else{
              setDataTalent((pre) => {
                if (dashboardData.totalTalentPage > 0) {
                  return [...pre, ...talentData];
                } else return talentData;
              });
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }
      }
    } else if (currentStatus !== "total") {
      if (
        typeof dashboardData.totalTalentFilterValue !== "undefined" &&
        dashboardData.totalTalentFilterValue.length > 0
      ) {
        message.loading({
          content: "Loading",
          duration: 0,
          key: "totalTalent",
        });
        if(dashboardData.sortColumn){
          axios
          .post(
            `${baseUrl}/ContractorStatusTalentData`,
            {
              status:
                currentStatus == "Incomplete Profile"
                  ? "incomplete"
                  : currentStatus == "Yet to Be Verified"
                  ? "yet to be verified"
                  : currentStatus == "Available"
                  ? "available"
                  : currentStatus == "Employed"
                  ? "employed"
                  : "all",
              talentFilterValue: {
                field_name: field_name,
                field_value:
                  dashboardData.totalTalentFilterValue[0].dataIndex ===
                    "handicapped" &&
                  dashboardData.totalTalentFilterValue[0].sval === "All"
                    ? true
                    : dashboardData.totalTalentFilterValue[0].sval,
              },
              user_id: userId,
              sortColumn: [dashboardData.sortColumn]
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/ContractorStatusTalentData`,
            {
              status:
                currentStatus == "Incomplete Profile"
                  ? "incomplete"
                  : currentStatus == "Yet to Be Verified"
                  ? "yet to be verified"
                  : currentStatus == "Available"
                  ? "available"
                  : currentStatus == "Employed"
                  ? "employed"
                  : "all",
              talentFilterValue: {
                field_name: field_name,
                field_value:
                  dashboardData.totalTalentFilterValue[0].dataIndex ===
                    "handicapped" &&
                  dashboardData.totalTalentFilterValue[0].sval === "All"
                    ? true
                    : dashboardData.totalTalentFilterValue[0].sval,
              },
              user_id: userId,
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }
      } else if (Object.keys(dashboardData.filterQuery).length > 0) {
        message.loading({
          content: "Loading",
          duration: 0,
          key: "totalTalent",
        });
        const talentData = [];
        if(dashboardData.sortColumn){
          axios
          .post(
            `${baseUrl}/ContractorFilterQueryTalentData`,
            {
              query: dashboardData.filterQuery,
              status:
                currentStatus == "Incomplete Profile"
                  ? "incomplete"
                  : currentStatus == "Yet to Be Verified"
                  ? "yet to be verified"
                  : currentStatus == "Available"
                  ? "available"
                  : currentStatus == "Employed"
                  ? "employed"
                  : "all",
              sortColumn:  [dashboardData.sortColumn]
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/ContractorFilterQueryTalentData`,
            {
              query: dashboardData.filterQuery,
              status:
                currentStatus == "Incomplete Profile"
                  ? "incomplete"
                  : currentStatus == "Yet to Be Verified"
                  ? "yet to be verified"
                  : currentStatus == "Available"
                  ? "available"
                  : currentStatus == "Employed"
                  ? "employed"
                  : "all",
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }
      } else {
        const userId = localStorage.getItem("contractor_id");
        message.loading({
          content: "Loading",
          duration: 0,
          key: "totalTalent",
        });
        if(dashboardData.sortColumn){
          axios
          .post(
            `${baseUrl}/ContractorStatusTalentData`,
            {
              status:
                currentStatus == "Incomplete Profile"
                  ? "incomplete"
                  : currentStatus == "Yet to Be Verified"
                  ? "yet to be verified"
                  : currentStatus == "Available"
                  ? "available"
                  : currentStatus == "Employed"
                  ? "employed"
                  : "all",
              size: 20 + dashboardData.totalTalentPage * 20,
              user_id: userId,
              sortColumn:  [dashboardData.sortColumn]
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData)
            }else{
              setDataTalent([])
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/ContractorStatusTalentData`,
            {
              status:
                currentStatus == "Incomplete Profile"
                  ? "incomplete"
                  : currentStatus == "Yet to Be Verified"
                  ? "yet to be verified"
                  : currentStatus == "Available"
                  ? "available"
                  : currentStatus == "Employed"
                  ? "employed"
                  : "all",
              from: dashboardData.totalTalentPage * 20,
              size: 20,
              user_id: userId,
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent((pre) => {
                if (dashboardData.totalTalentPage > 0) {
                  return [...pre, ...talentData];
                } else return talentData;
              });
            }else{
              setDataTalent((pre) => {
                if (dashboardData.totalTalentPage > 0) {
                  return [...pre, ...talentData];
                } else return talentData;
              });
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }
      }
    }
  }

  async function primaryEmployerFunction() {

    var employerId = localStorage.getItem("primary_employer_id");
    if (
      typeof dashboardData.totalTalentFilterValue !== "undefined" &&
      dashboardData.totalTalentFilterValue.length > 0
    ) {
      var field_name = "";
      if (dashboardData.totalTalentFilterValue[0].dataIndex === "all") {
        field_name = "all";
      } else if (dashboardData.totalTalentFilterValue[0].dataIndex === "name") {
        field_name = "personal.full_name";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "status"
      ) {
        field_name = "status";
      } else if (dashboardData.totalTalentFilterValue[0].dataIndex === "dob") {
        field_name = "personal.dob";
      } else if (dashboardData.totalTalentFilterValue[0].dataIndex === "mnum") {
        field_name = "contact.mobile_number";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "address"
      ) {
        field_name = "address";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "gender"
      ) {
        field_name = "personal.gender";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "bGroup"
      ) {
        field_name = "personal.blood_group";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "email"
      ) {
        field_name = "email";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "aadhaar"
      ) {
        field_name = "identity.aadhaar.aadhaar_number";
      } else if (dashboardData.totalTalentFilterValue[0].dataIndex === "pan") {
        field_name = "identity.pan.pan_number";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "bankAC"
      ) {
        field_name = "bank.account_number";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "handicapped"
      ) {
        if (dashboardData.totalTalentFilterValue[0].sval === "All") {
          field_name = "personal.is_handicapped";
        } else {
          field_name = "personal.handicapped_reason";
        }
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "contractor"
      ) {
        field_name = "status.current_contractor_name";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "employer"
      ) {
        field_name = "status.current_primary_employer_name";
      } else if (
        dashboardData.totalTalentFilterValue[0].dataIndex === "pincode"
      ) {
        field_name = "contact.pincode";
      }
    }
    if (currentStatus == "total") {
      if (
        typeof dashboardData.totalTalentFilterValue !== "undefined" &&
        dashboardData.totalTalentFilterValue.length > 0
      ) {
        message.loading({
          content: "Loading",
          duration: 0,
          key: "totalTalent",
        });
        if(dashboardData.sortColumn){
          axios
          .post(
            `${baseUrl}/EmployerTotalTalentData`,
            {
              talentFilterValue: {
                field_name: field_name,
                field_value:
                  dashboardData.totalTalentFilterValue[0].dataIndex ===
                    "handicapped" &&
                  dashboardData.totalTalentFilterValue[0].sval === "All"
                    ? true
                    : dashboardData.totalTalentFilterValue[0].sval,
              },
              user_id: employerId,
              sortColumn: [dashboardData.sortColumn]
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/EmployerTotalTalentData`,
            {
              talentFilterValue: {
                field_name: field_name,
                field_value:
                  dashboardData.totalTalentFilterValue[0].dataIndex ===
                    "handicapped" &&
                  dashboardData.totalTalentFilterValue[0].sval === "All"
                    ? true
                    : dashboardData.totalTalentFilterValue[0].sval,
              },
              user_id: employerId,
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }
      } else if (Object.keys(dashboardData.filterQuery).length > 0) {
        message.loading({
          content: "Loading",
          duration: 0,
          key: "totalTalent",
        });
        const talentData = [];
        if(dashboardData.sortColumn){
          axios
          .post(
            `${baseUrl}/EmployerFilterQueryTalentData`,
            {
              query: dashboardData.filterQuery,
              status: "total",
              sortColumn: [dashboardData.sortColumn]
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/EmployerFilterQueryTalentData`,
            {
              query: dashboardData.filterQuery,
              status: "total",
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }
      } else {
        message.loading({
          content: "Loading",
          duration: 0,
          key: "totalTalent",
        });
        if(dashboardData.sortColumn){
          axios
          .post(
            `${baseUrl}/EmployerTotalTalentData`,
            {
              size: 20 + dashboardData.totalTalentPage * 20,
              user_id: employerId,
              type: "total",
              sortColumn: [dashboardData.sortColumn]
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/EmployerTotalTalentData`,
            {
              from: dashboardData.totalTalentPage * 20,
              size: 20,
              user_id: employerId,
              type: "total",
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent((pre) => {
                if (dashboardData.totalTalentPage > 0) {
                  return [...pre, ...talentData];
                } else return talentData;
              });
            }else{
              setDataTalent((pre) => {
                if (dashboardData.totalTalentPage > 0) {
                  return [...pre, ...talentData];
                } else return talentData;
              });
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }
      }
    } else if (currentStatus !== "total") {
      if (
        typeof dashboardData.totalTalentFilterValue !== "undefined" &&
        dashboardData.totalTalentFilterValue.length > 0
      ) {
        message.loading({
          content: "Loading",
          duration: 0,
          key: "totalTalent",
        });
        if(dashboardData.sortColumn){
          axios
          .post(
            `${baseUrl}/EmployerStatusTalentData`,
            {
              status:
                currentStatus == "currentEmployee"
                  ? "assigned"
                  : currentStatus == "unassigned"
                  ? "unassigned"
                  : "all",
              talentFilterValue: {
                field_name: field_name,
                field_value:
                  dashboardData.totalTalentFilterValue[0].dataIndex ===
                    "handicapped" &&
                  dashboardData.totalTalentFilterValue[0].sval === "All"
                    ? true
                    : dashboardData.totalTalentFilterValue[0].sval,
              },
              user_id: employerId,
              sortColumn: [dashboardData.sortColumn]
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/EmployerStatusTalentData`,
            {
              status:
                currentStatus == "currentEmployee"
                  ? "assigned"
                  : currentStatus == "unassigned"
                  ? "unassigned"
                  : "all",
              talentFilterValue: {
                field_name: field_name,
                field_value:
                  dashboardData.totalTalentFilterValue[0].dataIndex ===
                    "handicapped" &&
                  dashboardData.totalTalentFilterValue[0].sval === "All"
                    ? true
                    : dashboardData.totalTalentFilterValue[0].sval,
              },
              user_id: employerId,
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }
      } else if (Object.keys(dashboardData.filterQuery).length > 0) {
        message.loading({
          content: "Loading",
          duration: 0,
          key: "totalTalent",
        });
        const talentData = [];
        if(dashboardData.sortColumn){
          axios
          .post(
            `${baseUrl}/EmployerFilterQueryTalentData`,
            {
              query: dashboardData.filterQuery,
              status:
                currentStatus == "currentEmployee"
                  ? "assigned"
                  : currentStatus == "unassigned"
                  ? "unassigned"
                  : "all",
              sortColumn: [dashboardData.sortColumn]
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/EmployerFilterQueryTalentData`,
            {
              query: dashboardData.filterQuery,
              status:
                currentStatus == "currentEmployee"
                  ? "assigned"
                  : currentStatus == "unassigned"
                  ? "unassigned"
                  : "all",
            }
          )
          .then((response) => {
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData);
            } else {
              setDataTalent([]);
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }
      } else {
        const userId = localStorage.getItem("primary_employer_id");
        message.loading({
          content: "Loading",
          duration: 0,
          key: "totalTalent",
        });
        if(dashboardData.sortColumn){
          axios
          .post(
            `${baseUrl}/EmployerStatusTalentData`,
            {
              status:
                currentStatus == "currentEmployee"
                  ? "assigned"
                  : currentStatus == "unassigned"
                  ? "unassigned"
                  : "all",
              size: 20 + dashboardData.totalTalentPage * 20,
              user_id: userId,
              sortColumn: [dashboardData.sortColumn]
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent(talentData)
            }else{
              setDataTalent([])
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }else{
          axios
          .post(
            `${baseUrl}/EmployerStatusTalentData`,
            {
              status:
                currentStatus == "currentEmployee"
                  ? "assigned"
                  : currentStatus == "unassigned"
                  ? "unassigned"
                  : "all",
              from: dashboardData.totalTalentPage * 20,
              size: 20,
              user_id: userId,
            }
          )
          .then((response) => {
            const talentData = [];
            response.data.talentDetails.forEach((val) => {
              talentData.push(changeTalentData(val));
            });
            if (talentData.length > 0) {
              setDataTalent((pre) => {
                if (dashboardData.totalTalentPage > 0) {
                  return [...pre, ...talentData];
                } else return talentData;
              });
            }else{
              setDataTalent((pre) => {
                if (dashboardData.totalTalentPage > 0) {
                  return [...pre, ...talentData];
                } else return talentData;
              });
            }
            message.destroy("totalTalent");
          })
          .catch((error) => {
            message.destroy("totalTalent");
            console.log(error);
          });
        }
      }
    }
  }

  useEffect(() => {
    if (auth.isSpectra) {
      spectraFunction();
    } else if (auth.isContract) {
      contractorFunction();
    } else if (auth.isPrimary) {
      primaryEmployerFunction();
    }
  }, [
    dashboardData.isSort,
    dashboardData.sortColumn,
    dashboardData.totalTalentPage,
    dashboardData.totalTalentFilterValue,
    dashboardData.contractValue,
    dashboardData.filterQuery,
  ]);

  const [columnTalent, setColumnTalent] = useState([
    {
      title: "Status",
      width: 10,
      sort: false,
      filter: "dropDown",
      dropDownList: [
        "Completed Profile",
        "Incomplete Profile",
        "Yet To Be Verified",
        "Employed",
        "Available",
      ],
      filterValue: "",
      dataIndex: "status",
      position: 0,
      visibility: true,
    },
    {
      title: "Name",
      filterValue: "",
      dataIndex: "name",
      width: 100,
      filter: "text",
      sort: true,
      position: 1,
      visibility: true,
    },
    {
      title: "Address",
      filterValue: "",
      dataIndex: "address",
      filter: "text",
      width: 100,
      sort: false,
      position: 3,
      visibility: true,
    },
    {
      title: "Mobile Number",
      filterValue: "",
      dataIndex: "mnum",
      filter: "text",
      width: 100,
      sort: false,
      position: 4,
      visibility: true,
    },
    {
      title: "Gender",
      filterValue: "",
      dataIndex: "gender",
      filter: "dropDown",
      dropDownList: ["Male", "Female", "Others"],
      width: 100,
      sort: false,
      position: 5,
      visibility: true,
    },
    {
      title: "Blood Group",
      width: 100,
      filter: "dropDown",
      dropDownList: [ "A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
      sort: false,
      filterValue: "",
      dataIndex: "bGroup",
      position: 6,
      visibility: false,
    },
    {
      title: "Email",
      filter: "text",
      width: 100,
      sort: false,
      filterValue: "",
      dataIndex: "email",
      position: 7,
      visibility: false,
    },
    {
      title: "Aadhaar Number",
      filter: "text",
      width: 100,
      sort: false,
      filterValue: "",
      dataIndex: "aadhaar",
      position: 8,
      visibility: true,
    },
    {
      title: "PAN Number",
      filter: "text",
      width: 100,
      sort: false,
      filterValue: "",
      dataIndex: "pan",
      position: 9,
      visibility: true,
    },
    {
      title: "BANK A/C NUM",
      filter: "text",
      width: 100,
      sort: false,
      filterValue: "",
      dataIndex: "bankAC",
      position: 10,
      visibility: true,
    },
    {
      title: "Physically challenged",
      filter: "dropDown",
      dropDownList: [
        "No, Not Physically challenged",
        "Yes, Visual",
        "Yes, Hearing",
        "Yes, Locomotive",
      ],
      width: 100,
      sort: false,
      filterValue: "",
      dataIndex: "handicapped",
      position: 11,
      visibility: false,
    },
    {
      title: "Contractor name",
      filter: "text",
      width: 100,
      sort: true,
      filterValue: "",
      dataIndex: "contractor",
      position: 12,
      visibility: true,
    },
    {
      title: "Employer name",
      filter: "text",
      width: 100,
      sort: true,
      filterValue: "",
      dataIndex: "employer",
      position: 13,
      visibility: true,
    },
    {
      title: "Pincode",
      filter: "text",
      width: 100,
      sort: true,
      filterValue: "",
      dataIndex: "pincode",
      position: 13,
      visibility: true,
    },
  ]);

  const handleGoback = () => {
    setDashboardData((pre) => {
      return { ...pre, contractValue: [], filterValue: [] };
    });
  };

  const isMounted = useRef(false);
  useEffect(() => {
      if (isMounted.current) {
          const colName = auth.isSpectra ? "spectrawise_bizops_users" : auth.isContract ? "contractor_users" : auth.isPrimary ? "primary_employer_users" : "";
          let list = columnTalent.filter(itx => itx.visibility).map(itx => itx.dataIndex);
          let undefinedList = list.filter(function( element ) {
            return element !== undefined;
         });
          if(list.length > 0){
            console.log(db, colName, localStorage.getItem('uid'), list)
            updateDoc(doc(db, colName, localStorage.getItem('uid')), {
              "settings.talent_user_column_list": undefinedList,
          });
          }
      } else {
          isMounted.current = true;
      }
  }, [columnTalent]);

  return (
    <div>
      {true && (
        <div style={{ width: "100%", height: "17vh" }}>
          <div style={{ padding: "15px" }}>
            <Link to={"/" + userPath}>
              <img
                src={TotalBack}
                width="140px"
                onClick={() => handleGoback()}
              />
            </Link>
          </div>
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            className="search"
          >
            <UserPanelSearch
              talents={true}
              contractor={false}
              columnTalent={columnTalent}
              selectedColumn={selectedColumn}
              totalTalents
            />
            <MoreOption
              columnTalent={columnTalent}
              setColumnTalent={setColumnTalent}
              selectedColumn={selectedColumn}
              setSelectedColumn={setSelectedColumn}
            />
          </div>
        </div>
      )}
      <ResponsiveTable
        talents={true}
        dashboard={true}
        data={dataTalent}
        column={columnTalent}
        setColumn={setColumnTalent}
        selectedColumn={selectedColumn}
        setSelectedColumn={setSelectedColumn}
        totoalTalents
      />
    </div>
  );
}

export default TotalTalents;

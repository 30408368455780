import React, { useEffect, useState } from "react";
import leftArrow from "../../assets/icons/leftArrow.svg";
import { useHistory, useLocation } from "react-router-dom";
import "../../styles/RequestDetails.scss";
import { Button, message, Popover, Spin, Table, Modal, DatePicker, Popconfirm } from "antd";
import { doc, getDoc, getFirestore, addDoc, collection, updateDoc, onSnapshot, } from "firebase/firestore";
import { useAuthContext } from "../../context/auth";
import moment from "moment";
import axios from "axios";
import { notification } from "antd";
import notesIcon from '../../assets/icons/notesIcon.svg'
import baseUrl from "../../utils/baseUrl";

const db = getFirestore();

function EnrollRequestDetail() {
  const auth = useAuthContext();
  const history = useHistory();
  const { id } = useLocation().state;
  const [detailsData, setDetailsData] = useState();
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [modalvisible, setModalVisible] = useState(false);
  const [rejectModalvisible, setRejectModalVisible] = useState(false);
  const [filteredTalents, setFilteredTalents] = useState([]);
  const [selectedTalents, setSelectedTalents] = useState([]);
  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // ellipsis: true
      className: auth.isContract ? 'contractPage' : ''
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // ellipsis: true
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      // ellipsis: true
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      // ellipsis: true
    },
    {
      title: "Email",
      dataIndex: "emailAddress",
      key: "emailAddress",
      // ellipsis: true
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
      render: (address) => (
        <Popover content={() => (<p>{address}</p>)}>
          <span>{address}</span>
        </Popover>
      ) 
    },
    ...(!auth.isContract ? [
      {
        title: '',
        width: '200px',
        render: (obj) => (
          <>
            {!(obj.isAlreadyEmployed || obj.status == 'REJECTED') ? <> 
              <Popconfirm
                placement="topRight"
                title={'are you sure, you want to reject this talent ?'}
                onConfirm={(e) => rejectRequest(e, [obj])}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
                overlayClassName='popUpDeletePost'
                >
                <Button type="secondary" >Reject</Button>
              </Popconfirm>
              <Popconfirm
                placement="topRight"
                title={'are you sure, you want to accept this talent ?'}
                onConfirm={(e) => acceptRequest(e, [obj])}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
                overlayClassName='popUpDeletePost'
                >
                <Button type='primary' style={{marginLeft: '10px'}}>Accept</Button>
              </Popconfirm>
            </>
            : <></>  
          }
          </>
        )
      }
    ]: [])
  ];

  useEffect(() => {
    if(tableData){
      const temp = tableData.filter((item) => {
          return !(item.isAlreadyEmployed || item.status == 'REJECTED')
      })
      setFilteredTalents(temp);
    }
    
  },[tableData])

  const data = [
    {
      key: "1",
      name: "John Brown",
      age: 32,
      address: "New York No. 1 Lake Park",
      tags: ["nice", "developer"],
    },
  ];

  const getRequestData = async () => {

    const unsubscribe = onSnapshot(doc(db, "enrollment_requests", id), (docSnap) => {
    if (docSnap.exists()) {
      const data = docSnap.data();

      setDetailsData({...data, id: docSnap.id});
      let count = 0;
      let talentsList = [];
      setTableLoading(true);
      data.talents.map(async (talent) => {
        const talDocRef = doc(db, "talent_users", talent.id);
        const talDocSnap = await getDoc(talDocRef);

        if (talDocSnap.exists()) {
          const talData = talDocSnap.data();
          let isAlreadyEmployed;
          if(talData.status.is_employed == true  && talData.status.current_primary_employer_id){
            isAlreadyEmployed = true
          }else{
            isAlreadyEmployed = false
          }
          talentsList.push({
            key: talent.id,
            id: talent.id,
            isAlreadyEmployed: isAlreadyEmployed,
            status: talent.status,
            name: talData.personal.full_name,
            mobileNumber: talData.contact.mobile_number,
            gender: talData.personal.gender,
            emailAddress: talData.contact.email,
            address:
              talData.contact?.address_line_1 ||
              talData.contact?.address_line_2 ||
              talData.contact?.city ||
              talData.contact?.state ||
              talData.contact?.pincode
                ? `${
                    talData.contact?.address_line_1 &&
                    `${talData.contact?.address_line_1},`
                  }${
                    talData.contact?.address_line_2 &&
                    `${talData.contact?.address_line_2},`
                  }
            ${talData.contact?.city && `${talData.contact?.city},`} ${
                    talData.contact?.pincode && `${talData.contact?.pincode},`
                  } ${talData.contact?.state && talData.contact?.state}`
                : "--",
          });
        } else {
          message.error({
            content: "Talent Data is not available",
            key: "requestDetail",
          });
        }

        if (data.talents.length - 1 === count++) {
          setTableData(talentsList)
          setTableLoading(false);
        }
      });
    } else {
      message.error({content: 'Something went wrong'})
      setDetailsData({});
      // history.goBack();
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
    })

    return () => unsubscribe();
  };

  useEffect(() => {
    getRequestData();
  }, []);

  const ShiftTime = ({startTime, endTime}) => {

    let startTimeNumber = startTime.slice(1, 3).trim()
    let endTimeNumber = endTime.slice(1, 3).trim();
    let startingTime = startTimeNumber > 12 ? `${startTimeNumber - 12}:00 PM` : `${startTimeNumber}:00 AM`
    let endingTime = endTimeNumber > 12 ? `${endTimeNumber - 12}:00 PM` : `${endTimeNumber}:00 AM`
    return (
      <h3>{startingTime} - {endingTime}</h3>
    )
  }

  const PopOver = ({data, earnings}) => {

    const [mapData, setMapData] = useState([]);

    useEffect(() => {
      if(earnings){
        let temp = [];
        data.earnings.map((item) => {
          temp.push(item);
        })
        setMapData(temp)
      }else{
        setMapData([...data])
      }
    },[])
    console.log('mapData', mapData)
     return (
     <div>
      <div style={{display: earnings ? '' : 'none'}}>
      <div style={{display: 'flex', justifyContent: 'space-between', color: 'white'}}>
        <span style={{width: 'max-content', margin: 'auto 20px auto 5px'}}>Basic</span>
        <span>₹{data.major_salary_split?.basic_salary}</span>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', color: 'white'}}>
        <span style={{width: 'max-content', margin: 'auto 20px auto 5px'}}>D A</span>
        <span>₹{data.major_salary_split?.da}</span>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', color: 'white'}}>
        <span style={{width: 'max-content', margin: 'auto 20px auto 5px'}}>HRA</span>
        <span>₹{data.major_salary_split?.hra}</span>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', color: 'white'}}>
        <span style={{width: 'max-content', margin: 'auto 20px auto 5px'}}>Special Allowance</span>
        <span>₹{data.major_salary_split?.special_allowance}</span>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', color: 'white'}}>
        <span style={{width: 'max-content', margin: 'auto 20px auto 5px'}}>L&T Allowances</span>
        <span>₹{data.major_salary_split?.l_t_allowances}</span>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', color: 'white'}}>
        <span style={{width: 'max-content', margin: 'auto 20px auto 5px'}}>Employer PF</span>
        <span>₹{data.major_salary_split?.employer_pf}</span>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', color: 'white'}}>
        <span style={{width: 'max-content', margin: 'auto 20px auto 5px'}}>Employer ESI</span>
        <span>₹{data.major_salary_split?.employer_esi}</span>
      </div>
      </div>
     {mapData.map((item, i) => {
     return (
      <div style={{display: 'flex', justifyContent: 'space-between', color: 'white'}}>
        <span style={{width: 'max-content', margin: 'auto 20px auto 5px'}}>{item.title}</span>
        <span>₹{item.amount}</span>
      </div>
      )
    })}
   </div>
   )
 }

  const acceptRequest = (e, talents) => {
    e.preventDefault();
    console.log(talents, detailsData)
    message.loading({content: 'Enrolling...', duration: 0, key: 'enroll'});
    var error = [];
    var talentsStatus = [...detailsData.talents]
    const date = new Date(detailsData.date_of_joining.seconds * 1000 + detailsData.date_of_joining.nanoseconds / 1000000);
    const dateString = date.toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit', year: 'numeric'});
    let salary, earnings, deductions, mandatorySalarySplitUp;
      if(detailsData.salary_per_period == 'year') {
        salary = parseFloat(detailsData.salary) / 12;
        earnings = detailsData.earnings.map((each) => {
          return {
            title: each.title,
            amount: parseFloat(each.amount) / 12
          }
        });
        deductions = detailsData.deductions.map((each) => {
          return {
            title: each.title,
            amount: parseFloat(each.amount) / 12
          }
        });
        mandatorySalarySplitUp = {
          basic_salary: (parseFloat(detailsData.major_salary_split.basic_salary)/ 12).toFixed(),
          da: (parseFloat(detailsData.major_salary_split.da)/ 12).toFixed(),
          hra:  (parseFloat(detailsData.major_salary_split.hra)/ 12).toFixed(),
          special_allowance:  (parseFloat(detailsData.major_salary_split.special_allowances)/ 12).toFixed(),
          l_t_allowances:  (parseFloat(detailsData.major_salary_split.l_t_allowances)/ 12).toFixed(),
          employer_pf:  (parseFloat(detailsData.major_salary_split.employer_pf)/ 12).toFixed(),
          employer_esi:  (parseFloat(detailsData.major_salary_split.employer_esi)/ 12).toFixed()
        }
      } else {
        salary = parseFloat(detailsData.salary);
        earnings = detailsData.earnings.map((each) => {
          return {
            title: each.title,
            amount: parseFloat(each.amount)
          }
        });
        deductions =  detailsData.deductions.map((each) => {
          return {
            title: each.title,
            amount: parseFloat(each.amount)
          }
        });
        mandatorySalarySplitUp = {
          basic_salary: detailsData.major_salary_split.basic_salary,
          da: detailsData.major_salary_split.da,
          hra: detailsData.major_salary_split.hra,
          special_allowance: detailsData.major_salary_split.special_allowances,
          l_t_allowances: detailsData.major_salary_split.l_t_allowances,
          employer_pf: detailsData.major_salary_split.employer_pf,
          employer_esi: detailsData.major_salary_split.employer_esi
        }
      }

    talents.map(async (tal, i) => {
      console.log(tal.id, i)
      let empDoc = {
        is_current_employer: true,
        employer_id: detailsData.primary_employer_id,
        esic: "",
        pf: "",
        work_location: "",
        date_of_exit: "",
        date_of_joining: detailsData.date_of_joining,
        salary: detailsData.salary,
        salary_per_period: detailsData.salary_per_period,
        major_salary_split: detailsData.major_salary_split, 
        earnings: detailsData.earnings,
        deductions:  detailsData.deductions,
        shift_start_time: detailsData.shift_start_time,
        shift_end_time: detailsData.shift_end_time,
        department: detailsData.department,
        designation: detailsData.designation,
        notes: detailsData.notes,
        supervisor_name: detailsData.supervisor_name
      }
      let tref = await addDoc(collection(db, `talent_users/${tal.id}/employer`), empDoc)
      .then(async () => {
          await updateDoc(doc(db, `talent_users`, tal.id), { "status.is_employed": true, "status.current_primary_employer_id": detailsData.primary_employer_id
            }).then(async () => {
              let tempData = talentsStatus.map((item) => {
                let d = item;
                if(item.id == tal.id){
                  d.status = 'ACCEPTED'
                }
                return d
              })
              talentsStatus = tempData;
              await  axios.post(`${baseUrl}/addEmployerDetailsInRazorPay`,
              {
                title: 'addEmpDetailsInRazorPay',             
                userId : tal.id,
                department: detailsData.department,
                designation: detailsData.designation,
                date_of_joining: dateString,
                salary: salary,
                split_up: mandatorySalarySplitUp,
                earnings: earnings,
                deductions:  deductions
              }
              ).then((response) => {
                  if(response.data.status !== true){
                    error.push(`${tal.name} - ${response.data.message}`)
                  }
                  if (talents.length - 1 == i) {
                    setSelectedTalents([])
                    setModalVisible(false)
                    if(error.length > 0 ){
                      notification.error({
                        message: 'Error occured on data adding in RazorpayX Payroll for this list of talents',
                        description: error.map((s, i) => `
                        ${i + 1}. ${s}`).join('.'),
                        duration: 0,
                        key: "notification",
                        style: { whiteSpace: 'break-spaces', maxHeight: "90vh", overflow: "auto" }
                      });
                      message.error({ content: "Something went wrong", key: "enroll" });
                    }else{
                      message.success({ content: "Users enrolled Successfully!", key: "enroll" });
                    }
                  }
            })
            .catch((error) => {
              error.push(`${tal.name} - Razorpay Initial Request Failed`)
              console.log(error);
              if (talents.length - 1 == i) {
                setSelectedTalents([])
                setModalVisible(false)
                if(error.length > 0 ){
                  notification.error({
                    message: 'Error occured on data adding in RazorpayX Payroll for this list of talents',
                    description: error.map((s, i) => `
                    ${i + 1}. ${s}`).join('.'),
                    duration: 0,
                    key: "notification",
                    style: { whiteSpace: 'break-spaces', maxHeight: "90vh", overflow: "auto" }
                  });
                  message.error({ content: "Something went wrong", key: "enroll" });
                }else{
                  message.success({ content: "Users enrolled Successfully!", key: "enroll" });
                }
              }
            }); 
            if (talents.length - 1 == i) {
              if(talentsStatus.every((obj) => obj.status !== 'PENDING')){
                await updateDoc(doc(db, `enrollment_requests`, id), {talents : talentsStatus, status: 'COMPLETED'})
              }else if(talentsStatus.some((obj) => obj.status == 'PENDING')){
                await updateDoc(doc(db, `enrollment_requests`, id), {talents : talentsStatus, status: 'PARTIALLY_ACCEPTED'})
              }
            }
        }).catch((err) => {
          message.error({content: 'Something went wrong!', key: 'enroll'})
          console.log(err)
          if (talents.length - 1 == i) {
            setSelectedTalents([])
            setModalVisible(false)
            if(error.length > 0 ){
              notification.error({
                message: 'Error occured on data adding in RazorpayX Payroll for this list of talents',
                description: error.map((s, i) => `
                ${i + 1}. ${s}`).join('.'),
                duration: 0,
                key: "notification",
                style: { whiteSpace: 'break-spaces', maxHeight: "90vh", overflow: "auto" }
              });
              message.error({ content: "Something went wrong", key: "enroll" });
            }else{
              message.success({ content: "Users enrolled Successfully!", key: "enroll" });
            }
          }
        })
      }).catch((err) => {
        message.error({content: 'Something went wrong!', key: 'enroll'})
        console.log(err)
        if (talents.length - 1 == i) {
          setSelectedTalents([])
          setModalVisible(false)
          if(error.length > 0 ){
            notification.error({
              message: 'Error occured on data adding in RazorpayX Payroll for this list of talents',
              description: error.map((s, i) => `
              ${i + 1}. ${s}`).join('.'),
              duration: 0,
              key: "notification",
              style: { whiteSpace: 'break-spaces', maxHeight: "90vh", overflow: "auto" }
            });
            message.error({ content: "Something went wrong", key: "enroll" });
          }else{
            message.success({ content: "Users enrolled Successfully!", key: "enroll" });
          }
        }
      })
      
    })
  }

  const rejectRequest = async (e, talents) => {
    e.preventDefault();
    let talentsStatus = [...detailsData.talents]
    let count = 0;
    console.log(talents)
    message.loading({content: 'Loading...', duration: 0, key: 'reject'})
    talents.map(async (tal) => {
      let tempData = talentsStatus.map((item) => {
        let d = item;
        if(item.id == tal.id){
          d.status = 'REJECTED'
        }
        return d
      })
      talentsStatus = tempData;
      if(talents.length - 1 == count++){
        let updateObj = null
        if(talentsStatus.every((obj) => obj.status !== 'PENDING')){
          updateObj = {
            talents : talentsStatus,
            status: 'COMPLETED'
          }
        }else if(talentsStatus.some((obj) => obj.status == 'PENDING')){
          updateObj = {
            talents : talentsStatus, 
            status: 'PARTIALLY_ACCEPTED'
          }
        }
        if(updateObj){
          await updateDoc(doc(db, `enrollment_requests`, id), updateObj).then((res) => {
            message.success({ content: "Users Rejected Successfully!", key: "reject" });
          }).catch((err) => {
            console.log(err)
          })

        }
      }
    })
  }

  
  return (
    <div className="container">
      <Modal visible={modalvisible} footer={null} onCancel={() => setModalVisible(false)}>
        <div>
          <div className="modalHeader">
            <h3>Are you sure you want to approve this <span style={{fontWeight: '900'}}>{selectedTalents.length > 0 ? selectedTalents.length : filteredTalents.length}</span> talents ?</h3>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem'}}>
            <Button onClick={() => setModalVisible(false)}>Cancel</Button>
            <Button style={{marginLeft: '20px'}} type='primary' onClick={(e) => acceptRequest(e, selectedTalents.length > 0 ? selectedTalents : filteredTalents)}>Enroll</Button>
          </div>       
        </div>
      </Modal>
      <Modal visible={rejectModalvisible} footer={null} onCancel={() => setRejectModalVisible(false)}>
        <div>
          <div className="modalHeader">
            <h3>Are you sure you want to reject this <span style={{fontWeight: '900'}}>{selectedTalents.length > 0 ? selectedTalents.length : filteredTalents.length}</span> talents ?</h3>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem'}}>
            <Button onClick={() => setRejectModalVisible(false)}>Cancel</Button>
            <Button style={{marginLeft: '20px'}} type='primary' onClick={(e) => rejectRequest(e, selectedTalents.length > 0 ? selectedTalents : filteredTalents)}>Reject</Button>
          </div>       
        </div>
      </Modal>
      <div className="detailsPageHeader">
        <div className="backBtnContainer" onClick={() => history.goBack()} style={{cursor: 'pointer'}}>
          <img className="image" src={leftArrow} width={"13px"}></img>
          <h2 className="btntop">Enroll Request Details</h2>
        </div>
        {!auth.isContract ? <div className="buttons">
          {(selectedTalents.length > 0 || filteredTalents.length > 0) && <> <Button type="secondary" size="middle" className="reject" onClick={() => setRejectModalVisible(true)}>{(selectedTalents.length == filteredTalents.length) ? 'Reject All' : ((selectedTalents.length  > 0)  ? 'Reject' : 'Reject All')}</Button>
          <Button type="primary" size="middle" className="accept" onClick={() => setModalVisible(true)}>{(selectedTalents.length == filteredTalents.length) ? 'Accept All' : ((selectedTalents.length  > 0)  ? 'Accept' : 'Accept All')}</Button> </>}
        </div> : <div></div>}
      </div>
      <>
      {!detailsData ? 
          <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Spin />
          </div>
      : <div className="enrolldetailscroll">
        <div className="enrolldetails">
          {(auth.isSpectra || auth.isContract) &&  <div className="reqname">
            <label> Primaryemployer</label>
            <h3>{detailsData.primary_employer_name}</h3>
          </div>}

          {(auth.isSpectra || auth.isPrimary) && <div className="reqname">
            <label>Contractor</label>
            <h3>{detailsData.contractor_name}</h3>
          </div>}
          
          <div className="reqname">
            <label> jobrole</label>
            <h3>{detailsData.designation}</h3>
          </div>
          <div className="reqname">
            <label> Gross salary</label>
            <h3>₹{detailsData.salary}/per {detailsData.salary_per_period}</h3>
          </div>
          <div className="reqname">
            <div className="labelWithNotes">
              <label>Earnings</label>
              <Popover placement="bottom" overlayClassName="popupoverenroll" content={<PopOver data={detailsData} earnings />}>
                <img className="icon" src={notesIcon} />
              </Popover>
            </div>
            <h3>₹{(detailsData.earnings?.reduce((acc, obj) => acc + obj.amount, 0)) + detailsData.major_salary_split?.basic_salary + detailsData.major_salary_split?.da + detailsData.major_salary_split?.hra + detailsData.major_salary_split?.special_allowance + detailsData.major_salary_split?.l_t_allowances + detailsData.major_salary_split?.employer_pf + detailsData.major_salary_split?.employer_esi}</h3>
          </div>
          <div className="reqname">
            <div className="labelWithNotes">
              <label>Deduction</label>
              <Popover placement="bottom" overlayClassName="popupoverenroll" content={<PopOver data={detailsData.deductions}/>}>
                <img className="icon" src={notesIcon} />
              </Popover>
            </div>
            <h3>₹{detailsData.deductions?.reduce((acc, obj) => acc + obj.amount, 0)}</h3>
          </div>
          <div className="reqname">
            <label>Date of joining</label>
            <h3>{detailsData.date_of_joining?.toDate().toLocaleDateString('en-IN')}</h3>
          </div>
          <div className="reqname">
            <label>Request sent on</label>
            <h3>{detailsData.created_at?.toDate().toLocaleDateString('en-IN')}</h3>
          </div>
          <div className="reqname">
            <label>Shift Timings</label>
            <ShiftTime startTime={detailsData.shift_start_time} endTime={detailsData.shift_end_time}/>
          </div>
          <div className="reqname">
            <label>No.of talents</label>
            <h3>{detailsData.talents.length}</h3>
          </div>
          <div className="reqname">
            <label>Supervisor name</label>
            <h3>{detailsData.supervisor_name}</h3>
          </div>
        </div>
        <div className="enrollnotes">
          <span className="notesheader">Notes</span>
          <>
          <span>
            <p>
              {detailsData.notes}
            </p>
          </span>
          </>
        </div>
        <div className="enrolldetailtable">
        <div className="heading">
        <h2> Talents</h2>
        </div>
        <div className="anttable">
        <Table
          loading={tableLoading}
          columns={columns}
          dataSource={tableData}
          rowSelection={auth.isContract ? false : {
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedTalents(selectedRows);
            },
            getCheckboxProps: (record) => ({
              disabled: (record.isAlreadyEmployed === true || record.status === 'REJECTED'),
            }),
          }}
          pagination={false}
        />
        </div>
        </div>
        </div>}
      </>
    </div>
  );
}

export default EnrollRequestDetail;

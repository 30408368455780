import React from "react";
import {
  Input,
  Select,
  Button,
  Modal,
  Tag,
  DatePicker,
  InputNumber,
  Form,
  message,
  Space,
} from "antd";
import {
  addDoc,
  firestore,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  getFirestore,
  Timestamp,
  deleteDoc,
  query,
  setDoc,
  where,
  serverTimestamp,
  get,
  limit,
  orderBy,
} from "firebase/firestore";
import { useState, useContext } from "react";
import "../../styles/JobHeadBar.scss";
import { useAuthContext } from "../../context/auth";
import { useEffect } from "react";
import app from "../../firebase";
import moment from "moment";
import { dataContext } from "../../context/data";
import { duration } from "moment";
import GoBack from "../../assets/icons/goBackModal.svg";
import editPostIcon from "../../assets/icons/editPostIcon.svg";
import { async } from "@firebase/util";
import { MinusCircleOutlined } from "@ant-design/icons";

const db = getFirestore(app);
function PostForm({ type, state, district, role, id }) {
  var citiesValue = district[state[0]];
  const [employer, setEmployer] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [form] = Form.useForm();
  const [secondForm] = Form.useForm();
  const [roleForm] = Form.useForm();
  const [lastJobPostId, setLastJobPostId] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editKey, setEditKey] = useState();
  const [editQueDoc, setEditQueDoc] = useState();
  const [page, setPage] = useState(1);
  const [counter, setCounter] = useState([1]);
  const [secQue, setSecQue] = useState([1]);
  const [earningCounter, setEarningCounter] = useState([1]);
  const [deductCounter, setDeductCounter] = useState([1]);
  const [primaryEmployer, setPrimaryEmployer] = useState("");
  const [postModalInput, setPostModalInput] = useState({
    designation: "",
    qualification: [],
    description: "",
    expFrom: "",
    expTo: "",
    startDate: "",
    salFrom: "",
    salTo: "",
    state: "",
    district: "",
  });
  const [roleModalInput, setRoleModalInput] = useState({
    designation: "",
    department: "",
    description: "",
    gross_salary: "",
    grossSalPeriod: "month",
    basic_salary: "",
    da: "",
    hra: "",
    employer_pf: "",
    employer_esi: "",
    special_allowance: "",
    l_t_allowances: "",
    deductions: [
      {
        title: "",
        amount: "",
      },
    ],
    earnings: [
      {
        title: "",
        amount: "",
      },
    ],
  });
  const [expPerPeriod, setExpPerPeriod] = useState("months");
  const [salPerPeriod, setSalPerPeriod] = useState("day");
  const [cities, setCities] = useState(citiesValue);
  const [formalities, setFormalities] = useState([""]);
  const [postQueInput, setPostQueInput] = useState([
    {
      question: "",
      question_type: "",
    },
  ]);
  const { Option } = Select;
  const auth = useAuthContext();
  const { TextArea } = Input;
  const { jobData, setJobData } = useContext(dataContext);
  useEffect(() => {
    getCatagory("primary_employers");
  }, []);
  useEffect(async () => {
    let questions = [];
    if (role == "edit") {
      let d;
      let key = id;
      let questionId = [];
      setEditKey(key);
      const editDoc = await getDoc(doc(db, "job_posts", key));
      if (editDoc.exists()) {
        d = editDoc.data();
      }
      const quesRef = await getDocs(
        query(collection(db, "job_posts", key, "questions"))
      );
      quesRef.forEach((itm) => {
        questions.push({
          question: itm.data().question,
          question_type: itm.data().question_type,
          id: itm.id,
        });
      });
      setEditQueDoc(questionId);
      setPostQueInput(questions);
      //   setCities()
      let tempDate = new Timestamp(
        d.start_date.seconds | d.start_date._seconds,
        d.start_date.nanoseconds | d.start_date._nanoseconds
      ).toDate();
      let startDate = moment(tempDate);
      setPrimaryEmployer(d.primary_employer);
      setFormalities(d.joining_formalities);
      form.setFieldsValue({
        employer: d.primary_employer,
        salPer: d.salary_per_period,
        expPer: d.experience_per_period,
      });
      setPostModalInput((prevState) => ({
        ...prevState,
        designation: d.designation,
        qualification: d.qualification,
        description: d.description,
        expFrom: `${d.experience_from}`,
        expTo: `${d.experience_to}`,
        startDate: startDate,
        salFrom: `${d.salary_from}`,
        salTo: `${d.salary_to}`,
        state: d.state,
        district: d.district,
      }));
    }
  }, [jobData.editTrigger]);
  const getCatagory = async (type, sVal = "") => {
    let d = [];
    try {
      let q = query(collection(db, type), where("name", ">=", sVal));
      const res = await getDocs(q);
      res.forEach((itm) => d.push({ ...itm.data(), id: itm.id }));
      setEmployer(d);
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };
  const handleQuestion = () => {
    let questionObj = {
      question: "",
      question_type: "",
    };
    let arr = [...postQueInput];
    arr.push(questionObj);
    setPostQueInput(arr);
  };

  const handleRemoveQuestion = (id) => {
    const removed = postQueInput.filter((_, index) => index !== id);
    setPostQueInput(removed);
  };

  const handleCounters = (val) => {
    let roleModal = {
      title: "",
      amount: "",
    };
    if (val == "counter") {
      let temp = [...counter];
      temp.push(1);
      setCounter(temp);
    } else if (val == "earning") {
      let temp = [...roleModalInput.earnings];
      temp.push(roleModal);
      setRoleModalInput((pre) => ({
        ...pre,
        earnings: temp,
      }));
    } else if (val == "deduct") {
      let temp = [...roleModalInput.deductions];
      temp.push(roleModal);
      setRoleModalInput((pre) => ({
        ...pre,
        deductions: temp,
      }));
    } else if (val == "secQue") {
      let temp = [...secQue];
      temp.push(1);
      setSecQue(temp);
    }
  };
  const handleStateClear = () => {
    setFormalities([""]);
    setPostQueInput([
      {
        question: "",
        question_type: "",
      },
    ]);
    setPostModalInput((prevState) => ({
      ...prevState,
      designation: "",
      description: "",
      qualification: "",
      expFrom: "",
      expTo: "",
      startDate: "",
      salFrom: "",
      salTo: "",
      state: "",
      district: "",
    }));
    setPrimaryEmployer("");
  };

  const handleModal = () => {
    setIsModalVisible(true);
    setPage(1);

    
  };

  const [isDisError, setIsDisError] = useState(false);
  const handlePost = async () => {
    setIsDisError(true);
    var result;
    var lastValue;
    let zero = "0000";
    const lastDocID = await getDoc(doc(db, "app_data_static", "job_data"));
    if (lastDocID.exists()) {
      lastValue = lastDocID.data().last_used_job_code;
      let slicedString = lastValue.slice(2);
      let SlicedNumber = parseInt(slicedString);
      let addNo = SlicedNumber + 1;
      let addNoString = addNo.toString();
      if (addNoString.length < 4) {
        let addedNumber = zero + addNo;
        let num = addedNumber.slice(addNoString.length);
        result = "PR" + num;
        setLastJobPostId(result);
      } else {
        result = "PR" + addNo;
        setLastJobPostId(result);
      }
    } else {
      result = "PR0001";
    }
    const postCheck = async (result) => {
      let toCheck;
      if (
        postQueInput.some((e) => e.question === "" || e.question_type === "")
      ) {
        toCheck = false;
      } else {
        toCheck = true;
      }
      if (toCheck) {
        console.log("result", result);
        let employer, userId;
        if (auth.isPrimary) {
          employer = localStorage.getItem("primary_employer_id");
          userId = localStorage.getItem("primary_employer_id");
        } else if (auth.isSpectra) {
          employer = primaryEmployer;
          userId = "Talntworx Technologies ";
        } else if (auth.isContract) {
          employer = primaryEmployer;
          userId = localStorage.getItem("contractor_id");
        }
        let experienceFrom = parseInt(postModalInput.expFrom);
        let experienceTo = parseInt(postModalInput.expTo);
        let SalaryFrom = parseInt(postModalInput.salFrom);
        let salaryTo = parseInt(postModalInput.salTo);
        let date = moment(postModalInput.startDate).toDate();
        message.loading({ content: "loading..", duration: 0, key: "saving" });
        if (role == "add") {
          try {
            const docRef = await addDoc(collection(db, "job_posts"), {});
            console.log("id", docRef.id);
            await setDoc(doc(db, "job_posts", docRef.id), {
              active_status: true,
              created_at: serverTimestamp(),
              description: postModalInput.description,
              designation: postModalInput.designation,
              district: postModalInput.district,
              experience_from: experienceFrom,
              experience_to: experienceTo,
              experience_per_period: expPerPeriod,
              primary_employer: employer,
              joining_formalities: formalities,
              job_id: result,
              qualification: postModalInput.qualification,
              salary_from: SalaryFrom,
              salary_per_period: salPerPeriod,
              salary_to: salaryTo,
              start_date: date,
              state: postModalInput.state,
              user_id: userId,
              updated_at: "",
            });
            {
              postQueInput.map(async (obj, id) => {
                console.log("it is working for map value");
                await addDoc(
                  collection(db, "job_posts", docRef.id, "questions"),
                  {
                    question: obj.question,
                    question_type: obj.question_type,
                  }
                );
              });
            }
            await updateDoc(doc(db, "app_data_static", "job_data"), {
              last_used_job_code: result,
            });
            message.success({
              content: "Job Post created successfully",
              key: "saving",
            });
            setJobData((pre) => {
              return { ...pre, DblastFetch: undefined, page: 0 };
            });
            setJobData((pre) => {
              return { ...pre, postSuccess: !pre.postSuccess };
            });
            setIsModalVisible(false);
            handleCancel();
          } catch (err) {
            console.log(err);
            message.error({ content: "something went wrong", key: "saving" });
          }
        } else if (role == "edit") {
          try {
            await updateDoc(doc(db, "job_posts", editKey), {
              active_status: true,
              description: postModalInput.description,
              designation: postModalInput.designation,
              district: postModalInput.district,
              experience_from: experienceFrom,
              experience_to: experienceTo,
              experience_per_period: expPerPeriod,
              primary_employer: employer,
              joining_formalities: formalities,
              qualification: postModalInput.qualification,
              salary_from: SalaryFrom,
              salary_per_period: salPerPeriod,
              salary_to: salaryTo,
              start_date: date,
              state: postModalInput.state,
              user_id: userId,
              updated_at: serverTimestamp(),
            });
            {
              postQueInput.map(async (itm) => {
                if (itm.id) {
                  console.log("id value", itm.id, itm.question);
                  await updateDoc(
                    doc(db, "job_posts", editKey, "questions", itm.id),
                    {
                      question: itm.question,
                      question_type: itm.question_type,
                    }
                  );
                } else {
                  console.log("id value is not there", itm.id, itm.question);
                  await addDoc(
                    collection(db, "job_posts", editKey, "questions"),
                    {
                      question: itm.question,
                      question_type: itm.question_type,
                    }
                  );
                }
              });
            }
            message.success({
              content: "Job Post updated successfully",
              key: "saving",
            });
            setJobData((pre) => {
              return { ...pre, postEditSuccess: !pre.postEditSuccess };
            });
            setIsModalVisible(false);
            handleCancel();
          } catch (err) {
            console.log(err);
            message.error({ content: "something went wrong", key: "saving" });
          }
        }
      }
    };
    postCheck(result);
  };
  const handleRoles = async (e) => {
    e.preventDefault();
    const checkValue = Object.values(roleModalInput).every((value) => {
      if (!(value == "" || value == [])) {
        return true;
      } else {
        return false;
      }
    });
    let toCheck;
    if (
      roleModalInput.earnings.some((e) => e.title === "" || e.amount === "") &&
      roleModalInput.deductions.some((e) => e.title === "" || e.amount === "")
    ) {
      toCheck = false;
    } else {
      toCheck = true;
    }

    let gross_salary = parseFloat(roleModalInput.gross_salary);
    let mandatoryEarnings =
      parseFloat(roleModalInput.basic_salary) +
      parseFloat(roleModalInput.da) +
      parseFloat(roleModalInput.hra) +
      parseFloat(roleModalInput.special_allowance) +
      parseFloat(roleModalInput.l_t_allowances) +
      parseFloat(roleModalInput.employer_pf) +
      parseFloat(roleModalInput.employer_esi);

    let additionalEarnings =
      roleModalInput.earnings.reduce(
        (sum, item) => sum + parseFloat(item.amount),
        0
      ) || 0;
    let salaryDeductions = roleModalInput.deductions.reduce(
      (sum, item) => sum + parseFloat(item.amount),
      0
    );

    if (toCheck && checkValue) {
      console.log(
        gross_salary,
        mandatoryEarnings + additionalEarnings + salaryDeductions,
        mandatoryEarnings,
        additionalEarnings,
        salaryDeductions
      );
      if (
        gross_salary ==
        mandatoryEarnings + additionalEarnings + salaryDeductions
      ) {
        message.loading({ content: "loading...", duration: 0, key: "roleAdd" });
        let userId;
        if (auth.isSpectra) {
          userId = "Talntworx Technologies ";
        } else if (auth.isPrimary) {
          userId = localStorage.getItem("primary_employer_id");
        } else if (auth.isContract) {
          userId = localStorage.getItem("contractor_id");
        }
        try {
          const roleRef = await addDoc(collection(db, "job_roles"), {});
          console.log("docRef.id", roleRef.id);
          await setDoc(doc(db, "job_roles", roleRef.id), {
            designation: roleModalInput.designation,
            description: roleModalInput.description,
            department: roleModalInput.department,
            gross_salary: gross_salary,
            basic_salary: parseFloat(roleModalInput.basic_salary),
            da: parseFloat(roleModalInput.da),
            hra: parseFloat(roleModalInput.hra),
            employer_pf: parseFloat(roleModalInput.employer_pf),
            employer_esi: parseFloat(roleModalInput.employer_esi),
            special_allowance: parseFloat(roleModalInput.special_allowance),
            l_t_allowances: parseFloat(roleModalInput.l_t_allowances),
            created_at: serverTimestamp(),
            salary_per_period: roleModalInput.grossSalPeriod,
            user_id: userId,
          });
          {
            roleModalInput.earnings.map(async (obj, id) => {
              let amount = parseInt(obj.amount);
              await addDoc(
                collection(db, "job_roles", roleRef.id, "earnings"),
                {
                  title: obj.title,
                  amount: amount,
                }
              );
            });
          }
          {
            roleModalInput.deductions.map(async (obj, id) => {
              let amount = parseInt(obj.amount);
              await addDoc(
                collection(db, "job_roles", roleRef.id, "deductions"),
                {
                  title: obj.title,
                  amount: amount,
                }
              );
            });
          }
          message.success({
            content: "Job role added successfully",
            key: "roleAdd",
          });
          setJobData((pre) => {
            return { ...pre, rolesLastFetch: undefined };
          });
          setJobData((pre) => {
            return { ...pre, roleAddSuccess: !pre.roleAddSuccess };
          });
          setIsModalVisible(false);
          handleCancel();
        } catch (err) {
          message.error({ content: err, key: "roleAdd" });
        }
      } else {
        message.error({
          content: "Please Check Salary Split up with Gross Salary",
        });
      }
    } else {
      message.error({ content: "Please fill mandatory fields" });
    }
  };
  const handleCancel = () => {
    if (role == "edit") {
      setIsModalVisible(false);
    } else {
      form.resetFields();
      setPostQueInput([
        {
          question: "",
          question_type: "",
        },
      ]);
      setIsModalVisible(false);
      handleStateClear();
      setCounter([1]);
      setDeductCounter([1]);
      setEarningCounter([1]);
      setSecQue([1]);
    }
    roleForm.resetFields();
    setRoleModalInput((pre) => ({
      ...pre,
      designation: "",
      department: "",
      description: "",
      gross_salary: "",
      deductions: [
        {
          title: "",
          amount: "",
        },
      ],
      earnings: [
        {
          title: "",
          amount: "",
        },
      ],
    }));
  };
  const handleQuestionInput = (e, id) => {
    let prevArray = [...postQueInput];
    let prevArray_elem = prevArray[id];
    console.log("postModal Input", postQueInput);
    prevArray_elem.question = e.target.value;
    prevArray[id] = prevArray_elem;
    setPostQueInput(prevArray);
  };

  // const handleQuestionTypeInput = (value, id) => {
  //   let prevArray = [...postQueInput];
  //   let prevArray_elem = prevArray[id];
  //   prevArray_elem.question_type = value;
  //   prevArray[id] = prevArray_elem;
  //   setPostQueInput(prevArray);
  // };

  const handleQuestionTypeInput = (value, id) => {
    setPostQueInput((prevArray) => {
      const updatedArray = [...prevArray];
      const updatedElement = {
        ...updatedArray[id],
        question_type: value,
      };
      updatedArray[id] = updatedElement;
      return updatedArray;
    });
  };

  const handleFormalityInput = (e, id) => {
    let idValue = --id;
    let prevArray = [...formalities];
    let prevArray_elem = prevArray[idValue];
    prevArray_elem = e.target.value;
    prevArray[idValue] = prevArray_elem;
    setFormalities(prevArray);
  };
  const handleFormalities = (e) => {
    let tempFormalities = "";
    let arrForm = [...formalities];
    arrForm.push(tempFormalities);
    setFormalities(arrForm);
  };
  const handleRemoveFormality = (id) => {
    formalities.splice(id, 1);
    let arrForm = [...formalities];
    if (arrForm.length > 0) {
      setFormalities(arrForm);
    }
  };

  const [isDisContinue, setIsDisContinue] = useState(false);

  const handleContinue = async () => {
    setIsDisContinue(true);
    console.log("this is postModalInput", postModalInput);
    if (
      Number(postModalInput.expFrom) < Number(postModalInput.expTo) &&
      postModalInput.salFrom < postModalInput.salTo
    ) {
      const check = Object.values(postModalInput).every((value) => {
        if (!(value == "" || value == [])) {
          console.log("true is running", value);
          return true;
        } else {
          console.log("false is happeninng", value);
          return false;
        }
      });
      let verifyPrime;
      if (auth.isPrimary) {
        verifyPrime = true;
      } else if (primaryEmployer == "") {
        verifyPrime = false;
      } else {
        verifyPrime = true;
      }
      function valueCheck(check, verifyPrime) {
        let verify;
        if (formalities.includes("")) {
          verify = false;
        } else {
          verify = true;
        }
        console.log("verfiy", verify);
        console.log(check, verify, verifyPrime);
        if (verify && check && verifyPrime) {
          console.log(verify);
          setPage(2);
          console.log("here from ");
        }
      }
      valueCheck(check, verifyPrime);
    } else {
      message.error("Invalid Experience or Salary data");
    }
  };
  const handleRoleModalInput = (e) => {
    setRoleModalInput((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
  };
  // id value ---
  const handleEarningSplitUp = (e, id) => {
    let idValue = id;
    let prevArray = [...roleModalInput.earnings];
    console.log("prevArray", prevArray);
    let prevArray_elem = prevArray[idValue];
    console.log("prevArray_elem", prevArray_elem);
    prevArray_elem[e.target.name] = e.target.value;
    prevArray[idValue] = prevArray_elem;
    setRoleModalInput((pre) => ({
      ...pre,
      earnings: prevArray,
    }));
  };
  // id value  --
  const handleDeductionSplitUp = (e, id) => {
    let value = e.target.value;

    if (value < 0) {
      setErrorMessage("No negative values allowed");
      return;
    }
    setErrorMessage("");

    let idValue = id;
    let prevArray = [...roleModalInput.deductions];
    console.log(prevArray);
    let prevArray_elem = prevArray[idValue];
    console.log(prevArray_elem, id, e.target.name, e.target.value);
    prevArray_elem[e.target.name] = e.target.value;
    prevArray[idValue] = prevArray_elem;
    setRoleModalInput((pre) => ({
      ...pre,
      deductions: prevArray,
    }));
  };

  const typeInput = (e) => {
    var value = e.target.value;
    var name_value = e.target.name;
    setPostModalInput((prevState) => ({
      ...prevState,
      [name_value]: value,
    }));
  };
  function handleSalaryValidate(e, type) {
    let value = e.target.value;
    if (
      value.match(/^[0-9]{8}$/) ||
      e.code == "Minus" ||
      e.code == "NumpadSubtract"
    ) {
      e.preventDefault();
    }
    if (
      type == "1" &&
      e.target.value.length == 0 &&
      (e.code == "Numpad0" || e.key == 0)
    ) {
      console.log("if works");
      e.preventDefault();
    }
  }
  const handleExpValidate = (e, type) => {
    let value = e.target.value;
    if (
      value.match(/^[0-9]{2}$/) ||
      e.code == "Minus" ||
      e.code == "NumpadSubtract"
    ) {
      e.preventDefault();
    }
    if (type == "to") {
      if (e.target.value.length == 0 && (e.code == "Numpad0" || e.key == 0)) {
        e.preventDefault();
      }
    }
  };
  const handleProvinceChange = (value) => {
    setCities(district[value]);
    setPostModalInput((prevState) => ({ ...prevState, state: value }));
  };

  function isDisapled(id) {
    if (
      id === postQueInput.length - 1 &&
      postQueInput[id].question !== "" &&
      postQueInput[id].question_type !== ""
    ) {
      return false;
    } else {
      return true;
    }
  }
  return (
    <div className="jobHeadBarRight">
      <Button type="primary" size="large" onClick={handleModal}>
        {role == "add" ? (
          type == "role" ? (
            "New Job Role"
          ) : (
            "New Job Post"
          )
        ) : type == "role" ? (
          "Edit Job Role"
        ) : (
          <div className="editBtn">
            <img src={editPostIcon} />
            Edit Post
          </div>
        )}
      </Button>
      <Modal
        className="post-custom"
        visible={isModalVisible}
        title={
          role == "add" ? (
            type == "role" ? (
              "New Job Role"
            ) : page == true ? (
              "New Job Post (1/2)"
            ) : (
              <div className="pageSecHeader">
                <div>
                  <img
                    src={GoBack}
                    width="85%"
                    style={{ cursor: "pointer" }}
                    onClick={() => setPage(1)}
                  ></img>
                </div>
                <div>New Job Post (2/2)</div>
              </div>
            )
          ) : type == "role" ? (
            "Edit Job Role"
          ) : page == true ? (
            "Edit Job Post (1/2)"
          ) : (
            <div className="pageSecHeader">
              <div>
                <img
                  src={GoBack}
                  width="85%"
                  style={{ cursor: "pointer" }}
                  onClick={() => setPage(1)}
                ></img>
              </div>
              <div>Edit Job Post (2/2)</div>
            </div>
          )
        }
        okButtonProps
        width={type == "role" ? 700 : 900}
        height="auto"
        centered
        className={type == "post" ? "postModal" : "roleModal"}
        onCancel={handleCancel}
        footer={null}
        style={{
          scrollbarWidth: "none",
        }}
      >
        {type == "role" ? (
          <Form id="form" form={roleForm}>
            <div className="roleModalForm">
              <div className="modalTop">
                <div className="modalTopLeft" style={{ width: "48%" }}>
                  <div>
                    <label>Designation</label>
                    <Form.Item
                      name="designation"
                      rules={[{ required: true, message: "Enter desgination" }]}
                    >
                      <Input
                        name="designation"
                        className="input"
                        placeholder="Enter job title"
                        onChange={(e) => handleRoleModalInput(e)}
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <label>Department</label>
                    <Form.Item
                      name="department"
                      rules={[{ required: true, message: "Enter department" }]}
                    >
                      <Input
                        name="department"
                        className="input"
                        placeholder="Enter Department"
                        onChange={(e) => handleRoleModalInput(e)}
                      />
                    </Form.Item>
                  </div>
                  <div className="grossSalary">
                    <label>Gross salary</label>
                    <div className="salaryContainer">
                      <div className="salary">
                        <Form.Item
                          name="gross_salary"
                          rules={[{ required: true, message: "Enter salary" }]}
                        >
                          <Input
                            name="gross_salary"
                            onKeyPress={(e) => handleSalaryValidate(e, "1")}
                            className="input salaryInput"
                            onChange={(e) => handleRoleModalInput(e)}
                            placeholder="00"
                            type="number"
                            prefix="₹"
                          />
                        </Form.Item>
                      </div>
                      <div className="salPerPeriod">
                        <Form.Item>
                          <Select
                            defaultValue="month"
                            required
                            showArrow
                            onChange={(value) => {
                              setRoleModalInput((pre) => {
                                return { ...pre, grossSalPeriod: value };
                              });
                            }}
                            className="input"
                            style={{ width: "100%" }}
                          >
                            <Option value="month">per month</Option>
                            <Option value="year">per year</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modalTopRight" style={{ width: "48%" }}>
                  <label>Description</label>
                  <div className="descriptionContainer">
                    <Form.Item
                      name="description"
                      rules={[{ required: true, message: "Enter description" }]}
                    >
                      <TextArea
                        name="description"
                        className="input descriptionInput"
                        onChange={(e) => handleRoleModalInput(e)}
                        placeholder="Enter details"
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="modalMiddle">
                <h4>Salary splitup</h4>
                <div className="splitUp">
                  <div className="earning" style={{ width: "48%" }}>
                    <div>
                      <div className="container">
                        <div>
                          <label>Earning title - 1</label>
                        </div>
                        <div className="inputContainer">
                          <div className="firstInp">
                            <Form.Item
                              name={"basicSalary"}
                              initialValue={"Basic"}
                              rules={[
                                { required: true, message: "Enter title" },
                              ]}
                            >
                              <Input
                                className="input"
                                disabled
                                placeholder="Enter title"
                              />
                            </Form.Item>
                          </div>
                          <div className="secondInp">
                            <Form.Item
                              name={"basicAmount"}
                              rules={[
                                { required: true, message: "Enter amount" },
                              ]}
                            >
                              <Input
                                name="basic_salary"
                                placeholder="00"
                                className="input"
                                type="number"
                                prefix="₹"
                                onKeyPress={(e) => handleSalaryValidate(e)}
                                onChange={(e) => handleRoleModalInput(e)}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div>
                          <label>Earning title - 2</label>
                        </div>
                        <div className="inputContainer">
                          <div className="firstInp">
                            <Form.Item
                              name={`da`}
                              initialValue={"D A"}
                              rules={[
                                { required: true, message: "Enter title" },
                              ]}
                            >
                              <Input
                                className="input"
                                placeholder="Enter title"
                                disabled
                              />
                            </Form.Item>
                          </div>
                          <div className="secondInp">
                            <Form.Item
                              name={`daAmount`}
                              rules={[
                                { required: true, message: "Enter amount" },
                              ]}
                            >
                              <Input
                                name="da"
                                placeholder="00"
                                className="input"
                                type="number"
                                prefix="₹"
                                onKeyPress={(e) => handleSalaryValidate(e)}
                                onChange={(e) => handleRoleModalInput(e)}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div>
                          <label>Earning title - 3</label>
                        </div>
                        <div className="inputContainer">
                          <div className="firstInp">
                            <Form.Item
                              name={`hra`}
                              initialValue={"HRA"}
                              rules={[
                                { required: true, message: "Enter title" },
                              ]}
                            >
                              <Input
                                className="input"
                                placeholder="Enter title"
                                disabled
                              />
                            </Form.Item>
                          </div>
                          <div className="secondInp">
                            <Form.Item
                              name={`hraAmount`}
                              rules={[
                                { required: true, message: "Enter amount" },
                              ]}
                            >
                              <Input
                                name="hra"
                                placeholder="00"
                                className="input"
                                onKeyPress={(e) => handleSalaryValidate(e)}
                                type="number"
                                prefix="₹"
                                onChange={(e) => handleRoleModalInput(e)}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div>
                          <label>Earning title - 4</label>
                        </div>
                        <div className="inputContainer">
                          <div className="firstInp">
                            <Form.Item
                              name={`specialAllowance`}
                              initialValue={"Special Allowance"}
                              rules={[
                                { required: true, message: "Enter title" },
                              ]}
                            >
                              <Input
                                className="input"
                                placeholder="Enter title"
                                disabled
                              />
                            </Form.Item>
                          </div>
                          <div className="secondInp">
                            <Form.Item
                              name={`specialAllowanceAMount`}
                              rules={[
                                { required: true, message: "Enter amount" },
                              ]}
                            >
                              <Input
                                name="special_allowance"
                                placeholder="00"
                                className="input"
                                onKeyPress={(e) => handleSalaryValidate(e)}
                                type="number"
                                prefix="₹"
                                onChange={(e) => handleRoleModalInput(e)}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div>
                          <label>Earning title - 5</label>
                        </div>
                        <div className="inputContainer">
                          <div className="firstInp">
                            <Form.Item
                              name={`lTAllowance`}
                              initialValue={"L T Allowance"}
                              rules={[
                                { required: true, message: "Enter title" },
                              ]}
                            >
                              <Input
                                className="input"
                                placeholder="Enter title"
                                disabled
                              />
                            </Form.Item>
                          </div>
                          <div className="secondInp">
                            <Form.Item
                              name={`lTAllowanceAmount`}
                              rules={[
                                { required: true, message: "Enter amount" },
                              ]}
                            >
                              <Input
                                name="l_t_allowances"
                                placeholder="00"
                                className="input"
                                onKeyPress={(e) => handleSalaryValidate(e)}
                                type="number"
                                prefix="₹"
                                onChange={(e) => handleRoleModalInput(e)}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div>
                          <label>Earning title - 6</label>
                        </div>
                        <div className="inputContainer">
                          <div className="firstInp">
                            <Form.Item
                              name={`employerPf`}
                              initialValue={"Employer PF"}
                              rules={[
                                { required: true, message: "Enter title" },
                              ]}
                            >
                              <Input
                                className="input"
                                placeholder="Enter title"
                                disabled
                              />
                            </Form.Item>
                          </div>
                          <div className="secondInp">
                            <Form.Item
                              name={`employerPfAmount`}
                              rules={[
                                { required: true, message: "Enter amount" },
                              ]}
                            >
                              <Input
                                name="employer_pf"
                                placeholder="00"
                                className="input"
                                onKeyPress={(e) => handleSalaryValidate(e)}
                                type="number"
                                prefix="₹"
                                onChange={(e) => handleRoleModalInput(e)}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div>
                          <label>Earning title - 7</label>
                        </div>
                        <div className="inputContainer">
                          <div className="firstInp">
                            <Form.Item
                              name={`employerEsi`}
                              initialValue={"Employer ESI"}
                              rules={[
                                { required: true, message: "Enter title" },
                              ]}
                            >
                              <Input
                                className="input"
                                placeholder="Enter title"
                                disabled
                              />
                            </Form.Item>
                          </div>
                          <div className="secondInp">
                            <Form.Item
                              name={`employerEsiAmount`}
                              rules={[
                                { required: true, message: "Enter amount" },
                              ]}
                            >
                              <Input
                                name="employer_esi"
                                placeholder="00"
                                className="input"
                                onKeyPress={(e) => handleSalaryValidate(e)}
                                type="number"
                                prefix="₹"
                                onChange={(e) => handleRoleModalInput(e)}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <Form.List
                        name="earnings"
                        initialValue={roleModalInput.earnings}
                      >
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Space
                                key={key}
                                style={{
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <div
                                  className="container"
                                  style={{ marginBottom: 0 }}
                                >
                                  <label>Earning title - {name + 8}</label>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "flex-start",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Form.Item
                                      {...restField}
                                      name={[name, "title"]}
                                      rules={[
                                        {
                                          required: false,
                                          message: "Missing Earning label",
                                        },
                                      ]}
                                      style={{ marginRight: "15px" }}
                                    >
                                      <Input
                                        placeholder="Enter"
                                        name="title"
                                        onChange={(e) =>
                                          handleEarningSplitUp(e, name)
                                        }
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "amount"]}
                                      rules={[
                                        {
                                          required: false,
                                          message: "Missing Earning Value",
                                        },
                                      ]}
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="00"
                                        prefix="₹"
                                        name="amount"
                                        onChange={(e) =>
                                          handleEarningSplitUp(e, name)
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                  <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                    style={{ margin: "10px auto auto 10px" }}
                                  />
                                </div>
                              </Space>
                            ))}
                            <Button
                              size="small"
                              style={{
                                backgroundColor: "#0361fe",
                                color: "white",
                                width: "max-content",
                              }}
                              onClick={() => {
                                add();
                                handleCounters("earning");
                              }}
                            >
                              {" "}
                              +{" "}
                            </Button>
                          </>
                        )}
                      </Form.List>
                    </div>
                  </div>
                  <div className="deduction" style={{ width: "48%" }}>
                    <div>
                      <Form.List
                        name="deductions"
                        initialValue={roleModalInput.deductions}
                      >
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Space
                                key={key}
                                style={{
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <div
                                  className="container"
                                  style={{ marginBottom: 0 }}
                                >
                                  <label>Deduction title - {name + 1}</label>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "flex-start",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Form.Item
                                      {...restField}
                                      name={[name, "title"]}
                                      rules={[
                                        {
                                          required: false,
                                          message: "Missing Earning label",
                                        },
                                      ]}
                                      style={{ marginRight: "15px" }}
                                    >
                                      <div>
                                        <Input
                                          placeholder="Enter Deduction"
                                          name="title"
                                          min="0"
                                          onChange={(e) =>
                                            handleDeductionSplitUp(e, name)
                                          }
                                        />
                                        {errorMessage && (
                                          <p style={{ color: "red" }}>
                                            {errorMessage}
                                          </p>
                                        )}
                                      </div>
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "amount"]}
                                      rules={[
                                        {
                                          required: false,
                                          message: "Missing Earning Value",
                                        },
                                      ]}
                                      style={{ width: "50%" }}
                                    >
                                      <div>
                                        <Input
                                          placeholder="00"
                                          prefix="₹"
                                          name="amount"
                                          min="0"
                                          type="number"
                                          onChange={(e) =>
                                            handleDeductionSplitUp(e, name)
                                          }
                                        />
                                        {errorMessage && (
                                          <p style={{ color: "red" }}>
                                            {errorMessage}
                                          </p>
                                        )}
                                      </div>
                                    </Form.Item>
                                  </div>
                                  <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                    style={{ margin: "10px auto auto 10px" }}
                                  />
                                </div>
                              </Space>
                            ))}
                            <Button
                              size="small"
                              style={{
                                backgroundColor: "#0361fe",
                                color: "white",
                                width: "max-content",
                              }}
                              onClick={() => {
                                add();
                                handleCounters("deduct");
                              }}
                            >
                              {" "}
                              +{" "}
                            </Button>
                          </>
                        )}
                      </Form.List>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="postFormFooter">
              <div className="footerbtns">
                <Form.Item>
                  <Button
                    key="back"
                    type="secondary"
                    className="btn"
                    style={{ marginRight: "1.5rem" }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    key="submit"
                    htmlType="submit"
                    type="primary"
                    className="btn"
                    onClick={(e) => handleRoles(e)}
                  >
                    Post
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        ) : page == 1 ? (
          // <PostForm form={form} type={type} state={state} district={district} employer={employer} postModalInput={postModalInput} setPostModalInput={setPostModalInput} formalities={formalities} setFormalities={setFormalities} primaryEmployer={primaryEmployer} setPrimaryEmployer={setPrimaryEmployer} setExpPerPeriod={setExpPerPeriod} setSalPerPeriod={setSalPerPeriod} handleCancel={handleCancel} handleFormalityInput={handleFormalityInput} setPage={setPage}/>
          <Form id="formTwo" form={form}>
            <div className="postModalForm">
              <div className="postModalFirstLine">
                <div className="firstLine" style={{ width: "48%" }}>
                  <label>Designation</label>
                  <Form.Item
                    name="designation"
                    initialValue={postModalInput.designation}
                    rules={[{ required: true, message: "Enter job title" }]}
                  >
                    <Input
                      name="designation"
                      value={postModalInput.designation}
                      className="input"
                      size="large"
                      placeholder="Enter job title"
                      onChange={(e) => typeInput(e)}
                    />
                  </Form.Item>
                </div>
                {auth.isPrimary ? (
                  <div className="firstLine" style={{ width: "49%" }}>
                    <label>Qualification</label>
                    <Form.Item
                      name="qualification"
                      initialValue={postModalInput.qualification}
                      rules={[
                        { required: true, message: "Enter qualification" },
                      ]}
                    >
                      {/* <Select mode="tags" name='qualification' size='large' className='input
                                        '   onChange={(value) => setPostModalInput(prevState => ({ ...prevState, qualification: value }))} placeholder='Enter qualification' > */}
                      <Select
                        mode="tags"
                        allowClear
                        className="input"
                        placeholder="Select qualification"
                        showSearch
                        showArrow
                        name="qualification"
                        value={postModalInput.qualification}
                        onChange={(value) =>
                          setPostModalInput((prevState) => ({
                            ...prevState,
                            qualification: value,
                          }))
                        }
                        size="large"
                      >
                        <Option value="SSC">SSC(10th)</Option>
                        <Option value="HSC">HSC(12th)</Option>
                        <Option value="Diploma">Diploma</Option>
                        <Option value="Bachelors">Bachelors</Option>
                        <Option value="Masters">Masters</Option>
                        <Option value="Any">Any</Option>
                      </Select>
                    </Form.Item>
                  </div>
                ) : (
                  <div className="firstLine" style={{ width: "49%" }}>
                    <label>Primary Employer</label>
                    <Form.Item
                      name="employer"
                      initailValue={primaryEmployer}
                      rules={[
                        { required: true, message: "Select primary employer" },
                      ]}
                    >
                      <Select
                        className="input"
                        style={{ display: "block" }}
                        showSearch
                        name="employer"
                        size="large"
                        showArrow
                        value={primaryEmployer}
                        onSelect={(value) => setPrimaryEmployer(value)}
                        allowClear
                        placeholder={"Select"}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {employer?.map((opt, i) => {
                          return (
                            <Option key={i} value={opt.id}>
                              {opt.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                )}
              </div>
              {auth.isPrimary ? (
                <div className="postModalDis">
                  <label>Description</label>
                  <Form.Item
                    name="description"
                    initialValue={postModalInput.description}
                    rules={[{ required: true, message: "Enter description" }]}
                  >
                    <Input
                      name="description"
                      value={postModalInput.description}
                      onChange={(e) => typeInput(e)}
                      className="input"
                      size="large"
                      placeholder="Max 15 words"
                      maxLength={100}
                    />
                  </Form.Item>
                </div>
              ) : (
                <div className="postModalDis">
                  <label>Description</label>
                  <Form.Item
                    name="description"
                    initialValue={postModalInput.description}
                    rules={[{ required: true, message: "Enter description" }]}
                  >
                    <Input
                      name="description"
                      value={postModalInput.description}
                      onChange={(e) => typeInput(e)}
                      className="input"
                      size="large"
                      placeholder="Max 15 words"
                      maxLength={100}
                    />
                  </Form.Item>
                  <div
                    className="qualification"
                    style={{ marginTop: "0.5rem" }}
                  >
                    <label>Qualification</label>
                    <Form.Item
                      name="qualification"
                      initialValue={
                        postModalInput.qualification == ""
                          ? undefined
                          : postModalInput.qualification
                      }
                      rules={[
                        { required: true, message: "Enter qualification" },
                      ]}
                    >
                      <Select
                        mode="tags"
                        className="input"
                        placeholder="Select qualification"
                        showSearch
                        showArrow
                        name="qualification"
                        value={postModalInput.qualification}
                        onChange={(value) =>
                          setPostModalInput((prevState) => ({
                            ...prevState,
                            qualification: value,
                          }))
                        }
                        size="large"
                      >
                        <Option value="SSC">SSC(10th)</Option>
                        <Option value="HSC">HSC(12th)</Option>
                        <Option value="Diploma">Diploma</Option>
                        <Option value="Bachelors">Bachelors</Option>
                        <Option value="Masters">Masters</Option>
                        <Option value="Any">Any</Option>
                      </Select>
                      {/* <Select mode="tags"  name='qualification' size='large' className='input'
 onChange={(value) =>setPostModalInput(prevState => ({...prevState,qualification : value}))}  placeholder='Enter qualification' style={{ width: '100%', overflowX:"auto" }} maxTagPlaceholder={5} dropdownStyle={{visibility:"hidden"}}>
                                                <Option value="day">per day</Option>
                                                <Option value="month">per month</Option>
                                                <Option value="year">per year</Option>
                                        </Select> */}
                      {/* <Select mode="tags" name='qualification' size='large' className='input qualSelect' value={postModalInput.qualification}  onChange={(value) =>setPostModalInput(prevState => ({...prevState,qualification : value}))}  placeholder='Enter Qualification' style={{ width: '100%', overflowX:"auto" }} dropdownStyle={{visibility:"hidden"}} /> */}
                    </Form.Item>
                  </div>
                </div>
              )}
              <div className="postModalMiddleLine">
                <div className="leftSide" style={{ width: "48%" }}>
                  {/* <div className='expInp'>
                                            <label>Experience required</label>
                                            <div className='wrap'>
                                                <div className='ExpC' >
                                                    <div style={{ width: "35%" }}>
                                                        <Form.Item 
                                                        name='expFrom' 
                                                        initialValue={postModalInput.expFrom} 
                                                        rules={[{ required: true, message: "*" }, { max: 2 }]}
                                                        >
                                                            <Input 
                                                            type='number' 
                                                            min={0} 
                                                            max={99} 
                                                            onKeyPress={(e) => handleExpValidate(e, "from")} 
                                                            onChange={(e) => typeInput(e)} 
                                                            size='large' 
                                                            name='expFrom' 
                                                            placeholder='00' 
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    <div className='connector'>to</div>
                                                    <div style={{ width: "35%" }}><Form.Item name='expTo' initialValue={postModalInput.expTo} rules={[{ required: true, message: "*" }, { max: 2 }, ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!(value == '' || value == 0 || value == undefined) && getFieldValue('expFrom') === value) {
                                                                return Promise.reject(new Error(`don't enter same value`));
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    }),]}><Input type="number" id="handleExpToValidate" onKeyPress={(e) => handleExpValidate(e, "to")} onChange={(e) => typeInput(e)} size='large' name='expTo' placeholder='00' /></Form.Item></div>
                                                </div>
                                                <div className='selectPer'>
                                                    <Form.Item name="expPer" initialValue={postModalInput.expPerPeriod} >
                                                        <Select defaultValue='months' name="expPer" value={postModalInput.expPerPeriod} onChange={(value) => setExpPerPeriod(value)} showArrow style={{ width: "100%" }} size='large'>
                                                            <Option value="months">month</Option>
                                                            <Option value="years">year</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div> */}
                  <div className="expInp">
                    <label>Experience required</label>
                    <div className="wrap">
                      <div className="ExpC">
                        <div style={{ width: "35%" }}>
                          <Form.Item
                            name="expFrom"
                            initialValue={postModalInput.expFrom}
                            rules={[
                              { required: true, message: "*" },
                              { max: 2, message: "Maximum 2 digits allowed" },
                            ]}
                          >
                            <Input
                              type="number"
                              min={0}
                              max={99}
                              onKeyPress={(e) => handleExpValidate(e, "from")}
                              onChange={(e) => typeInput(e)}
                              size="large"
                              name="expFrom"
                              placeholder="00"
                            />
                          </Form.Item>
                        </div>
                        <div className="connector">to</div>
                        <div style={{ width: "35%" }}>
                          <Form.Item
                            name="expTo"
                            initialValue={postModalInput.expTo}
                            rules={[
                              { required: true, message: "*" },
                              { max: 2, message: "Maximum 2 digits allowed" },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const expFrom = getFieldValue("expFrom");
                                  if (value && expFrom && expFrom > value) {
                                    return Promise.reject(
                                      new Error(
                                        "From value should not be greater than To value"
                                      )
                                    );
                                  }
                                  if (
                                    !(
                                      value === "" ||
                                      value === 0 ||
                                      value === undefined
                                    ) &&
                                    expFrom === value
                                  ) {
                                    return Promise.reject(
                                      new Error("Don't enter the same value")
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input
                              type="number"
                              id="handleExpToValidate"
                              onKeyPress={(e) => handleExpValidate(e, "to")}
                              onChange={(e) => typeInput(e)}
                              size="large"
                              name="expTo"
                              placeholder="00"
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="selectPer">
                        <Form.Item
                          name="expPer"
                          initialValue={postModalInput.expPerPeriod}
                        >
                          <Select
                            defaultValue="months"
                            name="expPer"
                            value={postModalInput.expPerPeriod}
                            onChange={(value) => setExpPerPeriod(value)}
                            showArrow
                            style={{ width: "100%" }}
                            size="large"
                          >
                            <Option value="months">month</Option>
                            <Option value="years">year</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="dateInp">
                    <label>Start date</label>
                    <Form.Item
                      name="startDate"
                      initialValue={postModalInput.startDate}
                      rules={[{ required: true, message: "Select start date" }]}
                    >
                      <DatePicker
                        className="input"
                        name="startDate"
                        value={postModalInput.startDate}
                        onChange={(value) =>
                          setPostModalInput((prevState) => ({
                            ...prevState,
                            startDate: value,
                          }))
                        }
                        size="large"
                        disabledDate={(current) =>
                          current && current < moment().startOf("day")
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="rightSide" style={{ width: "49%" }}>
                  <label>Salary</label>
                  <div className="salaryInp">
                    <div className="inp">
                      <div className="from Sal">
                        <Form.Item
                          name="salaryFrom"
                          initialValue={postModalInput.salFrom}
                          rules={[{ required: true, message: "*" }]}
                        >
                          <Input
                            name="salFrom"
                            onKeyPress={(e) => handleSalaryValidate(e, "1")}
                            onChange={(e) => typeInput(e)}
                            size="large"
                            type="number"
                            className="input"
                            placeholder="00"
                            prefix="₹"
                          />
                        </Form.Item>
                      </div>
                      <div className="connector">to</div>
                      <div className="to Sal">
                        <Form.Item
                          name="salaryTo"
                          initialValue={postModalInput.salTo}
                          rules={[
                            { required: true, message: "*" },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !(
                                    value == "" ||
                                    value == 0 ||
                                    value == undefined
                                  ) &&
                                  getFieldValue("salaryFrom") === value
                                ) {
                                  return Promise.reject(
                                    new Error(`don't enter same value`)
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            name="salTo"
                            onKeyPress={(e) => handleSalaryValidate(e, "1")}
                            onChange={(e) => typeInput(e)}
                            size="large"
                            type="number"
                            className="input"
                            placeholder="00"
                            prefix="₹"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="selectPer" style={{ width: "45%" }}>
                      <Form.Item
                        name="salPer"
                        initialValue={postModalInput.salPerPeriod}
                      >
                        <Select
                          name="salPer"
                          defaultValue="day"
                          showArrow
                          className="input"
                          value={postModalInput.salPerPeriod}
                          onChange={(value) => setSalPerPeriod(value)}
                          size="large"
                          style={{ width: "100%" }}
                        >
                          <Option value="day">per day</Option>
                          <Option value="month">per month</Option>
                          <Option value="year">per year</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="addressSel">
                <div className="leftSide">
                  <label>State</label>
                  <Form.Item
                    name="state"
                    initialValue={
                      postModalInput.state == ""
                        ? undefined
                        : postModalInput.state
                    }
                    rules={[{ required: true, message: "Select State" }]}
                  >
                    <Select
                      className="input"
                      placeholder="Select State"
                      showSearch
                      showArrow
                      name="state"
                      value={postModalInput.state}
                      onChange={(value) => handleProvinceChange(value)}
                      size="large"
                      listHeight={200}
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {state.map((stateName) => {
                        return (
                          <Option value={stateName} key={stateName}>
                            {stateName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="rightSide">
                  <label>District</label>
                  <Form.Item
                    name="district"
                    initialValue={
                      postModalInput.district == ""
                        ? undefined
                        : postModalInput.district
                    }
                    rules={[{ required: true, message: "Select district" }]}
                  >
                    <Select
                      className="input"
                      showSearch
                      showArrow
                      name="district"
                      value={postModalInput.district}
                      onChange={(value) =>
                        setPostModalInput((prevState) => ({
                          ...prevState,
                          district: value,
                        }))
                      }
                      size="large"
                      style={{ width: "100%" }}
                      listHeight={200}
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      placeholder="Select district"
                    >
                      {cities.map((city) => {
                        return (
                          <Option value={city} key={city}>
                            {city}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="joiningFormalities">
                <label>Joining formalities</label>
                {formalities.map((itm, id) => {
                  return (
                    <div style={{ display: "grid" }}>
                      <div className="formalList" key={id}>
                        <div className="formalListCount">
                          <div>{++id + "."}</div>
                        </div>
                        <div className="formalListInput">
                          <TextArea
                            className="txtArea"
                            value={itm}
                            onChange={(e) => handleFormalityInput(e, id)}
                            style={{ width: "100%" }}
                            autoSize={true}
                            size="large"
                            placeholder="Enter details"
                          />
                        </div>
                      </div>
                      <div>
                        {isDisContinue && !itm && (
                          <div style={{ color: "red" }}>Enter Detail</div>
                        )}
                      </div>
                      <div>
                        <Button
                          type="primary"
                          size="small"
                          style={{ margin: "1% 2%", backgroundColor: "0361fe", borderColor: "#0361fe" }}
                          disabled={
                            id !== formalities.length ||
                            formalities.includes("")
                              ? true
                              : false
                          }
                          onClick={handleFormalities}
                        >
                          +
                        </Button>

                        <Button
                          type="primary"
                          size="small"
                          style={{ margin: "1% 2%", backgroundColor: "0361fe" }}
                          onClick={() => handleRemoveFormality(id - 1)}
                          // onMouseDown={(e) => e.preventDefault()}
                          disabled={id === 1}
                        >
                          -
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="postFormFooter">
              <div className="footerbtns">
                <Form.Item>
                  <Button
                    key="back"
                    type="secondary"
                    className="btn"
                    size="large"
                    style={{ marginRight: "1.5rem" }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    key="submit"
                    htmlType="submit"
                    type="primary"
                    size="large"
                    className="btn"
                    onClick={handleContinue}
                  >
                    Continue
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        ) : (
          <Form form={secondForm}>
            {/* <div className='postModalSec'>
                                {postQueInput.map((itm, id) => {
                                    return (
                                        <div key={id} className='questionSet'>
                                            <div className='que'>
                                                <label>Custom question</label>
                                                <Form.Item 
                                                name={`question-${++id}`} 
                                                initialValue={itm.question} 
                                                rules={[{ required: true }]}
                                                >
                                                    <Input 
                                                    name={`question-${id}`} 
                                                    placeholder='Question here' 
                                                    className='input' 
                                                    size='large' 
                                                    onChange={(e) => handleQuestionInput(e, id)} 
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className='queType'>
                                                <label>Answer Type</label>
                                                <Form.Item 
                                                name={`questionType-${id}`} 
                                                initialValue={itm.question_type} 
                                                rules={[{ required: true }]}
                                                >
                                                    <Select 
                                                    placeholder="select" 
                                                    className='input select' 
                                                    size='large' 
                                                    onChange={(value) => handleQuestionTypeInput(value, id)}
                                                    >
                                                        <Option value='number'>Numeric</Option>
                                                        <Option value='text'>Text Box</Option>
                                                        <Option value="boolean">Yes / No</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className='removeBtn'>
                                                <Button type='link' onClick={() => handleRemoveQuestion(id)}>X</Button>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div>
                                    <Button 
                                    type='primary' 
                                    size='small' 
                                    className='input' 
                                    disabled={(postQueInput.some(e => e.question === '' || e.question_type === '')) ? true : false} 
                                    onClick={handleQuestion}
                                    >
                                        +
                                    </Button>
                                </div>
                            </div> */}

            <div className="postModalSec">
              {postQueInput.map((itm, id) => {
                return (
                  <div key={id} className="questionSet">
                    <div className="que">
                      <label>Custom question</label>
                      <Input
                        name={`question-${id}`}
                        placeholder="Question here"
                        className="input"
                        size="large"
                        value={itm?.question}
                        onChange={(e) => handleQuestionInput(e, id)}
                      />
                    </div>
                    <div className="queType">
                      <label>Answer Type</label>

                      <Select
                        placeholder="Select"
                        className="input select"
                        size="large"
                        value={itm?.question_type}
                        onChange={(value) => handleQuestionTypeInput(value, id)}
                      >
                        <Option value="number">Numeric</Option>
                        <Option value="text">Text Box</Option>
                        <Option value="boolean">Yes / No</Option>
                      </Select>
                    </div>

                    <div>
                      {isDisError &&
                        (!itm?.question || !itm?.question_type) && (
                          <div style={{ color: "red" }}>
                            Custome Query is required
                          </div>
                        )}
                    </div>

                    <div
                      className="removeBtn"
                      style={{ display: "flex", gap: "2%" }}
                    >
                      <Button
                        type="primary"
                        onClick={() => handleRemoveQuestion(id)}
                        size="small"
                        style={{
                          margin: "1% 2%",
                          backgroundColor: "0361fe",
                          borderColor: "#0361fe",
                        }}

                        disabled={id === 0}
                      >
                        -
                      </Button>

                      <Button
                        type="primary"
                        size="small"
                        style={{
                          margin: "1% 2%",
                          backgroundColor: "0361fe",
                          borderColor: "#0361fe",
                        }}
                        disabled={isDisapled(id)}
                        onClick={handleQuestion}
                      >
                        +
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="postFormFooter">
              <div className="footerbtns">
                <Form.Item>
                  <Button
                    key="back"
                    type="secondary"
                    className="btn"
                    size="large"
                    style={{ marginRight: "1.5rem" }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    className="btn"
                    onClick={handlePost}
                  >
                    Post
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        )}
      </Modal>
    </div>
  );
}
export default PostForm;

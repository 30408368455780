import React from 'react';
import sort from '../../assets/icons/sort.svg';
import { message, Tooltip} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { dataContext } from '../../context/data';
import { useContext } from 'react';
import useSortableData from '../useSortableData';


function RolesTable({jobDataColumn, data}) {

    const userContext = useContext(dataContext);
    const { items, requestSort } = useSortableData(data);

  const moreData = () => {
    console.log("it is working")
    userContext.setJobData(pre => { return { ...pre, rolesPage: pre.rolesPage + 1 } });
  }

  console.log("itmes", items)
  return (
    <div>
      {console.log("datalength", data.length)}
      <InfiniteScroll 
          dataLength={data.length}
          next={moreData}
          hasMore={true}
          key={userContext.dashboardData.activeContractTabIndex}
          scrollableTarget='scrolTABLE'
      >
      <div className='roleTableList' id='scrolTABLE'>
      <table>
      <thead>
          <tr>
              {jobDataColumn.map((itm, i) =>{
              
              return(
                  <th key={i} className={itm.dataIndex == "description" ? "descripThead thead" : "thead" } width={itm.width}>{itm.title}</th>
              )
              })} 
          </tr>
          </thead>
      <tbody>
        
        {items.map((row, idx) =>{
          return (

            <tr key={idx}>
                  {jobDataColumn.map((field) =>{
                    if(field.dataIndex == 'description'){
                      return(
                        <td className='descriptionData'><Tooltip title={row[field.dataIndex]} color="#262626" overlayInnerStyle={{fontWeight:500}} placement='bottom' arrowPointAtCenter={false} fitToScreen={true} zIndex={2}>{row[field.dataIndex]}</Tooltip></td>
                      )
                    }else{
                      return(
                        
                        <td className={"data"}>{row[field.dataIndex]}</td>
                      )
                    }
                  })}
              </tr>
          )
          })}
          </tbody>
          </table>
      </div>
      </InfiniteScroll>
    </div>
  )
}

export default RolesTable
import React from 'react'
import EnrollRequestDetail from './EnrollRequestDetail';
import DetachRequestDetails from './DetachRequestDetails';
import { useLocation } from 'react-router-dom';

function RequestDetail() {
  
  const { tab } = useLocation().state;

  return (
    <>
      {tab == '1' ? 
        <EnrollRequestDetail />
      : tab == '2' ?
        <DetachRequestDetails />
      : <div></div>
      }
    </>
  )
}

export default RequestDetail
import React from 'react';
import moreIcon from '../../../assets/icons/more.svg';
import dragIcon from '../../../assets/icons/drag.svg';
import tripleDot from '../../../assets/icons/tribleDot.svg';
import { useEffect, useState, useContext } from 'react';
import { useAuthContext } from '../../../context/auth';
import '../../../styles/moreOptions.scss';
import { dataContext } from '../../../context/data';

function MoreOption({setColumnTalent, setSelectedColumn, columnTalent, selectedColumn}) {



    // const [optionVisibile, setOptionVisibile] = useState(false);
    const showOption = (e) => {
        e.stopPropagation();
        setMoreOptionVisible(pre => !pre)
    };
    const [currentIndex, setCurrentIndex] = useState(null);
    const auth = useAuthContext();
    const {moreOptionVisible, setMoreOptionVisible} = useContext(dataContext);
    const handleColumnSelection = (event, i) => {

        event.stopPropagation();
        let col = [];
        let newData = columnTalent.map((itm, idx) => idx === i ? { ...itm, visibility: !itm.visibility } : itm)
        setColumnTalent(newData);
        setSelectedColumn(pre => {   
            newData.forEach((itm, idx) => {
                if (itm.visibility)
                    col.push(idx);
            })
            return col;
        })
        // if (selectedColumn.includes(i))
        //     setSelectedColumn(pre => pre.filter(x => x !== i));
        // else
        //     setSelectedColumn(pre => [...pre, i]);
    }
    const endCapture = e => {
    // e.stopPropagation();
      setCurrentIndex(null);
      if (e.currentTarget.ariaValueText === currentIndex || currentIndex === null) return;
      let movingValue = columnTalent[e.currentTarget.ariaValueText];
      let newData = [];
      columnTalent.forEach((item, i) => {
          if (i === parseInt(currentIndex)) {
              newData.push(movingValue);
              newData.push(item);
          }
          else if (i !== parseInt(e.currentTarget.ariaValueText)) newData.push(item);
      })
      setColumnTalent(newData);
      setSelectedColumn(pre => {
          let col = [];
          newData.forEach((itm, idx) => {
              if (itm.visibility)
                  col.push(idx);
          })
          return col;
      })
    }


  return (
    <div className="selectMore" id='btnMoreOption'>
    <button  className='trible_btn' size='large' onClick={showOption}> <img className='trible_dot' src={tripleDot} alt='more btn' /> </button>


    {moreOptionVisible &&
        <div className="optionList">
            {columnTalent.map((field, i) => {
                if (!(auth.isContract && field.dataIndex == 'contractor'))
                    if (!(auth.isPrimary && field.dataIndex == 'employer'))
                        if (!((auth.isContract || auth.isPrimary) && field.dataIndex == 'status'))
                            return (
                                <>
                                    {i === parseInt(currentIndex) && <div className="canDrop"></div>}

                                    <div aria-valuetext={i} onClick={(e) => e.stopPropagation()} onDragStart={(e) => {
                                        // console.log(e.currentTarget.ariaValueText);

                                    }
                                    } onDragOver={(e) => setCurrentIndex(e.currentTarget.ariaValueText)} draggable={(field.title === 'Name' || field.title === "Mobile Number") ? false : true} onDragEnd={endCapture} className={(field.title === 'Name' || field.title === "Mobile Number") ? "listItem immovable" : "listItem"}><span><img src={dragIcon} alt="drag" /> {field.title}</span> <input disabled={(field.title === 'Name' || field.title === "Mobile Number") ? selectedColumn === [] ? false : true : false} type="checkbox" checked={selectedColumn.includes(i) ? true : false} onChange={(e) => handleColumnSelection(e, i)} />
                                    </div>
                                </>
                            );
            })}
        </div>
    }
    </div>
  )
}

export default MoreOption
import React, { useEffect, useState, useContext } from 'react'
import RequestsList from './RequestsList'
import { query, collection, where, onSnapshot,getFirestore, orderBy, startAfter, limit, } from 'firebase/firestore'
import { Divider, message } from 'antd';
import { useAuthContext } from '../../context/auth';
import { dataContext } from '../../context/data';
import axios from 'axios';
import baseUrl from '../../utils/baseUrl';


const db = getFirestore();

function EnrollRequests() {
    const auth = useAuthContext();
    const {requestTabData, setRequestTabData} = useContext(dataContext);
    const [data, setData] = useState([]);
    const column = [
        {
            title: 'Status',
            dataIndex: 'status',
            sort: false,
            width: 100
        },
        ...((auth.isPrimary || auth.isSpectra) ? [{
            title: 'Contractors',
            dataIndex: 'contractors',
            sort: true,
        }]: []),
        ...((auth.isContract || auth.isSpectra) ? [{
            title: 'primary Employer',
            dataIndex: 'primary_employer',
            sort: true,
        }]: []),
        {
            title: 'Job Role',
            dataIndex: 'jobRole',
            sort: true,
            width: 100
        },
        {
            title: 'No. of Talents',
            dataIndex: 'noOfTalents',
            sort: false,
            width: 100
        },
        {
            title: 'Gross Salary',
            dataIndex: 'grossSalary',
            sort: true,
            width: 100
        },
        {
            title: 'Date of Joining',
            dataIndex: 'dateOfJoining',
            sort: false,
            width: 100
        },
        {
            title: 'Request Sent on',
            dataIndex: 'requestSentOn',
            sort: false,
            width: 100
        },
    ]

    const getDateInFormat = (value) => {
        let date = value?.toDate();
        let formattedDate = date?.toLocaleDateString("en-IN", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric"
        });
        return formattedDate
    }


    const getDateFormat = (values) => {
        var date = new Date((values._seconds * 1000) + (values._nanoseconds / 1000000));
        let formattedDate = date?.toLocaleDateString("en-IN", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric"
        });
        return formattedDate
    }

    const getRequestsList = () => {
        message.loading({content: 'Loading...', key: 'requestList'})
        let q;
        let sort;
        if(requestTabData.fsSort){
            sort = requestTabData.fsSort
        }else {
            sort = {
                fieldName : 'created_at',
                order : 'desc'
            }
        }
        if(auth.isSpectra){
            if(requestTabData.page && requestTabData.page > 0 && requestTabData.fsSort){
                q = query(collection(db, "enrollment_requests"), orderBy("status", "desc"), where("status", "!=", "COMPLETED"), orderBy(sort.fieldName, sort.order), limit((requestTabData.page + 1)*15))
            }else if(!requestTabData.page && !requestTabData.page > 0 && requestTabData.fsSort){
                q = query(collection(db, "enrollment_requests"), orderBy("status", "desc"), where("status", "!=", "COMPLETED"), orderBy(sort.fieldName, sort.order), limit(15));
            }else if(requestTabData.page && requestTabData.page > 0 && !requestTabData.fsSort){
                q = query(collection(db, "enrollment_requests"), orderBy("status", "desc"), where("status", "!=", "COMPLETED"), orderBy(sort.fieldName, sort.order), startAfter(requestTabData.lastFetch), limit(15))
            }else{
                q = query(collection(db, "enrollment_requests"), orderBy("status", "desc"), where("status", "!=", "COMPLETED"), orderBy(sort.fieldName, sort.order), limit(15))
            }
        } else if(auth.isPrimary){
            if(requestTabData.page && requestTabData.page > 0 && requestTabData.fsSort){
                q = query(collection(db, "enrollment_requests"), where('primary_employer_id', '==', localStorage.getItem('primary_employer_id')), orderBy("status", "desc"), where("status", "!=", "COMPLETED"), orderBy(sort.fieldName, sort.order), limit((requestTabData.page + 1)*15))
            }else if(!requestTabData.page && !requestTabData.page > 0 && requestTabData.fsSort){
                q = query(collection(db, "enrollment_requests"), where('primary_employer_id', '==', localStorage.getItem('primary_employer_id')), orderBy("status", "desc"), where("status", "!=", "COMPLETED"), orderBy(sort.fieldName, sort.order), limit(15));
            }else if(requestTabData.page && requestTabData.page > 0 && !requestTabData.fsSort){
                q = query(collection(db, "enrollment_requests"), where('primary_employer_id', '==', localStorage.getItem('primary_employer_id')), orderBy("status", "desc"), where("status", "!=", "COMPLETED"), orderBy(sort.fieldName, sort.order), startAfter(requestTabData.lastFetch), limit(15))
            }else{
                q = query(collection(db, "enrollment_requests"), orderBy("status", "desc"), where('primary_employer_id', '==', localStorage.getItem('primary_employer_id')), where("status", "!=", "COMPLETED"), orderBy(sort.fieldName, sort.order), limit(15))
            }

        }else if(auth.isContract){

            if(requestTabData.page && requestTabData.page > 0 && requestTabData.fsSort){
                console.log('sorting with pagination', requestTabData.page)
                q = query(collection(db, "enrollment_requests"), where("contractor_id", "==", localStorage.getItem('contractor_id')), orderBy(sort.fieldName, sort.order), limit((requestTabData.page + 1)*15))
            }else if(!requestTabData.page && !requestTabData.page > 0 && requestTabData.fsSort){
                q = query(collection(db, "enrollment_requests"), where("contractor_id", "==", localStorage.getItem('contractor_id')), orderBy(sort.fieldName, sort.order), limit(15));
            }else if(requestTabData.page && requestTabData.page > 0 && !requestTabData.fsSort){
                q = query(collection(db, "enrollment_requests"), where("contractor_id", "==", localStorage.getItem('contractor_id')), orderBy(sort.fieldName, sort.order), startAfter(requestTabData.lastFetch), limit(15))
            }else{
                q = query(collection(db, "enrollment_requests"), where("contractor_id", "==", localStorage.getItem('contractor_id')), orderBy(sort.fieldName, sort.order), limit(15))
            }
        }
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const temp = [];
            let count = 0;
            querySnapshot.forEach((doc) => {
                let data = doc.data()

                temp.push({
                    id: doc.id, 
                    status: data.status,
                    contractors: data.contractor_name,
                    primary_employer: data.primary_employer_name,
                    jobRole: data.designation,
                    noOfTalents: data.talents?.length,
                    grossSalary: data.salary,
                    dateOfJoining: getDateInFormat(data.date_of_joining),
                    requestSentOn: getDateInFormat(data.created_at) 
                });
                if(querySnapshot.docs.length - 1 == count++){
                    console.log(querySnapshot.docs.length)
                    setData((pre) => {
                        if(requestTabData.page > 0 && !requestTabData.fsSort) {
                            return [...pre, ...temp]
                        }else{
                            return [...temp]
                        }
                    })
                    setRequestTabData((pre) => {return {...pre, lastFetch : doc}})
                    message.destroy('requestList')
                }
            });
            if (querySnapshot.docs.length <= 0) {
                message.destroy("requestList");
                setData(pre => {
                  if (requestTabData.page > 0) {
                    return [...pre];
                  }
                  else return [];
                });
              }
        });

        return () => unsubscribe();
    }

    const getSearchResult = () => {
        let searchField = requestTabData.searchValueObj.fieldName;
        let searchValue = requestTabData.searchValueObj.fieldValue;
        if(searchField && searchValue){
            message.loading({content: 'Searching...', key: 'search', duration: 0})
            let field_name;
            let user_type = (auth.isSpectra) ? 'Talntworx' : (auth.isPrimary ? 'Primary' : 'Contract')
            let user_id = auth.isPrimary ? localStorage.getItem("primary_employer_id") : auth.isContract ? localStorage.getItem("contractor_id") : '';
            if(searchField == 'all'){
                field_name = 'all'
            }else if(searchField == 'contractors'){
                field_name = 'contractor_name'
            }else if(searchField == 'primary_employer'){
                field_name = 'primary_employer_name'
            }else if(searchField == 'jobRole'){
                field_name = 'designation'
            }else if(searchField == 'noOfTalents'){
                field_name = 'talents'
            }else if(searchField == 'grossSalary'){
                field_name = 'salary'
            }
            if(requestTabData.sortColumn){
                axios
                .post(
                    `${baseUrl}/enrollment_requests_search`,
                    {
                      user_type: user_type,
                      user_id: user_id,
                      field_name: field_name,
                      field_value: searchValue,
                      sortColumn : [requestTabData.sortColumn],
                      title: "enroll_request_search",
                    }
                ).then((res) => {
                    console.log(res.data.requestList)
                    const temp = [];
                    res.data.requestList.map((doc) => {
                        console.log(doc)
                        temp.push({
                            id: doc.id,
                            status: doc.result.status,
                            contractors: doc.result.contractor_name,
                            primary_employer: doc.result.primary_employer_name,
                            jobRole: doc.result.designation,
                            noOfTalents: doc.result.talents?.length,
                            grossSalary: doc.result.salary,
                            dateOfJoining: getDateFormat(doc.result.date_of_joining),
                            requestSentOn: getDateFormat(doc.result.created_at) 
                        });
                    });
                    setData(temp)
                    message.destroy('search')
                }).catch((err) => {
                    console.log(err)
                    message.destroy('search')
                })
            }else{
                axios
                .post(
                    `${baseUrl}/enrollment_requests_search`,
                    {
                      user_type: user_type,
                      user_id: user_id,
                      field_name: field_name,
                      field_value: searchValue,
                      title: "enroll_request_search",
                    }
                ).then((res) => {
                    console.log(res.data.requestList)
                    const temp = [];
                    res.data.requestList.map((doc) => {
                        console.log(doc)
                        temp.push({
                            id: doc.id,
                            status: doc.result.status,
                            contractors: doc.result.contractor_name,
                            primary_employer: doc.result.primary_employer_name,
                            jobRole: doc.result.designation,
                            noOfTalents: doc.result.talents?.length,
                            grossSalary: doc.result.salary,
                            dateOfJoining: getDateFormat(doc.result.date_of_joining),
                            requestSentOn: getDateFormat(doc.result.created_at) 
                        });
                    });
                    setData(temp)
                    message.destroy('search')
                }).catch((err) => {
                    console.log(err)
                    message.destroy('search')
                })
            }
        }
    }

    useEffect(() => {
        if(requestTabData.searchValueObj){
            console.log('search condition is working')
            getSearchResult();
        }else{
            console.log('initial condition is working')
            getRequestsList();
        }
    },  [requestTabData.searchValueObj, requestTabData.sortColumn, requestTabData.isSort, requestTabData.page])

  return (
    <div>
        <RequestsList column={column} data={data}/>
    </div>
  )
}

export default EnrollRequests
import React from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { dataContext } from "../../context/data";
import sortIcon from '../../assets/icons/sort.svg';

function RequestsTable({tableHead, data}) {

  const history = useHistory();
  const {requestTabData, setRequestTabData} = useContext(dataContext);

  const handleSort = (dataIndex) => {

    if (dataIndex || dataIndex !== '') {
      var sortName = ''
        if (dataIndex == 'contractors') {
            sortName = 'contractor_name.keyword'
        } else if (dataIndex == 'primary_employer') {
            sortName = "primary_employer_name.keyword";
        } else if (dataIndex == 'jobRole') {
            sortName = "designation.keyword";
        } else if (dataIndex == 'grossSalary') {
            sortName = "salary.keyword";
        }
      }
      console.log(requestTabData.sortColumn, requestTabData.activeTabIndex == '1', requestTabData.detachSortColumn, requestTabData.activeTabIndex)
      if ((requestTabData.sortColumn && requestTabData.activeTabIndex == '1')) {
        if ((Object.keys(requestTabData.sortColumn).includes(sortName)  && requestTabData.activeTabIndex == '1')) {
            let value = requestTabData.sortColumn
            if (value[sortName] === 'asc') {
                value[sortName] = 'desc'
            } else if (value[sortName] === 'desc') {
                value[sortName] = 'asc'
            }
            let fsSortObj = {
              fieldName : sortName.slice(0, -8),
              order : value[sortName]
            }
              setRequestTabData(pre => { return { ...pre, sortColumn: value, isSort: !pre.isSort, fsSort: fsSortObj } })
        } else {
            let temp = {
              [sortName]: 'desc'
            };
            let fsSortObj = {
              fieldName : sortName.slice(0, -8),
              order : temp[sortName]
            }
              setRequestTabData(pre => { return { ...pre, sortColumn: temp, fsSort: fsSortObj } })
        }
    } else if((requestTabData.detachSortColumn && requestTabData.activeTabIndex == '2')){
      if((Object.keys(requestTabData.detachSortColumn).includes(sortName)  && requestTabData.activeTabIndex == '2')){
          let value = requestTabData.detachSortColumn
          if (value[sortName] === 'asc') {
              value[sortName] = 'desc'
          } else if (value[sortName] === 'desc') {
              value[sortName] = 'asc'
          }
          let fsSortObj = {
            fieldName : sortName.slice(0, -8),
            order : value[sortName]
          }
            setRequestTabData(pre => { return { ...pre, detachSortColumn: value, detachIsSort: !pre.detachIsSort, detachFsSort: fsSortObj } })
      }else{
        let temp = {
          [sortName]: 'desc'
        };
        let fsSortObj = {
          fieldName : sortName.slice(0, -8),
          order : temp[sortName]
        }
          setRequestTabData(pre => { return { ...pre, detachSortColumn: temp, detachFsSort: fsSortObj } })
      }
    }
    
    else {
        let temp = {
          [sortName]: 'desc'
        };
        let fsSortObj = {
          fieldName : sortName.slice(0, -8),
          order : temp[sortName]
        }
        console.log('3',fsSortObj)
        if(requestTabData.activeTabIndex == '1'){
          setRequestTabData(pre => { return { ...pre, sortColumn: temp, fsSort: fsSortObj } })
        }else{
          setRequestTabData(pre => { return { ...pre, detachSortColumn: temp, detachFsSort: fsSortObj } })
        }
    }
  }

  const fetchMore = () => {
    if(requestTabData.activeTabIndex == '1'){
      setRequestTabData(pre => { return { ...pre, page: pre.page + 1 } })
    }else{
      setRequestTabData(pre => { return { ...pre, detachTablepage: pre.detachTablepage + 1 } })
    }
  }

  return (
    <div className="tableContainer">
      <InfiniteScroll
        dataLength={data.length}
        hasMore={true}
        key={requestTabData.activeTabIndex}
        next={fetchMore}
        scrollableTarget="scrollTable"
      >
        <div className="jobTableList" id="scrollTable">
          <table>
            <thead>
              <tr>
                <th></th>
                {tableHead.map((head, i) => {
                    return (
                        <th key={i} className="data">{head.title} <img src={sortIcon} style={{ cursor: "pointer", display: head.sort ? '' : 'none', marginLeft: '3px'}} width='15px' height='15px' onClick={() => handleSort(head.dataIndex)} alt={"sort"}/></th>
                    )
                })}
              </tr>
            </thead>
            <tbody>
                {data?.map((row) => {
                  return (
                    <tr 
                    onClick={() => {
                      history.push({
                        pathname: `./requests/detail`,
                        state: { id: row.id, 
                          tab: requestTabData.activeTabIndex
                        }
                      })
                    }}>
                      <td></td>
                      {tableHead.map((head, i) => {
                        return (
                            <td key={i} className="data">{row[head.dataIndex]}</td>
                        )
                      })}
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default RequestsTable;

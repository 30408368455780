import React, { useState } from 'react';
import loginPicture from '../../assets/imgs/bg-img.svg'
import './styles/mobileNumber.scss'
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../../context/auth';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { doc, getDoc, getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { message } from 'antd';
import loadingIcon from '../../assets/icons/loading.gif';


function MobileNumber() {
    const db = getFirestore();
    const auth = useAuthContext();
    const history = useHistory();
    const [otpStatus, setotpStatus] = useState('enterPhoneNumber')
    const [otp, setotp] = useState(new Array(6).fill(""))
    const [phoneNumber, setphoneNumber] = useState('')
    const [loading, setLoading] = useState(false);

    // let confirmationResult;
    const authFire = getAuth();
    const signInPhoneNumber = () => {
        signInWithPhoneNumber(authFire, `+91${phoneNumber}`, window.recaptchaVerifier)
            .then((cR) => {
                window.confirmationResult = cR
                setotp(new Array(6).fill(""));
                setotpStatus('enterOtp')
                setLoading(false)
                message.destroy('signin');
            })
            .catch((err) => {
                setLoading(false)
                console.log(err);
                message.error({ content: 'Something went wrong', key: "signin" });
              
            });
    }
    const sendOTP = async() => {
        setLoading(true);
        // message.loading({ content: "Loading...", duration: 0, key: "signin" });
        window.recaptchaVerifier = new RecaptchaVerifier("sign-in-button", {
            'size': 'invisible',
            'callback': (response) => {
                // console.log(response)
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                // ...
                // cap_value = window.recaptchaVerifier;


            },
            'expired-callback': (excb) => {
                // Response expired. Ask user to solve reCAPTCHA again.
                // ...
                console.log(excb);
            }
        }, authFire);        
        const queryRef = query(collection(db, 'all_users'), where('mobile_number', '==', `+91${phoneNumber}`));

        const querySnapshot = await getDocs(queryRef);
        if (querySnapshot.size > 0) {
            let usersArray = [];
            querySnapshot.forEach((itm) => {
                let data = itm.data();
                usersArray.push(data);
            })
            if(usersArray.length == 1 && usersArray[0].user_type !== 'talent'){
                signInPhoneNumber();
            }else if(usersArray.length == 1 && usersArray[0].user_type == 'talent'){
                message.error({ content: 'Invalid User', key: "verification" });
                setLoading(false)
                message.destroy('signin');
            }
        } else {
            setLoading(false)
            message.error({ content: 'user doesn`t exist', key: "verification" });
            console.log('Document does not exist');
        }
    };
    const confirmCode = () => {
        setLoading(true);
        // message.loading({ content: "Loading...", duration: 0, key: 'verification' });
        window.confirmationResult.confirm(otp.join("")).then(async (res) => {
            localStorage.setItem("uid", res.user.uid);
            // console.log("uid", res.user.uid);
            res = JSON.parse(JSON.stringify(res));
            const docRef = doc(db, "all_users", `${res.user.uid}`);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // console.log("Document data:", docSnap.data());
                if (docSnap.data().user_type === "spectrawise_bizops_user") {
                    auth.signIn({ token: res.user.stsTokenManager.refreshToken, isSpectra: true, isContract: false, isPrimary: false });
                    history.push('Talntworx');
                    localStorage.setItem('userType','spectraWise')
                    setLoading(false);
                    message.destroy("verification");
                }
                else if (docSnap.data().user_type === "contractor_user") {
                    auth.signIn({ token: res.user.stsTokenManager.refreshToken, isSpectra: false, isContract: true, isPrimary: false });
                    const docRef = doc(db, 'contractor_users', localStorage.getItem("uid"));
                    const docRes = await getDoc(docRef);
                    if (docRes.exists()) {
                        localStorage.setItem('contractor_id', docRes.data().personal.contractor_id);
                        history.push('contract');
                        setLoading(false);
                        message.destroy("verification");
                    } else {
                        setLoading(false);
                        message.error({ content: "Can't get details", key: "verification" });
                    }
                }
                else if (docSnap.data().user_type === "primary_employer_user") {
                    auth.signIn({ token: res.user.stsTokenManager.refreshToken, isSpectra: false, isContract: false, isPrimary: true });
                    const docRef = doc(db, 'primary_employer_users', localStorage.getItem("uid"));
                    const docRes = await getDoc(docRef);
                    if (docRes.exists()) {
                        localStorage.setItem('primary_employer_id', docRes.data().personal.contractor_id);
                        history.push('primary');
                        setLoading(false);
                        message.destroy("verification");
                    } else {
                        setLoading(false);
                        message.error({ content: "Can't get details", key: "verification" });
                    }
                }
                else {
                    setLoading(false);
                    message.error({ content: 'something went wrong', key: "verification" });
                }

            } else {
                // doc.data() will be undefined in this case
                // alert('user doesn`t exist');
                setLoading(false);
                message.error({ content: 'user doesn`t exist', key: "verification" });
                console.log("No such document!");
            }

        }).catch(err =>{
            setLoading(false);
            message.error({ content: 'Invaild OTP,Please enter the valid OTP', key: "verification" });
            console.log(err)});
    }


    const handleChange = (evt, index) => {

        if (evt.keyCode === 8) {
         setotp(pre => pre.map((item, i) => (i === index) ? "" : item));
         if (evt.currentTarget.previousElementSibling){
             evt.currentTarget.previousElementSibling.focus();
         }
            return;
        }
        if (isNaN(evt.key)) return false;
        setotp(pre => pre.map((item, i) => (i === index) ? evt.key : item));
        //focusing on the next input//

        if (evt.currentTarget.nextSibling) {
            evt.currentTarget.nextSibling.focus();
        }
        // console.log('the value in the OTP state is :', otp)
    };

    const validateAsNumber = (e) => {
        if (e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 13) return true;
        if (isNaN(e.key)) e.preventDefault();
        else return true;
        // if (e.keyCode === 8 || e.keyCode === 46
        //     || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 13 || e.keycode > 96 || e.keycode < 105) {
        //     return true;
        // }
        // else if (e.keyCode < 48 || e.keyCode > 57) {
        //     e.preventDefault();
        // }
    }

    const handlePhoneNumberChange = e => {
        setphoneNumber(e.target.value)
        // console.log(phoneNumber)

    };

    return (
        <div>
            {/* <div id="sign-in-button"></div> */}
            <div className='loginPageContainer' >
                <div className='loginPageLeft'>
                    <h3 className='loginPageHeader'>TALNTX</h3>
                    {otpStatus === 'enterPhoneNumber' ? <>  <div className='loginPageNotificationPage'>
                        <h1 className='subHeader'>Enter your mobile number</h1>
                        <p>You will recieve a verification code</p>
                    </div>
                        <form onSubmit={(data) => {
                            data.preventDefault();
                            sendOTP();
                        }}>

                            <div className='phoneInput'>
                                <div className='selectCountryCode'>
                                    <div className="countryCode" id="countryCode">
                                        <p >+91</p>
                                    </div>
                                    <input
                                        className='inputPhoneNumber'
                                        value={phoneNumber}
                                        required
                                        maxLength='10'
                                        autoFocus
                                        type='tel'
                                        onKeyDown={validateAsNumber}
                                        onChange={handlePhoneNumberChange} />
                                </div>

                            </div>
                            <button className={loading ? "phoneNumberSubmitButton disabled" : 'phoneNumberSubmitButton'} href='/otpValidation' value='submit' type='submit' id='sign-in-button' >Next {loading && <img src={loadingIcon} style={{ width: "1.5rem" }} />}</button>
                        </form>
                    </> : <>
                        <div className='otpPage'>
                            <div className='loginPageNotificationPage' maxLength="1">
                                <h1>Enter verification code </h1>
                                <p>We sent a verification code to {`+91 ${phoneNumber}`}</p>
                            </div>

                            <form onSubmit={(e) => {
                                e.preventDefault();
                                confirmCode();

                            }}>
                                <div className='otpPage loginPagePhoneNumber'>
                                    {
                                        otp.map((item, index) => {
                                            return (
                                                <input
                                                    className='otpInput'
                                                    maxLength='1'
                                                    type='text'
                                                    key={index}
                                                    autoFocus={index === 0 ? true : false}
                                                    required
                                                    value={item}
                                                    onChange={() => null}
                                                    onKeyUp={(e) => { handleChange(e, index) }}
                                                    onFocus={(e) => { e.target.select() }}
                                                />
                                            )
                                        })
                                    }
                                </div>

                                <div className='resendingLinks'><a href='#a' id='sign-in-button' onClick={() => sendOTP()} >Send the code again</a></div>
                                <div className='resendingLinks'><a href='#a' onClick={() => setotpStatus("enterPhoneNumber")} >Change the phone number</a></div>

                                <button className={loading ? "phoneNumberSubmitButton disabled" : 'phoneNumberSubmitButton'} type='submit' >Login {loading && <img src={loadingIcon} style={{ width: "1.5rem" }} />}</button>
                            </form>
                        </div>
                    </>}
                </div>
                <div className='loginPageRight'>
                    <div><img src={loginPicture} className='loginPagePicture' alt='home background' /></div>
                </div>
            </div>
        </div>
    );
}

export default MobileNumber;